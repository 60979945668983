import React from 'react';
import { AnnouncementList } from 'components';

/**
 * 公告頁面
 */

export const AnnouncementPage = () => {
  return (
    <AnnouncementList />
  );
};
