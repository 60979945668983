import styled from 'styled-components';

export const UiLogin = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
`;

export const UiLoginBox = styled.div`
	position: relative;
	margin: auto;
	padding: 1rem 3rem 2rem;
	width: 100%;
	max-width: 650px;
	text-align: center;
	background: #fff;
	border-radius: 10px 10px 10px 10px;
	box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
`;


export const UiTitle = styled.h2`
	font-size: 2.4rem;
`;

export const UiButtonBox = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 0 4rem;

	& > * + * {
		margin-top: 2rem;
	}

	& > * {
		width: 100%;
		max-width: 300px;
	}
`;

export const UiButton = styled.div`
	padding: 0.5rem 0;
	font-size: 1.8rem;
	color: #fff;
	background-color: #0f62ff;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.35s;
	cursor: pointer;

	&:hover {
		background-color: #6096ff;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}
`;

export const UiLoginButton = styled.div`
	padding: 0.5rem 0;
	font-size: 1.8rem;
	color: #333;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.35s;
	cursor: pointer;

	&:hover {
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}
`;
