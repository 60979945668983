import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiRadioGroup, Uilabel, UiRadioList } from './RadioGroup.style';
import { Radio } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * Radio
 */

export const RadioGroup = ({
  data = [],
  name,
  onChange,
  value = '',
  defaultValue,
  reset,
  label,
}) => {
  const [{ radioValue }, setState] = useSetState({
    radioValue: '',
  });

  const onChangeHandler = e => {
    const { value } = e.target;
    setState({
      radioValue: value
    });
    onChange({ [name]: value });
  };

  useEffect(() => {
    setState({
      radioValue: ''
    });
  }, [reset]);

  useEffect(() => {
    if (value === '' || typeof value === 'object') return;
    setState({
      radioValue: value
    });

    onChange({ [name]: value });
  }, [data]);
  return (
    <UiRadioGroup>
      {
        label &&
        <Uilabel>{label}</Uilabel>
      }
      <UiRadioList>
        {
          data.map(item => (
            <Radio
              key={item.value}
              value={item.value}
              label={item.name}
              name={name}
              checked={radioValue === item.value}
              onCheck={onChangeHandler}
            />
          ))
        }
      </UiRadioList>

    </UiRadioGroup>
  );
};

RadioGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  reset: PropTypes.bool,
};


