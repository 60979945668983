import React, { useEffect, useCallback } from 'react';
import { ReportContainer, Select, RadioGroup, Graph, StickyTable, AutoComplete, Icon } from 'components';
import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST, RADIOLIST } from 'constants/index';
import { useModal } from 'utils/hooks/useModal';
import { useFormatterReport } from 'utils/hooks/useFormatterReport';
import { UiGraphRemind, UiGraphRemindRow } from './SingleSubjectExtent.style';

/**
 * 各科報表
 */


// eslint-disable-next-line max-len
const alertStr = '<table> <thead> <tr> <td>難度指數</td> <td>題目評鑑</td> </tr> </thead> <tbody> <tr> <td>難度<=0.35</td> <td>題目較容易</td> </tr> <tr> <td>0.35 < 難度 <= 0.65</td> <td>題目難度適中</td> </tr> <tr> <td>難度 > 0.65 </td> <td>題目難度較高</td> </tr> </tbody> </table>';

export const SingleSubjectExtent = () => {

  const { setModal } = useModal();
  const [{
    educationSelected,
    subject,
    examType,
    school,
    schoolYear,
    schoolID,
    grade,
    classNo,
    group,
    subjectClassOption,
    searchCriteria,
    remind,
  }, setState] = useSetState({
    educationSelected: '',
    subject: '',
    examType: '',
    school: '',
    schoolYear: '',
    schoolID: '',
    grade: '',
    classNo: '',
    group: '',
    subjectClassOption: '',
    searchCriteria: {},
    remind: '',
  });

  const {
    formatterGraphData,
    formatterSubAbilityTableData,
  } = useFormatterReport();

  const [{
    selections,
    subDifficultyData,
  }, {
    getReportSeletions,
    getSubDifficulty,
  }] = useReport();

  //取得radio資料（以後可能多選用selectGruop）
  const getDataFromRadioListHandler = data => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data)
    });
  };

  const onGradeChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setClassListOptions(value);
  };

  const setClassListOptions = (chooseGrade) => {
    if (selections.class[chooseGrade]) {
      const nextClassList = selections.class[chooseGrade].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        classListOption: nextClassList
      });
    } else {
      setState({
        classListOption: [],
      });
    }
  };


  const onChangeSubjectHandler = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setSubjectClassOptions(value);
  };

  const setSubjectClassOptions = (chooseSubject) => {
    if (selections.subjectClass[chooseSubject]) {
      const nextClassList = selections.subjectClass[chooseSubject].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        subjectClassOption: nextClassList
      });
    } else {
      setState({
        subjectClassOption: [],
      });
    }
  };

  //基礎篩選（學年度...）
  const onChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      searchCriteria: Object.assign(searchCriteria, data),
      [key]: value,
    });
  };

  const onChangeSchool = value => {
    setState({
      school: value.school,
      schoolID: value.school,
      searchCriteria: Object.assign(searchCriteria, { schoolID: value.school }),
    });
  };



  const getReport = async (payload) => {
    await getSubDifficulty(payload);
  };


  const selectGroup = useCallback(() => {
    const { education, schools, schoolSelection, class: classList, subjectClass } = selections;
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            selectName='educationSelected'
            options={selections.education}
            value={educationSelected}
            onChange={onChangeHandler}
          />
          {educationSelected &&
            <AutoComplete
              list={educationSelected ? schools[educationSelected] : []}
              label="選擇學校"
              name="school"
              onChange={onChangeSchool}
            />}
          {
            school &&
            <>
              <Select
                label={SELECTLIST.SCHOOLYEAR.countyLabel}
                options={schoolSelection[school].schoolYear}
                selectName="schoolYear"
                onChange={onChangeHandler}
              />
              <Select
                label="年級"
                options={schoolSelection[school].grade}
                selectName="grade"
                onChange={onChangeHandler}
              />
              <Select
                label="科目"
                options={schoolSelection[school].subject}
                selectName="subject"
                onChange={onChangeHandler}
              />
              <Select
                label={SELECTLIST.EXAMTYPE.label}
                options={schoolSelection[school].examType}
                selectName="examType"
                onChange={onChangeHandler}
              />
            </>
          }
        </>
      );
    }
    if (classList) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            selectName={SELECTLIST.GRADE.name}
            options={selections.grade}
            value={subject}
            onChange={onGradeChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            selectName={SELECTLIST.SUBJECT.name}
            options={selections.subject}
            value={subject}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (subjectClass) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />

          <Select
            label={SELECTLIST.SUBJECT.label}
            selectName={SELECTLIST.SUBJECT.name}
            options={selections.subject}
            value={subject}
            onChange={onChangeSubjectHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
          {
            (subjectClassOption && subjectClassOption.length > 0) &&
            <Select
              label={SELECTLIST.CLASSNO.label}
              selectName={SELECTLIST.CLASSNO.name}
              options={subjectClassOption}
              value={classNo}
              onChange={onChangeHandler}
            />
          }

        </>
      );
    }
    return (
      <>loading...</>
    );

  }, [selections, educationSelected, school, subject]);

  useEffect(() => {
    const payload = {
      reportType: 'SubDifficulty'
    };
    getReportSeletions(payload);
  }, []);

  useEffect(() => {
    const { education, class: classList, subjectClass } = selections;
    if (education) {
      if (
        subject === '' ||
        examType === '' ||
        schoolYear === '' ||
        schoolID === '' ||
        grade === ''
      ) {
        return;
      }
      getReport(searchCriteria);
    }
    if (classList) {
      if (
        grade === '' ||
        examType === '' ||
        schoolYear === '' ||
        subject === ''
      ) {
        return;
      }
      getReport(searchCriteria);
    }
    if (subjectClass) {
      if (
        subject === '' ||
        examType === '' ||
        schoolYear === '' ||
        classNo === '') {
        return;
      }
      getReport(searchCriteria);
    }
  }, [schoolID, examType, schoolYear, subject, grade, classNo]);

  const radioGroup = useCallback(() => {
    const { education, class: classList, schoolSelection } = selections;
    //縣籍
    if (education && grade && school) {
      return (
        <RadioGroup
          name="group"
          data={schoolSelection[school].class[grade]}
          onChange={onChangeHandler}
          label={RADIOLIST.CLASS.label}
        />
      );
    }
    if (classList && grade) {
      return (
        <RadioGroup
          name="group"
          data={classList[grade]}
          onChange={getDataFromRadioListHandler}
          label={RADIOLIST.CLASS.label}
        />
      );
    }
  }, [selections, grade]);


  const dataGroup = () => {
    const { title, className: groupList, dataList, resource } = subDifficultyData;
    const { schoolSelection, subject: subjectList } = selections;

    const schema = ['班級', ...title.map((item, index) => `題${index + 1}`)];
    const subjectName = schoolID === '' ? subjectList.filter(item => item.value === subject)[0].name :
      schoolSelection[school].subject.filter(item => item.value === subject)[0].name;
    const { data, pv, uv } = formatterGraphData({
      reportData: subDifficultyData,
      title,
      groupList,
      dataList,
      reportTypeString: '難易度',
      reportRoleString: `全校${subjectName}`,
      group
    });

    const nextTableData = formatterSubAbilityTableData({
      reportData: subDifficultyData,
      title,
      groupList,
      dataList,
      reportTypeString: '難易度',
      reportRoleString: `全校${subjectName}`,
      groupName: '班級',
      group,
      subHeader: schoolID === '' ? [] : resource,
      subHeaderTitle: schoolID === '' ? '' : '出處'
    });
    return (
      <>
        <Graph.BarLine data={data} pv={pv} uv={uv} />
        <UiGraphRemindRow>
          <UiGraphRemind
            onClick={() => {
              setModal({
                title: '難易度提示',
                html: alertStr,
                showCancelButton: false,
                confirmEvent: () => { },
              });
            }}
          >
            <Icon name='error' color='#f00' />
            <div>難易度提示</div>
          </UiGraphRemind>
        </UiGraphRemindRow>
        <StickyTable.FixedTitle
          schema={schema}
          data={nextTableData.data}
          isSubjectTable={true}
          headerCount={schoolID === '' ? 2 : 3}
        />
      </>
    );
  };

  const teacherDataGroup = () => {
    const { average, classCorrectRate, className, schoolCorrectRate } = subDifficultyData;
    const tableSchema = ['班級', '平均', ...schoolCorrectRate.map((item, index) => `題${index + 1}`)];
    let newtableData = [];
    let nextGraphData = [];
    const uv = `${className}難易度`;
    let pv = '全校難易度';
    if (Object.keys(subDifficultyData).length > 0 && classNo !== '') {
      const nextClassCorrectRate = classCorrectRate.map(item => `${item}%`);
      newtableData = [{
        catelogey: uv,
        list: [`${average}%`, ...nextClassCorrectRate],
      }];

      nextGraphData = schoolCorrectRate.map((item, index) => {
        return {
          name: (index + 1),
          [uv]: subDifficultyData.classCorrectRate[index],
          [pv]: item,
        };
      });
      return (
        Object.keys(subDifficultyData).length > 0 &&
        <>
          <Graph.BarLine data={nextGraphData} pv={pv} uv={uv} />
          <UiGraphRemindRow>
            <UiGraphRemind
              onClick={() => {
                setModal({
                  title: '難易度提示',
                  html: alertStr,
                  showCancelButton: false,
                  confirmEvent: () => { },
                });
              }}
            >
              <Icon name='error' color='#f00' />
              <div>難易度提示</div>
            </UiGraphRemind>
          </UiGraphRemindRow>
          <StickyTable.FixedTitle canSort={false} schema={tableSchema} data={newtableData} headerCount={1} />
        </>
      );
    }
  };


  useEffect(() => {
    const { education, class: classList, subjectClass } = selections;
    //縣籍
    if (education) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (classList) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (subjectClass) {
      setState({
        remind: ''
      });
    }

  }, [selections]);


  return (
    <ReportContainer
      title="各科題目難度"
      remind={remind}
      selectGroup={selectGroup()}
      radioChildren={radioGroup()}
    >
      {(subDifficultyData.title?.length > 0 && group !== '') && dataGroup()}
      {(subDifficultyData.classCorrectRate?.length > 0 && classNo !== '') && teacherDataGroup()}
    </ReportContainer>
  );
};
