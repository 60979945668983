import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiPagination, UiText, UiInputBase } from './Pagination.style';

/**
 * 分頁組件
 */

export const Pagination = ({
  total = 10,
  limit = 10,
  page = 1,
  onChange = () => { },
}) => {
  const [{ value, totalPage }, setState] = useSetState({
    value: 1,
    totalPage: Math.ceil(total / limit)
  });

  const onArrowLeftHandle = () => {
    const num = value - 1;
    if (num === 0 || num < 0) return;
    setState({ value: num });
  };

  const onArrowRighthandle = () => {
    const num = value + 1;
    if (num > totalPage) return;
    setState({ value: num });
  };

  const onChangevalueHandle = e => {
    const num = e.target.value;
    setState({ value: num });
  };

  useEffect(() => {
    onChange(value);
  }, [value]);


  useEffect(() => {
    setState({
      value: page
    });
  }, [page]);

  useEffect(() => {
    setState({
      totalPage: Math.ceil(total / limit) <= 0 ? 1 : Math.ceil(total / limit)
    });
  }, [limit, total]);

  return (
    <UiPagination>
      <Icon name='arrow_left' color='#333' sizetype='normal' onClick={onArrowLeftHandle} />
      <UiInputBase defaultValue={1} value={value} inputProps={{ maxLength: 2 }} onChange={onChangevalueHandle} />
      <UiText>of</UiText>
      <UiText>{totalPage}</UiText>
      <Icon name='arrow_right' color='#333' sizetype='normal' onClick={onArrowRighthandle} />
    </UiPagination>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
};


