import styled from 'styled-components';
import {
  Dialog as MatDialog,
  DialogContent as MatDialogContent,
  DialogContentText as MatDialogContentText,
} from '@material-ui/core';

export const UiModal = styled(MatDialog)`
	& > div > div {
		padding: 1.25rem;
		width: ${({ type }) => type === 'question' ? '85rem' : '42.5rem'};
		max-width: 100%;
		border-radius: 0.3125rem;
	}
`;

export const UiMatDialogContent = styled(MatDialogContent)`
	/* text-align: center; */
	text-align: ${({ type }) => type === 'question' ? 'left' : 'center'};

	& > ul {
		display: inline-block;
		padding-left: 1.5rem;
		text-align: left;
		list-style: decimal;

		& > li > span {
			color: ${({ theme }) => theme.text.color.error};
		}
	}
`;

export const UiMatDialogContentText = styled(MatDialogContentText)`
	font-size: 1.5rem;
	text-align: center;
	color: #595959;

	& > span {
		color: #f00;
	}
`;
export const UiTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 8px 24px;
	color: ${({ theme }) => theme.colorTheme.primary};

	& > h1 {
		display: flex;
		align-items: center;
		margin: 0;
	}
`;

export const UiTextTitle = styled(UiTitle)`
	justify-content: center;
	color: ${({ theme }) => theme.text.color.error};
`;

export const UiTitleRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #00923f;

	& > *:not(:last-child) {
		margin-right: 1rem;
	}
`;
export const UiFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 8px 24px;
	color: #00923f;

	& > h1 {
		display: flex;
		align-items: center;
		margin: 0;
	}
`;
