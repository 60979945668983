import styled from 'styled-components';

export const UiExamAnalysis = styled.div`
	margin: auto;
	padding: 1rem;
	width: 95%;
`;

export const UiButtonBox = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 2rem auto;
	width: 100%;

	& > * + * {
		margin-left: 2rem;
	}
`;
