
import Cookies from 'js-cookie';

export const useDownload = () => {
  const blobDownload = (url, filename, type, params, callback) => {
    let xhr = new XMLHttpRequest();
    xhr.open(type, url, true);
    xhr.responseType = 'blob';
    const cookieToken = Cookies.get('tikuHualien');
    const token = cookieToken ? JSON.parse(cookieToken).token : '';
    xhr.setRequestHeader('Authorization', token);
    const requestOnload = () => {
      xhr.onload = function (e) {
        if (this.status === 200) {
          const blob = this.response;
          const a = document.createElement('a');
          document.body.appendChild(a);
          const blobUrl = window.URL.createObjectURL(blob);
          a.href = blobUrl;
          a.download = filename;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
            callback && callback();
          }, 0);

        }else{
          callback && callback({
            code:this.status,
            message:'下載失敗'
          });

        }
      };
    };
    if (type === 'POST') {
      xhr.setRequestHeader('Content-type', 'application/json');
      requestOnload();
      xhr.send(JSON.stringify(params));
    }
    if (type === 'GET') {
      requestOnload();
      xhr.send();
    }
  };
  return { blobDownload };
};

export default useDownload;
