import styled from 'styled-components';

export const UiFooter = styled.div`
	margin: 1rem auto;
	max-width: 95%;
	font-size: 1.5rem;
	text-align: center;
`;

export const UiHelpOption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 0 auto 1.5rem;
	width: 75%;

	& > button {
		padding: 5px 25px;
	}
`;

export const UiNumber = styled.div``;
