import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getReview as getReviewApi,
  getReviewDetail as getReviewDetailApi,
  saveReview as saveReviewApi,
  getCheckList as getCheckListApi,
  getCheckDetail as getCheckDetailApi
} from 'services/api/review';
import { useAlert } from 'utils/hooks/useAlert';

const GET_REVIEW_LIST = 'GET_REVIEW_LIST';
const GET_REVIEW_DETAIL = 'GET_REVIEW_DETAIL';
const SAVE_REVIEW = 'SAVE_REVIEW';
const GET_CHECK_LIST = 'GET_CHECK_LIST';

const initState = {
  reviewList: {
    data: []
  },
  reviewDetail: {},
  isLoading: false,
  checkList: {
    data: []
  },
};

export const useReview = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { reviewList, reviewDetail, checkList, isLoading } = useSelector(state => state.review);

  const getReviewListAction = createAction(GET_REVIEW_LIST, async () => {
    const response = await getReviewApi();
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getReviewList = () => dispatch(getReviewListAction());

  const getCheckListAction = createAction(GET_CHECK_LIST, async () => {
    const response = await getCheckListApi();
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getCheckList = () => dispatch(getCheckListAction());

  const getReviewDetailAction = createAction(GET_REVIEW_DETAIL, async id => {
    const response = await getReviewDetailApi(id);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getReviewDetail = id => dispatch(getReviewDetailAction(id));


  const getCheckDetailAction = createAction(GET_REVIEW_DETAIL, async id => {
    const response = await getCheckDetailApi(id);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getCheckDetail = id => dispatch(getCheckDetailAction(id));

  const saveReivewAction = createAction(SAVE_REVIEW, async params => {
    const response = await saveReviewApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const saveReview = params => dispatch(saveReivewAction(params));


  return [
    {
      reviewList,
      reviewDetail,
      checkList,
      isLoading
    }, // state
    {
      getReviewList,
      getReviewDetail,
      getCheckList,
      getCheckDetail,
      saveReview,
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    GET_REVIEW_LIST_PENDING: state => ({
      ...state,
    }),
    GET_REVIEW_LIST_FULFILLED: (state, action) => ({
      ...state,
      reviewList: {
        data: action.payload
      }
    }),
    GET_REVIEW_LIST_REJECTED: state => ({
      ...state,
      reviewList: {
        data: []
      }
    }),
    GET_CHECK_LIST_PENDING: state => ({
      ...state,
    }),
    GET_CHECK_LIST_FULFILLED: (state, action) => ({
      ...state,
      checkList: {
        data: action.payload
      }
    }),
    GET_CHECK_LIST_REJECTED: state => ({
      ...state,
      reviewList: {
        data: []
      }
    }),
    GET_REVIEW_DETAIL_PENDING: state => ({
      ...state,
    }),
    GET_REVIEW_DETAIL_FULFILLED: (state, action) => ({
      ...state,
      reviewDetail: {
        ...action.payload
      }
    }),
    GET_REVIEW_DETAIL_REJECTED: state => ({
      ...state,
      reviewDetail: {}
    }),
    SAVE_REVIEW_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    SAVE_REVIEW_FULFILLED: state => ({
      ...state,
      isLoading: false
    }),
    SAVE_REVIEW_REJECTED: state => ({
      ...state,
      isLoading: false
    }),
  },
  initState
);

export default reducer;
