import styled from 'styled-components';

export const UiCheckBoxGroup = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const Uilabel = styled.h3`
	margin-right: 1rem;
`;
export const UiCheckBoxList = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex: 1;

	& > div {
		margin: 1rem;
		width: inherit;
	}
`;
