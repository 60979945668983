import React from 'react';
import { QuestionDetail } from 'components';

/**
 * 命題詳細
 */

export const QuestionDetailPage = () => {
  return (
    <QuestionDetail />
  );
};

