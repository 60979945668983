import React from 'react';
import { CommonLayout, Login } from 'components';

/**
 * 登入頁面
 */

export const LoginPage = () => {
  return (
    <CommonLayout>
      <Login />
    </CommonLayout>
  );
};
