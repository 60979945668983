import styled from 'styled-components';
import { Pagination as MatPagination } from '@material-ui/lab';


export const UiQuestionPagination = styled.div`
		display: flex;
		justify-content: center;
		margin-top: 2rem;
`;
export const UiMatPagination = styled(MatPagination)`
	ul {
		li {
			button.MuiPaginationItem-page.Mui-selected {
				color: #000;
				background-color: rgba(148, 255, 187, 0.3);
				border: 1px solid rgba(148, 255, 187, 0.9);
			}
		}
	}
`;
