import { Api } from 'services/api';
import { stringify } from 'query-string';


/**
 * 取得搜尋選項
 * type
 * CorrectRate-答對率
 * CorrectRateSUB-各科答對率
 */
export const getReportSeletions = async (params) => {
  const querystring = stringify(params);

  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/GetSeletions?${querystring}`);
  return response;
};

/**
 * 取得全科答對率報表
 */
export const getCorrectRate = async (params) => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/CorrectRate`, params);
  return response;
};



/**
 * 取得分項能力答對率報表
 */
export const getExamAbility = async (params) => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/ExamAbility`, params);
  return response;
};

/**
 * 取得各科答題分析
 */
export const getSubCorrectRate = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/SubCorrectRate`, params);
  return response;
};

/**
 * 取得全科試卷難易度
 */
export const getDifficulty = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/Difficulty`, params);
  return response;
};


/**
 * 取得各科試卷難易度
 */
export const getSubDifficulty = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/SubDifficulty`, params);
  return response;
};

/**
 * 取得全科試卷鑑別度
 */
export const getDiscrimination = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/Discrimination`, params);
  return response;
};

/**
 * 取得各科試卷鑑別度
 */
export const getSubDiscrimination = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/SubDiscrimination`, params);
  return response;
};

/**
 * 取得全科試卷信度
 */
export const getReliability = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/Reliability`, params);
  return response;
};


/**
 * 取得雙向細目表
 */
export const getCognitive = async params => {
  const response =
    await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Report/Cognitive`, params);
  return response;
};
