import styled from 'styled-components';

export const UiAutoComplete = styled.div`
	min-width: 250px;

	& > h3 {
		margin: 0;
		flex: 0.2;
		text-align: left;
	}

	& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
		padding: 4px;
		background-color: #fff;
		border: 1px solid #2a4865;
		border-radius: 5px;
	}

`;
