import React from 'react';
import { TitleContainer, Icon, Button, LoadingBackground, ProgressBar } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { useModal } from 'utils/hooks/useModal';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useAnswer } from 'store/answer';
import { useMember } from 'store/member';
import { useAlert } from 'utils/hooks/useAlert';
import {
  UiColorBlock,
  UiTitle,
  UiIconBox,
  UiIconItem,
  UiUploadFile,
} from './ImportAnswerCard.style';


/**
 * 答案卡匯入
 */

const icons = [
  {
    name: 'scanner',
    text: '1.老師可以用影印事務機將卡片掃描成jpg檔案'
  },
  {
    name: 'camera',
    text: '2.老師可以用webcam拍照'
  },
  {
    name: 'devices',
    text: '3.老師可以用手機拍照後傳輸檔案到電腦'
  },
];

export const ImportAnswerCard = () => {
  const history = useHistory();
  const { examID } = useParams();
  const [{ memberInfo }] = useMember();
  const { setAlert } = useAlert();
  const { setModal } = useModal();
  const [{ isLoading }, {
    addAnswer,
    getReadCard,
    getClassResult,
  }] = useAnswer();
  const {
    upload,
  } = useFirebaseStorage();
  const [{
    fileLength,
    waitingUploadFileLength,
    upLoading,
  }, setState] = useSetState({
    fileLength: 0,
    waitingUploadFileLength: 0,
    upLoading: false
  });

  let n = 0;
  const onUploaded = async (file, folderName) => {
    const fileName = file.name;
    const uploadPath = `answer-card/${folderName}/${fileName}`;
    // await deleteFile(uploadPath);
    const response = await upload(uploadPath, file);
    const { status } = response;
    if (status) {
      //更改傳參
      const readCardPayload = {
        client: 'tikuHualien',
        data: {
          examID,
          folderID: folderName,
          fileName,
        }
      };
      const { value: parsingStatus } = await getReadCard(readCardPayload);
      if (parsingStatus === 'DONE') {
        n++;
        setState({
          waitingUploadFileLength: n
        });
      }
      return fileName;
    } else {
      return false;
    }
  };
  const onSubmitHandler = async e => {
    const files = Array.from(e.target.files);
    if (files.length <= 0) return;
    const newFiles = files.filter(file => file.name !== '.DS_Store');
    const payload = {
      client: 'tikuHualien',
      data: {
        examID,
        user: memberInfo.id
      }
    };
    const { value: newFolderName } = await addAnswer(payload);
    if (!newFolderName) {
      setAlert('創建上傳資料夾失敗', 'error');
      setState({
        upLoading: false,
        waitingUploadFileLength: 0,
        fileLength: 0,
      });
      return;
    }
    setState({
      waitingUploadFileLength: 0
    });

    let newErrorFile = [];
    newFiles.forEach((file) => {
      const fileName = file.name;
      if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName)) {
        newErrorFile.push(fileName);
      }
    });
    setState({
      errorFile: newErrorFile
    });
    if (newErrorFile.length > 0) {
      let errorStr = '';
      newErrorFile.forEach(file => {
        errorStr += `<li>${file}</li>`;
      });
      setModal({
        html: `<div>以下圖片解析失敗，請重新檢查並再次上傳</div><ul>${errorStr}</ul`,
        showCancelButton: false,
      });
      return;
    }
    setState({
      fileLength: newFiles.length
    });
    // // await deleteFolder(folderName);

    await Promise.all(newFiles.map(async file => {
      setState({
        upLoading: true,
      });
      const fileName = await onUploaded(file, newFolderName);
      if (!fileName) throw fileName;
      return fileName;
    })).then(async fileDatas => {
      const checkReadCardPayload = {
        client: 'tikuHualien',
        data: newFolderName
      };
      const { value: analyzeResult } = await getClassResult(checkReadCardPayload);
      if (analyzeResult.success) {
        setAlert('匯入畫卡成功', 'success');
        history.push(`${MAIN_PATH.CHECK_ANSWERCARD}/${examID}`);
      } else {
        let nextErrorStr = '';
        analyzeResult.error[0].files.forEach(file => {
          nextErrorStr += `<li>${file}</li>`;
        });
        setModal({
          html: `<div>以下圖片解析失敗，請重新檢查並再次上傳</div><span>${analyzeResult.error[0].desc}</span><ul>${nextErrorStr}</ul`,
          showCancelButton: false,
        });
        setState({
          waitingUploadFileLength: 0,
          fileLength: 0,
          upLoading: false
        });
      }
    }).catch(() => {
      // alert('图片类型必须是.gif,jpeg,jpg,png中的一种');
      console.log('WTFFFFFFFFFFF');
    });
  };


  return (
    <TitleContainer title="畫卡匯入" titleIcon="move_to_inbox">
      <UiColorBlock>
        <UiTitle>說明：建議老師的掃描方式</UiTitle>
        <UiIconBox>
          {
            icons.map((item, index) =>
              <UiIconItem key={index}>
                <Icon name={item.name} color="#fff" size="8rem" />
                {item.text}
              </UiIconItem>
            )
          }
        </UiIconBox>
      </UiColorBlock>
      <UiTitle color="#f00">
        <div>1. 請將全班的作答卷放入同一個資料夾後上傳</div>
        <div>2. 拍照注意事項：避免陰影、反光、過度歪斜角度</div>
        <div>3. 每張答案卡（包含四個角落的黑色方格）都必須拍攝完整</div>
      </UiTitle>
      <UiUploadFile>
        <Button
          buttoncolor='orange'
          loading={upLoading}
          icon="movie_creation"
        >
          <label htmlFor='upload'>匯入圖檔資料夾</label>
        </Button>
        <input
          directory=""
          webkitdirectory=""
          mozdirectory=""
          allowdirs=""
          multiple
          type="file"
          id='upload'
          onChange={onSubmitHandler}
        />
        <ProgressBar barHeight={50} startRate={waitingUploadFileLength} endRate={fileLength} />
        <div>匯入圖片：{waitingUploadFileLength}/{fileLength}</div>
      </UiUploadFile>
      <LoadingBackground isLoading={isLoading} />
    </TitleContainer>
  );
};
