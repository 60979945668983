import React from 'react';
import PropTypes from 'prop-types';
import { Icon, QuestionItem } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

import {
  UiExpandableContainer,
  UiExpandableBox,
  UiExpandableTitle,
  UiIcon,
  UiExpandableContent,
} from './ExpandableContainer.style';


/**
 * 可展開的列表box
 */

export const ExpandableContainer = ({ list, onChangeOrderby = () => { }, onDelete = () => { } }) => {
  const [{
    active
  }, setState] = useSetState({
    active: 0
  });
  const openContent = number => {
    setState({
      active: active === number ? null : number
    });
  };
  const onChangeOrderbyHandelr = (data, oldNumber, newNumber) => {
    onChangeOrderby(data, oldNumber, newNumber);
  };

  return (
    <UiExpandableContainer>
      {
        list.map((item, index) => {
          return (
            <UiExpandableBox key={index}>
              <UiExpandableTitle number={index} onClick={() => openContent(index)}>
                {item.name}
                <UiIcon>
                  <Icon name="keyboard_arrow_down" sizetype="large" />
                </UiIcon>
              </UiExpandableTitle>
              <UiExpandableContent isOpen={active === index ? true : false}>
                {
                  item.questionInfo.map((li, index) => {
                    return (
                      <QuestionItem
                        key={li.id}
                        showAllocation={true}
                        data={li}
                        number={index + 1}
                        onDelete={onDelete}
                        onChangeOrderby={(oldNumber, newNumber) => onChangeOrderbyHandelr(item, oldNumber, newNumber)}
                      />
                    );
                  })
                }
              </UiExpandableContent>
            </UiExpandableBox>
          );
        })
      }
    </UiExpandableContainer>
  );
};

ExpandableContainer.propTypes = {
  list: PropTypes.array,
  onChangeOrderby: PropTypes.func,
  onDelete: PropTypes.func,
};


