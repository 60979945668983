import React from 'react';
import { QuestionList } from 'components';

/**
 * 命題列表頁面
 */

export const QuestionListPage = () => {
  return (
    <QuestionList />
  );
};
