// https://github.com/diegohaz/arc/wiki/API-service
import { stringify } from 'query-string';
import Cookies from 'js-cookie';
import { COMMON_PATH } from 'routes/routesConfig/constants';

const errorCode = {
  '500': '伺服器錯誤，請稍後在試！',
  '404': '伺服器連結錯誤，請稍後在試！',
  '400': '客戶端參數錯誤，請稍候在試！',
};

const checkStatus = response => {
  if (!response.ok) response.errorMessage = `${errorCode[response.status]} errorCode ${response.status}`;
  return response;
};
// const parseJSON = response => response.json();

const formatResponse = response => {
  response.isSuccess = (response.status === 'success' || response.code === 'SUCCESS' || response.ok);
  return response;
};

const catchError = error => {
  if (error.message === 'Failed to fetch') {
    if (process.env.REACT_APP_NODE_ENV !== 'release') return;
    Cookies.remove('tikuHualien', { token: '', userName: '', schoolName: '' });
    window.location.href = `${window.location.origin + COMMON_PATH.LOGIN}`;
  }
};

const parseSettings = ({ method = 'GET', data, ...otherSettings } = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json;charset=UTF-8',
    ...otherSettings.headers,
  };
  const settings = Object.assign({
    body: data ? JSON.stringify(data) : undefined,
    method,
    headers,
  });
  return settings;
};

const parseEndpoint = (endpoint, params) => {
  const url = endpoint.indexOf('http') === 0 ? endpoint : process.env.REACT_APP_API_DOMAIN + endpoint;
  const querystring = params ? `?${stringify(params)}` : '';
  return `${url}${querystring}`;
};

const api = {};

api.request = async (endpoint, { params, ...settings } = {}) =>
  await fetch(parseEndpoint(endpoint, params), parseSettings(settings))
    .then(checkStatus)
    .then(formatResponse)
    .catch(catchError);
['GET'].forEach(method => {
  api[method] = (endpoint, settings) => api.request(endpoint, { method, ...settings });
});
['POST', 'PUT', 'PATCH', 'DELETE'].forEach(method => {
  api[method] = (endpoint, data, settings) => api.request(endpoint, { method, data, ...settings });
});

api.create = (settings = {}) => ({
  settings,

  setToken(token) {
    if (token) {
      this.settings.headers = {
        ...this.settings.headers,
        Authorization: `${token}`,
      };
    }
  },

  unsetToken() {
    delete this.settings.headers.Authorization;
  },

  request(endpoint, settings) {
    return api.request(endpoint, Object.assign({}, this.settings, settings));
  },

  post(endpoint, data, settings) {
    return this.request(endpoint, { method: 'POST', data, ...settings });
  },

  get(endpoint, settings) {
    return this.request(endpoint, { method: 'GET', ...settings });
  },

  put(endpoint, data, settings) {
    return this.request(endpoint, { method: 'PUT', data, ...settings });
  },

  patch(endpoint, data, settings) {
    return this.request(endpoint, { method: 'PATCH', data, ...settings });
  },

  delete(endpoint, data, settings) {
    return this.request(endpoint, { method: 'DELETE', data, ...settings });
  },
});

export const Api = api.create();

export default api;
