import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { UiDropContainer, UiDropItem } from './DropDnd.style';


/**
 * 拖曳組件
 */

export const DropDnd = ({ total, list, onChange = () => { }, DropItem, onSocreChange = () => { }, options = [] }) => {
  return (
    <DragDropContext
      onDragEnd={result => {
        const { source, destination } = result;
        if (!destination) {
          return;
        }

        let arr = Array.from(list);
        const [remove] = arr.splice(source.index, 1);
        arr.splice(destination.index, 0, remove);

        onChange(arr);
      }}
    >
      <Droppable droppableId="questions">
        {provided => (
          <UiDropContainer ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((t, i) => (
              <Draggable draggableId={t.id} index={i} key={t.id}>
                {p => (
                  <UiDropItem
                    ref={p.innerRef}
                    {...p.draggableProps}
                    {...p.dragHandleProps}
                    key={t.id}
                  >
                    <DropItem total={total} itemData={t} index={i} onChangeHandler={onSocreChange} options={options} />
                  </UiDropItem>
                )}
              </Draggable>
            ))}
          </UiDropContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DropDnd.propTypes = {
  total: PropTypes.number,
  list: PropTypes.array,
  onChange: PropTypes.func,
  DropItem: PropTypes.func,
  options: PropTypes.array,
  onSocreChange: PropTypes.func
};


