import React, { useEffect } from 'react';
import { TitleContainer, AnswerCardTab, ColorBlockTitle, Button } from 'components';
import { useAnswer } from 'store/answer';
import { useSetState } from 'utils/hooks/useSetState';
import { objectMapCovertToArray } from 'utils/object';
import { useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { UiButtonBox } from './CheckAnswerCard.style';
import { useAlert } from 'utils/hooks/useAlert';
import { useModal } from 'utils/hooks/useModal';
/**
 * 檢查答案卡
 */
export const CheckAnswerCard = () => {

  const { setAlert } = useAlert();
  const { setModal } = useModal();
  const history = useHistory();
  const [{ answerData }, setState] = useSetState({
    answerData: []
  });
  const [{ analyzeResult }, { submitClassResult }] = useAnswer();

  const changeDataHandler = (data) => {
    setState({
      answerData: data
    });
  };

  const submitResult = async () => {
    const { classCode, examID } = analyzeResult;
    const payload = {
      client: 'tikuHualien',
      data: {
        classCode,
        examID,
        studentAnswer: answerData
      }
    };
    const response = await submitClassResult(payload);
    if (response) {
      setAlert('上傳成績成功!', 'success');
      history.push(`${MAIN_PATH.EXAMSEARCH_LIST}`);
    } else {
      setAlert('上傳成績失敗!', 'error');
    }
  };

  const leftCheckAnswerCard = () => {
    setModal({
      html: '所做變更將不會儲存，確定要退出此頁面嗎？',
      confirmEvent: () => { history.push(MAIN_PATH.EXAMSEARCH_LIST); },
    });
  };

  useEffect(() => {
    if (Object.keys(analyzeResult).length === 0) {
      history.push(`${MAIN_PATH.EXAMSEARCH_LIST}`);
      return;
    }
    const newData = objectMapCovertToArray(analyzeResult.studentAnswer, (data, key) => {
      return data;
    });
    setState({
      answerData: newData
    });
  }, [analyzeResult]);

  return (
    <TitleContainer title="畫卡匯入" titleIcon="move_to_inbox">
      <ColorBlockTitle>{analyzeResult.examName}</ColorBlockTitle>
      {answerData.length > 0 && <AnswerCardTab data={answerData} changeData={changeDataHandler} />}
      <UiButtonBox>
        {/* todo  按鈕事件 */}
        <Button buttoncolor="orange" onClick={() => { leftCheckAnswerCard(); }}>放棄本次匯入紀錄</Button>
        <Button buttoncolor="orange" onClick={() => { submitResult(); }}>檢視完畢上傳成績</Button>
      </UiButtonBox>
    </TitleContainer>
  );
};
