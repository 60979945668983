import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiInput, Uilabel, UiMatFormHelperText } from './Input.style';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * input 組件
 */

export const Input = ({
  placeholder = '',
  value = '',
  label,
  type,
  name,
  errormsg,
  error = false,
  rows = 4,
  cols = 50,
  check,
  textcolor,
  maxlength = 500,
  onChange = () => { },
}) => {
  const [{ inputValue }, setState] = useSetState({
    inputValue: '',
  });
  const onChangeHandler = e => {
    const val = inputCheck(e.target.value);
    setState({ inputValue: val });
    onChange({ [name]: val });
  };
  useEffect(() => {
    if (value !== '') {
      const val = inputCheck(value);
      setState({ inputValue: inputCheck(val) });
      onChange({ [name]: inputCheck(val) });
    }
  }, [value]);
  const inputCheck = value => {
    const val = value.toString();
    let newValue;
    switch (check) {
      case 'number':
        newValue = numberCheck(val);
        break;
      default:
        newValue = value;
    }
    return newValue;
  };
  const numberCheck = val => {
    let newValue;
    newValue = val.replace(/[^\d.]/g, ''); //先把非數字的都替換掉，除了數字和.
    newValue = newValue.replace(/^\./g, ''); //必須保證第一個為數字而不是.
    newValue = newValue.replace(/\.{2,}/g, '.'); //保證只有出現一個.而沒有多個.
    newValue = newValue.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.'); //保證.只出現一次，而不能出現兩次以上
    return newValue;
  };
  return (
    <UiInput
      error={error}
      textcolor={textcolor}
    >
      {
        label &&
        <Uilabel>{label}</Uilabel>
      }
      {
        type === 'textarea' ?
          <textarea
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            name={name}
            value={inputValue}
            onChange={(e) => { onChangeHandler(e); }}
            maxLength={maxlength}
          /> :
          <input
            name={name}
            type={type}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => { onChangeHandler(e); }}
            maxLength={maxlength}
          />
      }

      {
        (errormsg && error) && (
          <UiMatFormHelperText>{errormsg}</UiMatFormHelperText>
        )
      }
    </UiInput>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'textarea']),
  errormsg: PropTypes.string,
  error: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  onChange: PropTypes.func,
  check: PropTypes.oneOf(['text', 'number']),
  textcolor: PropTypes.string,
  maxlength: PropTypes.number,
};


