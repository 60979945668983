import React from 'react';
import { Review } from 'components';

/**
 * 審查列表頁面
 */

export const ReviewPage = () => {
  return (
    <Review />
  );
};
