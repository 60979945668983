export const objectMap = (object, mapFn) => {
  return Object.keys(object).reduce(function (result, key) {
    result[key] = mapFn(object[key], key);
    if (result[key] === null) delete result[key];
    return result;
  }, {});
};

export const objectMapCovertToArray = (object, mapFn) => {
  return Object.keys(object).reduce(function (result, key, index) {
    const nextData = mapFn(object[key], key, index);
    if (nextData !== undefined) {
      result.push(nextData);
    }
    return result;
  }, []);
};

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};
