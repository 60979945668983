import { Api } from 'services/api';


export const getReview = async () => {
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Approval/ApprovalList`);
  return response;
};

export const getReviewDetail = async id => {
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Approval/QuestionInfo?ID=${id}`);
  return response;
};

export const saveReview = async params => {
  const response =
    await Api.put(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Approval/Update`, params);
  return response;
};

export const getCheckList = async () => {
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Approval/CheckedList`);
  return response;
};

export const getCheckDetail = async id => {
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Approval/CheckedInfo?ID=${id}`);
  return response;
};
