import React from 'react';
import { SingleSubject } from 'components';

/**
 * 各科答題分析頁面
 */

export const SingleSubjectPage = () => {
  return (
    <SingleSubject />
  );
};
