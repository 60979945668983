import React from 'react';
import PropTypes from 'prop-types';
import { UiTextStroke } from './TextStroke.style';


/**
 * 有邊的文字！！！
 */

export const TextStroke = ({ text }) => {
  return (
    <UiTextStroke>{text}</UiTextStroke>
  );
};

TextStroke.propTypes = {
  text: PropTypes.string
};


