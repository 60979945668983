import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { UiUploadBox, UiUploadButton, UiPrompt } from './UploadButton.style';


/**
 * 上傳檔案的按鈕
 */

const defaultMessage = '請上傳此題mp3音檔，每題只接受一個音檔若重複上傳，只會保留最後一次上傳檔案。';
const noop = () => { };

const MatTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(32),
    border: '1px solid #dadde9',
    lineHeight: 1.5,
  },
}))(Tooltip);

export const UploadButton = ({
  text = '上傳音檔',
  message = defaultMessage,
  value = [],
  onChange = noop,
  limit = 1,
  ...props
}) => {

  return (
    <UiUploadBox>
      <MatTooltip title={message} placement="top" arrow={true}>
        <UiUploadButton for="upload">
          {text}
          <input
            {...props}
            id="upload"
            type="file"
            accept=".mp3"
            onChange={e => onChange([...e.target.files].slice(limit - 1))}
          />
        </UiUploadButton>
      </MatTooltip>
      {Boolean(value.length) && <UiPrompt>已上傳：{value.map(file => file.name).join(', ')}</UiPrompt>}
    </UiUploadBox>
  );
};

UploadButton.propTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
  limit: PropTypes.number,
};


