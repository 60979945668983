import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UiSelectＷrapper,
  UiSelect,
  Uilabel,
  UiSelectContent,
  UiSelectOptions,
  UiSelectLi,
} from './Select.style';
import { useSetState } from 'utils/hooks/useSetState';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
/**
 * select 組件
 */

export const Select = ({
  label,
  options,
  value = '',
  isFlex = false,
  selectName,
  needDefault = false,
  blockSelect = false,
  onChange = () => { },
}) => {
  const [{
    showOptions,
    activeValue,
    active,
  }, setState] = useSetState({
    showOptions: false,
    activeValue: '請選擇條件',
    active: false
  });
  const changeHandler = (value, name, selectName, edit = false) => {
    onChange({ [selectName]: value }, name, edit);
    if (blockSelect) return;
    setState({
      activeValue: name,
      showOptions: false,
      active: false,
    });
  };
  const openOptions = action => {
    setState({
      showOptions: action,
      active: action,
    });
  };

  useEffect(() => {
    setState({
      activeValue: '請選擇條件',
    });
    if (value === '') return;
    if (options) {
      const defaultValue = options.filter((item) => item.value === value);
      if (defaultValue.length > 0) {
        setState({
          activeValue: defaultValue[0].name,
        });
        changeHandler(value, defaultValue[0].name, selectName, true);
      }

    }
  }, [options]);
  return (
    <UiSelectＷrapper isFlex={isFlex}>
      {
        label &&
        <Uilabel isFlex={isFlex}>{label}</Uilabel>
      }
      <ClickAwayListener onClickAway={() => { openOptions(false); }}>
        <UiSelect>
          <UiSelectContent
            active={active}
            onClick={() => { openOptions(true); }}
          >{activeValue.length > 10 ? activeValue.slice(0, 9) + '...' : activeValue}</UiSelectContent>
          {
            showOptions &&
            <UiSelectOptions>
              {needDefault &&
                <UiSelectLi
                  onClick={() => { changeHandler('', '請選擇條件', selectName); }}
                  value={''}
                >
                  請選擇條件
              </UiSelectLi>
              }
              {
                options && options.map(option => {
                  return (
                    <UiSelectLi
                      onClick={() => { changeHandler(option.value, option.name, selectName); }}
                      key={option.value}
                      value={option.value}
                      active={activeValue === option.name}
                    >
                      {option.name}
                    </UiSelectLi>
                  );
                })
              }
            </UiSelectOptions>
          }

        </UiSelect>
      </ClickAwayListener>

    </UiSelectＷrapper>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  isFlex: PropTypes.bool,
  selectName: PropTypes.string,
  blockSelect: PropTypes.bool,
  needDefault: PropTypes.bool,
};


