import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MatTextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UiAutoComplete } from './AutoComplete.style';


/**
 * 可以搜尋的下拉選單
 */

export const AutoComplete = ({ list = [], label = '別亂開支票啊', onChange = () => { }, name = 'defaultName' }) => {
  const onChangeHandler = (e, selected) => {
    if (!selected) return;
    const { value, name: selectName } = selected;
    onChange({ [name]: value, schoolName: selectName });
  };
  return (
    <UiAutoComplete>
      <h3>{label}</h3>
      <Autocomplete
        options={list}
        onChange={onChangeHandler}
        getOptionLabel={(option) => option.name}
        renderInput={params => <MatTextField {...params} variant="outlined" />}
      />
    </UiAutoComplete>
  );
};

AutoComplete.propTypes = {
  list: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};
