import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { UiRegisterScoreTable, UiCol, UiScrollBody, UiRow, UiCell } from './RegisterScoreTable.style';


/**
 * 登入分數表格
 */

export const RegisterScoreTable = ({ score = [], students = [], onChange = () => { } }) => {

  const [{ studentScore }, setState] = useSetState({
    studentScore: []
  });

  const onChangeHandler = (score, seq, studentId,) => {
    const nextStudentScore = studentScore.map(item => {
      if (item.id === studentId) {
        item.scoreList = item.scoreList.map(li => {
          if (li.seq === seq) li.score = parseInt(score);
          return li;
        });
      }
      return item;
    });
    setState({
      studentScore: nextStudentScore
    });
    onChange(nextStudentScore);
  };

  useEffect(() => {
    if (!students) return;
    const scoreList = [];
    let totalAmount = 0;
    score.forEach(item => {
      totalAmount += item.amount;
    });
    for (let i = 1; i <= totalAmount; i++) {
      scoreList.push(
        {
          seq: i,
          score: 0
        }
      );
    }
    const result = students.map(student => {
      return {
        id: student.studentID,
        seatNumber: student.seatNo,
        name: student.studentName,
        scoreList
      };
    });
    setState({
      studentScore: result
    });
  }, [students]);
  return (
    <UiScrollBody>
      <UiRegisterScoreTable>
        <UiRow>
          <UiCell col="2" background="#0057FF" isTitle={true} size="2.4rem" sticky={true}>題型</UiCell>
          {
            score.map((item, index) => {
              return (
                <UiCell key={index} background="#0057FF" isTitle={true} size="2.4rem" mincol="6">
                  {index + 1}.
                  {item.questionType}
                </UiCell>
              );
            })
          }
        </UiRow>
        <UiRow>
          <UiCell col="2" background="#6096ff" isTitle={true} size="1.8rem" sticky={true}>配分方式</UiCell>
          {
            score.map((item, index) => {
              return (
                <UiCell key={index} background="#6096ff" isTitle={true} size="1.8rem">
                  每格配分{item.score}分
                </UiCell>
              );
            })
          }
        </UiRow>
        <UiRow>
          <UiCol sticky={true}>
            <UiCell col="1" background="#ffc7ce">班級</UiCell>
            <UiCell col="1" background="#ffc7ce">姓名</UiCell>
          </UiCol>
          {
            score.map((item, index) => {
              let amount = [];
              for (let i = 1; i <= item.amount; i++) {
                amount.push(i);
              }
              return (
                <UiCol key={index}>
                  {
                    amount.map((cell, cellIndex) => {
                      return (
                        <UiCell col="1" background="#ffc7ce" key={cellIndex}>{cell}</UiCell>
                      );
                    })
                  }
                </UiCol>
              );
            })
          }
        </UiRow>
        {
          students.map(student => {
            return (
              <UiRow key={student.uuid}>
                <UiCol sticky={true}>
                  <UiCell col="1">
                    <span>{student.seatNo}</span>
                  </UiCell>
                  <UiCell col="1">
                    <span>{student.studentName}</span>
                  </UiCell>
                </UiCol>
                {
                  score.map((item, index) => {
                    let totalNumber = 0;
                    let amount = [];
                    for (let i = 1; i <= item.amount; i++) {
                      amount.push(i);
                    }
                    return (
                      <UiCol key={index}>
                        {
                          amount.map((cell, cellIndex, cells) => {
                            totalNumber += 1;
                            return (
                              <UiCell col="1" background="#ffc7ce" key={cellIndex}>
                                <input
                                  type="text"
                                  onChange={
                                    e => onChangeHandler(
                                      e.target.value,
                                      (index === 0 ? totalNumber * index : totalNumber * index - 1) + cellIndex + 1,
                                      student.studentID)
                                  }
                                />
                              </UiCell>
                            );
                          })
                        }
                      </UiCol>
                    );
                  })
                }
              </UiRow>
            );
          })
        }
      </UiRegisterScoreTable>
    </UiScrollBody>
  );
};

RegisterScoreTable.propTypes = {
  score: PropTypes.array,
  students: PropTypes.array,
};
