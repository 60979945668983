import styled from 'styled-components';


export const UiTitle = styled.div`
	margin: 0 auto 2.9rem;
	padding: 1rem;
	width: max-content;
	font-size: 1.7rem;
	border: 1px solid #333;
`;

export const UiStep = styled.div`
	display: flex;

	& > div:nth-child(1) {
		margin-right: 0.5rem;
		width: max-content;
		font-size: 2rem;
	}

	& > div:nth-child(2) {
		display: flex;
		padding: 0.6rem 0;
		font-size: 1.5rem;
		flex-direction: column;
		flex: 1;

		span {
			color: #f00;
		}
	}

	.isFocus {
		color: #f00;
	}
`;

export const UiImage = styled.div`
	font-size: 0;

	& > img {
		max-width: max-content;
	}
`;

export const UiCenterContent = styled.div`
	margin: auto;
	width: 90%;

	video {
		max-width: 100%;
	}
`;

export const UiParagraphTitle = styled.h4``;

export const UiParagraph = styled.p`
	font-size: 1.5rem;

	&:first-of-type {
		margin-top: 0;
	}

	& > span {
		color: #f00;
	}

	& > .link {
		text-decoration: underline;
		color: ${({theme})=>theme.colorTheme.secondary};
	}
`;

export const UiPrecautionsTitle = styled.div`
	margin: 1.2rem 0;
	font-size: 2rem;
	font-weight: bold;
	color: #000;
`;

export const UiPrecautionsTable = styled.table`
	width: 100%;
	max-width: ${props => props.size};
	font-size: 1.4rem;
	border: 1px solid #dedede;
	border-collapse: collapse;
	table-layout: fixed;
`;

export const UiPrecautionsTr = styled.tr`
	border: 1px solid #dedede;

`;

export const UiPrecautionsTd = styled.td`
	display: table-cell;
	padding: 1.7rem 0.8rem;
	border: 1px solid #dedede;
`;

export const UiPrecautions = styled.td`
	padding: 1.7rem 0.8rem;
	border: 1px solid #dedede;
`;

export const UiBorderWrap = styled.div`
	padding: 1.6rem 2.4rem;
	border: 1px solid #dedede;
	box-sizing: border-box;

	.title {
		font-size: 1.4rem;
		font-weight: bold;
		color: #000;
		margin-bottom: 1.2rem;
	}

	.content {
		font-size: 1.4rem;
	}

	.isFocus {
		color: #f00;
	}
`;
