import styled from 'styled-components';

export const UiReviewDetail = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 3rem;

	& > *:nth-child(1) {
		width: 58%;
	}

	& > *:nth-child(2) {
		width: 38%;
	}
`;

export const UiContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 1rem 3rem;
	flex-wrap: wrap;
`;

export const UiTitle = styled.div`
	padding: 1rem 3rem;
	font-size: 2rem;
	background-color: ${({ theme, isParent }) => isParent ? '#95ffbb' : theme.colorTheme.tertiary};
`;

export const UiImage = styled.div`
	overflow-x: scroll;
	width: 58%;
	max-height: 700px;
	font-size: 0;

	& > div {
		& > img {
			width: inherit !important;
			max-width: 100%;
			height: inherit !important;
		}
	}
`;

export const UiInfo = styled.div`
	width: 38%;
`;

export const UiInfoItem = styled.div`
	padding: 1rem 3rem;
	word-break: break-all;
	font-size: 1.5rem;
`;

export const UiSelectBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

export const UiAuthor = styled.ul`
	margin: 2rem auto;
	width: 100%;
	background-color: ${({ theme, isParent }) => isParent ? '#95ffbb' : theme.colorTheme.tertiary};

	& > li {
		padding: 1rem 3rem;
		font-size: 1.5rem;
	}
`;
