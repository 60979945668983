import { Api } from 'services/api';
import { stringify } from 'query-string';

export const queuePush = async payload => {
  const response = await Api.post('/api/Queue/Push', payload);
  return response;
};


export const convertList = async () => {
  const response = await Api.get('/api/Convert/Status');
  return response.json();
};

export const serchConvert = async payload => {
  const response = await Api.post('/api/Convert/Search', payload);
  return response.json();
};

//to-do minxin add fail and success response
export const disabledStatus = async id => {
  const queryParams = {
    fileID: id
  };
  const querystring = stringify(queryParams);
  const response = await Api.get(`/api/Convert/DisableStatus?${querystring}`);
  return response;
};
