import React from 'react';
import { SearchQuestion } from 'components';

/**
 * 搜尋題目頁面組件
 */

export const SearchQuestionPage = () => {
  return (
    <SearchQuestion />
  );
};
