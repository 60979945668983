import React from 'react';
import { ImportAnswerCard } from 'components';


/**
 * 匯入畫卡
 */

export const ImportAnswrCardPage = () => {
  return (
    <ImportAnswerCard />
  );
};
