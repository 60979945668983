import React from 'react';
import { ReviewDetail } from 'components';

/**
 * 審查詳細頁面
 */

export const ReviewDetailPage = () => {
  return (
    <ReviewDetail />
  );
};
