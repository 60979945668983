import styled from 'styled-components';

export const UiDatePicker = styled.div`
	.MuiFormControl-root {
		width: 100%;

		input {
			font-size: 1.7rem;
		}
	}

	.MuiInputBase-root {
		padding-left: 12px;
		width: 100%;
		max-width: 320px;
		background-color: #fff;
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: #2a4865;
	}
`;
export const Uilabel = styled.h3`
	margin: 0;
`;
