/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { ReportContainer, Select, RadioGroup, Graph, StickyTable } from 'components';
import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST, RADIOLIST } from 'constants/index';
import { useFormatterReport } from 'utils/hooks/useFormatterReport';
import { objectMapCovertToArray } from 'utils/object';
import { Prompt } from 'react-router-dom';
import {
  UiRadioBox,
} from './SubAbility.style';
/**
 * 各科報表
 */


const teacherReport = ({
  reportTitle = '',
  groupValue = '',
  classNo = '',
  reportData = {},
  groupOption = [],
  classStudentsOption = [],
  selectGroup,
  getDataFromRadioList = () => { },
}) => {
  const tableSchema = [''];
  let newtableData = [];
  let nextGraphData = [];
  let types = [];
  if (Object.keys(reportData).length > 0 && groupValue !== '') {
    const { classCorrectRate, dataList, header, questionAmount, schoolCorrectRate } = reportData;
    const studentDataList = dataList[groupValue];
    const chooseClassName = groupOption.filter(item => item.value === classNo)[0].name;

    types = [
      {
        name: '全校',
        id: '1',
        key: 'A'
      },
      {
        name: chooseClassName,
        id: '2',
        key: 'B'
      },
      {
        name: '個人',
        id: '3',
        key: 'C'
      },
    ];

    const schoolCorrectRateList = objectMapCovertToArray(schoolCorrectRate, (data, key) => {
      const ability = header.filter(item => item.value === key)[0].name;
      tableSchema.push(ability);
      return `${data}%`;
    });
    const questionAmountList = objectMapCovertToArray(questionAmount, data => {
      return data;
    });
    const classCorrectRateList = objectMapCovertToArray(classCorrectRate, data => {
      return `${data}%`;
    });

    const studentRateList = objectMapCovertToArray(studentDataList.correctRate, data => {
      return `${data}%`;
    });

    const studentPRList = objectMapCovertToArray(studentDataList.pr, data => {
      return data;
    });

    newtableData = [
      {
        catelogey: '題數',
        list: questionAmountList,
      },
      {
        catelogey: '個人答對率',
        list: studentRateList,
      },
      {
        catelogey: `${chooseClassName}答對率`,
        list: classCorrectRateList,
      },
      {
        catelogey: '全校答對率',
        list: schoolCorrectRateList,
      },
      {
        catelogey: '個人PR值',
        list: studentPRList,
      },
    ];


    nextGraphData = objectMapCovertToArray(schoolCorrectRate, (data, key) => {
      const ability = header.filter(item => item.value === key)[0].name;
      return {
        subject: ability,
        A: data,
        B: classCorrectRate[key],
        C: dataList[groupValue].correctRate[key],
        fullMark: 100
      };
    });
  }
  return (
    <>
      <ReportContainer
        title={reportTitle}
        remind="※ 點選學生名稱會以黃色折線圖呈現於圖表"
        selectGroup={
          selectGroup()
        }
        radioChildren={
          Object.keys(reportData).length > 0 &&
          <UiRadioBox>
            <RadioGroup
              name={RADIOLIST.STUDENTS.name}
              label={RADIOLIST.STUDENTS.label}
              data={reportData.options}
              value={groupValue}
              onChange={getDataFromRadioList} />
          </UiRadioBox>
        }
      >
        {
          newtableData.length > 0 &&
          <>
            <Graph.Radar data={nextGraphData} types={types} />
            <StickyTable.FixedTitle canSort={false} schema={tableSchema} data={newtableData} headerCount={1} />
          </>
        }
      </ReportContainer>

    </>
  );
};

const principalReport = ({
  reportTitle = '',
  groupOption = [],
  groupValue = '',
  reportData = {},
  selectGroup,
  formatterGraphData = () => { },
  formatterTableData = () => { },
  getDataFromRadioList = () => { },
}) => {
  const { title, className: groupList, dataList, header, questionAmount } = reportData;
  let selectClassNameList = {};
  groupOption.forEach(item => {
    Object.assign(selectClassNameList, { [item.value]: item.name });
  });
  const graphData = formatterGraphData({
    reportData,
    title,
    groupList: groupList ? groupList : selectClassNameList,
    dataList,
    reportTypeString: '答對率',
    reportRoleString: '全校',
    group: groupValue,
    header
  });
  const nextTableData = formatterTableData({
    reportData,
    title,
    groupList: groupList ? groupList : selectClassNameList,
    dataList,
    reportTypeString: '答對率',
    reportRoleString: '全校',
    groupName: '班級',
    group: groupValue,
    header,
    subHeader: questionAmount,
    subHeaderTitle: '題數'
  });
  return (
    <>
      <ReportContainer
        title={reportTitle}
        remind="※ 點選班級名稱會以紅色折線圖呈現於圖表"
        selectGroup={
          selectGroup()
        }
        radioChildren={
          groupOption && groupOption.length > 0 &&
          <UiRadioBox>
            <RadioGroup
              name={RADIOLIST.CLASS.name}
              label={RADIOLIST.CLASS.label}
              data={groupOption}
              value={groupValue}
              onChange={getDataFromRadioList} />
          </UiRadioBox>
        }
      >
        {
          Object.keys(graphData).length > 0 &&
          <>
            <Graph.Radar data={graphData.data} types={graphData.types} />
            <StickyTable.FixedTitle schema={nextTableData.tableSchema} data={nextTableData.data} headerCount={3} />
          </>
        }
      </ReportContainer>

    </>
  );
};

const countyReport = ({
  reportTitle = '',
  groupOption = [],
  groupValue = '',//radio選的資料
  reportData = {},
  selectGroup,
  formatterGraphData = () => { },
  formatterTableData = () => { },
  getDataFromRadioList = () => { },
}) => {
  const { title, schoolName: groupList, dataList, header } = reportData;
  const graphData = formatterGraphData({
    reportData,
    title,
    groupList,
    dataList,
    reportTypeString: '答對率',
    reportRoleString: '全縣',
    group: groupValue,
    graphType: 'radar',
    header,
  });
  const nextTableData = formatterTableData({
    reportData,
    title,
    groupList,
    dataList,
    reportTypeString: '答對率',
    reportRoleString: '全縣',
    groupName: '學校',
    group: groupValue,
    header
  });

  return (
    <>
      <ReportContainer
        title={reportTitle}
        remind="※ 點選學校名稱會以藍色折線圖呈現於圖表"
        selectGroup={
          selectGroup()
        }
        radioChildren={
          groupOption && groupOption.length > 0 &&
          <UiRadioBox>
            <RadioGroup
              name={RADIOLIST.COUNTY.name}
              label={RADIOLIST.COUNTY.label}
              data={groupOption}
              value={groupValue}
              onChange={getDataFromRadioList} />
          </UiRadioBox>
        }
      >
        {
          Object.keys(graphData).length > 0 &&
          <>
            <Graph.Radar data={graphData.data} types={graphData.types} />
            <StickyTable.FixedTitle schema={nextTableData.tableSchema} data={nextTableData.data} />
          </>
        }
      </ReportContainer>

    </>
  );
};

export const Report = {
  CountyReport: countyReport,
  PrincipalReport: principalReport,
  TeacherReport: teacherReport,
};

export const SubAbility = () => {

  const [{
    selections,
    examAbilityData,
  }, {
    getReportSeletions,
    getExamAbility,
    clearReport,
  }] = useReport();

  const {
    formatterRadarGraphData,
    formatterSubAbilityTableData,
  } = useFormatterReport();

  const [{
    students,
    examType,
    schoolYear,
    grade,
    classNo,
    className,
    classListOption,
    schoolName,
    schoolOption,
    education,
    subject,
    searchCriteria,
    isBlocking,
    reportTitle,
    subjectClassOption,
    classStudentsOption,
  }, setState] = useSetState({
    students: '',
    examType: '',
    schoolYear: '',
    grade: '',
    classNo: '',
    className: '',
    classListOption: [],
    schoolName: '',
    schoolOption: [],
    education: '',
    subject: '',
    searchCriteria: {},//送出的資料
    isBlocking: true,
    reportTitle: '分項能力答對率',
    subjectClassOption: [],
    classStudentsOption: [],
  });

  //取得radio資料（以後可能多選用selectGruop）
  const getDataFromRadioListHandler = data => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data)
    });
  };

  const onGradeChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setClassListOptions(value);
  };

  const setClassListOptions = (chooseGrade) => {
    if (selections.class[chooseGrade]) {
      const nextClassList = selections.class[chooseGrade].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        classListOption: nextClassList
      });
    } else {
      setState({
        classListOption: [],
      });
    }
  };

  const onEducationChangeHandler = (data, name, edit) => {//選擇學制
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setSchoolListOptions(value);
  };

  const setSchoolListOptions = (chooseEducation) => {
    if (selections.school[chooseEducation]) {
      const nextClassList = selections.school[chooseEducation].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        schoolOption: nextClassList
      });
    } else {
      setState({
        schoolOption: [],
      });
    }
  };

  const onChangeSubjectHandler = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setSubjectClassOptions(value);
  };

  const setSubjectClassOptions = (chooseSubject) => {
    if (selections.subjectClass[chooseSubject]) {
      const nextClassList = selections.subjectClass[chooseSubject].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        subjectClassOption: nextClassList
      });
    } else {
      setState({
        subjectClassOption: [],
      });
    }
  };

  const onChangeSubjectClassHandler = (data) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setClassStudentsOptions(value);
  };

  const setClassStudentsOptions = (chooseClassStudents) => {
    if (selections.classStudents[chooseClassStudents]) {
      const nextClassStudentsList = selections.classStudents[chooseClassStudents].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        classStudentsOption: nextClassStudentsList
      });
    } else {
      setState({
        classStudentsOption: [],
      });
    }
  };

  //基礎篩選（學年度...）
  const onChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      searchCriteria: Object.assign(searchCriteria, data),
      [key]: value,
    });
  };

  const selectGroup = useCallback(() => {
    const { education, class: classList, classStudents: students } = selections;
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            selectName={SELECTLIST.EDUCATION.name}
            options={selections.education}
            value={education}
            onChange={onEducationChangeHandler}
          />
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            selectName={SELECTLIST.SUBJECT.name}
            options={selections.subject}
            value={subject}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (classList) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            selectName={SELECTLIST.GRADE.name}
            options={selections.grade}
            value={subject}
            onChange={onGradeChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            selectName={SELECTLIST.SUBJECT.name}
            options={selections.subject}
            value={subject}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (students) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />

          <Select
            label={SELECTLIST.SUBJECT.label}
            selectName={SELECTLIST.SUBJECT.name}
            options={selections.subject}
            value={subject}
            onChange={onChangeSubjectHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
          {
            (subjectClassOption && subjectClassOption.length > 0) &&
            <Select
              label={SELECTLIST.CLASSNO.label}
              selectName={SELECTLIST.CLASSNO.name}
              options={subjectClassOption}
              value={classNo}
              onChange={onChangeSubjectClassHandler}
            />
          }

        </>
      );
    }
    return (
      <>loading...</>
    );

  }, [selections, subjectClassOption]);

  const getReport = async (payload) => {
    await getExamAbility(payload);
  };

  useEffect(() => {
    const payload = {
      reportType: 'ExamAbility'
    };
    getReportSeletions(payload);
  }, []);

  useEffect(() => {//teacherReport
    if (
      classNo === '' ||
      examType === '' ||
      subject === '' ||
      schoolYear === '') {
      return;
    }
    getReport(searchCriteria);
  }, [classNo, examType, subject, schoolYear]);

  useEffect(() => {//校長
    if (
      grade === '' ||
      examType === '' ||
      schoolYear === '' ||
      subject === ''
    ) {
      return;
    }
    getReport(searchCriteria);
  }, [grade, examType, schoolYear, subject]);

  useEffect(() => {//縣級
    if (
      subject === '' ||
      examType === '' ||
      schoolYear === '' ||
      education === ''
    ) {
      return;
    }
    getReport(searchCriteria);
  }, [subject, examType, schoolYear, education]);

  return (
    <>
      <Prompt
        when={isBlocking}
        message={() => {
          clearReport();
        }}
      />
      {
        selections.classStudents &&
        <Report.TeacherReport
          reportTitle={reportTitle}
          groupValue={students}
          groupOption={subjectClassOption}//抓班級名稱
          reportData={examAbilityData}
          classNo={classNo}
          classStudentsOption={classStudentsOption}
          selectGroup={selectGroup}
          onSelectChange={onChangeHandler}
          getDataFromRadioList={getDataFromRadioListHandler}
        />
      }
      {
        selections.class &&
        <Report.PrincipalReport
          reportTitle={reportTitle}
          groupValue={className}
          groupOption={classListOption}
          reportData={examAbilityData}
          selectGroup={selectGroup}
          formatterGraphData={formatterRadarGraphData}
          formatterTableData={formatterSubAbilityTableData}
          getDataFromRadioList={getDataFromRadioListHandler}
        />
      }
      {
        selections.education &&
        <Report.CountyReport
          reportTitle={reportTitle}
          groupValue={schoolName}
          groupOption={schoolOption}
          reportData={examAbilityData}
          selectGroup={selectGroup}
          formatterGraphData={formatterRadarGraphData}
          formatterTableData={formatterSubAbilityTableData}
          getDataFromRadioList={getDataFromRadioListHandler}
        />
      }
    </>
  );
};
