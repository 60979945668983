import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiTitle,
  UiStep,
  UiImage,
  UiCenterContent,
  UiParagraph,
  UiParagraphTitle,
  UiPrecautionsTitle,
  UiPrecautionsTable,
  UiPrecautionsTr,
  UiPrecautionsTd,
  UiBorderWrap
} from './InstructionsModal.style';
import step1 from 'assets/images/step1.png';
import step2 from 'assets/images/step2.png';
import step31 from 'assets/images/step3-1.png';
import step32 from 'assets/images/step3-2.png';
import Video from 'assets/videos/instructions.mp4';

/**
 * 題目填寫說明跳窗
 */

export const InstructionsModal = ({ open, getModalState = () => { } }) => {
  const [{ isOpen }, setState] = useSetState({
    isOpen: false
  });
  const buttons = [
    {
      text: '取消',
      sizetype: 'normal',
      icon: 'close',
      buttonborder: false,
      textcolor: '#fff',
      buttoncolor: 'visibility',
      func: () => { getModalState(false); }
    }
  ];
  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);

  const handleTableSize = (size) => {
    switch (size) {
      case 2:
        return '35rem';
      default:
        return 'auto';
    }
  };
  return (
    <Modal
      open={isOpen}
      title="題目填寫說明"
      titleIcon="notification_important"
      getModalState={getModalState}
      buttons={buttons}
    >
      <article>
        <UiTitle>入庫注意事項</UiTitle>
        <UiStep>
          <div>步驟1.</div>
          <div>
            <div>點選下載題目範本按鈕 (附圖紅框)  ，選擇學制 → 科目 → 版本 → 冊次，匯出入題範本檔</div>
            <UiImage>
              <img src={step1} alt="" />
            </UiImage>
          </div>
        </UiStep>
        <UiStep>
          <div className='isFocus'>步驟2.</div>
          <div>
            <div>開啟下載後的word檔，<span>Word檔最前的表頭，請勿更動或刪除。</span></div>
            <UiImage>
              <img src={step2} alt="" />
            </UiImage>
          </div>
        </UiStep>
        <h3>入題表格說明:</h3>
        <UiParagraphTitle>一、題目欄位</UiParagraphTitle>
        <UiParagraph>(一)題幹內若有多段文字內容，分段請使用Shift + Enter，每段最後應該出現↓(垂直向下的箭頭)。若選項、解析內有多段文字時亦同。</UiParagraph>
        <UiParagraph>(二)題幹、選項、解析若需出現圖片＆表格，請將圖片＆表格轉換成物件使用。(詳細操作步驟請看下方1-3的步驟說，或可搭配示範操作影片)</UiParagraph>
        <UiCenterContent>
          <video controls src={Video} type="video/mp4" />
        </UiCenterContent>
        <UiBorderWrap>
          <UiParagraph>
            1.在要插入圖片 (表格) 的位置點選工具列的插入，選擇物件，選擇建立「Microsoft Word 97-2003文件」，
            點選確定後，Word會開啟新的一個Word檔案，將要置入的圖片放入，關閉該Word文件即可。
          <span>(圖片建議寬度不超過7公分，超出可能造成試卷跑版。)</span>
          </UiParagraph>
          <UiImage>
            <img src={step31} alt="" />
          </UiImage>
          <UiImage>
            <img src={step32} alt="" />
          </UiImage>
          <UiParagraph>2.題幹、答案、解析以Enter區隔。</UiParagraph>
          <UiParagraph>3.解析並非必填欄位，若該題並無解析，請將『解析：』一併刪除。</UiParagraph>
          <UiParagraph>4.選擇題的答案，建議從範本中複製選項後貼上，例如：國中從選項中複製(C)，國小從選項中複製③。</UiParagraph>
        </UiBorderWrap>
        <UiParagraphTitle>二、基本資料欄位</UiParagraphTitle>
        <UiParagraph>(一)若選填方式點選「選單填寫」時，基本資料欄請使用下拉選單進行選擇。</UiParagraph>
        <UiParagraph>(二)若選填方式點選「代碼填寫」時，核心素養、學習內容及學習表現請用代碼填寫，若需要複數核心素養、學習內容及學習表現，請選擇「代碼填寫」。</UiParagraph>
        <UiBorderWrap>
          <div className='title'>複數填寫範例：</div>
          <div className='content'>
            核心素養：社-J-B2,社-J-B3 <br /><span className='isFocus'>下載範本時，也會同時下載對應的代碼清單檔案，請複製檔案上的對應代碼，較不容易出錯。</span>
          </div>
        </UiBorderWrap>
        <UiParagraph>(三)答案數請輸入阿拉伯數字。</UiParagraph>
        <UiParagraphTitle>三、No.欄位</UiParagraphTitle>
        <UiParagraph>(一) 請順著題目數量，往下遞增。</UiParagraph>
        <UiParagraph>(二)每個檔案上限題目為20題，上限容量為5M。</UiParagraph>
        <UiPrecautionsTitle>※單選題型注意事項</UiPrecautionsTitle>
        <UiParagraphTitle>一、適用題型</UiParagraphTitle>
        <UiParagraph>國小：</UiParagraph>
        <UiPrecautionsTable size={handleTableSize}>
          <tbody>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選出正確的答案</UiPrecautionsTd>
              <UiPrecautionsTd>選擇題(基本修辭)</UiPrecautionsTd>
              <UiPrecautionsTd>字詞義測驗(選擇)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選出正確的讀音</UiPrecautionsTd>
              <UiPrecautionsTd>選擇題(其他)</UiPrecautionsTd>
              <UiPrecautionsTd>課文文意測驗(選擇)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選出適當的答案</UiPrecautionsTd>
              <UiPrecautionsTd>選擇題(生字延伸成語)</UiPrecautionsTd>
              <UiPrecautionsTd>生字成語(選擇)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選詞填寫</UiPrecautionsTd>
              <UiPrecautionsTd>選擇題(統整活動)</UiPrecautionsTd>
              <UiPrecautionsTd>閱讀理解題</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選擇題</UiPrecautionsTd>
              <UiPrecautionsTd>字詞義測驗</UiPrecautionsTd>
              <UiPrecautionsTd>選出適當的語詞</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選擇題(字音字形)</UiPrecautionsTd>
              <UiPrecautionsTd>綜合測驗</UiPrecautionsTd>
              <UiPrecautionsTd>成語運用(選擇題)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選擇題(字詞義及運用)</UiPrecautionsTd>
              <UiPrecautionsTd>課文文意測驗</UiPrecautionsTd>
              <UiPrecautionsTd>高層次思考題</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選擇題(文意理解)</UiPrecautionsTd>
              <UiPrecautionsTd>選詞填寫(選擇)</UiPrecautionsTd>
              <UiPrecautionsTd>填單位量詞(選擇)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>選擇題(標點符號)</UiPrecautionsTd>
              <UiPrecautionsTd></UiPrecautionsTd>
              <UiPrecautionsTd></UiPrecautionsTd>
            </UiPrecautionsTr>
          </tbody>
        </UiPrecautionsTable>
        <UiParagraph>國中：</UiParagraph>
        <UiPrecautionsTable size={handleTableSize(2)}>
          <tbody>
            <UiPrecautionsTr>
              <UiPrecautionsTd>文法</UiPrecautionsTd>
              <UiPrecautionsTd>對話</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>字彙選擇</UiPrecautionsTd>
              <UiPrecautionsTd>字彙選擇(認識字彙)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>克漏字選擇</UiPrecautionsTd>
              <UiPrecautionsTd>字彙選擇(應用字彙)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>單選題</UiPrecautionsTd>
              <UiPrecautionsTd>閱讀理解</UiPrecautionsTd>
            </UiPrecautionsTr>
          </tbody>
        </UiPrecautionsTable>
        <UiParagraphTitle>二、試題排打規則：</UiParagraphTitle>
        <UiParagraph>● 選項符號前加全形空白。</UiParagraph>
        <UiParagraph>● 題目內的選項符號不能有樣式(EX.底線、顏色..等)。</UiParagraph>
        <UiParagraph>● 題幹內的選項符號，需與答案、解析內的格式相同。</UiParagraph>
        <UiParagraph>
          ● <a className='link' 
            href={`${process.env.PUBLIC_URL}/sampleQuestion/multipleChoice.doc`} 
            download="單選題範本.doc" 
            rel="noreferrer">單選題範本</a>
        </UiParagraph>
        <UiPrecautionsTitle>※題組題型注意事項</UiPrecautionsTitle>
        <UiParagraphTitle>一、適用題型</UiParagraphTitle>
        <UiParagraph>國小：</UiParagraph>
        <UiPrecautionsTable size={() => handleTableSize(2)}>
          <tbody>
            <UiPrecautionsTr>
              <UiPrecautionsTd>閱讀測驗</UiPrecautionsTd>
              <UiPrecautionsTd>成語閱讀測驗</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>題組</UiPrecautionsTd>
              <UiPrecautionsTd>修辭練習(題組題)</UiPrecautionsTd>
            </UiPrecautionsTr>
            <UiPrecautionsTr>
              <UiPrecautionsTd>字詞義測驗(題組)</UiPrecautionsTd>
              <UiPrecautionsTd>成語運用(題組題)</UiPrecautionsTd>
            </UiPrecautionsTr>
          </tbody>
        </UiPrecautionsTable>
        <UiParagraph>國中：</UiParagraph>
        <UiPrecautionsTable size={() => handleTableSize(2)}>
          <tbody>
            <UiPrecautionsTr>
              <UiPrecautionsTd>閱讀測驗</UiPrecautionsTd>
              <UiPrecautionsTd>題組</UiPrecautionsTd>
            </UiPrecautionsTr>
          </tbody>
        </UiPrecautionsTable>
        <UiParagraphTitle>二、試題排打規則：</UiParagraphTitle>
        <UiParagraph>● 子題間以SHIFT+ENTER區隔。</UiParagraph>
        <UiParagraph>● 子題前請自行增加作答區(EX.  (　　)(１)  )</UiParagraph>
        <UiParagraph>● 題目內的選項符號不能有樣式(EX.底線、顏色..等)。</UiParagraph>
        <UiParagraph>● 題幹內的選項符號，需與答案、解析內的格式相同。</UiParagraph>
        <UiParagraph>● 各子題以全形分號區隔，包含子題號(１)。</UiParagraph>
        <UiParagraph>
          ● <a className='link'
            href={`${process.env.PUBLIC_URL}/sampleQuestion/questionGroup.doc`}
            download="題組題範本.doc"
            rel="noreferrer">題組題範本</a>
        </UiParagraph>
      </article>
    </Modal>
  );
};

InstructionsModal.propTypes = {
  open: PropTypes.bool,
  getModalState: PropTypes.func,
};


