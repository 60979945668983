import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getUploadFolderName as getUploadFolderNameApi,
  getReadCard as getReadCardApi,
  getClassResult as getClassResultApi,
  submitClassResult as submitClassResultApi
} from 'services/api/answer';
const GET_UPLOAD_FOLDER_NAME = 'GET_UPLOAD_FOLDER_NAME';
const GET_READ_CARD = 'GET_READ_CARD';
const GET_CLASS_RESULT = 'GET_CLASS_RESULT';
const SUBMIT_CLASS_RESULT = 'SUBMIT_CLASS_RESULT';

const initState = {
  folderName: '',
  analyzeResult: {},
  isLoading: false,
};

export const useAnswer = () => {
  const dispatch = useDispatch();
  const { folderName, analyzeResult, isLoading } = useSelector(state => state.answer);

  const addAnswerAction = createAction(GET_UPLOAD_FOLDER_NAME, async params => {
    try {
      const response = await getUploadFolderNameApi(params);
      return response;
    } catch{

      return;
    }
  });
  const addAnswer = params => dispatch(addAnswerAction(params));

  const getReadCardAction = createAction(GET_READ_CARD, async params => {
    try {
      const response = await getReadCardApi(params);
      return response;
    } catch{

      return;
    }
  });
  const getReadCard = params => dispatch(getReadCardAction(params));


  const getClassResultAction = createAction(GET_CLASS_RESULT, async params => {
    try {
      const response = await getClassResultApi(params);
      return response;
    } catch{

      return;
    }
  });
  const getClassResult = params => dispatch(getClassResultAction(params));

  const submitClassResultAction = createAction(SUBMIT_CLASS_RESULT, async params => {
    try {
      const response = await submitClassResultApi(params);
      return response;
    } catch{

      return;
    }
  });
  const submitClassResult = params => dispatch(submitClassResultAction(params));


  return [
    { folderName, analyzeResult, isLoading }, // state
    {
      addAnswer,
      getReadCard,
      getClassResult,
      submitClassResult
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    GET_UPLOAD_FOLDER_NAME_PENDING: state => ({
      ...state,
      isLoading: false,
    }),
    GET_UPLOAD_FOLDER_NAME_FULFILLED: (state, action) => ({
      ...state,
      folderName: action.payload,
      isLoading: true,
    }),
    GET_UPLOAD_FOLDER_NAME_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    GET_READ_CARD_PENDING: state => ({
      ...state,
      isLoading: false,
    }),
    GET_READ_CARD_FULFILLED: state => ({
      ...state,
      isLoading: false,
    }),
    GET_READ_CARD_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    GET_CLASS_RESULT_PENDING: state => ({
      ...state,
      isLoading: false,
    }),
    GET_CLASS_RESULT_FULFILLED: (state, action) => ({
      ...state,
      analyzeResult: action.payload,
      isLoading: false,
    }),
    GET_CLASS_RESULT_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    SUBMIT_CLASS_RESULT: state => ({
      ...state,
      isLoading: false,
    }),
  },
  initState
);

export default reducer;
