import styled from 'styled-components';
import { media } from 'theme';

export const UiMainLayout = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;
export const UiContainer = styled.div`
	padding: 5.5rem 0;
	max-width: 2560px;
	flex: 1;

	@media ${media.md} {
		width: 90%;
	}

	@media ${media.xs} {
		width: 90%;
		max-width: 1280px;
	}
`;
