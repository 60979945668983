import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from 'components';
import { UiMainLayout, UiContainer } from './CommonLayout.style';


/**
 * 通用的版型
 */

export const CommonLayout = ({ children }) => {
  return (
    <UiMainLayout>
      <Header isCommon={false} />
      <UiContainer>
        {children}
      </UiContainer>
      <Footer />
    </UiMainLayout>
  );
};

CommonLayout.propTypes = {
  children: PropTypes.node,
};


