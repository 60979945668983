import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'utils/hooks/useAlert';
import { Modal, QuestionDetail, QuestionPagination, LoadingBackground, UploadButton, Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useDownload } from 'utils/hooks/useDownload';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { format } from 'date-fns';
import { useQuestion } from 'store/question';
import { UiFlexBox } from './QuestionModal.style';

/**
 * 題目預覽彈窗
 */



export const QuestionModal = ({
  data,
  getModalState,
  subject,
  open,
  isEdit = false
}) => {
  const [{
    isLoading,
  }, {
    downloadSingleQuestion }] = useQuestion();
  const [{ page, isOpen }, setState] = useSetState({
    page: 1,
    isOpen: false,
  });
  const { blobDownload } = useDownload();
  const { setAlert } = useAlert();
  const {
    upload,
    getDownloadURL
  } = useFirebaseStorage();

  const onUploaded = async (file, id) => {
    const uploadPath = `engSound/${id}.mp3`;
    const response = await upload(uploadPath, file);
    const { status } = response;
    if (status) {
      return id;
    } else {
      return false;
    }
  };

  const onSubmitFile = async file => {
    const id = data.questions[page - 1].id;
    await onUploaded(file[0], id);
    setAlert('上傳檔案成功!', 'success');
  };

  const downloadQuestion = async () => {
    const { value: url } =
      await downloadSingleQuestion({
        subject,
        ID: data.questions[page - 1].id
      });
    const nowDate = format(new Date(), 'yyyyMMddHHmmss') - 19110000000000;
    blobDownload(url, `月段考系統題目下載_${nowDate}.doc`, 'GET');
  };
  const buttons = [
    {
      text: '下載本題檔案',
      buttoncolor: 'deepblue',
      func: () => {
        downloadQuestion();
      }
    },
    {
      text: '取消',
      sizetype: 'normal',
      icon: 'close',
      buttonborder: true,
      textcolor: '#3C4858',
      func: () => { }
    }
  ];

  const downloadHandler = async () => {
    const path = `engSound/${data.questions[page - 1].id}.mp3`;
    const { status, url } = await getDownloadURL(path);
    if (status === 'success') window.open(url);
  };

  const onQuestionNumberChange = value => {
    setState({
      page: value,
    });
  };
  const getModalStateHandler = state => {
    getModalState(state);
    setState({
      isOpen: state,
      page: 1,
    });
  };
  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);

  return (
    <>
      <Modal
        open={isOpen}
        title='試題預覽'
        titleIcon='preview'
        buttons={buttons}
        titleChildren={
          (subject.indexOf('EL') !== -1 && isEdit) &&
          <UiFlexBox>
            <UploadButton onChange={onSubmitFile} />
            <Button onClick={() => { downloadHandler(); }}>下載音檔</Button>
          </UiFlexBox>
        }
        getModalState={getModalStateHandler}
      >
        <>
          <QuestionDetail data={data} page={page} isEdit={isEdit} />
          {
            data.questions &&
            <QuestionPagination count={data.questions.length} onChange={onQuestionNumberChange} />
          }
        </>
      </Modal>
      {isLoading && <LoadingBackground />}
    </>
  );
};

QuestionModal.propTypes = {
  data: PropTypes.shape({
    questions: PropTypes.array,
    fileName: PropTypes.string,
    examName: PropTypes.string,
  }),
  open: PropTypes.bool,
  subject: PropTypes.string,
  getModalState: PropTypes.func,
  isEdit: PropTypes.bool,
};


