import React from 'react';
import PropTypes from 'prop-types';
import {
  UiProgressBg,
  UiProgressMain
} from './ProgressBar.style';


/**
 * 進度條
 */

export const ProgressBar = ({
  barHeight = 25,
  barColor = '#ffa869',
  startRate = 0,
  endRate = 100
}) => {
  return (
    <UiProgressBg barHeight={barHeight}>
      <UiProgressMain barColor={barColor} startRate={startRate} endRate={endRate} />
    </UiProgressBg>
  );
};

ProgressBar.propTypes = {
  barHeight: PropTypes.number,
  barColor: PropTypes.string,
  startRate: PropTypes.number,
  endRate: PropTypes.number,
};


