import Swal from 'sweetalert2';

export const useModal = () => {
  const setModal = ({
    confirmEvent,
    cancelEvent,
    ...props }) => {
    Swal.fire({
      icon: 'warning',
      confirmButtonText: '確定',
      cancelButtonText: '取消',
      confirmButtonColor: '#00923F',
      cancelButtonColor: '#F38A04',
      showCancelButton: true,
      customClass: {
        content: 'modalContent',
      },
      ...props,
    }).then((result) => {
      if (result.value) {
        confirmEvent && confirmEvent();
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        cancelEvent && cancelEvent();
      }
    });
  };

  return { setModal };
};
