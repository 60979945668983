import styled from 'styled-components';

export const UiUploadFileModal = styled.div`
	.MuiGrid-container {
		margin: 0;
		width: 100%;
	}

	.MuiGrid-spacing-xs-8 > .MuiGrid-item {
		padding: 32px 0;
		max-width: initial;
		flex-basis: auto;
	}

	.MuiSnackbar-anchorOriginBottomLeft {
		span {
			font-size: 2rem;
		}
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5rem 1rem;

	& > * + * {
		margin-left: 1rem;
	}
`;

export const UiImportantText = styled.div`
	color: #f00;
`;
