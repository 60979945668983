import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from 'components';
import {
  UiMenu,
  UiMenuBox,
  UiMenuItem
} from './Menu.style';


/**
 * header的選單
 */

export const Menu = ({ links = [] }) => {
  const history = useHistory();
  const nowUrl = history.location.pathname;
  const goTo = (event, link) => {
    if (event.target.tagName === 'I' && link !== '') history.push(link);
    if (event.currentTarget !== event.target || link === '') return;
    history.push(link);
  };
  const isSameGroup = link => {
    if (link.url === nowUrl) return true;
    let isSame = false;
    if (!link.subMenu) return;
    link.subMenu.forEach(item => {
      if (item.url === nowUrl) isSame = true;
    });
    return isSame;
  };
  return (
    <UiMenu>
      {
        links.map((link, index) => {
          return (
            <UiMenuBox
              onClick={e => goTo(e, link.url)}
              key={index}
              bcolor={isSameGroup(link) ? '#00a6ff' : 'transparent'}
            >
              <Icon name={link.icon} sizetype="large" color="#fff" />
              {link.label}
              {
                link.subMenu && <UiMenuItem>
                  {
                    link.subMenu.map((sub, subIndex) => {
                      return (
                        <Link to={{ pathname: sub.url, state: { from: nowUrl } }} key={subIndex}>{sub.label}</Link>
                      );
                    })
                  }
                </UiMenuItem>
              }
            </UiMenuBox>
          );
        })
      }
    </UiMenu>
  );
};

Menu.propTypes = {
  links: PropTypes.array,
};


