import { LoginPage, } from 'components';
import { COMMON_PATH } from './constants';


const routes = [
  {
    path: COMMON_PATH.LOGIN,
    component: LoginPage,
    exact: true
  },
];

export default routes;
