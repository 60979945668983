import React from 'react';
import {
  UiSearchQuestion,
} from './SearchQuestion.style';
import {
  TitleContainer,
  QuestionRangeSelect,
  QuestionPickList,
  Button,
  LoadingBackground,
} from 'components';
import { useModal } from 'utils/hooks/useModal';
import { useSetState } from 'utils/hooks/useSetState';
import { Prompt } from 'react-router-dom';
import { useQuestion } from 'store/question';

/**
 * 搜尋題目
 */

export const SearchQuestion = () => {
  const { setModal } = useModal();
  const [{ stepIndex, isBlocking }, setState] = useSetState({
    stepIndex: 0,
    isBlocking: true,
  });
  const [{ isLoading }, { clearQuestions }] = useQuestion();

  const getNextStep = (index) => {
    setState({
      stepIndex: index
    });
  };
  const switchRender = () => {
    switch (stepIndex) {
      case 1:
        return (
          <QuestionPickList type='search' />
        );
      case 0:
      default:
        return (
          <QuestionRangeSelect type='search' nextStep={getNextStep} />
        );
    }
  };
  const backPrev = ()=>{
    setModal({
      html: '若離開此畫面，<span>您的試題不會儲存</span>，需再重新加入，請問是否確定？',
      confirmEvent: () => { getNextStep(stepIndex - 1); },
    });
  };
  return (
    <UiSearchQuestion>
      <TitleContainer
        title={'搜尋題目'}
        titleChildren={
          <>
            {
              stepIndex === 1 &&
              <Button
                textcolor={'#000'}
                sizetype={'normal'}
                buttonborder={true}
                icon={'keyboard_return'}
                onClick={() => { backPrev(); }}>回上一頁</Button>
            }
          </>
        }
      >
        <Prompt
          when={isBlocking}
          message={(params) => {
            if (params.state) {
              const { pathname, state: { from = {} } } = params;
              pathname !== from &&
                clearQuestions();
            }
          }}
        />
        {switchRender()}
      </TitleContainer>
      <LoadingBackground isLoading={isLoading} />
    </UiSearchQuestion>
  );
};
