import styled from 'styled-components';

export const UiExamLayoutConfig = styled.div`
`;

export const UiExamLayoutConfigWrapper = styled.div`
`;
export const UiExamLayoutConfigRow = styled.div`
`;
export const UiExamLayoutConfigContent = styled.div`
	margin: auto;
	padding: 1.5rem 0;
	width: 60%;
`;
export const UiExamLayoutConfigTitle = styled.div`
	padding: 1.5rem 0;
	width: 100%;
	font-size: 2rem;
	text-align: center;
	background: #95ffbb;
`;

export const UiExamLayoutConfigInfoContent = styled.div`
	padding: 1.5rem 1rem;
`;
export const UiExamLayoutConfigInfoContentRow = styled.div`
	display: flex;
	margin: 1.5rem 0;

	& > div {
		flex: 0.8;
		margin: 0 1rem;
	}
`;
export const UiExamLayoutConfigContentCheckboxRow = styled(UiExamLayoutConfigInfoContentRow)`
	display: inline-flex;

	& > div {
		flex: 1;
		margin: 0 0;
	}
`;
export const UiExamLayoutConfigContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 2rem 0;
`;

export const UiExamTeacher = styled.div`
	flex: 0.2 !important;
	font-size: 1.7rem;
	text-align: center;
`;
export const UiStudentInfo = styled.div`
	display: flex;
	flex: initial !important;
	font-size: 1.7rem;
`;
export const UiunderLine = styled.div`
	border-bottom: 1px solid #000;
	width: ${({ col = 1 }) => `${50 * col}px`};
`;

export const UiExamLayoutOtherConfig = styled.div`
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
	flex: 1;
`;
export const UiExamLayoutOtherConfigTitle = styled.div`
	padding: 1rem 2rem;
	font-size: 1.7rem;
	background: #daffe7;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;
export const UiExamLayoutOtherConfigContent = styled.div`
		display: flex;
		justify-content: center;
		padding: 5rem;

		& > button {
			margin: 1rem;
		}
`;

export const UiExamLayoutConfigButtonList = styled.div`
		flex: 1;
		display: flex;
		justify-content: space-between;
`;

export const UiExamLayoutConfigDescription = styled.div`
`;

export const UiExamLayoutConfigDescriptionTitle = styled.div`
	font-size: 1.7rem;
`;

export const UiExamLayoutConfigDescriptionUl = styled.ul`
	padding: 0 1.5rem;
	font-size: 1.5rem;
	list-style-type: decimal;
`;

export const UiExamLayoutConfigDescriptionLi = styled.li`
	& > span {
		color: ${({ theme }) => theme.text.color.error};
	}
`;


export const UiProgressBg = styled.div`
	overflow: hidden;
	width: 100%;
	height: 25px;
	background-color: #8c8c8c;
	border-radius: 50px;
`;

export const UiProgressMain = styled.div`
	width: ${({ startRate, endRate }) => `${(startRate / endRate) * 100}%`};
	height: 25px;
	background-color: #ffa869;
	border-radius: 50px;
	transition: 0.35s;
`;
