/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { ReportContainer, Select, RadioGroup, Graph, StickyTable } from 'components';
import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST, RADIOLIST, SUBJECT_DESC } from 'constants/index';
import { useFormatterReport } from 'utils/hooks/useFormatterReport';
import { objectMapCovertToArray } from 'utils/object';


/**
 * 全科答對率報表
 */

export const ReportFramed = () => {

  const [{
    selections,
    reportData,
  }, {
    getReportSeletions,
    getCorrectRate,
  }] = useReport();


  const {
    formatterGraphData,
    formatterTableData,
  } = useFormatterReport();

  const [{
    students,
    examType,
    schoolYear,
    grade,
    className,
    classListOption,
    schoolName,
    schoolOption,
    education,
    searchCriteria,
    remind,
  }, setState] = useSetState({
    students: '',
    examType: '',
    schoolYear: '',
    grade: '',
    className: '',
    classListOption: [],
    schoolName: '',
    schoolOption: [],
    education: '',
    searchCriteria: {},//送出的資料
    remind: ''
  });


  //取得radio資料（以後可能多選用selectGruop）
  const getDataFromRadioListHandler = data => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data)
    });
  };

  const onGradeChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setClassListOptions(value);
  };

  const setClassListOptions = (chooseGrade) => {
    if (selections.classList[chooseGrade]) {
      const nextClassList = selections.classList[chooseGrade].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        classListOption: nextClassList
      });
    } else {
      setState({
        classListOption: [],
      });
    }
  };

  const onEducationChangeHandler = (data, name, edit) => {//選擇學制
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      [key]: value,
      searchCriteria: Object.assign(searchCriteria, data),
    });
    setSchoolListOptions(value);
  };

  const setSchoolListOptions = (chooseEducation) => {
    if (selections.school[chooseEducation]) {
      const nextClassList = selections.school[chooseEducation].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });
      setState({
        schoolOption: nextClassList
      });
    } else {
      setState({
        schoolOption: [],
      });
    }
  };

  //基礎篩選（學年度...）
  const onChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      searchCriteria: Object.assign(searchCriteria, data),
      [key]: value,
    });
  };

  const selectGroup = useCallback(() => {
    const { education, classList, students } = selections;
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            selectName={SELECTLIST.EDUCATION.name}
            options={selections.education}
            value={education}
            onChange={onEducationChangeHandler}
          />
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (classList) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            selectName={SELECTLIST.GRADE.name}
            options={selections.grade}
            value={grade}
            onChange={onGradeChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (students) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.label}
            selectName={SELECTLIST.SCHOOLYEAR.name}
            options={selections.schoolYear}
            value={schoolYear}
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            selectName={SELECTLIST.EXAMTYPE.name}
            options={selections.examType}
            value={examType}
            onChange={onChangeHandler}
          />
        </>
      );
    }
    return (
      <>loading...</>
    );

  }, [selections]);


  const radioGroup = useCallback(() => {
    const { education, classList, students } = selections;
    //縣籍
    if (education) {
      return (
        <RadioGroup
          name={RADIOLIST.COUNTY.name}
          label={RADIOLIST.COUNTY.label}
          data={schoolOption}
          value={schoolName}
          onChange={getDataFromRadioListHandler} />
      );
    }
    if (classList) {
      return (
        <RadioGroup
          name={RADIOLIST.CLASS.name}
          label={RADIOLIST.CLASS.label}
          data={classListOption}
          value={className}
          onChange={getDataFromRadioListHandler} />
      );
    }
    if (students) {
      return (
        <RadioGroup
          name={RADIOLIST.STUDENTS.name}
          data={selections.students}
          onChange={getDataFromRadioListHandler}
          label={RADIOLIST.STUDENTS.label}
        />
      );
    }
  }, [selections, grade, education]);


  const countyDataGroup = () => {
    const { title, schoolName: groupList, dataList } = reportData;
    const graphData = formatterGraphData({
      reportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: '全縣',
      group: schoolName
    });
    const nextTableData = formatterTableData({
      reportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: '全縣',
      groupName: '學校',
      group: schoolName
    });

    return (
      Object.keys(graphData).length > 0 &&
      <>
        <Graph.BarLine data={graphData.data} pv={graphData.pv} uv={graphData.uv} />
        <StickyTable.FixedTitle schema={nextTableData.tableSchema} data={nextTableData.data} />
      </>
    );


  };

  const principalDataGroup = () => {
    const { title, className: groupList, dataList } = reportData;
    const graphData = formatterGraphData({
      reportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: '全校',
      group: className
    });
    const nextTableData = formatterTableData({
      reportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: '全校',
      groupName: '班級',
      group: className
    });

    return (
      Object.keys(graphData).length > 0 &&
      <>
        <Graph.BarLine data={graphData.data} pv={graphData.pv} uv={graphData.uv} />
        <StickyTable.FixedTitle schema={nextTableData.tableSchema} data={nextTableData.data} />
      </>
    );


  };

  const teacherDataGroup = () => {
    const tableSchema = ['科目', '答對題數', '個人答對率', '班級答對率', '全校答對率', '總分', 'PR值'];
    let newtableData = [];
    let nextGraphData = [];
    const uv = '個人答對率';
    let pv = '全班答對率';
    let av = '全校答對率';
    if (Object.keys(reportData).length > 0 && students !== '') {
      newtableData = reportData[students].map(item => {
        const list = objectMapCovertToArray(item, (data, key) => {
          if (key !== 'subject') {
            if (key !== 'correctAmount' && key !== 'score' && key !== 'pr') {
              return `${data}%`;
            } else {
              return data;
            }
          }
        });
        return {
          catelogey: SUBJECT_DESC[item.subject],
          list,
        };
      });


      nextGraphData = reportData[students].map(item => {
        return {
          name: SUBJECT_DESC[item.subject],
          [uv]: item.personalRate,
          [pv]: item.classRate,
          [av]: item.schoolRate
        };
      });

      return (
        Object.keys(reportData).length > 0 &&
        <>
          <Graph.BarLine data={nextGraphData} pv={pv} uv={uv} av={av} />
          <StickyTable.FixedTitle canSort={false} schema={tableSchema} data={newtableData} headerCount={1} />
        </>
      );
    }
  };




  const getReport = async (payload) => {
    await getCorrectRate(payload);
  };

  useEffect(() => {
    const payload = {
      reportType: 'CorrectRate'
    };
    getReportSeletions(payload);
  }, []);



  useEffect(() => {
    const { education, classList, students } = selections;
    if (education) {
      if (
        schoolName === '' ||
        examType === '' ||
        schoolYear === '' ||
        education === ''
      ) {
        return;
      }
      getReport(searchCriteria);
    }
    if (classList) {
      if (
        grade === '' ||
        examType === '' ||
        schoolYear === '' ||
        className === ''
      ) {
        return;
      }
      getReport(searchCriteria);
    }
    if (students) {
      if (
        students === '' ||
        examType === '' ||
        schoolYear === '') {
        return;
      }
      getReport(searchCriteria);
    }
  }, [schoolName, examType, schoolYear, education, grade, className, students]);

  useEffect(() => {
    const { education, classList, students } = selections;
    //縣籍
    if (education) {
      setState({
        remind: '※ 點選學校名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (classList) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (students) {
      setState({
        remind: '※ 點選學生名稱會以黃色直方圖呈現於圖表'
      });
    }

  }, [selections]);

  return (
    <ReportContainer
      title="全科答對率"
      remind={remind}
      selectGroup={selectGroup()}
      radioChildren={radioGroup()}
    >
      {/* <Select isFlex={true} label="圖表量尺" /> */}
      {(Object.keys(reportData)?.length > 0 && selections.education) && countyDataGroup()}
      {(Object.keys(reportData)?.length > 0 && selections.classList) && principalDataGroup()}
      {(Object.keys(reportData)?.length > 0 && selections.students) && teacherDataGroup()}

    </ReportContainer>
  );
};
