import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Avatar as MatAvatar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMember } from 'store/member';
import { TextStroke, Menu, Icon } from 'components';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { Tooltip as MatTooltip } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiHeader,
  UiTop,
  UiBottom,
  UiTopContent,
  UiImage,
  UiText,
  UiInfo,
  UiLogout,
} from './Header.style';
import hualien from 'assets/images/hualien.png';

/**
 * header
 */

const normalLinks = [
  {
    id: 'home',
    icon: 'home',
    label: '首頁',
    url: MAIN_PATH.ANNOUNCEMENT_LIST
  },
  {
    id: 'proposition',
    icon: 'edit',
    label: '命題',
    url: '',
    subMenu: [
      {
        label: '新題入庫',
        url: MAIN_PATH.NEWQUESTION_LIST,
      },
      {
        label: '搜尋題目',
        url: MAIN_PATH.SEARCHQUESTION
      },
    ]
  },
  {
    id: 'test',
    icon: 'receipt_long',
    label: '出卷',
    url: MAIN_PATH.EXAMPAPER_LIST
  },
  {
    id: 'examSearchList',
    icon: 'grading',
    label: '閱卷/登分',
    url: MAIN_PATH.EXAMSEARCH_LIST
  },
  {
    id: 'report',
    icon: 'insert_chart_outlined',
    label: '報表',
    url: MAIN_PATH.REPORTFRAMD
  },
];

export const Header = ({ linksDefault, isCommon = true }) => {
  const history = useHistory();
  const [{ memberInfo: { userName, schoolName, isAdvisoryMember } }, { logout }] = useMember();
  const [{
    links
  }, setState] = useSetState({
    links: normalLinks
  });

  useEffect(() => {
    if (userName === '') return;
    if (isAdvisoryMember) {
      setState({
        links: [
          ...normalLinks,
          {
            id: 'check',
            icon: 'done_outline',
            label: '勾稽審查',
            url: MAIN_PATH.REVIEW
          }
        ]
      });
    } else {
      setState({
        links: normalLinks
      });
    }
  }, [isAdvisoryMember, userName]);

  return (
    <UiHeader>
      <UiTop>
        <UiTopContent onClick={() => history.push(MAIN_PATH.ANNOUNCEMENT_LIST)}>
          <UiImage>
            <img src={hualien} alt="tiku" />
          </UiImage>
          <UiText>
            <TextStroke text="花蓮縣 月段考系統" />
            <div>智慧教育深化數據採集</div>
          </UiText>
        </UiTopContent>
      </UiTop>
      {isCommon && <UiBottom>
        <Menu links={links} />
        <UiInfo>
          <MatTooltip title={`version : ${process.env.REACT_APP_VERSION || '0.0.1'}`} placement="top">
            <MatAvatar />
          </MatTooltip>
          {userName} {schoolName}
        </UiInfo>
        <UiLogout onClick={() => logout()}>
          <Icon name="run_circle" size="2.5rem" color="#005197" />
          登出
        </UiLogout>
      </UiBottom>}
    </UiHeader>
  );
};

Header.propTypes = {
  linksDefault: PropTypes.array,
  isCommon: PropTypes.bool,
};


