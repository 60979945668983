import {
  MainLayout,
  AnnouncementPage,
  EditAnnouncementListPage,
  AnnouncementDetailPage,
  QuestionListPage,
  AnnouncementFormPage,
  QuestionDetailPage,
  SearchQuestionPage,
  NewQuestionListPage,
  CreateExamPaperPage,
  ExamSearchListPage,
  ImportAnswrCardPage,
  CheckAnswerCardPage,
  ReportFramdPage,
  RegisterScorePage,
  SingleSubjectPage,
  SubAbilityPage,
  AllSubjectExtentPage,
  TwoWaySchedulePage,
  ReliabilityOfGeneralExaminationPapersPage,
  DiscriminationOfSubjectsPage,
  DiscriminationOfAllSubjectsPage,
  SingleSubjectExtentPage,
  ReviewPage,
  ReviewDetailPage,
  ComprehensiveAnalysisPage,
  InstructionsPage,
  SuggestPage
} from 'components';

import { MAIN_PATH } from 'routes/routesConfig/constants';

const routes = [
  {
    path: '/home',
    component: MainLayout,
    routes: [
      {
        path: MAIN_PATH.ANNOUNCEMENT_LIST,
        component: AnnouncementPage,
        exact: true
      },
      {
        path: MAIN_PATH.EDIT_ANNOUNCEMENT_LIST,
        component: EditAnnouncementListPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.ANNOUNCEMENT_DETAIL}/:id`,
        component: AnnouncementDetailPage,
        exact: true
      },
      {
        path: MAIN_PATH.ANNOUNCEMENT_FORM,
        component: AnnouncementFormPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.ANNOUNCEMENT_FORM}/:id`,
        component: AnnouncementFormPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.QUESTION_DETAIL}/:id`,
        component: QuestionDetailPage,
        exact: true
      },
      {
        path: MAIN_PATH.SEARCHQUESTION,
        component: SearchQuestionPage,
        exact: true
      },
      {
        path: MAIN_PATH.NEWQUESTION_LIST,
        component: NewQuestionListPage,
        exact: true
      },
      {
        path: MAIN_PATH.EXAMPAPER_LIST,
        component: QuestionListPage,
        exact: true
      },
      {
        path: MAIN_PATH.CREATEEXAM,
        component: CreateExamPaperPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.CREATEEXAM}/:examID`,
        component: CreateExamPaperPage,
        exact: true
      },
      {
        path: MAIN_PATH.EXAMSEARCH_LIST,
        component: ExamSearchListPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.IMPORT_ANSWERCARD}/:examID`,
        component: ImportAnswrCardPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.CHECK_ANSWERCARD}/:examID`,
        component: CheckAnswerCardPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.REGISTER_SCORE}/:examID`,
        component: RegisterScorePage,
        exact: true
      },
      {
        path: MAIN_PATH.REPORTFRAMD,
        component: ReportFramdPage,
        exact: true
      },
      {
        path: MAIN_PATH.SINGLE_SUBJECT,
        component: SingleSubjectPage,
        exact: true
      },
      {
        path: MAIN_PATH.SUBABILITY,
        component: SubAbilityPage,
        exact: true
      },
      {
        path: MAIN_PATH.ALLSUBJECT_EXTENT,
        component: AllSubjectExtentPage,
        exact: true
      },
      {
        path: MAIN_PATH.TWOWAYSCHEDULE,
        component: TwoWaySchedulePage,
        exact: true
      },
      {
        path: MAIN_PATH.RELIABILITYOFGENERALEXAMINATIONPAPERS,
        component: ReliabilityOfGeneralExaminationPapersPage,
        exact: true
      },
      {
        path: MAIN_PATH.DISCRIMINATIONOFSUBJECTS,
        component: DiscriminationOfSubjectsPage,
        exact: true
      },
      {
        path: MAIN_PATH.DISCRIMINATIONOFALLSUBJECTS,
        component: DiscriminationOfAllSubjectsPage,
        exact: true
      },
      {
        path: MAIN_PATH.SINGLE_SUBJECT_EXTENT,
        component: SingleSubjectExtentPage,
        exact: true
      },
      {
        path: MAIN_PATH.REVIEW,
        component: ReviewPage,
        exact: true
      },
      {
        path: `${MAIN_PATH.REVIEW_DETAIL}/:id`,
        component: ReviewDetailPage,
        exact: true
      },
      {
        path: MAIN_PATH.COMPREHENSIVE_ANALYSIS,
        component: ComprehensiveAnalysisPage,
        exact: true
      },
      {
        path: MAIN_PATH.INSTRUCTIONS,
        component: InstructionsPage,
        exact: true
      },
      {
        path: MAIN_PATH.SUGGEST,
        component: SuggestPage,
        exact: true
      },
    ]
  }
];

export default routes;
