import React from 'react';
import {
  TitleContainer,
  QuestionStep,
  QuestionRangeSelect,
  QuestionPickList,
  ExamLayoutConfig,
  QuestionsSort,
  ExamAnalysis,
  Button,
  LoadingBackground,
} from 'components';
import { useParams, Prompt } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useQuestion } from 'store/question';
import { useModal } from 'utils/hooks/useModal';
import { UiCreateExamPaper, UiFooterButtonList } from './CreateExamPaper.style';


/**
 * 創建試卷組件
 */


const stepData = [
  '範圍選擇',
  '試題選擇',
  '試題排序',
  '試卷分析',
  '版面配置',
];

export const CreateExamPaper = () => {
  const { setModal } = useModal();
  const { examID } = useParams();
  const [{ stepIndex, isBlocking }, setState] = useSetState({
    stepIndex: examID ? 2 : 0,
    isBlocking: true,
  });
  const [{ chapter, isLoading }, { clearQuestions }] = useQuestion();
  const goTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };
  const getNextStep = (index) => {
    if(stepIndex === 2 && index< stepIndex){
      setModal({
        html: '若回上一頁，<span>您的配分或試題排序不會儲存</span>，需再重新填寫，請問是否確定？',
        confirmEvent: () => {
          setState({
            stepIndex: index
          });
          goTop();
         },
      });
      return;
    }
    setState({
      stepIndex: index
    });
    goTop();
  };
  const switchRender = () => {
    switch (stepIndex) {
      case 4:
        return (
          <ExamLayoutConfig nextStep={getNextStep} />
        );
      case 3:
        return (
          <ExamAnalysis />
        );
      case 2:
        return (
          <QuestionsSort examID={examID} />
        );
      case 1:
        return (
          <QuestionPickList />
        );
      case 0:
      default:
        return (
          <QuestionRangeSelect examID={examID} nextStep={getNextStep} />
        );
    }
  };
  return (
    <UiCreateExamPaper>
      <TitleContainer
        title={examID ? '編輯試卷' : '新增試卷'}
        titleChildren={
          <QuestionStep data={stepData} activeIndex={stepIndex} />
        }
        titledistributed={'flex-start'}
      >
        <Prompt
          when={isBlocking}
          message={() => {
            clearQuestions();
          }}
        />
        {switchRender()}

        {
          (stepIndex !== 0 && stepIndex !== 4) &&
          <UiFooterButtonList>
            <Button
              onClick={() => {
                getNextStep((examID && stepIndex === 2 && !chapter.chapter) ? 0 : stepIndex - 1);
              }}>
              上一步
            </Button>
            <Button onClick={() => { getNextStep(stepIndex + 1); }}>下一步</Button>
          </UiFooterButtonList>
        }
      </TitleContainer>
      <LoadingBackground isLoading={isLoading} />
    </UiCreateExamPaper>
  );
};

CreateExamPaper.propTypes = {

};


