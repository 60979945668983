import React from 'react';
import { Instructions } from 'components';


/**
 * 操作說明頁面
 */

export const InstructionsPage = () => {
  return (<Instructions />);
};


