import styled, { keyframes } from 'styled-components';

const dotAnimation = keyframes`
	0% { padding: 0 0 11.2em 0; }
	5% { padding: 5.6em 0; }
	50% { padding: 5.6em 0; }
	55% { padding: 0 0 11.2em 0; }
	100% { padding: 0 0 11.2em 0; }
`;

const dotAnimationTwo = keyframes`
	0% {
		opacity: 1;
		transform: scale(1);
	}

	5% {
		opacity: 0.5;
		transform: scale(0.5);
	}

	50% {
		opacity: 0.5;
		transform: scale(0.5);
	}

	55% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
`;


export const UiLoadingBackground = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	visibility: ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
	z-index: ${({ theme }) => theme.zIndex.loading};
	transition: 0.35s;
	flex-direction: column;
	flex: 1;

	& > ul {
		position: absolute;
		top: calc(50% - 4.2em);
		left: calc(50% - 0.7em);
		display: inline-block;
		margin: 0;
		padding: 0;
		text-indent: 5.6em;
		list-style: none;

		&::after {
			position: absolute;
			top: 5.6em;
			display: block;
			width: 2.8em;
			height: 2.8em;
			background-color: #fff;
			border-radius: 100%;
			content: "";
		}

		& > li {
			position: absolute;
			top: 0;
			left: 0;
			padding-bottom: 11.2em;

			animation: ${dotAnimation} 2.5s infinite;

			&::after {
				display: block;
				width: 2.8em;
				height: 2.8em;
				background-color: #fff;
				border-radius: 100%;
				content: '';
				animation: ${dotAnimationTwo} 2.5s infinite;
			}

			&:nth-child(1) {
				transform: rotate(0deg);
				animation-delay: 0.125s;
			}

			&:nth-child(1)::after {
				animation-delay: 0.125s;
			}

			&:nth-child(2) {
				transform: rotate(36deg);
				animation-delay: 0.25s;
			}

			&:nth-child(2)::after {
				animation-delay: 0.25s;
			}

			&:nth-child(3) {
				transform: rotate(72deg);
				animation-delay: 0.375s;
			}

			&:nth-child(3)::after {
				animation-delay: 0.375s;
			}

			&:nth-child(4) {
				transform: rotate(108deg);
				animation-delay: 0.5s;
			}

			&:nth-child(4)::after {
				animation-delay: 0.5s;
			}

			&:nth-child(5) {
				transform: rotate(144deg);
				animation-delay: 0.625s;
			}

			&:nth-child(5)::after {
				animation-delay: 0.625s;
			}

			&:nth-child(6) {
				transform: rotate(180deg);
				animation-delay: 0.75s;
			}

			&:nth-child(6)::after {
				animation-delay: 0.75s;
			}

			&:nth-child(7) {
				transform: rotate(216deg);
				animation-delay: 0.875s;
			}

			&:nth-child(7)::after {
				animation-delay: 0.875s;
			}

			&:nth-child(8) {
				transform: rotate(252deg);
				animation-delay: 1s;
			}

			&:nth-child(8)::after {
				animation-delay: 1s;
			}

			&:nth-child(9) {
				transform: rotate(288deg);
				animation-delay: 1.125s;
			}

			&:nth-child(9)::after {
				animation-delay: 1.125s;
			}

			&:nth-child(10) {
				transform: rotate(324deg);
				animation-delay: 1.25s;
			}

			&:nth-child(10)::after {
				animation-delay: 1.25s;
			}
		}
	}
`;

