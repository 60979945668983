import styled from 'styled-components';

export const UiCreateExamPaper = styled.div`
`;
export const UiFooterButtonList = styled.div`
	display: flex;
	justify-content: center;
	padding: 2rem 0;

	& > button {
		margin: 0 2rem;
	}
`;
