import React from 'react';
import { CreateExamPaper } from 'components';


/**
 * 新增考卷頁面
 */

export const CreateExamPaperPage = () => {
  return (
    <CreateExamPaper />
  );
};
