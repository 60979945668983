import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import getTime from 'date-fns/getTime';
import { UiDatePicker, Uilabel } from './DatePicker.style';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { useSetState } from 'utils/hooks/useSetState';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-input': {
      padding: '1.5rem 0'
    }
  },
});

/**
 * 日期輸入框
 */

export const DatePicker = ({
  type = 'time',
  name = 'time',
  label = 'label',
  value = '',
  onChange = () => { },
  cancelLabel = '取消',
  okLabel = '確定',
  clearLabel = '清除',
  inputVariant = 'outlined',
  disablePast = false,
  ...props
}) => {
  const classes = useStyles();
  const [{ inputValue }, setState] = useSetState({
    inputValue: new Date(),
  });
  const onValueChange = date => {
    const dataTimestamp = getTime(date);
    setState({ inputValue: dataTimestamp });
    onChange({ [name]: dataTimestamp });
  };
  useEffect(() => {
    const defaultValue = value !== '' ? value : getTime(new Date());
    setState({ inputValue: defaultValue });
    onChange({ [name]: defaultValue });
  }, [value]);
  const getDatePicker = (type) => {
    switch (type) {
      case 'time':
        return (
          <KeyboardTimePicker
            className={classes.root}
            mask="__:__ _M"
            variant="dialog"
            inputVariant={inputVariant}
            ampm={false}
            value={new Date(inputValue)}
            onChange={onValueChange}
            disablePast={disablePast}
            format={'HH:mm'}
            cancelLabel={cancelLabel}
            okLabel={okLabel}
            clearLabel={clearLabel}
            clearable
          />
        );
      case 'date':
        return (
          <KeyboardDatePicker
            className={classes.root}
            variant="dialog"
            inputVariant={inputVariant}
            value={new Date(inputValue)}
            onChange={onValueChange}
            disablePast={disablePast}
            format={'yyyy/MM/dd'}
            cancelLabel={cancelLabel}
            okLabel={okLabel}
            clearLabel={clearLabel}
            minDate={Date.now()}
            clearable
            {...props}
          />
        );
      case 'dateTime':
        return (
          <KeyboardDateTimePicker
            className={classes.root}
            mask="__:__ _M"
            variant="dialog"
            inputVariant={inputVariant}
            ampm={false}
            value={new Date(inputValue)}
            onChange={onValueChange}
            disablePast={disablePast}
            format={'yyyy/MM/dd HH:mm'}
            cancelLabel={cancelLabel}
            okLabel={okLabel}
            clearLabel={clearLabel}
            clearable
          />
        );
      default:
        return (
          <KeyboardTimePicker
            className={classes.root}
            mask="__:__ _M"
            variant="dialog"
            inputVariant={inputVariant}
            ampm={false}
            label={label}
            value={new Date(inputValue)}
            onChange={onValueChange}
            disablePast={disablePast}
            format={'HH:mm'}
            cancelLabel={cancelLabel}
            okLabel={okLabel}
            clearLabel={clearLabel}
            clearable
          />
        );
    }
  };

  return (
    <UiDatePicker>
      {
        label &&
        <Uilabel>{label}</Uilabel>
      }
      {getDatePicker(type)}

    </UiDatePicker>
  );
};

DatePicker.propTypes = {
  type: PropTypes.oneOf(['dateTime', 'date', 'time']),
  name: PropTypes.string,
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool
};


