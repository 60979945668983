import MatButton from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const UiButton = styled(MatButton)`
position: relative;
display: flex;
padding: ${({ buttonborder }) => buttonborder === 'true' ? '0 25px' : '5px 25px'};
font-size:
	${(
		{ theme,
		sizetype }
	) => theme.text[sizetype]};
color:
	${(
		{ theme,
		textcolor }
	) => textcolor ?
	textcolor
	: '#fff'};

background:
	${(
		{ theme,
		buttoncolor,
		loading,
		buttonborder }
	) => loading === true ?
	theme.btn['disable'] :
	buttonborder === 'true' ? 'transparent' :
	theme.btn[buttoncolor]};
border: ${({ buttonborder }) => buttonborder === 'true' ? '1px solid #9F9F9F' : 'none'};
border-radius: 5px;
box-shadow: ${({ buttonborder }) => buttonborder === 'true' ? 'none' : '1px 2px 5px 1px rgba(0, 0, 0, 0.3)'};
cursor:
	${(
		{ buttoncolor,
		loading }
	) =>
	(buttoncolor === 'disable' || loading === true) ? 'not-allowed' : 'pointer'};

&:hover {
	background:
		${(
			{ theme,
			buttoncolor,
			loading,
			buttonborder }
		) => loading === true ?
		theme.btn['disable'] :
		buttonborder === 'true' ? theme.btn['borderhover'] :
		theme.btn[buttoncolor + 'hover']};
}

& > span {
	font-family: Noto Sans TC, Helvetica Neue, PingFang TC, Heiti TC, sans-serif;
}
`;
export const UiCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	color: #fff;
`;
export const UiInlineFlex = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
`;
