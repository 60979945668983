import React from 'react';
import { DiscriminationOfAllSubjects } from 'components';

/**
 * 全科試卷鑑別度
 */

export const DiscriminationOfAllSubjectsPage = () => {
  return (
    <DiscriminationOfAllSubjects />
  );
};
