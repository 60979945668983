import React from 'react';
import PropTypes from 'prop-types';
import { UiRadio } from './Radio.style';
/**
 * Radio組件
 */

export const Radio = ({
  id,
  value,
  name,
  label,
  checked = false,
  onCheck = () => { },
}) => {
  const checkHandler = e => {
    onCheck && onCheck(e);
  };
  return (
    <UiRadio>
      <input
        checked={checked}
        type="radio"
        id={value}
        value={value}
        name={name}
        onChange={e => { checkHandler(e); }}
      />
      <label htmlFor={value}>
        <div className='checkBox'>
          <div className="check"></div>
        </div>
        {label}
      </label>
    </UiRadio>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
};


