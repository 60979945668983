import styled from 'styled-components';

export const UiSuggest = styled.div`
`;


export const UiContent = styled.div`
	padding: 10rem 0;
	background: #e1ebff;
	margin-bottom: 5rem;
`;
export const UiRow = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
	margin: auto;
	width: 75%;
`;
export const UiLeft = styled.div`
	width: 25%;
`;
export const UiRight = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 2rem;
	flex-direction: column;

	& > div {
		margin: 0 0 1rem;
	}

	& > div:last-child {
		margin: 1rem 0;
	}
`;
export const UiTitle = styled.div`
font-size: 2.5rem;
`;
export const UiText = styled.div`
	font-size: 1.5rem;
`;
export const UiButton = styled.div`
	display: flex;
	justify-content: flex-end;
`;
