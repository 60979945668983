import React, { useEffect } from 'react';
import { TitleContainer, RegisterScoreTable, ColorBlockTitle, Select, Button } from 'components';
import { useQuestion } from 'store/question';
import { useMember } from 'store/member';
import { useParams, useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useSetState } from 'utils/hooks/useSetState';

import { UiSelectBox, UiButtonBox } from './RegisterScore.style';


/**
 * 登記分數
 */


export const RegisterScore = () => {
  const history = useHistory();
  const { examID } = useParams();
  const [{ memberInfo }] = useMember();
  const [, { registerScore, registerScoreUpload }] = useQuestion();

  const [{
    examName,
    options,
    selectedClass,
    scoreValue,
    studentsObject,
    studentScore
  }, setState] = useSetState({
    examName: '',
    options: [],
    selectedClass: '',
    scoreValue: [],
    studentsObject: {},
    studentScore: []
  });

  const onChangeHandler = value => setState(value);

  const getRegisterScore = async () => {
    const payload = {
      client: 'tikuHualien',
      data: examID
    };
    const { value: { students, isScoring, score, examName } } = await registerScore(payload);
    if (!isScoring) return;
    const classOptions = Object.keys(students).map(item => {
      return {
        name: item,
        value: item
      };
    });

    setState({
      options: classOptions,
      scoreValue: score,
      studentsObject: students,
      examName
    });
  };

  const onChange = studentScore => setState({ studentScore });

  const submitHandler = () => {
    if (studentScore.length === 0) return;
    const nextStudentScore = studentScore.map(item => {
      item.id = item.id.toString();
      item.seatNumber = item.seatNumber.toString();
      return item;
    });
    const payload = {
      client: 'tikuHualien',
      data: {
        examID,
        classCode: selectedClass,
        studentScore: nextStudentScore
      }
    };
    registerScoreUpload(payload);
  };


  useEffect(() => {
    if (!memberInfo.accessToken) return;
    getRegisterScore();
  }, []);

  return (
    <TitleContainer
      title="非選登分"
      titleIcon="how_to_reg"
    >
      <ColorBlockTitle>
        <div>{examName}</div>
        <UiSelectBox>
          <Select label="班級" isFlex={true} selectName="selectedClass" options={options} onChange={onChangeHandler} />
        </UiSelectBox>
      </ColorBlockTitle>
      {
        selectedClass &&
        <RegisterScoreTable
          score={scoreValue}
          students={studentsObject[selectedClass]}
          onChange={onChange}
        />
      }
      <UiButtonBox>
        <Button textcolor="#fff" onClick={() => history.push(MAIN_PATH.EXAMSEARCH_LIST)}>返回列表</Button>
        {
          selectedClass &&
          <Button
            buttoncolor="orange"
            textcolor="#fff"
            onClick={() => submitHandler()}
          >
            輸入完畢上傳成績
          </Button>
        }
      </UiButtonBox>
    </TitleContainer>
  );
};
