/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMember } from 'store/member';
import {
  ColorBlockTitle,
  DropTable,
  ExpandableContainer,
} from 'components';
import { useQuestion } from 'store/question';

/**
 * 題目分析
 */

const schema = [
  {
    name: '題型'
  },
  {
    name: '已選題數'
  },
  {
    name: '已選答數'
  },
  {
    name: '配分',
    size: 'large'
  },
  {
    name: '百分比'
  },
  {
    name: '小計'
  }
];

export const QuestionsSort = ({
  examID,
}) => {
  const [{
    scoreType,
    section,
    searchQusetionPayload,
    addedQuestionList,
    selections,
    chapter,
    examAnalysis,
    examID: oldExamID
  },
    { createExam,
      createExamBySort,
      deleteQuestionList,
      getTempExam,
      clearChapter,
      getQuestionSelections,
      updateExam,
      clearExamAnalysis,
    }] = useQuestion();

  const [{
    memberInfo
  }] = useMember();

  const getCreateExamInfo = async () => {
    const questions = addedQuestionList.map(item => item.id);
    const { client, data: {
      school,
      year,
      education,
      subject,
      examType,
      version,
      book
    } } = searchQusetionPayload;
    const payload = {
      client,
      data: {
        title: {
          school,
          year,
          subjectID: `${education}${subject}`,
          examType,
          version,
          book
        },
        user: memberInfo.id,
        questionID: [
          ...questions
        ],
      }
    };
    if (examID) payload.examID = examID;
    await createExam(payload);
  };

  const onTableChange = newSection => {
    createExamBySort(newSection);
  };

  const onChangeOrderby = (targetData, oldNumber, newNumber) => {
    const { questionInfo } = targetData;
    const newArr = [
      ...questionInfo
    ];
    newArr.splice(oldNumber - 1, 1); //刪除
    newArr.splice(newNumber - 1, 0, questionInfo[oldNumber - 1]); //新增
    const newData = {
      ...targetData,
      questionInfo: newArr
    };
    const newSection = section.map(item => {
      if (item.id === newData.id) {
        item = { ...newData };
      }
      return item;
    });
    createExamBySort(newSection);
  };

  const onDeleteHandler = id => deleteQuestionList(id);


  useEffect(() => {
    if (!memberInfo.id) return;
    if (examID) {
      if (oldExamID !== examID && chapter.chapter) {
        clearChapter();
      }
      if (selections.education.length <= 0) {
        // const schoolList = formatSchoolData(schools);
        getQuestionSelections();
      }
    } else {
      getCreateExamInfo();
    }
  }, [memberInfo]);

  useEffect(() => {
    if (!memberInfo.id) return;
    if (selections.education.length > 0 && examID) {
      const getTempExamHandler = async () => {
        let payload = {
          client: 'tikuHuailen',
          data: {
            user: memberInfo.id,
            examID
          }
        };
        if (addedQuestionList.length > 0) {
          const questions = addedQuestionList.map(item => item.id);
          const { data: { subject, education } } = searchQusetionPayload;
          payload = {
            ...payload,
            data: {
              ...payload.data,
              subjectID: `${education}${subject}`,
              questionID: questions
            }

          };
          if (Object.keys(examAnalysis).length > 0) {
            clearExamAnalysis();
            return;
          }
          await updateExam(payload);
          return;
        }
        await getTempExam({ payload, schoolName: memberInfo.schoolName });
      };
      getTempExamHandler();
    }
  }, [selections.education, memberInfo]);

  return (
    <>
      <ColorBlockTitle>題型排序與配分</ColorBlockTitle>
      <DropTable
        schema={schema}
        data={section}
        options={scoreType}
        onChange={nextSection => onTableChange(nextSection)}
      />
      <ExpandableContainer
        list={section}
        onChangeOrderby={(targetData, oldNumber, newNumber) => onChangeOrderby(targetData, oldNumber, newNumber)}
        onDelete={id => onDeleteHandler(id)}
      />
    </>
  );
};

QuestionsSort.propTypes = {
  examID: PropTypes.string
};
