import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  login as loginApi,
  adminLogin as adminLoginApi
} from 'services/api/member';
import { Api } from 'services/api';
import { useAlert } from 'utils/hooks/useAlert';
import { COMMON_PATH } from 'routes/routesConfig/constants';
import Cookies from 'js-cookie';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const SETSCHOOL = 'SETSCHOOL';


const cookieToken = Cookies.get('tikuHualien');
const token = cookieToken ? JSON.parse(cookieToken).token : '';
const userName = cookieToken ? JSON.parse(cookieToken).userName : '';
const schoolName = cookieToken ? JSON.parse(cookieToken).schoolName : '';
const isAdvisoryMember = cookieToken ? JSON.parse(cookieToken).isAdvisoryMember : false;
const isGovernment = cookieToken ? JSON.parse(cookieToken).isGovernment : false;

Api.setToken(token);

const initState = {
  memberInfo: {
    id: token,
    schoolName,
    userName,
    accessToken: token,
    oneSchool: true,
    isAdvisoryMember,
    isGovernment,
    isLogin: false
  },
  schools: [],
  isLoading: false,
};

export const useMember = () => {
  const dispatch = useDispatch();
  const { memberInfo, schools, isLoading } = useSelector(state => state.member);
  const { setAlert } = useAlert();

  const removeToken = () => {
    setTimeout(() => {
      Cookies.remove('tikuHualien', { token: '', userName: '', schoolName: '', isAdvisoryMember: '' });
      window.location.href = `${window.location.origin + COMMON_PATH.LOGIN}`;
    }, 500);
  };

  const loginAction = createAction(LOGIN, async (type, payload) => {
    if (type === 'admin') {
      const { data } = await adminLoginApi(payload);
      try {
        const { accessToken, oneSchool } = data;
        Api.setToken(accessToken);
        setAlert('登入成功！', 'success');
        return { ...data, isLogin: oneSchool };
      } catch {
        setAlert('帳號不存在或是密碼錯誤，請重新嘗試！', 'error');
        return {
          ...initState
        };
      }
    } else {
      const result = await loginApi(payload);
      try {
        const { accessToken, userName, schoolName, oneSchool, isAdvisoryMember, isGovernment } = result;
        if (oneSchool) {
          Api.setToken(accessToken);
          Cookies.set('tikuHualien',
            {
              token: accessToken,
              userName,
              schoolName,
              isGovernment,
              isAdvisoryMember
            }, { expires: 1 });
        }
        return {
          ...result,
          id: accessToken,
          isLogin: true
        };
      } catch {
        setAlert('登入失敗,請稍後再試', 'error');
        removeToken();
        return {
          ...initState
        };
      }
    }
  });

  const login = (type, payload) => dispatch(loginAction(type, payload));



  const logoutAction = createAction(LOGOUT, () => {
    setAlert('您已成功登出！', 'success');
    removeToken();
  });

  const logout = () => dispatch(logoutAction());

  const setSchoolAction = createAction(SETSCHOOL, params => {
    const { schoolName, accessToken, userName } = params;
    Api.setToken(accessToken);
    Cookies.set('tikuHualien',
      {
        token: accessToken,
        userName,
        schoolName,
        isGovernment: true,
        isAdvisoryMember: false
      }, { expires: 1 });
    return params;
  });

  const setSchool = params => dispatch(setSchoolAction(params));




  return [
    { memberInfo, schools, isLoading }, // state
    {
      login,
      logout,
      setSchool,
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    LOGIN_PENDING: (state) => ({
      ...state,
      isLoading: true,
    }),
    LOGIN_FULFILLED: (state, action) => ({
      ...state,
      memberInfo: {
        ...state.memberInfo,
        ...action.payload
      },
      isLoading: false,
    }),
    LOGIN_REJECTED: (state, action) => ({
      ...state,
      isLoading: false,
    }),
    SETSCHOOL: (state, action) => ({
      ...state,
      memberInfo: {
        ...state.memberInfo,
        ...action.payload
      }
    })
  },
  initState
);

export default reducer;
