import styled from 'styled-components';

export const UiQuestionItem = styled.div`
	display: flex;
	align-items: stretch;
	max-width: 1200px;

	& + div {
		margin-top: 2rem;
	}
`;

export const UiInfo = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	padding: 1.5rem;
	width: 100%;
	background-color: #6096ff;
`;
export const UiImageBox = styled.div`
	overflow-y: scroll;
	flex: 0.8;
	max-height: 300px;
	border: 1px solid #ddd;
`;

export const UiImage = styled.img`
	width: 100%;
	font-size: 0;

	object-fit: cover;
	cursor: pointer;
`;

export const UiContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 0.2;
	background-color: #e1ebff;

	& > ul {
		margin: 0;
		padding: 0.5rem;
		list-style-type: none;
		font-size: 1.5rem;
	}

	& > div {
		padding: 0.5rem 0.5rem 2rem 0.5rem;
		text-align: center;

		& > button {
			margin: 1rem auto;
		}

		& > a {
			display: block;
			margin-top: 0.5rem;
		}
	}
`;

export const UiSortInput = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 2rem;

	& > label {
		width: 100%;
		font-size: 1.6rem;
	}

	& > div {
		display: flex;

		& > input {
			padding: 5px;
			width: 70px;
		}
	}
`;

export const UiIcon = styled.div`
	background-color: #06f;
	border-radius: 0 5px 5px 0;
	cursor: pointer;
`;

export const UiNumber = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	width: 5rem;
	height: 5rem;
	font-size: 2.5rem;
	color: #6096ff;
	background-color: #fff;
`;

export const UiTeacherText = styled.div`
	padding: 0.55rem 1rem;
	width: 11rem;
	background-color: #fff;
	border-radius: 2rem;
`;