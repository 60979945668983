import React from 'react';
import { SingleSubjectExtent } from 'components';

/**
 * 各科題目難度
 */

export const SingleSubjectExtentPage = () => {
  return (
    <SingleSubjectExtent />
  );
};
