import React from 'react';
import PropTypes from 'prop-types';
import { UiTitleContainer, Uititle, UiContent } from './TitleContainer.style';
import { Icon } from 'components';

/**
 * 有標題的頁面內容外框組件
 */

export const TitleContainer = ({
  title = 'title',
  titleshadow = false,
  titleIcon,
  titleChildren,
  titledistributed = 'space-between',
  overflowHidden = false,
  children,
}) => {
  return (
    <UiTitleContainer>
      <Uititle titleshadow={titleshadow} titledistributed={titledistributed}>
        <h1><Icon name={titleIcon} sizetype={'large'} color={'#0f62ff'} />{title}</h1>
        <div>{titleChildren}</div>
      </Uititle>
      <UiContent overflowHidden={overflowHidden}>
        {children}
      </UiContent>
    </UiTitleContainer>
  );
};

TitleContainer.propTypes = {
  title: PropTypes.string,
  titleshadow: PropTypes.bool,
  titleIcon: PropTypes.string,
  titleChildren: PropTypes.node,
  children: PropTypes.node,
  overflowHidden: PropTypes.bool,
  titledistributed: PropTypes.oneOf(['flex-start', 'spaceBetween']),
};


