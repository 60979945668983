import styled from 'styled-components';

export const UiColorBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2rem 0;
	background-color: ${({ theme }) => theme.colorTheme.secondary};
`;

export const UiTitle = styled.div`
	margin: 2rem auto;
	padding: 1rem;
	width: max-content;
	font-size: 1.8rem;
	color: ${({ color }) => color ? color : '#fff'};
	border: 1px solid ${({ color }) => color ? color : '#fff'};
`;

export const UiIconBox = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const UiIconItem = styled.div`
	display: flex;
	align-items: center;
	width: 25%;
	flex-direction: column;
	font-size: 1.6rem;
	text-align: center;
	color: #fff;
`;

export const UiUploadFile = styled.div`
	margin: auto;
	padding: 2rem;
	width: 50%;
	font-size: 1.6rem;
	text-align: center;
	margin-bottom: 1rem;

	& > * {
		margin: 0 auto 3rem;
	}

	& > input {
		display: none;
	}
`;

