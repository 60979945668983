import { Api } from 'services/api';
// import { stringify } from 'query-string';

/**
 * 取得要上傳的資料夾名稱
 */
export const getUploadFolderName = async (params) => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/ReadAnswer/FileID`, params);
  let fileName = '';
  await response.text().then((data) => {
    fileName = data;
  });
  return fileName;
};

export const getReadCard = async (params) => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/ReadAnswer/ReadCard`, params);
  let status = '';
  await response.text().then((data) => {
    status = data;
  });
  return status;
};


export const getClassResult = async (params) => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/ReadAnswer/ClassResult`, params);

  return response.json();
};

export const submitClassResult = async (params) => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/ReadAnswer/SubmitResult`, params);
  let status = false;
  await response.text().then((data) => {
    if (data === 'Submit Success') {
      status = true;
    }
  });
  return status;
};
