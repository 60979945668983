import styled from 'styled-components';

export const UiGraph = styled.div`
	width: 100%;
	height: 70vh;
	background-color: #fff;

	.recharts-polar-radius-axis-tick text {
		position: relative;
		right: 5px;
		font-size: 1.25rem;
	}

	text {
		font-size: 1.5rem;
	}
`;
