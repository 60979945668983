import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { Icon } from 'components';
import {
  UiStickyTable,
  UiStickyRow,
  UiStickyCell,
  UiCoustomCell,
  UiStickyTwoCell,
  UiStickyCellIcon,
  UiStickyCellText,
} from './StickyTable.style';

/**
 * 固定標題表單
 */


const FixedTitle = ({
  schema = [],
  data = [],
  tableWidth = '900',
  tableHeight = '300',
  headerCount = 2,
  isSubjectTable = false,
  canSort = true,
  checkAns = false,
  getData = () => { } }) => {

  const [{ schemaSort, sortData }, setState] = useSetState({
    schemaSort: [],
    sortData: [],
    sortKey: '',
  });

  const changeSort = (index, itemData, sortType) => {
    const nextSchemaSort = schemaSort.sortList.map((item, itemIndex) => itemIndex === index ? !item : false);
    setState({
      schemaSort: { schema, sortList: nextSchemaSort }
    });
    sortTableData(index, sortType);
  };

  const sortTableData = (index, sortType) => {
    if (headerCount === 0) return;
    const { staticData, sortData } = setSkipSortData();
    // sort by value
    if (index === 0) {
      sortData.sort((a, b) => {
        return sortType === 'desc' ?
          b.catelogey.localeCompare(a.catelogey) :
          a.catelogey.localeCompare(b.catelogey);
      });
    } else {
      sortData.sort((a, b) => {
        return sortType === 'desc' ?
          b.list[index - 1] - a.list[index - 1] :
          a.list[index - 1] - b.list[index - 1]
          ;
      });
    }

    const nextSortData = staticData.concat(sortData).map(item => {
      if (item.isImportant) {
        return {
          ...item,
          catelogey: item.catelogey,
          list: item.list.map(val => (val)),
        };
      } else {
        return {
          ...item,
          catelogey: item.catelogey,
          list: item.list.map(val => (typeof val == 'string' || val instanceof String) ? val : (`${val}%`)),
        };
      }

    });

    setState({
      sortData: nextSortData,
    });
  };

  const setSkipSortData = () => {
    const skipCount = headerCount - 1;
    let staticData = [];
    const sortData = data.map(item => item);
    sortData.splice(0, skipCount);
    let nextSortData = sortData.map(item => {
      return {
        ...item,
        catelogey: item.catelogey,
        list:
          item.list.map(val =>
            (
              (typeof val == 'string' || val instanceof String) && val.includes('/')) ? val
              : parseInt(val.split('%')[0])),
      };
    });
    for (let i = 0; i < skipCount; i++) {
      staticData.push(data[i]);
    }


    return {
      staticData,
      sortData: nextSortData,
    };
  };

  const getModalData = (data) => {
    console.log('dddd', data);
  };

  useEffect(() => {
    if (schema.length <= 0) return;
    setState({
      schemaSort: { schema, sortList: schema.map(() => (false)) }
    });
  }, [schema]);

  useEffect(() => {
    if (data.length <= 0) return;
    setState({
      sortData: data
    });
  }, [data]);

  return (
    <div style={{ margin: 'auto', maxWidth: '100%', width: tableWidth + 'px', height: tableHeight + 'px', }}>
      <UiStickyTable style={{ margin: 'auto', width: '100%', }} stickyHeaderCount={headerCount} borderWidth={0}>
        <UiStickyRow>
          {
            Object.keys(schemaSort).length > 0 && schemaSort.schema.map((item, index) => {
              return (
                <UiStickyCell key={index}>
                  {
                    isSubjectTable ?
                      index === 0 ?
                        <UiStickyCellText>
                          {item}
                        </UiStickyCellText> :
                        <div onClick={() => { getModalData(data[0].list[index - 1]); }}>
                          {item}
                        </div>
                      :
                      <UiStickyCellText>
                        {item}
                      </UiStickyCellText>
                  }

                  {
                    (schemaSort.sortList[index] && canSort) &&
                    <UiStickyCellIcon>
                      <Icon color='#6096ff' name='arrow_upward' onClick={() => { changeSort(index, item, 'asc'); }} />
                    </UiStickyCellIcon>
                  }
                  {
                    (!schemaSort.sortList[index] && canSort) &&
                    <UiStickyCellIcon>
                      <Icon
                        color='#6096ff'
                        name='arrow_downward'
                        onClick={() => { changeSort(index, item, 'desc'); }}
                      />
                    </UiStickyCellIcon>
                  }
                </UiStickyCell>
              );
            })
          }
        </UiStickyRow>
        {
          sortData.map((item, index) => {
            return (
              <UiStickyRow key={index}>
                <UiStickyCell isImportant={item.isImportant}>{item.catelogey}</UiStickyCell>
                {
                  item.list.map((li, liIndex) => {
                    if (liIndex === 1 && checkAns) {
                      return (
                        <UiStickyCell key={liIndex} isImportant={item.isImportant}>
                          {
                            item.list[liIndex] === item.list[liIndex - 1] ?
                              'V' :
                              <p>{li}</p>
                          }
                        </UiStickyCell>
                      );
                    } else {
                      return (
                        <UiStickyCell key={liIndex} isImportant={item.isImportant}>
                          {li}
                        </UiStickyCell>
                      );
                    }

                  })
                }
              </UiStickyRow>
            );
          })
        }
      </UiStickyTable>
    </div>
  );
};

const Normal = ({ schema = [], data = [], sendId, tableWidth = '1000', tableHeight = '500' }) => {
  return (
    <div style={{ width: tableWidth + 'px', height: tableHeight + 'px', }}>
      <UiStickyTable style={{ margin: 'auto', }}>
        <UiStickyRow>
          {
            schema.map((item, index) => {
              return (
                <UiStickyCell key={index}>{item}</UiStickyCell>
              );
            })
          }
        </UiStickyRow>
        {
          data.map((item, index) => {
            return (
              <UiStickyRow key={index}>
                {
                  sendId &&
                  <UiStickyCell onClick={() => sendId(item.id)}>
                    <div>{index + 1}</div>
                  </UiStickyCell>
                }
                {
                  Object.entries(item).map(([key, value], cellIndex) => {
                    if (key === 'id') return '';
                    return (
                      <UiStickyCell key={cellIndex}>{value}</UiStickyCell>
                    );
                  })
                }
              </UiStickyRow>
            );
          })
        }
      </UiStickyTable>
    </div>
  );
};

const Custom = ({ schema = [], tableWidth = '1000', tableHeight = '500' }) => {
  return (
    <div style={{ width: tableWidth + 'px', height: tableHeight + 'px', }}>
      <UiStickyTable style={{ margin: 'auto', }}>
        <UiStickyRow>
          {
            schema.map((item, index) => {
              return (
                <UiStickyCell key={index}>{item}</UiStickyCell>
              );
            })
          }
        </UiStickyRow>
        <UiStickyRow>
          <UiCoustomCell>
            <div>國文</div>
          </UiCoustomCell>
          <UiStickyCell>39/48</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>80</UiStickyCell>
          <UiStickyCell>85</UiStickyCell>
        </UiStickyRow>
        <UiStickyRow>
          <UiCoustomCell>
            <div>數學</div>
            <div>
              <div>選擇</div>
              <div>非選</div>
            </div>
          </UiCoustomCell>
          <UiStickyTwoCell>
            <div>
              <div>21/26</div>
              <div>3/3</div>
            </div>
          </UiStickyTwoCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>80</UiStickyCell>
          <UiStickyCell>85</UiStickyCell>
        </UiStickyRow>
        <UiStickyRow>
          <UiCoustomCell>
            <div>英文</div>
            <div>
              <div>閱讀</div>
              <div>聽力</div>
            </div>
          </UiCoustomCell>
          <UiStickyTwoCell>
            <div>
              <div>21/26</div>
              <div>3/3</div>
            </div>
          </UiStickyTwoCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>80</UiStickyCell>
          <UiStickyCell>85</UiStickyCell>
        </UiStickyRow>
        <UiStickyRow>
          <UiCoustomCell>
            <div>自然</div>
          </UiCoustomCell>
          <UiStickyCell>39/48</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>80</UiStickyCell>
          <UiStickyCell>85</UiStickyCell>
        </UiStickyRow>
        <UiStickyRow>
          <UiCoustomCell>
            <div>社會</div>
          </UiCoustomCell>
          <UiStickyCell>39/48</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>81.25</UiStickyCell>
          <UiStickyCell>80</UiStickyCell>
          <UiStickyCell>85</UiStickyCell>
        </UiStickyRow>
      </UiStickyTable>
    </div>
  );
};

export const StickyTable = {
  FixedTitle,
  Normal,
  Custom,
};

FixedTitle.propTypes = {
  schema: PropTypes.array,
  data: PropTypes.array,
  tableWidth: PropTypes.string,
  tableHeight: PropTypes.string,
  headerCount: PropTypes.number,
  isSubjectTable: PropTypes.bool,
  getData: PropTypes.func,
  canSort: PropTypes.bool,
  checkAns: PropTypes.bool,
};

Normal.propTypes = {
  schema: PropTypes.array,
  data: PropTypes.array,
  tableWidth: PropTypes.string,
  tableHeight: PropTypes.string,
  sendId: PropTypes.func
};

Custom.propTypes = {
  schema: PropTypes.array,
  tableWidth: PropTypes.string,
  tableHeight: PropTypes.string,
};
