import { useLocation } from 'react-router-dom';

export const useQuery = (key) => {
  const queryString = useLocation().search;
  const getQueryParams = key => {
    const searchParams = new URLSearchParams(queryString);
    return key ? searchParams.get(key) : searchParams;
  };
  return { getQueryParams };
};
