import styled from 'styled-components';


export const UiUploadBox = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	padding: 0 1.5rem;
`;

export const UiUploadButton = styled.label`
	position: relative;
	display: flex;
	padding: 5px 25px;
	width: max-content;
	font-size: 1.5rem;
	color: #fff;
	background: ${({ theme }) => theme.btn.normal};
	border: none;
	border-radius: 5px;
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
	cursor: pointer;
	transition: 0.35s;

	& > input {
		display: none;
	}

	&:hover {
		background: ${({ theme }) => theme.btn.normalhover};
	}
`;

export const UiPrompt = styled.div`
	padding: 0 0.5rem;
	font-size: 1.4rem;
	font-weight: bold;
`;
