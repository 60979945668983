import styled from 'styled-components';

export const UiInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 2rem 3rem;
	width: 100%;
	font-size: 1.8rem;
	background-color: ${({ theme }) => theme.colorTheme.secondary};
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	& > div {
		display: flex;

		& > * + * {
			margin-left: 1rem;
		}
	}
`;

export const UiContent = styled.div`
	padding: 2rem 3rem;
	width: 100%;
	flex: 1;
	background-color: ${({ theme }) => theme.colorTheme.quaternary};
	word-break: break-all;

	div {
		font-size: 1rem;
		color: #3c4858;
		margin-bottom: 1.5rem;

		span {
			padding: 0 1rem;
		}
	}
`;
