import React from 'react';
import PropTypes from 'prop-types';
import { UiColorBlockTitle } from './ColorBlockTitle.style';


/**
 * 色塊標題
 */

export const ColorBlockTitle = ({ bgcolor, color, children }) => {
  return (
    <UiColorBlockTitle bgcolor={bgcolor} color={color}>
      {children}
    </UiColorBlockTitle>
  );
};

ColorBlockTitle.propTypes = {
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any
};


