import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiNestedListGroup, UiCheckAll } from './NestedListGroup.style';
import { NestedList, ListItem, Checkbox } from 'components';
import { useSetState } from 'utils/hooks/useSetState';


/**
 * 巢狀checkedList組件群組
 */



export const NestedListGroup = ({
  data,
  getData,
  maxLevel = 3,
  checkAll,
}) => {
  const [{ listData, isAllSelected }, setState] = useSetState({
    listData: [],
    isAllSelected: false,
  });

  //選擇全部的checkbox
  const onAllCheckBoxChange = (value, isChecked) => {
    setState({
      isAllSelected: isChecked
    });
    nestedListDataChange(listData, value, isChecked);
    if (getData) {
      getData(listData, isChecked);
    }
  };

  //checkbox變更時 觸發相關的func
  const checkedDataChange = (val, isChecked) => {
    nestedListDataChange(listData, val, isChecked);
    checkAllSelected(listData);
    const isAllSelected = (listData.findIndex((item) => item.checked === false) === -1);
    setState({
      isAllSelected,
      checked: isAllSelected
    });
    if (getData) {
      getData(listData);
    }
  };

  //更改listData checked資料
  const nestedListDataChange = (data, checkName, isChecked) => {
    data.forEach(obj => {
      if (obj.value === checkName || checkName === 'all') {
        obj.checked = isChecked;
        if (Object.prototype.hasOwnProperty.call(obj, 'nextPart')) {
          nestedListDataChange(obj.nextPart, 'all', isChecked);
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(obj, 'nextPart')) {
          nestedListDataChange(obj.nextPart, checkName, isChecked);
        }
      }

    });
  };

  //檢查子物件全部checked:true時 父物件checked:true
  const checkAllSelected = array => {
    let nextIsAllChecked = false;
    array.forEach((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'nextPart')) {
        checkAllSelected(obj.nextPart);
        nextIsAllChecked = obj.nextPart.findIndex(item => item.checked === false) !== -1 ? false : true;
        obj.checked = nextIsAllChecked;
      }
    });
  };
  //渲染畫面
  const renderList = (data, nowlevel = 1) => {
    const list = data.map((item, index) => {
      if (Object.prototype.hasOwnProperty.call(item, 'nextPart') && nowlevel < maxLevel) {
        return (
          <NestedList
            key={index}
            name={item.name}
            label={item.name}
            value={item.value}
            isChecked={item.checked}
            onChange={checkedDataChange}
          >
            {renderList(item.nextPart, nowlevel + 1)}
          </NestedList>
        );
      } else {
        return (
          <ListItem
            key={index}
            name={item.name}
            label={item.name}
            value={item.value}
            isChecked={item.checked}
            onChange={checkedDataChange}
          />
        );
      }
    });
    return list;

  };


  useEffect(() => {
    setState({
      listData: data
    });
  }, [data]);

  useEffect(() => {
    setState({
      isAllSelected: checkAll
    });
  }, [checkAll]);

  return (
    <UiNestedListGroup>
      {renderList(listData)}
      <UiCheckAll>
        <Checkbox label='選擇全部' tick={isAllSelected} value={'all'} onCheck={onAllCheckBoxChange} />
      </UiCheckAll>
    </UiNestedListGroup>
  );
};

NestedListGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
    })
  ),
  getData: PropTypes.func,
  maxLevel: PropTypes.number,
  checkAll: PropTypes.bool
};


