const HOME = '/home';

export const COMMON_PATH = {
  LOGIN: '/',
};

export const MAIN_PATH = {
  ANNOUNCEMENT_LIST: `${HOME}`,
  EDIT_ANNOUNCEMENT_LIST: `${HOME}/editAnnouncement`,
  ANNOUNCEMENT_DETAIL: `${HOME}/announcementDetail`,
  ANNOUNCEMENT_FORM: `${HOME}/announcementForm`,
  EXAMPAPER_LIST: `${HOME}/examPaperList`,
  QUESTION_DETAIL: `${HOME}/questionDetail`,
  SEARCHQUESTION: `${HOME}/searchQuestion`,
  NEWQUESTION_LIST: `${HOME}/newquestionList`,
  CREATEEXAM: `${HOME}/createExamPaper`,
  EXAMSEARCH_LIST: `${HOME}/examSearchList`,
  IMPORT_ANSWERCARD: `${HOME}/importAnswerCard`,
  CHECK_ANSWERCARD: `${HOME}/checkAnswerCard`,
  REGISTER_SCORE: `${HOME}/registerScore`,
  REPORTFRAMD: `${HOME}/report`,
  SINGLE_SUBJECT: `${HOME}/singleSubject`,
  SUBABILITY: `${HOME}/subAbility`,
  ALLSUBJECT_EXTENT: `${HOME}/allSubjectExtent`,
  TWOWAYSCHEDULE: `${HOME}/TwoWaySchedule`,
  RELIABILITYOFGENERALEXAMINATIONPAPERS: `${HOME}/ReliabilityOfGeneralExaminationPapers`,
  DISCRIMINATIONOFSUBJECTS: `${HOME}/DiscriminationOfSubjects`,
  DISCRIMINATIONOFALLSUBJECTS: `${HOME}/DiscriminationOfAllSubjects`,
  SINGLE_SUBJECT_EXTENT: `${HOME}/singleSubjectExtentPage`,
  REVIEW: `${HOME}/Review`,
  REVIEW_DETAIL: `${HOME}/ReviewDetail`,
  COMPREHENSIVE_ANALYSIS: `${HOME}/ComprehensiveAnalysis`,
  INSTRUCTIONS: `${HOME}/Instructions`,
  SUGGEST: `${HOME}/Suggest`,
};
