import styled, { keyframes } from 'styled-components';

const loading = keyframes`
	0% { transform: translate(0, 0); }
	50% { transform: translate(0, 15px); }
	100% { transform: translate(0, 0); }
`;

export const UiLine = styled.div`
	display: inline-block;
	width: 12px;
	height: 12px;
	background-color: ${({ theme }) => theme.palette.primary.main};
	border-radius: 12px;
`;

export const UiLoading = styled.div`
	display: block;
	margin: 3rem auto;
	text-align: center;

	& ${UiLine}:nth-last-child(1) {
		animation: ${loading} 0.6s 0.1s linear infinite;
	}

	& ${UiLine}:nth-last-child(2) {
		animation: ${loading} 0.6s 0.2s linear infinite;
	}

	& ${UiLine}:nth-last-child(3) {
		animation: ${loading} 0.6s 0.3s linear infinite;
	}
`;

