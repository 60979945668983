import React from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { Icon } from 'components';
import {
  UiAnswerCardTab,
  UiStudentTabs,
  UiStudentItem,
  UiImage,
  UiTwoCellItem,
  UiCenterContainer,
  UiSidebar,
  UiPagination,
} from './AnswerCardTab.style';


/**
 * 檢查答案卡
 */

export const AnswerCardTab = ({ data, changeData = () => { }, }) => {
  const [{
    id,
    index,
  }, setState] = useSetState({
    id: data[0].id,
    index: 1,
  });
  const setActive = userId => {
    const activeIndex = data.findIndex(item => item.id === userId);
    setState({
      id: userId,
      index: activeIndex + 1
    });
  };

  const changeStudent = activeIndex => {
    let next;
    if (activeIndex > data.length) {
      next = data.length;
    } else if (activeIndex <= 0) {
      next = 1;
    } else {
      next = activeIndex;
    }
    setActive(data[next - 1].id);
    setState({
      index: next
    });
  };
  const changeAnswerData = (index, val) => {
    let ansRightCount = 0;
    const nowStudentData = data.filter(item => (item.id === id))[0];
    const { answerList } = nowStudentData;
    answerList[index - 1].studentAnswer = val;
    answerList.forEach(item => {
      if (item.answer === item.studentAnswer) ansRightCount++;
    });

    nowStudentData.rightAnswer = ansRightCount;

    changeData(data);
  };
  const toInputUppercase = e => {
    e.target.value = ('' + e.target.value).replace(/[^a-zA-Z]/g, '').toUpperCase();
  };

  return (
    <UiAnswerCardTab>
      <UiSidebar>
        <UiStudentTabs>
          {
            data.map(item => {
              return (
                <UiStudentItem
                  key={item.id}
                  onClick={() => setActive(item.id)}
                  active={id === item.id ? true : false}
                >
                  {item.classCode} {item.seatNumber}號 {item.name}
                </UiStudentItem>
              );
            })
          }
        </UiStudentTabs>
      </UiSidebar>
      <UiCenterContainer>
        <UiImage>
          <img src={data.filter(item => (item.id === id))[0].cardPath} alt="" />
        </UiImage>
        <UiPagination>
          <Icon name="arrow_back" background="#0057ff" size="3.5rem" onClick={() => { changeStudent(index - 1); }} />
          <div> {index} / {data.length}個</div>
          <Icon name="arrow_forward" background="#0057ff" size="3.5rem" onClick={() => { changeStudent(index + 1); }} />
        </UiPagination>
      </UiCenterContainer>
      <UiSidebar>
        <UiStudentTabs height="600px">
          <UiTwoCellItem isTitle={true}>
            <div>題號</div>
            <div>作答</div>
          </UiTwoCellItem>
          {
            data.filter(item => (item.id === id))[0].answerList.map(list => {
              return (
                <UiTwoCellItem key={list.seq}>
                  <div>{list.seq}</div>
                  <div>

                    <input
                      type="text"
                      maxLength={1}
                      onInput={toInputUppercase}
                      defaultValue={list.studentAnswer}
                      onChange={(e) => { changeAnswerData(list.seq, e.target.value); }} />
                  </div>
                </UiTwoCellItem>
              );
            })
          }
        </UiStudentTabs>
        <UiStudentTabs>
          <UiTwoCellItem isTitle={true}>
            <div>答數</div>
            <div>正確</div>
          </UiTwoCellItem>
          <UiTwoCellItem>
            <div>
              {data.filter(item => (item.id === id))[0].totalAnswer}
            </div>
            <div>
              {data.filter(item => (item.id === id))[0].rightAnswer}
            </div>
          </UiTwoCellItem>
        </UiStudentTabs>
      </UiSidebar>
    </UiAnswerCardTab>
  );
};

AnswerCardTab.propTypes = {
  data: PropTypes.array
};
