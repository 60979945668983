import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
	0% {
		opacity: 0;
		transform: rotateY(-90deg) translateY(20px);
	}

	100% {
		opacity: 1;
		transform: rotateY(0) translateY(0);
	}
`;

export const UiMenu = styled.div`
	display: flex;
	align-items: center;
	width: 72%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
`;

export const UiMenuBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0.5rem 2.8rem;
	height: 100%;
	font-size: 1.5rem;
	color: #fff;
	background-color: ${({ bcolor }) => bcolor};
	transition: 0.35s;
	perspective: 1000px;
	letter-spacing: 2px;
	cursor: pointer;

	&:hover {
		background-color: #00a6ff;
	}

	&:hover div > a {
		display: block;
	}

	& > div {
		margin-right: 0.5rem;
	}
`;

export const UiMenuItem = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: block;
	padding: 0;
	width: 100%;

	& > a {
		display: none;
		margin-top: 3px;
		padding: 10px;
		color: #fff;
		background-color: #b3e4ff;
		letter-spacing: 2px;
		transition: 0.35s;

		&:nth-child(1) {
			animation: ${rotate} 300ms ease-in-out forwards;
		}

		&:nth-child(2) {
			animation: ${rotate} 400ms ease-in-out forwards;
		}

		&:nth-child(3) {
			animation: ${rotate} 500ms ease-in-out forwards;
		}

		&:nth-child(4) {
			animation: ${rotate} 500ms ease-in-out forwards;
		}

		&:hover {
			background-color: #00a6ff;
		}
	}
`;
