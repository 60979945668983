import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useMember } from 'store/member';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import {
  UiSidebarBox,
  UiSidebar,
  UiSidebarCatelogey,
  UiSidebarLink,
  UiSidebarButton,
  UiIconBox,
  UiLinkLabel,
} from './Sidebar.style';


/**
 * 側邊欄
 */

const gradesLinks = {
  catelogey: '成績總表',
  list: [
    {
      name: '全科答對率',
      url: MAIN_PATH.REPORTFRAMD
    },
    {
      name: '各科答題分析',
      url: MAIN_PATH.SINGLE_SUBJECT
    },
    {
      name: '分項能力答對率',
      url: MAIN_PATH.SUBABILITY
    },
  ]
};
const analysisLinks = {
  catelogey: '試卷分析',
  list: [
    {
      name: '全科試卷難度',
      url: MAIN_PATH.ALLSUBJECT_EXTENT
    },
    {
      name: '各科題目難度',
      url: MAIN_PATH.SINGLE_SUBJECT_EXTENT
    },
    {
      name: '全科試卷鑑別度',
      url: MAIN_PATH.DISCRIMINATIONOFALLSUBJECTS,
    },
    {
      name: '各科題目鑑別度',
      url: MAIN_PATH.DISCRIMINATIONOFSUBJECTS
    },
    {
      name: '全科試卷信度',
      url: MAIN_PATH.RELIABILITYOFGENERALEXAMINATIONPAPERS
    },
    {
      name: '雙向細目表',
      url: MAIN_PATH.TWOWAYSCHEDULE
    }
  ]
};
const defaultLinks = [
  gradesLinks,
  analysisLinks
];

export const Sidebar = () => {
  const [{ isOpen, links }, setState] = useSetState({
    isOpen: false,
    links: defaultLinks,
  });

  const history = useHistory();
  const [{ memberInfo: { userName, isGovernment } }] = useMember();
  const nowUrl = history.location.pathname;
  const sidebarToggle = () => {
    setState({
      isOpen: !isOpen
    });
  };

  useEffect(() => {
    if (userName === '') return;
    if (isGovernment) {
      setState({
        links: [
          gradesLinks,
          {
            catelogey: analysisLinks.catelogey,
            list: analysisLinks.list.concat({
              name: '綜合分析',
              url: MAIN_PATH.COMPREHENSIVE_ANALYSIS
            })
          }
        ]
      });
    }
  }, [isGovernment, userName]);
  return (
    <UiSidebarBox isOpen={isOpen}>
      <UiSidebar>
        {
          links.map((item, index) =>
            <UiSidebarCatelogey key={index}>
              <UiSidebarLink>{item.catelogey}</UiSidebarLink>
              {
                item.list.map((li, liIndex) =>
                  <UiSidebarLink key={liIndex}>
                    <NavLink to={li.url}>
                      <UiIconBox active={li.url === nowUrl ? true : false}>
                        <Icon name="arrow_right" sizetype="large" color="#95ffbb" />
                      </UiIconBox>
                      <UiLinkLabel active={li.url === nowUrl ? true : false}>{li.name}</UiLinkLabel>
                    </NavLink>
                  </UiSidebarLink>
                )
              }
            </UiSidebarCatelogey>
          )
        }
        <UiSidebarButton onClick={() => sidebarToggle()}>
          <Icon name="insert_chart_outlined" color="#0f62ff" sizetype="large" />
          總表及分析
        </UiSidebarButton>
      </UiSidebar>
    </UiSidebarBox>
  );
};

Sidebar.propTypes = {
  links: PropTypes.shape({
    catelogey: PropTypes.string.isRequired,
    list: PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  })
};


