import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  createAnnouncement as createAnnouncementApi,
  getAnnouncement as getAnnouncementApi,
  updateAnnouncement as updateAnnouncementApi,
  deleteAnnouncement as deleteAnnouncementApi,
  getEditAnnouncement as getEditAnnouncementApi
} from 'services/api/announcement';
import { convertArrayToMapById } from 'utils/array';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
const GET_EDITANNOUNCEMENTS = 'GET_EDITANNOUNCEMENTS';
const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';

const initState = {
  announcements: {
    data: [],
    dataMap: [],
    isEdit: false
  },
  editAnnouncements: {
    data: []
  },
  announcement: {},
  isLoading: false
};

export const useAnnouncement = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { announcements, announcement, editAnnouncements, isLoading } = useSelector(state => state.announcement);

  const createAnnouncementAction = createAction(CREATE_ANNOUNCEMENT, async params => {
    const result = await createAnnouncementApi(params);
    if (result) {
      setAlert(ALERT_MESSAGE.CREATE_SUCCESS, 'success');
    } else {
      setAlert(ALERT_MESSAGE.CREATE_FAIL, 'fail');
    }
    return result;
  });
  const createAnnouncement = params => dispatch(createAnnouncementAction(params));

  const getAnnouncementAction = createAction(GET_ANNOUNCEMENTS, async () => {
    try {
      const { isEdit, announcements } = await getAnnouncementApi();
      let data = [], dataMap = [];
      if (Array.isArray(announcements)) {
        data = announcements.map(item => {
          const startOn = item.date.split('～')[0];
          const endOn = item.date.split('～')[1];
          item.startAt = startOn;
          item.endAt = endOn;
          return item;
        });
        dataMap = convertArrayToMapById(announcements);
      }
      return { data, dataMap, isEdit };
    } catch{
      return { data: [], dataMap: [] };
    }
  });

  const getAnnouncement = () => dispatch(getAnnouncementAction());

  const getEditAnnouncementAction = createAction(GET_EDITANNOUNCEMENTS, async () => {
    try {
      const { announcements } = await getEditAnnouncementApi();
      const data = announcements.map(item => {
        const startOn = item.date.split('～')[0];
        const endOn = item.date.split('～')[1];
        item.startAt = startOn;
        item.endAt = endOn;
        return item;
      });
      return { data };
    } catch{
      return { data: [] };
    }
  });

  const getEditAnnouncement = () => dispatch(getEditAnnouncementAction());

  const getAnnouncementByIdAction = createAction(GET_ANNOUNCEMENT, async id => {
    try {
      const response = await getAnnouncementApi(id);
      const error = {
        message: 'no data'
      };
      if (!response.id) throw error;
      return response;
    } catch{
      return;
    }
  });

  const getAnnouncementById = id => dispatch(getAnnouncementByIdAction(id));

  const updateAnnouncementAction = createAction(UPDATE_ANNOUNCEMENT, async payload => {
    try {
      await updateAnnouncementApi(payload);
      setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    } catch{
      setAlert(ALERT_MESSAGE.UPDATE_FAIL, 'error');
    }
    return;
  });

  const updateAnnouncement = payload => dispatch(updateAnnouncementAction(payload));


  const deleteAnnouncementAction = createAction(DELETE_ANNOUNCEMENT, async (params) => {
    await deleteAnnouncementApi(params);
    setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
    return;
  });

  const deleteAnnouncement = (params) => dispatch(deleteAnnouncementAction(params));


  return [
    { announcements, editAnnouncements, announcement, isLoading }, // state
    {
      createAnnouncement,
      getAnnouncement,
      getEditAnnouncement,
      getAnnouncementById,
      updateAnnouncement,
      deleteAnnouncement
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    CREATE_ANNOUNCEMENT_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    CREATE_ANNOUNCEMENT_FULFILLED: (state, action) => ({
      ...state,
      isLoading: false
    }),
    CREATE_ANNOUNCEMENT_REJECTED: (state, action) => ({
      ...state,
      isLoading: false
    }),
    GET_ANNOUNCEMENTS_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_ANNOUNCEMENTS_FULFILLED: (state, action) => ({
      ...state,
      announcements: {
        data: action.payload.data,
        dataMap: action.payload.dataMap,
        isEdit: action.payload.isEdit
      },
      isLoading: false
    }),
    GET_ANNOUNCEMENTS_REJECT: (state, action) => ({
      ...state,
      announcements: {
        data: [],
        dataMap: []
      },
      isLoading: false
    }),
    GET_EDITANNOUNCEMENTS_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_EDITANNOUNCEMENTS_FULFILLED: (state, action) => ({
      ...state,
      editAnnouncements: {
        data: action.payload.data,
      },
      isLoading: false
    }),
    GET_EDITANNOUNCEMENTS_REJECT: (state, action) => ({
      ...state,
      editAnnouncements: {
        data: [],
      },
      isLoading: false
    }),
    GET_ANNOUNCEMENT_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_ANNOUNCEMENT_FULFILLED: (state, action) => ({
      ...state,
      announcement: {
        ...action.payload
      },
      isLoading: false
    }),
    GET_ANNOUNCEMENT_REJECT: (state, action) => ({
      ...state,
      announcements: {},
      isLoading: false
    }),
  },
  initState
);

export default reducer;
