import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Graph } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiTabs, UiTabItem } from './GraphModal.style';


/**
 *
 */

export const GraphModal = ({
  tabs,
  open = false,
  modalStateHandler = () => { },
}) => {
  const [{ tab, isOpen, data, type, color }, setState] = useSetState({
    tab: 'one',
    isOpen: false,
    data: [],
    type: '',
    color: ''
  });
  const buttons = [
    {
      text: 'X 關閉',
      func: () => { getModalState(false); }
    },
  ];
  const getModalState = state => {
    setState({
      isOpen: state
    });
    modalStateHandler(state);
  };
  const chooseChart = () => {
    switch (type) {
      case 'verticalBar':
        return (
          <Graph.VerticalBar data={data} simple={true} />
        );
      case 'pie':
        return (
          <Graph.Pie data={data} />
        );
      default:
        return;
    }
  };
  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);
  useEffect(() => {
    const goal = tabs.filter(item => item.value === tab)[0];
    setState({
      data: goal.data,
      color: goal.color,
      type: goal.chart
    });
  }, [tab]);
  return (
    <Modal
      open={isOpen}
      titleIcon="pie_chart"
      title="選題分析"
      buttons={buttons}
      getModalState={getModalState}
    >
      <UiTabs color={color}>
        {
          tabs.map((item, index) => {
            return (
              <UiTabItem key={index} color={item.color} onClick={() => setState({ tab: item.value })}>
                {item.name}
              </UiTabItem>
            );
          })
        }
      </UiTabs>
      {chooseChart()}
    </Modal>
  );
};

GraphModal.propTypes = {
  tabs: PropTypes.shape([{
    name: PropTypes.string,
    value: PropTypes.string,
    color: PropTypes.string
  }]),
  open: PropTypes.bool,
  type: PropTypes.string,
  data: PropTypes.array,
  modalStateHandler: PropTypes.func,
};


