import styled from 'styled-components';

export const UiQuestionDetail = styled.div`
`;
export const UiQuestionDetailWrapper = styled.div`
	width: 100%;
`;
export const UiQuestionDetailContent = styled.div`
	display: flex;
`;
export const UiQuestionDetailTitle = styled.div`
	padding: 1rem 3rem;
	font-size: 2rem;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};
`;
export const UiQuestionDetailLeft = styled.div`
	margin-right: 0.5%;
	width: 60%;
	font-size: 0;
`;

export const UiQuestionDetailRight = styled.ul`
	display: flex;
	margin: 0;
	padding: 0;
	width: 40%;
	flex-direction: column;
`;

export const UiQuestionDetailText = styled.ul`
	padding: 3rem 0;
	background: ${({ theme }) => theme.colorTheme.tertiary};
`;
export const UiQuestionDetailTextItem = styled.li`
	display: flex;
	margin: auto;
	padding: 0.1rem 0;
	width: 80%;
	font-size: 1.5rem;

	span {
		display: inline-block;
		margin-right: 3px;
		font-size: 1.5rem;
		font-weight: bold;
		white-space: nowrap;
	}
`;

export const UiQuestionDetailItem = styled.li`
	display: flex;
	padding: 1rem 3rem;
	font-size: 1.5rem;

	span {
		display: inline-block;
		margin-right: 3px;
		font-size: 1.5rem;
		font-weight: bold;
		white-space: nowrap;
	}
`;
export const UiQuestionDetailImage = styled.div`
	overflow: scroll;
	margin: 1rem auto;
	font-size: 0;
	text-align: center;

	& > div {
		& > img {
			height: inherit !important;
		}
	}
`;
