import styled from 'styled-components';

export const UiHeader = styled.div`
	position: relative;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.header};
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export const UiTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 0;
	background-color: #b6bdff;
`;

export const UiTopContent = styled.div`
	display: flex;
	align-items: stretch;
	cursor: pointer;
`;

export const UiImage = styled.div`
	margin-right: 1.5rem;
	font-size: 0;

	& > img {
		max-width: 100%;
	}
`;

export const UiText = styled.h1`
	display: flex;
	justify-content: space-between;
	margin: 0;
	padding: 0;
	font-size: 2rem;
	color: #fff;
	line-height: 1.3;
	flex-direction: column;
	cursor: default;

	& > div {
		width: 100%;
		text-align: right;
	}
`;

export const UiBottom = styled.div`
	display: flex;
	align-items: stretch;
	background-color: #005197;
`;

export const UiInfo = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0.5rem 1rem;
	width: 20%;
	font-size: 1.5rem;
	color: #fff;
	cursor: default;

	& > div:nth-child(1) {
		margin-right: 1rem;
	}
`;

export const UiLogout = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	width: 8%;
	font-size: 1.8rem;
	color: #005197;
	background-color: #e1ebff;
	cursor: pointer;
	transition: 0.35s;

	&:hover {
		color: #fff;
		background-color: #00a6ff;
	}

	i {
		transition: 0.35s;
	}

	&:hover i {
		color: #fff !important;
	}
`;




