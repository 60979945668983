import React, { useEffect, useCallback } from 'react';
import {
  ReportContainer,
  Select,
  Icon,
  QuestionTable,
  AutoComplete,
} from 'components';
import { SELECTLIST } from 'constants/index';
import { useSetState } from 'utils/hooks/useSetState';
import { useReport } from 'store/report';
import { useModal } from 'utils/hooks/useModal';

import {
  UiOptionRemind,
  UiGraphRemindRow
} from './TwoWaySchedule.style';


/**
 * 報表 - 雙向細目表
 */

export const TwoWaySchedule = () => {
  const { setModal } = useModal();
  const [{
    educationSelected,
    schoolYear,
    examType,
    grade,
    group,
    subject,
  }, setState] = useSetState({
    educationSelected: '',
    schoolYear: '',
    examType: '',
    grade: '',
    group: '',
    subject: '',
  });
  const [{
    selections,
    cognitive
  }, {
    getReportSeletions,
    getCognitive,
  }] = useReport();

  const onChangeSchool = value => {
    setState({
      group: value.school,
      schoolName: value.schoolName
    });
  };

  const onChangeHandler = value => {
    setState({
      ...value
    });
  };

  const selectGroup = useCallback(() => {
    if (Object.entries(selections).length === 0) return (<div>Loading...</div>);
    const {
      education,
      schoolYear: schoolYearOptions,
      grade: gradeOptions,
      examType: examTypeOptions,
      subject: subjectOptions,
    } = selections;
    //縣籍
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            options={education}
            selectName="educationSelected"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            options={subjectOptions}
            selectName="subject"
            onChange={onChangeHandler}
          />
        </>
      );
    } else if (gradeOptions) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            options={gradeOptions}
            selectName="grade"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            options={subjectOptions}
            selectName="subject"
            onChange={onChangeHandler}
          />
        </>
      );
    } else if (subjectOptions) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            options={subjectOptions}
            selectName="subject"
            onChange={onChangeHandler}
          />
        </>
      );
    }
    return (
      <div>Loading...</div>
    );
  }, [selections, educationSelected]);


  const filterGroup = () => {
    const { education, school: schoolList } = selections;
    if (education) {
      return (
        <>
          {
            educationSelected &&
            <AutoComplete
              list={schoolList[educationSelected]}
              label="選擇學校"
              name="school"
              onChange={onChangeSchool}
            />
          }
        </>
      );
    }
  };

  useEffect(() => {
    const payload = {
      reportType: 'Cognitive'
    };
    getReportSeletions(payload);
  }, []);

  useEffect(() => {
    const { education, grade: gradeOptions, subject: subjectOptions } = selections;
    if (education) {
      if (educationSelected && schoolYear && examType && subject && group) {
        const payload = {
          education: educationSelected,
          schoolYear,
          examType,
          subject,
          schoolID: group
        };
        getCognitive(payload);
      }
    } else if (gradeOptions) {
      if (schoolYear && examType && grade && subject) {
        const payload = {
          grade,
          schoolYear,
          examType,
          subject
        };
        getCognitive(payload);
      }
    } else if (subjectOptions) {
      if (schoolYear && examType && subject) {
        const payload = {
          schoolYear,
          examType,
          subject
        };
        getCognitive(payload);
      }
    }
  }, [educationSelected, schoolYear, examType, group, subject, grade]);

  return (
    <ReportContainer
      title="雙向細目表"
      selectGroup={selectGroup()}
      radioChildren={filterGroup()}
    >
      <UiGraphRemindRow>
        <UiOptionRemind
          onClick={() => {
            setModal({
              title: '雙向細目表用途及相關提示',
              html: '<p>以雙向細目表來命題，能確保該試卷的題目能覆蓋所有的教學目標與教材內容，確保試卷的內容能測量到預期的學習目標，既能夠確立該試卷具有適當的效度。</p>',
              showCancelButton: false,
              confirmEvent: () => { },
            });
          }}
        >
          <Icon name='error' color='#f00' />
          <div>雙向細目表用途及相關提示</div>
        </UiOptionRemind>
      </UiGraphRemindRow>
      {cognitive.chapter && <QuestionTable data={cognitive} tableWidth="900" />}
    </ReportContainer>
  );
};
