import React from 'react';
import PropTypes from 'prop-types';
import { UiQuestionPagination, UiMatPagination } from './QuestionPagination.style';

/**
 * 題目詳細頁碼
 */

export const QuestionPagination = ({
  count = 1,
  onChange = () => { },
}) => {

  const onQuestionNumberChange = page => {
    onChange(page);
  };
  return (
    <UiQuestionPagination>
      <UiMatPagination
        count={count}
        variant="outlined"
        onChange={(e, page) => onQuestionNumberChange(page)}
        siblingCount={3}
      />
    </UiQuestionPagination>
  );
};

QuestionPagination.propTypes = {
  count: PropTypes.number,
  onChange: PropTypes.func,
};


