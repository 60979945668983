import commonRoutes from 'routes/routesConfig/commonRoutes';
import mainRoutes from 'routes/routesConfig/mainRoutes';

export const getAuthRoutes = role => {
  let authRoutes = [];

  switch (role) {
    default:
      authRoutes = authRoutes.concat([...mainRoutes]);
      break;
  }

  return authRoutes.concat(commonRoutes);
};
