import { Api } from 'services/api';
import { stringify } from 'query-string';


/**
 * 取得搜尋選項
 */
export const getSearchSelect = async () => {
  const response = await Api.get(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Search/Selections`);
  return response.json();
};


/**
 * 取得章節資料
 * @param {*} object
 */
export const getSearchChapter = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Search/Chapter`, params);
  return response.json();
};

/**
 * 搜尋題目
 * @param {*} object
 */
export const getSearchQuestion = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Search/SearchQuestions`, params);
  return response.json();
};

/**
 * 下載單題
 * @param {*} object
 */
export const downloadSingleQuestion = async params => {
  const querystring = stringify(params);
  const response = await Api.get(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/SingleQuestion?${querystring}`);
  return response.url;
};

/**
 * 題目分析
 * @param {*} object
 */
export const calculateExamQuestion = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Calculate/ExamQuestion`, params);
  return response.json();
};

/**
 * 獲取出卷列表
 * @param {*} object
 */
export const getExamList = async params => {
  const querystring = stringify(params);
  const response = await Api.get(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Search/ExamEdit?${querystring}`);
  return response.json();
};

export const createExam = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Exam/CreateExam`, params);
  return response.json();
};

export const examCignitive = async params => {
  const { data: { section } } = params;
  const nextSection = section.map(item => ({
    ...item,
    questionInfo: item.questionInfo.map(info => info.id),
    score: parseInt(item.score)
  }));

  params.data.section = nextSection;
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Calculate/ExamCognitive`, params);
  return response.json();
};

export const emptySample = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/EmptySample`, params);
  return response.url;
};
//=== 刪除試卷 ===
export const deleteExam = async params => {
  const response = await Api.delete(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Exam/DeleteExam`, params);
  return response;
};

//=== 取得暫存試卷 ===
export const getTempExam = async params => {
  const response = await Api.post(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Exam/GetExam`, params);
  return response.json();
};

//=== 更新暫存試卷 ===
export const updateExam = async params => {
  const response = await Api.put(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Exam/UpdateExam`, params);
  return response.json();
};

/**
 * 登分
 * @param {*} params
 */
export const registerScore = async payload => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/Scoring/ExamScoring`, payload);
  return response.json();
};


/**
 * 登分上傳
 * @param {*} params
 */
export const registerScoreUpload = async payload => {
  const response = await Api.post(`${process.env.REACT_APP_ANSWER_API_DOMAIN}/api/Scoring/SubmitResult`, payload);
  return response.text();
};
