/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { UiGraph } from './Graph.style';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  Pie,
  Cell,
  LineChart,
} from 'recharts';

/**
 * 圖表組件 ToDo 新增其他類型圖表
 */

//隨機產生顏色
const getRandomColor = () => {
  const COLORS = [
    '#0088fe',
    '#00c49f',
    '#ffbb28',
    '#fa6230',
    '#ddd',
    '#8884d8',
    '#57c0e8',
    '#ff6565',
    '#ffda83',
  ];
  return COLORS[Math.floor(Math.random() * COLORS.length)];
};

const radarColor = [
  '#0f62ff',
  '#ff3636',
  '#ffb800',
  '#00c49f',
  '#ffbb28',
  '#fa6230',
  '#ddd',
  '#8884d8',
  '#57c0e8',
  '#ff6565',
  '#ffda83',
];

export const CustomizedDot = ({ cx, cy, fill }) => {
  return (
    <circle cx={cx} cy={cy} r={4} stroke="black" strokeWidth={1} fill={fill} />
  );
};

export const CustomizedLabel = ({ x, y, name }) => {
  return (
    <text x={x} y={y} fill="red" textAnchor="end" dominantBaseline="central">
      {name}
    </text>
  );
};

export const customizedTick = ({ payload, x, y, index }) => {
  const cos = Math.cos(-payload.angle * Math.PI / 180);
  return (

    <text x={x - 5} y={y + 5} width={500} textAnchor={cos >= 0 ? 'start' : 'end'} fill={'#888'} >
      {payload.value}%
    </text>
  );
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} textAnchor='middle' verticalAnchor='middle'>
      {`${name}${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const GraphLayout = ({ children }) => {
  return (
    <UiGraph>
      <ResponsiveContainer width='100%' height="100%">
        {children}
      </ResponsiveContainer>
    </UiGraph>
  );
};

const radar = ({ data, types = [] }) => {
  return (
    <GraphLayout>
      <RadarChart data={data} >
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis
          angle={90}
          domain={[0, 100]}
          orientation={'left'}
          tick={customizedTick}
          ticks={[0, 20, 40, 60, 80, 100]} />
        {
          types.map((item, index) => {
            return (
              <Radar
                key={index}
                name={item.name}
                dot={<CustomizedDot />}
                dataKey={item.key}
                stroke={radarColor[index]}
                fill={radarColor[index]}
                fillOpacity={0.1} />
            );
          })
        }
        <Legend />
        <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
      </RadarChart>
    </GraphLayout>
  );
};
const barLine = ({ data, simple, uv, pv, av }) => {
  return (
    <GraphLayout>
      <ComposedChart data={data} >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickSize={12}
        />
        <YAxis
          domain={[0, 100]}
          tickFormatter={(value) => ([`${value}%`])}
          ticks={[0, 20, 40, 60, 80, 100]}
          tickSize={12}
        />
        {
          simple ?
            <Tooltip formatter={(value, name, props) => ([`${value}%`])} /> :
            <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
        }
        {
          !simple &&
          <Legend />
        }
        <Bar isAnimationActive={true} dataKey={uv} fill="#FFB800" />
        <Line strokeWidth={5} isAnimationActive={true} dataKey={pv} stroke="#0F62FF" />
        {
          av && <Line strokeWidth={5} dataKey={av} stroke="#FF3636" />
        }
      </ComposedChart>
    </GraphLayout>
  );
};
const bar = ({ data, simple }) => {
  return (
    <GraphLayout>
      <ComposedChart data={data} >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickSize={12}
        />
        <YAxis
          domain={[0, 100]}
          tickFormatter={(value) => ([`${value}%`])}
          ticks={[0, 20, 40, 60, 80, 100]}
          tickSize={12}
          tick={{ fontSize: 14, width: 300 }}
        />
        {
          simple ?
            <Tooltip formatter={(value, name, props) => ([`${value}%`])} /> :
            <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
        }
        {
          !simple &&
          <Legend />
        }
        <Bar dataKey='value' fill="#82ca9d" />
      </ComposedChart>
    </GraphLayout>
  );
};
const verticalBar = ({ data, simple }) => {
  return (
    <GraphLayout>
      <ComposedChart data={data} layout="vertical" margin={{ top: 5, right: 50, left: 75, bottom: 100 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          domain={[0, 100]}
          tickFormatter={(value) => ([`${value}%`])}
          ticks={[0, 20, 40, 60, 80, 100]}
          tickSize={12}
          type='number'
        />
        <YAxis
          dataKey="name"
          tickSize={12}
          type='category'
          allowDataOverflow={true}
          width={200}
        />
        {
          simple ?
            <Tooltip formatter={(value, name, props) => ([`${value}%`])} /> :
            <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
        }
        {
          !simple &&
          <Legend />
        }
        <Bar dataKey='value' fill="#82ca9d" />
        {/* <Line dataKey={pv} stroke="#8884d8" /> */}
      </ComposedChart>
    </GraphLayout>
  );
};
const pie = ({ data }) => {
  return (
    <GraphLayout>
      <PieChart width={600} height={400}>
        <Pie
          data={data}
          cx='50%'
          cy='50%'
          labelLine={true}
          label={renderCustomizedLabel}
          outerRadius="60%"
          fill="#8884d8"
          isAnimationActive={true}
        >
          {
            data.map((entry, index) => <Cell key={index} fill={getRandomColor()} />)
          }
        </Pie>
        <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
        <Legend />
      </PieChart>
    </GraphLayout>
  );
};

const line = ({ data, types = [] }) => {
  return (
    <GraphLayout>
      <LineChart
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          domain={[0, 100]}
          tickFormatter={(value) => ([`${value}%`])}
          ticks={[0, 20, 40, 60, 80, 100]}
          tickSize={12}
          tick={{ fontSize: 14, width: 300 }}
        />
        <Tooltip formatter={(value, name, props) => ([`${value}%`, name])} />
        <Legend />
        {
          types.map((item, index) => {
            return (
              <Line
                key={index}
                type="monotone"
                name={item.name}
                dataKey={item.key}
                strokeWidth={5}
                stroke={radarColor[index]} />
            );
          })
        }
      </LineChart>
    </GraphLayout>
  );
};

export const Graph = {
  Radar: radar,
  Bar: bar,
  VerticalBar: verticalBar,
  Pie: pie,
  BarLine: barLine,
  Line: line,
};

Graph.propTypes = {
  data: PropTypes.array,
};


