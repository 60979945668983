import styled from 'styled-components';
import { InputBase as MatInputBase } from '@material-ui/core';


export const UiPagination = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;

	& > div {
		margin: 0 0.5rem;
	}
`;

export const UiText = styled.div``;


export const UiInputBase = styled(MatInputBase)`
	width: 30px;
	background-color: #fff;
	border: 1px solid #000;
	border-radius: 5px;
	opacity: 1;

	& > input {
		width: auto;
		text-align: center;
	}
`;
