import styled from 'styled-components';

export const UiDropTable = styled.div`
	margin: 0 auto 3rem;
	width: 100%;
	max-width: 900px;
`;

export const UiDropTr = styled.div`
	display: flex;
	border-top: 1px solid #fff;
`;

export const UiDropTh = styled.div`
	padding: 1rem 1.5rem;
	font-size: 1.8rem;
	text-align: center;
	color: #fff;
	background: #6096ff;
	flex: ${({ size }) => size === 'large' ? `${(100 / 7 * 2)}%` : `${(100 / 7)}%`};

	& + div {
		border-left: 1px solid #fff;
	}
`;

export const UiDropTd = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 1.5rem;
	font-size: 1.5rem;
	text-align: center;
	color: #333;
	background-color: #c7daff;
	flex: ${({ size }) => size === 'large' ? `${(100 / 7 * 2)}%` : `${(100 / 7)}%`};

	& + div {
		border-left: 1px solid #fff;
	}

	& > div:first-child {
		margin-right: 5%;
		width: 70%;
	}

	& > div:nth-child(2) {
		margin-bottom: 7px;
		width: 25%;
	}
`;

export const UiDropFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 1.5rem;
	font-size: 1.8rem;
	color: #fff;
	background: #6096ff;
`;
