import styled from 'styled-components';

export const UiQuestionRangeSelect = styled.div`
`;
export const UiSearchQuestionWrapper = styled.div`
`;

export const UiSearchQuestionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
`;

export const UiBasicSearchQuestionHeader = styled(UiSearchQuestionHeader)`
	display: block;
`;
export const UiQuestionSelectList = styled.div`
	display: flex;
	padding: 0.5rem 3rem;
	color: #fff;
	background: ${({ theme }) => theme.colorTheme.secondary};
	flex: 1;
	flex-wrap: wrap;

	& > div {
		margin-left: 1%;
		flex: 1;

		& > div {
			margin: 1rem 0;
		}
	}
`;

export const UiQuestionCircleSelectList = styled(UiQuestionSelectList)`
	padding: 0 10rem;
	color: #000;
	background: ${({ theme }) => theme.colorTheme.quaternary};
`;

export const UiNestedListTitle = styled.div`
	padding: 0.5rem;
	font-size: 2rem;
	border: 1px solid #000;
`;

export const UiSearchQuestionContent = styled.div`
	display: flex;
	justify-content: center;
	overflow-y: scroll;
	margin: 0 3rem 2rem;
	height: 550px;
	max-height: 550px;
`;

export const UiNestedListBox = styled.div`
	display: flex;
	align-items: center;
	padding: 2rem 3rem;
	flex-direction: column;
	flex: 0.5;
`;

export const UiGetchapter = styled.div`
	text-align: center;
`;
