import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMember } from 'store/member';
import RenderRoutes from 'routes/RenderRoutes';
import { getAuthRoutes } from 'routes/authRoutes';
import { COMMON_PATH, MAIN_PATH } from 'routes/routesConfig/constants';


const AppRoutes = () => {
  const [{ memberInfo }] = useMember();
  const location = useLocation();
  const history = useHistory();

  const checkUserLoginStatus = async () => {
    const isLoginPage = location.pathname === COMMON_PATH.LOGIN;
    if (memberInfo.accessToken) {
      // 如果登入狀態 在登入頁的話就跳轉
      if (isLoginPage && memberInfo.isLogin) history.push(MAIN_PATH.ANNOUNCEMENT_LIST);

    } else {
      if (!isLoginPage) history.push(COMMON_PATH.LOGIN);
    }
  };

  useEffect(() => {
    checkUserLoginStatus();
  }, [location, memberInfo]);

  return (
    <RenderRoutes
      // routes={getAuthRoutes(profile.role)}
      routes={getAuthRoutes()}
    />
  );
};


export default AppRoutes;
