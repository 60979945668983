import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  UiModal,
  UiTitle,
  UiTextTitle,
  UiTitleRight,
  UiMatDialogContent,
} from './Modal.style';
import { Icon, Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';


/**
 * 彈窗組件
 */

export const Modal = ({
  titleIcon,
  titleChildren,
  title = 'title',
  children,
  footer,
  getModalState = () => { },
  open,
  buttons,
  type = 'question'
}) => {
  const [{ isOpen }, setState] = useSetState({
    isOpen: false,
  });

  const modalStateHandler = callBack => {
    callBack && callBack();
    getModalState(!isOpen);
    setState(!isOpen);
  };
  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);
  return (
    <UiModal
      open={isOpen}
      type={type}
    >
      {
        type === 'question' ?
          <UiTitle>
            <h1><Icon name={titleIcon} sizetype={'large'} color={'#0f62ff'} />{title}</h1>
            {titleChildren}
            {
              buttons &&
              <UiTitleRight>
                {buttons.map((button, index) => {
                  return (
                    <Button
                      onClick={() => modalStateHandler(button.func)}
                      key={button.text}
                      sizetype={button.sizetype}
                      icon={button.icon}
                      buttonborder={button.buttonborder}
                      textcolor={button.textcolor}
                      buttoncolor={button.buttoncolor}
                    >
                      {button.text}
                    </Button>
                  );
                })
                }
              </UiTitleRight>
            }

          </UiTitle>
          :
          <UiTextTitle>
            <h1>{title}</h1>
          </UiTextTitle>
      }

      <UiMatDialogContent type={type}>
        {children}
      </UiMatDialogContent>
      {footer}
    </UiModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  titleChildren: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  getModalState: PropTypes.func,
  open: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      func: PropTypes.func
    })
  ),
  type: PropTypes.string
};


