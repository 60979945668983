import React, { useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';
// import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST } from 'constants/index';
// import { useFormatterReport } from 'utils/hooks/useFormatterReport';
import { ReportContainer, Select, Graph, StickyTable, AutoComplete } from 'components';


/**
 * 綜合分析組件
 */

const selections = {
  subject: [
    { value: 'MA', name: '數學' },
  ],
  grade: [
    { value: '7', name: '一年級' }
  ],
  schoolYear: [
    { value: '106-1', name: '106學年度(上)' }
  ]
};


export const ComprehensiveAnalysis = () => {

  const [{
    reportData,
    subject,
    schoolYear,
    grade,
    subjectClassOption,
    searchCriteria,
    remind,
    schoolName,
    // schoolOption,
    education,
  }, setState] = useSetState({
    reportData: {},
    subject: '',
    schoolYear: '',
    grade: '',
    subjectClassOption: '',
    searchCriteria: {},
    remind: '',
    schoolName: '',
    // schoolOption: [],
    education: '',
  });

  // const [{
  //   selections,
  //   examAbilityData,
  // }, {
  //   getReportSeletions,
  //   getExamAbility,
  // }] = useReport();

  // const {
  //   formatterRadarGraphData,
  //   formatterSubAbilityTableData,
  // } = useFormatterReport();

  //取得radio資料（以後可能多選用selectGruop）
  // const getDataFromRadioListHandler = data => {
  //   const key = Object.keys(data)[0];
  //   const value = Object.values(data)[0];
  //   setState({
  //     [key]: value,
  //     searchCriteria: Object.assign(searchCriteria, data)
  //   });
  // };




  //基礎篩選（學年度...）
  const onChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    setState({
      searchCriteria: Object.assign(searchCriteria, data),
      [key]: value,
    });
  };


  // const onEducationChangeHandler = (data, name, edit) => {//選擇學制
  //   const key = Object.keys(data)[0];
  //   const value = Object.values(data)[0];
  //   setState({
  //     [key]: value,
  //     searchCriteria: Object.assign(searchCriteria, data),
  //   });
  //   setSchoolListOptions(value);
  // };

  // const setSchoolListOptions = (chooseEducation) => {
  //   if (selections.school[chooseEducation]) {
  //     const nextClassList = selections.school[chooseEducation].map(subject => {
  //       return {
  //         value: subject.value,
  //         name: subject.name
  //       };
  //     });
  //     setState({
  //       schoolOption: nextClassList
  //     });
  //   } else {
  //     setState({
  //       schoolOption: [],
  //     });
  //   }
  // };




  // const getReport = async (payload) => {
  //   await getExamAbility(payload);
  // };

  const selectGroup = useCallback(() => {
    // const { education } = selections;
    // if (education) {
    return (
      <>
        <Select
          label={SELECTLIST.SCHOOLYEAR.label}
          selectName={SELECTLIST.SCHOOLYEAR.name}
          options={selections.schoolYear}
          value={education}
          onChange={onChangeHandler}
        />
        <Select
          label={SELECTLIST.GRADE.label}
          selectName={SELECTLIST.GRADE.name}
          options={selections.grade}
          value={grade}
          onChange={onChangeHandler}
        />
        <Select
          label={SELECTLIST.SUBJECT.label}
          selectName={SELECTLIST.SUBJECT.name}
          options={selections.subject}
          value={subject}
          onChange={onChangeHandler}
        />
      </>
    );
    // }
    // return (
    //   <>loading...</>
    // );

  }, [selections, subjectClassOption]);






  const onChangeSchool = value => {
    setState({
      group: value.school,
      schoolName: value.schoolName
    });
  };

  const filterGroup = () => {
    // const { education } = selections;
    const { schoolName } = reportData;
    if (schoolName) {
      if (Object.keys(schoolName).length === 0) return;
      const schoolOptions = Object.entries(schoolName).map(([key, value]) => {
        return {
          name: value,
          value: key
        };
      });
      return (
        <AutoComplete
          list={schoolOptions}
          label="選擇學校"
          name="school"
          onChange={onChangeSchool}
        />
      );
    }
    return null;
  };

  const dataGroup = () => {
    // const { title, schoolName: groupList, dataList, header } = examAbilityData;
    // const graphData = formatterRadarGraphData({
    //   reportData: examAbilityData,
    //   title,
    //   groupList,
    //   dataList,
    //   reportTypeString: '答對率',
    //   reportRoleString: '全縣',
    //   group: schoolName,
    //   graphType: 'radar',
    //   header,
    // });
    // const nextTableData = formatterSubAbilityTableData({
    //   reportData: examAbilityData,
    //   title,
    //   groupList,
    //   dataList,
    //   reportTypeString: '答對率',
    //   reportRoleString: '全縣',
    //   groupName: '學校',
    //   group: schoolName,
    //   header,
    //   useImportant: false
    // });
    const graphData = {
      data: [
        { name: '知識理解', A: 54, B: 54, C: 64, D: 76, fullMark: 100 },
        { name: '程序執行', A: 53, B: 51, C: 60, D: 71, fullMark: 100 },
        { name: '解題應用', A: 45, B: 57, C: 62, D: 73, fullMark: 100 },
        { name: '分析思考', A: 47, B: 67, C: 67, D: 77, fullMark: 100 },
      ],
      types: [
        { name: '第一次段考', id: '1', key: 'A' },
        { name: '第二次段考', id: '2', key: 'B' },
        { name: '第三次段考', id: '3', key: 'C' },
        { name: '學力檢測', id: '4', key: 'D' }
      ]
    };
    const nextTableData = {
      tableSchema: [
        '分項能力',
        '月段考平均',
        '學力檢測',
        '差異',
      ],
      data: [
        {
          catelogey: '知識理解',
          list: [
            '57%',
            '76%',
            '18%',
          ]
        },
        {
          catelogey: '程序執行',
          list: [
            '55%',
            '71%',
            '16%',
          ]
        },
        {
          catelogey: '解題應用',
          list: [
            '55%',
            '73%',
            '18%',
          ]
        },
        {
          catelogey: '分析思考',
          list: [
            '60%',
            '77%',
            '17%',
          ]
        },
      ]
    };
    return (
      <>
        <Graph.Line data={graphData.data} types={graphData.types} />
        <StickyTable.FixedTitle schema={nextTableData.tableSchema} data={nextTableData.data} headerCount={1} />
      </>
    );
  };


  useEffect(() => {
    if (
      subject === '' ||
      grade === '' ||
      schoolYear === ''
    ) {
      return;
    }
    setState({
      reportData: {
        schoolName: {
          159998: '花蓮測試學校',
        }
      }
    });
    // getReport(searchCriteria);


  }, [grade, schoolYear, subject]);

  // useEffect(() => {
  //   const payload = {
  //     reportType: 'ExamAbility'
  //   };
  //   getReportSeletions(payload);
  // }, []);

  useEffect(() => {
    const { education, class: classList, subjectClass } = selections;
    //縣籍
    if (education) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (classList) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
    }
    if (subjectClass) {
      setState({
        remind: ''
      });
    }

  }, [selections]);

  return (
    <ReportContainer
      title="綜合分析"
      remind={remind}
      selectGroup={selectGroup()}
      radioChildren={filterGroup()}
    >
      {(reportData?.schoolName && schoolName !== '') && dataGroup()}
    </ReportContainer>
  );
};

ComprehensiveAnalysis.propTypes = {

};


