import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import { format } from 'date-fns';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';

import {
  queuePush as queuePushApi,
  convertList as convertListApi,
  serchConvert as serchConvertApi,
  disabledStatus as disabledStatusApi,
} from 'services/api/convert';


const QUEUE_PUSH = 'QUEUE_PUSH';
const CONVER_LIST = 'CONVER_LIST';
const CONVER_SEARCH = 'CONVER_SEARCH';
const DISABLED_STATUS = 'DISABLED_STATUS';

const initState = {
  convertList: {
    data: [],
    dataMap: [],
  },
  serachResult: {
    fileID: '',
    fileName: '',
    questions: []
  },
  isLoading: false
};

export const useConvert = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { convertList, serachResult, isLoading } = useSelector(state => state.convert);

  //轉檔 action
  const queuePushAction = createAction(QUEUE_PUSH, async payload => {
    try {
      await queuePushApi(payload);
      return true;
    } catch{
      return false;
    }

  });
  //轉檔
  const queuePush = payload => dispatch(queuePushAction(payload));


  //轉檔列表 action
  const convertListAction = createAction(CONVER_LIST, async () => {
    const data = await convertListApi();
    if (!data) return;
    const nextData = data.map(item => {
      item.systemTime = format(new Date(item.systemTime), 'yyyy-MM-dd HH:mm:ss');
      return item;
    });
    return {
      data: nextData,
    };
  });
  //轉檔列表
  const getConvertList = () => dispatch(convertListAction());

  //預覽題庫action
  const converSearchAction = createAction(CONVER_SEARCH, async payload => {
    const response = await serchConvertApi(payload);
    return response;
  });
  //預覽題庫
  const getConvertSearch = payload => dispatch(converSearchAction(payload));

  //隱藏檔案
  const disabledStatusAction = createAction(DISABLED_STATUS, async id => {
    const { isSuccess } = await disabledStatusApi(id);
    if (isSuccess) {
      setAlert(ALERT_MESSAGE.DELETE_SUCCESS, 'success');
      return id;
    }
    return null;
  });

  const getDisabledStatus = id => dispatch(disabledStatusAction(id));

  return [
    { convertList, serachResult, isLoading }, // state
    {
      queuePush,
      getConvertList,
      getConvertSearch,
      getDisabledStatus,
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    CONVER_LIST_PENDING: (state) => ({
      ...state,
      isLoading: true
    }),
    CONVER_LIST_FULFILLED: (state, action) => ({
      ...state,
      convertList: {
        data: action.payload.data,
        dataMap: []
      },
      isLoading: false
    }),
    CONVER_LIST_REJECTED: (state, action) => ({
      ...state,
      convertList: {
        data: [],
        dataMap: []
      },
      isLoading: false
    }),
    QUEUE_PUSH_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    QUEUE_PUSH_FULFILLED: state => ({
      ...state,
      isLoading: false
    }),
    QUEUE_PUSH_REJECT: state => ({
      ...state,
      isLoading: false
    }),
    CONVER_SEARCH_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    CONVER_SEARCH_FULFILLED: (state, action) => ({
      ...state,
      serachResult: action.payload,
      isLoading: false
    }),
    CONVER_SEARCH_REJECTED: state => ({
      ...state,
      isLoading: false
    }),
    DISABLED_STATUS_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    DISABLED_STATUS_FULFILLED: (state, action) => {
      const nextData = state.convertList.data.filter(item => item.fileID !== action.payload);
      return {
        ...state,
        convertList: {
          data: nextData,
          dataMap: []
        },
        isLoading: false
      };
    },
    DISABLED_STATUS_REJECTED: state => ({
      ...state,
      isLoading: false
    })
  },
  initState
);

export default reducer;
