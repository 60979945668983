import styled from 'styled-components';

export const UiOptionBox = styled.div`
	background-color: ${({ theme }) => theme.colorTheme.secondary};
`;

export const UiOptionTop = styled.div`
	display: flex;
	padding: 1rem;
	padding-left: 8rem;
	color: #fff;

	& > * {
		flex: 1;
		max-width: 20%;

		& + * {
			margin-left: 1rem;
		}
	}
`;

export const UiOptionBottom = styled.div`
	padding: 1rem;
	padding-left: 8rem;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};
`;
export const UiOptionRemind = styled.div`
	font-size: 1.4rem;
	color: #f00;
`;
export const UiRadioTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.75rem;
`;
export const UiRadioBox = styled.div`
	display: flex;
`;

export const UiContent = styled.div`
	display: flex;
	align-items: center;
	margin: 3rem auto;
	width: 80%;
	min-height: 700px;
	flex-direction: column;

	& > * {
		min-width: 340px;
		margin-bottom: 5rem;

		& + * {
			margin-bottom: 3rem;
		}
	}
`;
