import styled from 'styled-components';

export const UiSliderBar = styled.div`
`;
export const Uilabel = styled.h3`
	margin: 0 0 2rem 0;
`;
export const UiSliderBarFlex = styled.div`
	display: flex;
	align-items: center;
	max-width: 450px;

	& > *:not(:first-child) {
		margin-left: 2rem;
	}
`;
