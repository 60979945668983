import styled from 'styled-components';

export const UiAnnouncementForm = styled.div`
	i {
		color: #f44336;
	}
`;

export const UiAnnouncementFormWrapper = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colorTheme.quaternary};
`;

export const UiAnnouncementFormRow = styled.div`
	margin-bottom: 2rem;
	display: flex;

	& > * {
		width: -webkit-fill-available;
		font-size: 2rem;
	}
`;

export const UiAnnouncementFormContent = styled.div`
	margin: auto;
	padding: 5rem 0;
	width: 80%;
`;

export const UiInlineFlex = styled.div`
	display: flex;

	& > button {
		width: 12rem;

		&:first-child {
			margin-right: 3rem;
		}
	}
`;
