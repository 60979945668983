import { Api } from 'services/api';
import { stringify } from 'query-string';


//第三方登入來的
export const login = async payload => {
  const querystring = stringify(payload);
  try {
    const response = await Api.put(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Member/Login?${querystring}`);
    return response.json();
  } catch (error) {
    console.log('error', error);
  }
};

//管理員登入
export const adminLogin = async payload => {
  try {
    const response = await Api.post(`${process.env.REACT_APP_TIKU_MEMBER_DOMAIN}/users/admin-login`, payload);
    return response.json();
  } catch (error) {
    console.log('error', error);
  }
};
