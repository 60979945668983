import styled from 'styled-components';

export const UiSearchQuestion = styled.div`
`;
export const UiSearchQuestionWrapper = styled.div`
`;
export const UiSearchQuestionContent = styled.div`
	display: flex;
	justify-content: center;
	height: 550px;
	max-height: 550px;
`;

export const UiSearchQuestionHeader = styled.div`
	display: flex;
	justify-content: space-between;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
`;

export const UiBasicSearchQuestionHeader = styled(UiSearchQuestionHeader)`
	display: block;
`;

export const UiQuestionSelectList = styled.div`
	display: flex;
	padding: 0.5rem;
	padding: 0 3rem;
	background: #95ffbb;
	flex: 1;
	flex-wrap: wrap;

	& > div {
		margin-left: 1%;
		flex: 1;

		& > div {
			margin: 1rem 0;
		}
	}
`;

export const UiQuestionCircleSelectList = styled(UiQuestionSelectList)`
	background: #daffe7;
`;

export const UiQuestionRadioGroup = styled.div`
	padding: 2rem 0;
	width: 12.5vw;
	min-width: 120px;
	text-align: center;
	background: #daffe7;

	& > div {
		display: inline-flex;
		justify-content: space-between;
		height: 100%;
		flex-direction: column;
	}
`;
export const UiNestedListBox = styled.div`
	display: flex;
	align-items: center;
	overflow-y: scroll;
	padding: 2rem 3rem;
	box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.3);
	flex-direction: column;
	flex: 1;
`;
export const UiNestedListTitle = styled.div`
	padding: 0.5rem;
	font-size: 2rem;
	border: 1px solid #000;
`;
export const UiQuestionListBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;
export const UiQuestionList = styled.div`
	overflow-y: scroll;
	padding: 3rem 2rem;
	flex: 1;

	& > div {
		margin-top: 2rem;
	}

	& > div:first-child {
		margin-top: 0;
	}

`;
export const UiQuestionListHeader = styled.div`
	position: relative;
`;
export const UiQuestionListItem = styled.div`
	& > div {
		background-color: #c4c4c4;
	}
`;

export const UiQuestionListHeaderContent = styled.div`
	display: flex;
	background: #6096ff;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
`;
export const UiQuestionSearchBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 2rem;
	font-size: 2rem;
	color: #fff;
	flex: 1;
	cursor: pointer;
`;
export const UiQuestionSearchDrawer = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: flex;
	align-items: center;
	padding: ${({ drawerIsOpen }) => drawerIsOpen ? '2rem' : '0 2rem'};
	width: 100%;
	height: ${({ drawerIsOpen }) => drawerIsOpen ? 'auto' : '0px'};
	color: #000;
	background-color: #fff;
	opacity: ${({ drawerIsOpen }) => drawerIsOpen ? '1' : '0'};
	z-index: ${({ theme }) => theme.zIndex.drawer};
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
	transition: 0.5s;
	flex-wrap: wrap;

	& > div {
		margin: 2rem 0 0;
		width: 32%;
	}

	& > div:not(:nth-child(3n+1)) {
		margin-left: 2%;
	}
`;
export const UiQuestionSearchPagination = styled.div`
	width: 12.5vw;
	background: #c7daff;

	& > div {
		height: 100%;
	}
`;
export const UiIconBox = styled.div`
	cursor: pointer;
	transform: ${({ drawerIsOpen }) => drawerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: 0.5s;
`;

export const UiGetchapter = styled.div`
	text-align: center;
`;

export const UiQuestionListContent = styled.div`
	display: flex;
	flex: 1;
`;

export const UiNestedListHeader = styled(UiQuestionListHeader)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2.25rem 0;
	width: 100%;
	font-size: 2rem;
	color: #000;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};
`;
export const UiQuestionLeftBox = styled(UiNestedListBox)`
	flex: 0.2;
	padding: 0;
`;
