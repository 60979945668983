import styled from 'styled-components';

export const UiQuestionPickList = styled.div`
`;

export const UiQuestionPickWrapper = styled.div`
`;

export const UiQuestionPickContent = styled.div`
	display: flex;
	flex: 1;
	height: 650px;
	max-height: 650px;
`;

export const UiNestedListBox = styled.div`
	display: flex;
	align-items: center;
	overflow-y: scroll;
	padding: 2rem 3rem;
	box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.3);
	flex-direction: column;
	flex: 1;
`;

export const UiQuestionLeftBox = styled(UiNestedListBox)`
	flex: 0.3;
	padding: 0;
`;

export const UiNestedListTitle = styled.div`
	padding: 0.5rem;
	font-size: 2rem;
	border: 1px solid #000;
`;

export const UiQuestionPickBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const UiQuestionPickHeader = styled.div`
	position: relative;
`;

export const UiQuestionPickItem = styled.div`
	& > div {
		background-color: #c4c4c4;
	}
`;

export const UiQuestionPickHeaderContent = styled.div`
	display: flex;
	background: #6096ff;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
`;

export const UiQuestionSearchBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 2rem;
	font-size: 2rem;
	color: #fff;
	flex: 1;
	cursor: pointer;
`;

export const UiIconBox = styled.div`
	cursor: pointer;
	transform: ${({ drawerIsOpen }) => drawerIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
	transition: 0.5s;
`;

export const UiQuestionSearchDrawer = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: flex;
	align-items: center;
	padding: ${({ drawerIsOpen }) => drawerIsOpen ? '2rem' : '0 2rem'};
	width: 100%;
	height: ${({ drawerIsOpen }) => drawerIsOpen ? 'auto' : '0px'};
	color: #000;
	background-color: #fff;
	opacity: ${({ drawerIsOpen }) => drawerIsOpen ? '1' : '0'};
	z-index: ${({ theme }) => theme.zIndex.drawer};
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
	transition: 0.5s;
	flex-wrap: wrap;

	& > div {
		margin: 2rem 0 0;
		width: 32%;
	}

	& > div:not(:nth-child(3n+1)) {
		margin-left: 2%;
	}
`;

export const UiQuestionSearchPagination = styled.div`
	width: 12.5vw;
	background: #c7daff;

	& > div {
		height: 100%;
	}
`;

export const UiQuestionPick = styled.div`
	overflow-y: scroll;
	padding: 3rem 2rem;
	flex: 1;

	& > div {
		margin-top: 2rem;
	}

	& > div:first-child {
		margin-top: 0;
	}

`;


export const UiNestedListHeader = styled(UiQuestionPickHeader)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2.25rem 0;
	width: 100%;
	font-size: 2rem;
	color: #000;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};
`;


export const UiFixedBox = styled.div`
	position: absolute;
	top: 110%;
	right: 0;
	text-align: center;
	border-radius: 5px;
	z-index: ${({ theme }) => theme.zIndex.drawer};
	box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.6);
`;
export const UiFixedBoxTop = styled.div`
	display: flex;
	align-items: center;
	padding: 1.5rem;
	font-size: 2rem;
	color: #ff660f;
	background: #fff;
`;
export const UiFixedBoxBottom = styled.div`
	padding: 0.5rem;
	font-size: 1.5rem;
	color: #fff;
	background: #717171;
`;
