import React, { useEffect } from 'react';
import { useQuery } from 'utils/hooks/useQuery';
import { useMember } from 'store/member';
import { useHistory } from 'react-router-dom';
import { Api } from 'services/api';
import { LoadingBackground, Input, AutoComplete, } from 'components';
import { COMMON_PATH, MAIN_PATH } from 'routes/routesConfig/constants';
import { useSetState } from 'utils/hooks/useSetState';
import { UiLogin, UiLoginBox, UiTitle, UiButton, UiLoginButton, UiButtonBox } from './Login.style';


/**
 * 登入
 */

export const Login = () => {
  const { getQueryParams } = useQuery();
  const [{ isLoading, memberInfo: { oneSchool, schools, userName } }, { login, setSchool }] = useMember();
  const code = getQueryParams('code');
  const history = useHistory();
  const [{
    type,
    account,
    password,
  }, setState] = useSetState({
    type: 'other',
    account: '',
    password: '',
  });

  const goToLogin = () => {
    const redirect_uri = window.location.href;
    // eslint-disable-next-line max-len
    window.location.href = `https://auth.hlc.edu.tw/oauth/authorize.php?client_id=a873728ad3b0a7dd140fc498c8579951&response_type=code&redirect_uri=${redirect_uri}&scope=User.Mail,User.BasicInfo,User.Application`;
  };

  const onChangeHandler = value => setState(value);

  const adminLogin = async () => {
    const adminPayload = {
      account,
      password
    };
    const payload = {
      redirectUrl: ''
    };
    await login('admin', adminPayload).then(async res => {
      if (res.value?.accessToken) await login('other', payload);
    });
  };

  const confirmSchool = async value => {
    const { school, schoolName } = value;
    await setSchool({
      id: school,
      schoolName,
      accessToken: school,
      userName
    });
    history.push(MAIN_PATH.ANNOUNCEMENT_LIST);
  };

  const testLogin = () => {
    const { name, value } = schools.filter(item => item.name === '花蓮測試學校')[0];
    confirmSchool({
      school: value,
      schoolName: name
    });
  };

  useEffect(() => {
    if (code === null) return;
    Api.setToken(code);
    const payload = {
      redirectUrl: `${window.location.origin + COMMON_PATH.LOGIN}`
    };
    login('other', payload);
  }, [code]);

  return (
    <UiLogin>
      <UiLoginBox>
        <UiTitle>
          {(type === 'other' && oneSchool) && '登入'}
          {(type === 'admin' && oneSchool) && '管理帳號 登入'}
          {!oneSchool && '選擇學校後進行登入'}
        </UiTitle>
        {
          (type === 'other' && oneSchool) &&
          <UiButtonBox>
            <UiButton onClick={() => goToLogin()}>教育雲端帳號  登入</UiButton>
            <UiLoginButton onClick={() => setState({ type: 'admin' })}>管理帳號  登入</UiLoginButton>
          </UiButtonBox>
        }
        {
          (!oneSchool && schools.length > 0) &&
          <>
            <UiButtonBox>
              <AutoComplete list={schools} label="學校列表" name="school" onChange={confirmSchool} />
              <UiButton onClick={() => testLogin()}>以 花蓮測試學校  登入</UiButton>
            </UiButtonBox>
          </>
        }
        {
          (type === 'admin' && oneSchool) &&
          <UiButtonBox>
            <Input label="帳號*" name="account" value={account} onChange={onChangeHandler} />
            <Input label="密碼*" name="password" type="password" value={password} onChange={onChangeHandler} />
            <UiButton onClick={() => adminLogin()}>登入</UiButton>
            <UiButton onClick={() => setState({ type: 'other' })}>返回</UiButton>
          </UiButtonBox>
        }
      </UiLoginBox>
      <LoadingBackground isLoading={isLoading} />
    </UiLogin>
  );
};
