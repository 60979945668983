import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'utils/hooks/useModal';
import Linkify from 'linkifyjs/react';
import { format } from 'date-fns';
import { TitleContainer, Icon, Button } from 'components';
import { useAnnouncement } from 'store/announcement';
import { getUnixTime } from 'date-fns';
import { useSetState } from 'utils/hooks/useSetState';
import { MAIN_PATH } from 'routes/routesConfig/constants';

import { UiInfo, UiContent } from './AnnouncementDetail.style';


/**
 * 公告詳細
 */

const showText = {
  System: '系統維護單位'
};
const linkOptions = {
  nl2br: true
};


export const AnnouncementDetail = () => {
  const history = useHistory();
  const { setModal } = useModal();
  const { id } = useParams();
  const nowTimeStamp = new Date().getTime();
  const [{ isCanEdit }, setState] = useSetState({
    isCanEdit: false
  });

  const [{ announcement }, { getAnnouncementById, deleteAnnouncement }] = useAnnouncement();

  const deleteThisAnnouncement = async () => {
    await deleteAnnouncement(id);
    history.goBack();
  };
  useEffect(() => {
    getAnnouncementById(id);
  }, []);
  useEffect(() => {
    if (!announcement.startDate) return;
    const startTimeStamp = getUnixTime(new Date(announcement.startDate)) * 1000;
    setState({
      isCanEdit: startTimeStamp > nowTimeStamp ? true : false
    });
  }, [announcement.startDate]);
  return (
    <TitleContainer
      title="公告內容"
      titleIcon="textsms"
      titleChildren={<Button onClick={() => history.goBack()}>返回列表</Button>}
    >
      <UiInfo>
        <span>{announcement.title}</span>
        <div>
          {
            isCanEdit &&
            <>
              <Icon
                name="create"
                background="#0066FF"
                sizetype="large"
                onClick={() => { history.push(`${MAIN_PATH.ANNOUNCEMENT_FORM}/${id}`); }}
              />
              <Icon
                name="delete_forever"
                background="#FF3636"
                sizetype="large"
                onClick={() => {
                  setModal({
                    html: '確定要<span>刪除公告</span>嗎？',
                    confirmEvent: () => deleteThisAnnouncement(),
                  });
                }}
              />
            </>
          }
        </div>
      </UiInfo>
      <UiContent>
        <div>
          發佈時間:{announcement.startDate && format(new Date(announcement.startDate), 'yyyy-MM-dd')}
          <span>{showText[announcement.role] || '花蓮縣單位'}</span>
        </div>
        <article>
          {
            <Linkify tagName="span" options={linkOptions}>{announcement.content}</Linkify>
          }
        </article>
      </UiContent>
      {/* <Pagination /> */}
    </TitleContainer>
  );
};
