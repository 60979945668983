import React from 'react';
import { CheckAnswerCard } from 'components';

/**
 * 檢查答案卡頁面
 */

export const CheckAnswerCardPage = () => {
  return (
    <CheckAnswerCard />
  );
};
