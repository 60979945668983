import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { useAnnouncement } from 'store/announcement';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { TitleContainer, Table, Button, Pagination } from 'components';

/**
 *  公告列表
 */

const schema = {
  date: {
    name: '起始日期',
  },
  title: {
    name: '標題',
    size: 'large'
  },
  creater: {
    name: '公告者',
  }
};


export const AnnouncementList = () => {
  const history = useHistory();
  const [{ announcements, isLoading }, { getAnnouncement }] = useAnnouncement();
  const [{
    page,
    limit
  }, setState] = useSetState({
    page: 0,
    limit: 7
  });

  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };

  const actions = [
    {
      status: 'normal',
      icon: 'visibility',
      title: '詳細內容',
      background: '#ffb800',
      onClick: data => {
        const { id } = data;
        history.push(`${MAIN_PATH.ANNOUNCEMENT_DETAIL}/${id}`);
      }
    }
  ];

  const goToForm = () => {
    history.push(MAIN_PATH.ANNOUNCEMENT_FORM);
  };

  const goToEditPage = () => {
    history.push(MAIN_PATH.EDIT_ANNOUNCEMENT_LIST);
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  return (
    <TitleContainer
      title="公告"
      titleIcon="description"
      titleChildren={
        announcements.isEdit &&
        <>
          <Button onClick={goToEditPage} icon="create">編輯公告頁面</Button>
          <Button onClick={goToForm} icon="playlist_add">新增公告</Button>
        </>
      }
    >
      <Table
        data={announcements.data}
        schema={schema}
        isNeedTitle={true}
        actions={actions}
        limit={limit}
        page={page}
        isLoading={isLoading}
      />
      <Pagination total={announcements.data.length} limit={limit} onChange={onChangePage} />
    </TitleContainer>
  );
};



