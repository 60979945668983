import styled from 'styled-components';

export const UiSidebarBox = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	overflow: hidden;
	width: 300px;
	z-index: ${({ theme }) => theme.zIndex.drawer};
	transition: 0.35s;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	transform: ${({ isOpen }) => `translatex(${isOpen ? '0px' : '-252px'}) `};
	border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const UiSidebar = styled.div`
	position: relative;
	padding: 2rem;
	width: 250px;
	background-color: #fff;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	border-bottom: 1px solid #eee;
`;


export const UiSidebarButton = styled.div`
	position: absolute;
	top: -1px;
	left: 100%;
	transform: tran;
	display: flex;
	align-items: center;
	padding: 1rem;
	width: 50px;
	height: max-content;
	font-size: 2rem;
	color: ${({ theme }) => theme.colorTheme.primary};
	background-color: #fff;
	writing-mode: vertical-lr;
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	border-right: 1px solid rgba(0, 0, 0, 0.06);
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
	cursor: pointer;
`;

export const UiSidebarCatelogey = styled.ul`
	margin: 0;
	padding: 0;
`;

const tanslateleft = 'translateX(-2rem)';
const tanslateright = 'translateX(0)';

export const UiSidebarLink = styled.li`
	font-size: 1.6rem;
	list-style: none;

	&:nth-child(1) {
		display: block;
		padding: 0.5rem 1rem;
		margin-bottom: 0.5rem;
		max-width: max-content;
		font-size: 1.8rem;
		font-weight: bold;
		background-color: ${({ theme }) => theme.colorTheme.tertiary};
	}

	& > a {
		display: flex;
		align-items: center;
		padding: 0.8rem 0;
		color: #333;

		&:hover div {
			transform: ${tanslateright};
			opacity: 1;
		}

		&:hover span {
			transform: ${tanslateright};
		}
	}
`;

export const UiIconBox = styled.div`
	transform: ${({ active }) => active ? tanslateright : tanslateleft};
	opacity: ${({ active }) => active ? 1 : 0};
	transition: 0.35s;
`;

export const UiLinkLabel = styled.span`
	transform: ${({ active }) => active ? tanslateright : tanslateleft};
	transition: 0.35s;
`;
