import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          WebkitFontSmoothing: 'auto',
          fontFamily: [
            'Noto Sans TC',
            'Helvetica Neue',
            'PingFang TC',
            'Heiti TC',
            'sans-serif'
          ].join(','),
          boxSizing: 'border-box',
          lineHeight: 1.75,
          letterSpacing: 0,
          height: '100%',
        },
        ul: {
          listStyle: 'none',
          margin: 0,
          padding: 0
        },
        img: {
          display: 'block',
          fontFamily: 'sans-serif',
          fontWeight: 300,
          height: 'auto',
          lineHeight: 2,
          position: 'relative',
          textAlign: 'center',
          width: '100%',
        },
        h1: {
          fontSize: '2.5rem',
        },
        h2: {
          fontSize: '2rem',
        },
        h3: {
          fontSize: '1.7rem',
        },
        h4: {
          fontSize: '1.5rem',
        },
        h5: {
          fontSize: '1.2rem',
        },
        h6: {
          fontSize: '1rem',
        },
        a: {
          textDecoration: 'none'
        },
        article: {
          color: '#666',
          lineHeight: 1.75,
          fontSize: '1.6rem',
          letterSpacing: '0.2rem'
        },
        '.swal2-popup': {
          width: '40rem !important'
        },
        '.swal2-container': {
          'z-index': '9999 !important',
        },
        '.swal2-title': {
          color: '#ff3636 !important',
        },
        '.modalContent': {
          'font-size': '2rem !important',
          color: '#ff3636 !important',
        },
        '.modalContent div': {
          'font-size': '1.4rem !important',
          'text-align': 'center',
          color: 'rgba(60, 72, 88, 0.99) !important',
        },
        '.modalContent span': {
          'font-size': '1.4rem !important',
          'text-align': 'center',
          color: '#FF3636 !important',
        },
        '.modalContent p': {
          'font-size': '1.4rem !important',
          'text-align': 'left',
          color: 'rgba(60, 72, 88, 0.99) !important',
        },
        '.modalContent ul': {
          color: '#8c8c8c',
          'text-align': 'left',
          'list-style': 'none',
          'font-size': '1.3rem'
        },
        '.modalContent table': {
          'border-collapse': 'collapse',
          width: '100%',
          'text-align': 'center',
        },
        '.modalContent thead td': {
          color: '#fff',
          'background-color': '#ffa800',
          border: '2px solid #FFFFFF',
          'box-sizing': 'border-box',
          padding: '0.5rem',
        },
        '.modalContent tbody td': {
          color: '#000',
          'background-color': '#fde89e',
          border: '2px solid #FFFFFF',
          'box-sizing': 'border-box',
          padding: '0.5rem',
        },
      },
    },
  },
  text: {
    large: '3rem',
    normal: '2rem',
    small: '1.5rem',
    color: {
      error: '#ff3636',
    }
  },
  btn: {//todo change btn palette for tiku
    normal: '#0f62ff',
    normalhover: '#7aa8ff',
    menu: '#a1e4ed;',
    info: '#006db2',
    infohover: '#009be5',
    highlight: '#f38a04',
    highlighthover: '#f29f05',
    disable: '#a5a5a5',
    disablehover: '#a5a5a5',
    visibility: '#43a047',
    visibilityhover: '#47a547',
    delete: '#ff3636',
    deletehover: '#ff6262',
    bar: '#a1e6ef',
    barhover: '#71dae0',
    danger: '#ff3636',
    dangerhover: '#e91e63',
    borderhover: '#c7daff',
    blue: '#0f62ff',
    bluehover: '#1ab3ff',
    white: '#ffe',
    whitehover: '#fff',
    orange: '#ff660f',
    orangehover: '#fe9345',
    deepblue: '#003a92',
    deepbluehover: '#1E60C1',
    yellow: '#ffb800',
    yellowhover: '#ffe205',
  },
  colorTheme: {
    primary: '#0f62ff',
    secondary: '#6096ff',
    tertiary: '#c7daff',
    quaternary: '#e1ebff'
  },
  zIndex: {
    header: 1250,
    loading: 1600
  }
});

theme.overrides.MuiCssBaseline['@global'].html = {
  [theme.breakpoints.up('xs')]: { //0
    fontSize: '37.5%',
    height: '100%',
  },
  [theme.breakpoints.up('sm')]: { //600
    fontSize: '50%',
  },
  [theme.breakpoints.up('md')]: { //960
    fontSize: '56.25%',
  },
  [theme.breakpoints.up('lg')]: { //1280
    fontSize: '62.5%',
  },
  [theme.breakpoints.up('xl')]: { //1920
    fontSize: '75%',
  },
};

export const media = {
  xl: `(min-width: ${theme.breakpoints.values.xl}px)`, //1920
  lg: `(min-width: ${theme.breakpoints.values.lg}px)`, //1280
  md: `(min-width: ${theme.breakpoints.values.md}px)`, // 960
  sm: `(min-width: ${theme.breakpoints.values.sm}px)`, // 600
  xs: `(min-width: ${theme.breakpoints.values.xs}px)`, // 0
};

export default theme;
