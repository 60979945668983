import styled from 'styled-components';

const cellColor = '#c7daff';

export const UiQuestionTable = styled.div`
	overflow-y: hidden;
`;

export const UiTable = styled.div`
	width: max-content;
`;

export const UiThead = styled.div``;
export const UiTbody = styled.div`
	overflow: auto;
`;
export const UiTfoot = styled.div``;
export const UiRow = styled.div`
	display: flex;
`;
export const UiCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0;
	width: ${({ col = 1 }) => `${80 * col}px`};
	height: ${({ row = 1 }) => `${50 * row}px`};
	font-size: ${({ size }) => size === 'large' ? '2rem' : '1.4rem'};
	text-align: center;
	color: ${({ type, textcolor }) => textcolor ? textcolor : type === 'title' ? '#fff' : '#333'};
	background-color: ${({ color }) => color ? color : cellColor};
	border: 1px solid #fff;
`;

export const UiTitleCell = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 160px;
	height: 50px;
	border: 1px solid #fff;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		height: 50px;
		font-size: 1.6rem;
		color: #fff;
		background-color: #6096ff;
	}

	& > div:first-of-type {
		width: 100%;
		font-size: 1.8rem;
		background-color: #0057ff;
		border-bottom: 1px solid #fff;
	}

	& > div:nth-child(2) {
		border-right: 1px solid #fff;
	}
`;

export const UiCatelogey = styled.div`
	display: flex;
	align-items: stretch;

	& > div:first-of-type {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 1rem 2rem;
		width: 160px;
		font-size: 1.5rem;
		text-align: center;
		color: #fff;
		background-color: #6096ff;
		border: 1px solid #fff;
	}
`;

export const UiCatelogeyContainer = styled.div`

`;
