import React from 'react';
import { TitleContainer, Button } from 'components';
import {
  UiContent,
  UiRow,
  UiLeft,
  UiRight,
  UiTitle,
  UiText,
  UiButton
} from './Suggest.style';


/**
 * 建議回饋
 */

export const Suggest = () => {
  return (
    <TitleContainer
      title='建議回饋'
      titleIcon='content_paste'
    >
      <UiContent>
        <UiRow>
          <UiLeft>
            <img src='http://s05.calm9.com/qrcode/2020-11/6PSSWFKXOK.png' alt="QRCODE" />
          </UiLeft>
          <UiRight>
            <UiTitle>使用意見線上回饋表單</UiTitle>
            <UiText>
              有任何系統或是操作上的問題，可以使用手機掃描QRcode進入表單並拍照上傳，
              也可以點選下方表單連結進入填寫。
            </UiText>
            <UiButton>
              <Button
                onClick={() => { window.open('https://reurl.cc/ygWKjy'); }}
                icon='menu_book'
              >
                表單連結
              </Button>
            </UiButton>
          </UiRight>
        </UiRow>
      </UiContent>
    </TitleContainer>);
};

Suggest.propTypes = {

};


