import styled from 'styled-components';

const triangleColor = '#3C4858';
const borderColor = '#2a4865';

export const UiSelectＷrapper = styled.div`
	display: ${({ isFlex }) => isFlex ? 'flex' : 'block'};
`;

export const UiSelect = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: 320px;
	min-height: 40px;
	font-size: 16px;
	color: #828282;
	cursor: pointer;
	flex: 0.8;
`;

export const UiSelectContent = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid ${borderColor};
	border-radius: 5px;
	transition: all 0.2s ease-in;
	box-sizing: border-box;

	&::after {
		position: relative;
		top: ${({ active }) => active ? '-3.5px' : '3.5px'};
		right: 10px;
		width: 0;
		height: 0;
		border: 7px solid transparent;
		border-color:
			${({ active }) => active ? `transparent transparent ${triangleColor} transparent` :
			`${triangleColor} transparent transparent transparent`};
		content: "";
	}
`;

export const Uilabel = styled.h3`
	margin: 0;
	margin: ${({ isFlex }) => isFlex ? '0.5rem' : '0'};
	flex: 0.2;
	text-align: ${({ isFlex }) => isFlex ? 'right' : 'left'};
`;

export const UiSelectOptions = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	margin: 0;
	padding: 0;
	background-color: #fff;
	border: 1px solid ${borderColor};
	box-sizing: border-box;
	border-radius: 5px;
	list-style: none;
	z-index: ${({ theme }) => theme.zIndex.drawer};

`;
export const UiSelectLi = styled.li`
	margin: 0;
	padding: 8px 0;
	text-indent: 15px;
	background-color: ${({ active, theme }) => active ? theme.colorTheme.tertiary : 'none'};

	&:hover {
		background-color: ${({ theme }) => theme.colorTheme.tertiary};
	}

	&:not(:first-child) {
		border-top: 1px solid ${borderColor};
	}
`;
