import styled from 'styled-components';


export const UiIcon = styled.div`
	display: flex;
	justify-content: center;
	-webkit-user-select: none;
	-moz-user-select: none;

	.material-icons {
		padding: 0.5rem;
		font-size: ${({ theme, sizetype, size }) => size ? size : theme.text[sizetype]};
		background-color: ${({ background }) => background ? background : 'none'};
		border: ${({ border, color }) => border ? `1px solid ${color}` : 'none'};
		border-radius: 5px;

		/* cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'}; */
		cursor: pointer;
	}
`;
