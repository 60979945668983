import React from 'react';
import PropTypes from 'prop-types';
import {
  UiQuestionTable,
  UiTable,
  UiThead,
  UiRow,
  UiTitleCell,
  UiCatelogey,
  UiCatelogeyContainer,
  UiCell,
  UiTbody,
  UiTfoot
} from './QuestionTable.style';


/**
 * 出卷用表單（好難
 */

export const QuestionTable = ({ data = [], tableWidth = '700', tableHeight = '450' }) => {
  const chapterTotal = obj => {
    let total = 0;
    Object.entries(obj).forEach(([key, value]) => {
      total += value.score;
    });
    return total;
  };
  return (
    <UiQuestionTable style={{ width: tableWidth ? tableWidth + 'px' : '100%', }}>
      <UiTable>
        <UiThead>
          <UiRow>
            <UiCell row={2} col={2} type='title' color="#0057ff" size="large">教材內容<br />(節數)</UiCell>
            <UiCell row={2} col={2} type='title' color="#0057ff" size="large">試題<br />類型</UiCell>
            <UiTitleCell>
              <div>記憶</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiTitleCell>
              <div>了解</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiTitleCell>
              <div>應用</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiTitleCell>
              <div>分析</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiTitleCell>
              <div>評鑑</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiTitleCell>
              <div>創造</div>
              <div>配分</div>
              <div>題數</div>
            </UiTitleCell>
            <UiCell row={2} col={2} type='title' color="#ffdc5f" textcolor="#333" size="large">合計<br />(配分)</UiCell>
          </UiRow>
        </UiThead>
        <UiTbody style={{ maxHeight: tableHeight + 'px', }}>
          {
            data.chapter.map(item => {
              return (
                <UiCatelogey key={item.chapterID}>
                  <div>{item.name}</div>
                  <UiCatelogeyContainer>
                    {
                      item.questionTypes.map((type, index) => {
                        return (
                          <UiRow key={index}>
                            <UiCell col={2}>{type.name}</UiCell>
                            <UiCell>
                              {type.memory.score}
                            </UiCell>
                            <UiCell>
                              {type.memory.amount}
                            </UiCell>
                            <UiCell>
                              {type.realize.score}
                            </UiCell>
                            <UiCell>
                              {type.realize.amount}
                            </UiCell>
                            <UiCell>
                              {type.used.score}
                            </UiCell>
                            <UiCell>
                              {type.used.amount}
                            </UiCell>
                            <UiCell>
                              {type.analyze.score}
                            </UiCell>
                            <UiCell>
                              {type.analyze.amount}
                            </UiCell>
                            <UiCell>
                              {type.evaluation.score}
                            </UiCell>
                            <UiCell>
                              {type.evaluation.amount}
                            </UiCell>
                            <UiCell>
                              {type.creative.score}
                            </UiCell>
                            <UiCell>
                              {type.creative.amount}
                            </UiCell>
                            <UiCell col={2} color="#ffdc5f">{type.total}</UiCell>
                          </UiRow>
                        );
                      })
                    }
                    <UiRow>
                      <UiCell col={2} color="#ffefc7">合計</UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.memory.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.realize.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.used.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.analyze.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.evaluation.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {item.total.creative.score}
                      </UiCell>
                      <UiCell col={2} color="#ffefc7">
                        {chapterTotal(item.total)}
                      </UiCell>
                    </UiRow>
                  </UiCatelogeyContainer>
                </UiCatelogey>
              );
            })
          }
        </UiTbody>
        <UiTfoot>
          <UiRow>
            <UiCell col={4} size="large" color="#ffc7ce">總計</UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.memory.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.realize.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.used.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.analyze.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.evaluation.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.total.creative.score}
            </UiCell>
            <UiCell col={2} color="#ffc7ce">
              {data.score}
            </UiCell>
          </UiRow>
        </UiTfoot>
      </UiTable>
    </UiQuestionTable >
  );
};

QuestionTable.propTypes = {
  schema: PropTypes.array,
  data: PropTypes.object,
  tableWidth: PropTypes.string,
  tableHeight: PropTypes.string,
};


