/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DropDnd, Select, Input } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiDropTable, UiDropTr, UiDropTh, UiDropTd, UiDropFooter } from './DropTable.style';


/**
 * 可以拖移的表單
 */

const DropItem = ({ itemData, index, options, onChangeHandler, total }) => {
  const { name, questionNum, answerNum, type, score } = itemData;
  const roundTo = (num, decimal) => {
    return Math.round((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
  };
  return (
    <UiDropTr>
      <UiDropTd>{name}</UiDropTd>
      <UiDropTd>{questionNum}</UiDropTd>
      <UiDropTd>{answerNum}</UiDropTd>
      <UiDropTd size="large">
        <Select
          options={options}
          value={type}
          selectName="scoreType"
          onChange={value => onChangeHandler(value, index)}
        />
        <Input
          value={score}
          name="number"
          check="number"
          onChange={value => onChangeHandler(value, index)}
        />
      </UiDropTd>
      <UiDropTd>
        {total === 0 ? '0%' :
          type === 'perQues' ?
            `${roundTo(((questionNum * score * 100) / total), 2)}%` :
            `${roundTo(((answerNum * score * 100) / total), 2)}%`}
      </UiDropTd>
      <UiDropTd>{type === 'perQues' ? questionNum * score : answerNum * score}</UiDropTd>
    </UiDropTr>
  );
};


export const DropTable = ({ schema, data, options = [], onChange = () => { } }) => {
  const [{ total }, setState] = useSetState({
    total: 0
  });

  const onChangeHandler = (value, index) => {
    const newQuestions = data.map((item, questionIndex) => {
      if (questionIndex === index && value.number) {
        const number = parseInt(value.number);
        const isNumber = /^[1-9]+[0-9]*]*$/;
        if (!isNumber.test(number)) {
          item.score = 0;
        } else {
          item.score = value.number;
        }
      }
      if (questionIndex === index && value.number === '') {
        item.score = 0;
      }
      if (questionIndex === index && value.scoreType) {
        item.type = value.scoreType;
      }
      return item;
    });
    onChange(newQuestions);
  };



  const sort = newQuestions => {
    onChange(newQuestions);
  };

  const totalScore = data => {
    let total = 0;
    data.forEach(item => {
      total += item.type === 'perAns' ? item.answerNum * item.score : item.score * item.questionNum;
    });
    setState({
      total
    });
  };

  useEffect(() => {
    totalScore(data);
  }, [data]);

  return (
    <UiDropTable>
      <UiDropTr>
        {
          schema.map((item, key) => {
            return (
              <UiDropTh key={key} size={item.size}>{item.name}</UiDropTh>
            );
          })
        }
      </UiDropTr>
      <DropDnd
        total={total}
        list={data}
        DropItem={DropItem}
        onChange={sort}
        onSocreChange={onChangeHandler}
        options={options}
      />
      <UiDropFooter>
        <span>總計</span>
        <span>{total}</span>
      </UiDropFooter>
    </UiDropTable>
  );
};

DropTable.propTypes = {
  schema: PropTypes.shape([{
    name: PropTypes.string.isRequired,
    size: PropTypes.string
  }]),
  data: PropTypes.array,
  options: PropTypes.shape([{
    value: PropTypes.string,
    name: PropTypes.string
  }]),
  onChange: PropTypes.func
};


