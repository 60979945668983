import React from 'react';
import PropTypes from 'prop-types';
import { UiIcon } from './Icon.style';
import MaterialIcon from 'material-icons-react';
import { Tooltip as MatTooltip } from '@material-ui/core';
/**
 * icon組件
 */

export const Icon = ({
  name = 'dashboard',
  color = '#fff',
  sizetype = 'small',
  size = '',
  background,
  border,
  onClick,
  title,
}) => {
  return (
    <UiIcon
      sizetype={sizetype}
      background={background}
      onClick={onClick}
      border={border}
      color={color}
      size={size}
    >
      {
        title ?
          <MatTooltip title={title} placement="top" arrow={true}>
            <MaterialIcon
              icon={name}
              color={color}
            />
          </MatTooltip>
          :
          <MaterialIcon
            icon={name}
            color={color}
          />
      }
    </UiIcon>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  sizetype: PropTypes.oneOf(['large', 'normal', 'small']),
  background: PropTypes.string,
  border: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};


