import { Api } from 'services/api';
import { stringify } from 'query-string';

export const createAnnouncement = async payload => {
  const response = await Api.put(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Announcement/Insert`, payload);
  return response.json();
};

export const getAnnouncement = async id => {
  const queryParams = {
    id,
  };
  const querystring = stringify(queryParams);
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Announcement/Query?${querystring}`);
  return response.json();
};

export const getEditAnnouncement = async () => {
  const response =
    await Api.get(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Announcement/Edit`);
  return response.json();
};

export const updateAnnouncement = async payload => {
  const response = await Api.post(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Announcement/Update`, payload);
  return response;
};

export const deleteAnnouncement = async (params) => {
  const response =
    await Api.delete(`${process.env.REACT_APP_ANNOUNCEMENT_API_DOMAIN}/api/Announcement/Delete`, params);
  return response;
};
