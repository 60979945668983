import React from 'react';
import { AnnouncementForm } from 'components';

/**
 * 創建表單頁面
 */

export const AnnouncementFormPage = () => {
  return (
    <AnnouncementForm />
  );
};

