import styled from 'styled-components';

export const UiTabs = styled.ul`
	position: relative;
	display: flex !important;
	margin: 0 0 4rem 0;
	padding: 0 !important;
	width: 100%;

	&::after {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2rem;
		background-color: ${({ color }) => color};
		content: '';
		transition: 0.35s;
	}
`;

export const UiTabItem = styled.li`
	padding: 1rem 2rem;
	font-size: 1.5rem;
	background-color: ${({ color }) => color};
	opacity: 1;
	list-style: none;
	transition: 0.35s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;

	&:hover {
		opacity: 0.9;
	}
`;
