import React from 'react';
import { ReliabilityOfGeneralExaminationPapers } from 'components';

/**
 * 全科試卷信度
 */

export const ReliabilityOfGeneralExaminationPapersPage = () => {
  return (
    <ReliabilityOfGeneralExaminationPapers />
  );
};

