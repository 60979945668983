import styled from 'styled-components';

export const UiTitleContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	background: #fff;

	/* line-light */
	border: 1px solid #d0e0e2;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	flex-direction: column;
	box-sizing: border-box;
`;
export const Uititle = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${({ titledistributed }) => (titledistributed)};
	padding: 2rem 3rem;
	color: ${({ theme }) => theme.colorTheme.primary};
	z-index: 1;
	box-shadow: ${({ titleshadow }) => titleshadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};

	& > h1 {
		display: flex;
		align-items: center;
		margin: 0;
	}

	& > div {
		display: flex;
		align-items: center;
		flex: ${({ titledistributed }) => titledistributed === 'space-between' ? 'initial' : '1'};
		margin-left: ${({ titledistributed }) => titledistributed === 'space-between' ? '0' : '1rem'};

		& > * + * {
			margin-left: 1rem;
		}
	}
`;

export const UiContent = styled.div`
	position: relative;
	display: flex;
	overflow: ${({ overflowHidden }) => overflowHidden ? 'hidden' : 'visible'};
	height: 100%;
	flex-direction: column;
`;
