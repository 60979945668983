import React, { useEffect, useCallback } from 'react';
import { ReportContainer, Select, RadioGroup, Graph, StickyTable, AutoComplete, Icon } from 'components';
import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST, RADIOLIST } from 'constants/index';
import { useModal } from 'utils/hooks/useModal';
import { useFormatterReport } from 'utils/hooks/useFormatterReport';

import { UiGraphRemind, UiGraphRemindRow } from './AllSubjectExtent.style';

/**
 * 全科難易度
 */

// eslint-disable-next-line max-len
const alertStr = '<table> <thead> <tr> <td>難度指數</td> <td>題目評鑑</td> </tr> </thead> <tbody> <tr> <td>難度<=0.35</td> <td>題目較容易</td> </tr> <tr> <td>0.35 < 難度 <= 0.65</td> <td>題目難度適中</td> </tr> <tr> <td>難度 > 0.65 </td> <td>題目難度較高</td> </tr> </tbody> </table>';

export const AllSubjectExtent = () => {

  const {
    formatterGraphData,
    formatterTableData,
  } = useFormatterReport();
  const { setModal } = useModal();
  const [{
    educationSelected,
    schoolYear,
    examType,
    grade,
    group,
    remind,
  }, setState] = useSetState({
    educationSelected: '',
    schoolYear: '',
    examType: '',
    grade: '',
    group: '',
    remind: '',
  });

  const [{
    selections,
    difficultyData
  }, {
    getReportSeletions,
    getDifficulty,
  }] = useReport();

  const onChangeSchool = value => {
    setState({
      group: value.school,
      schoolName: value.schoolName
    });
  };

  const onChangeHandler = value => {
    setState({
      ...value
    });
  };

  const selectGroup = useCallback(() => {
    if (Object.entries(selections).length === 0) return (<div>Loading...</div>);
    const {
      education,
      schoolYear: schoolYearOptions,
      grade: gradeOptions,
      examType: examTypeOptions,
    } = selections;
    //縣籍
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            options={education}
            selectName="educationSelected"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (gradeOptions) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            options={gradeOptions}
            selectName="grade"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
        </>
      );
    }
    if (examTypeOptions && schoolYearOptions) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
        </>
      );
    }
    return (
      <div>Loading...</div>
    );
  }, [selections]);

  const filterGroup = () => {
    const { education, grade: gradeOptions, } = selections;
    const { schoolName, className } = difficultyData;
    if (education) {
      if (Object.keys(schoolName).length === 0) return;
      const schoolOptions = Object.entries(schoolName).map(([key, value]) => {
        return {
          name: value,
          value: key
        };
      });
      return (
        <AutoComplete
          list={schoolOptions}
          label="選擇學校"
          name="school"
          onChange={onChangeSchool}
        />
      );
    }
    if (gradeOptions) {
      if (Object.keys(className).length === 0) return;
      const classOptions = Object.entries(className).map(([key, value]) => {
        return {
          name: value,
          value: key
        };
      });
      return (
        <RadioGroup
          name="group"
          data={classOptions}
          onChange={onChangeHandler}
          label={RADIOLIST.CLASS.label}
        />
      );
    }
  };



  const dataGroup = useCallback(() => {
    const { education, grade: gradeOptions } = selections;
    if (Object.keys(difficultyData.title).length === 0) return null;
    if (group === '') return;
    let reportTypeString = '';
    let reportRoleString = '';
    let groupList = {};
    const { title, schoolName, className, dataList } = difficultyData;
    if (education) {
      reportTypeString = '難易度';
      reportRoleString = '全縣';
      groupList = schoolName;
    } else if (gradeOptions) {
      reportTypeString = '難易度';
      reportRoleString = '全校';
      groupList = className;
    }
    const formatData = {
      reportData: difficultyData,
      title,
      groupList,
      dataList,
      reportTypeString,
      reportRoleString,
      group
    };
    const { data, pv, uv } = formatterGraphData({ ...formatData });
    const nextTableData = formatterTableData({
      ...formatData,
      groupName: education ? '學校' : '班級'
    });
    return (
      <>
        <Graph.BarLine data={data} pv={pv} uv={uv} />
        <UiGraphRemindRow>
          <UiGraphRemind
            onClick={() => {
              setModal({
                title: '難易度提示',
                html: alertStr,
                showCancelButton: false,
                confirmEvent: () => { },
              });
            }}
          >
            <Icon name='error' color='#f00' />
            <div>難易度提示</div>
          </UiGraphRemind>
        </UiGraphRemindRow>
        <StickyTable.FixedTitle
          schema={nextTableData.tableSchema}
          data={nextTableData.data}
        />
      </>
    );
  }, [group, difficultyData.title]);

  const teacherDataGroup = useCallback(() => {
    if (Object.keys(difficultyData.title).length === 0) return null;
    const { title, data, header } = difficultyData;
    const tableSchema = ['', ...header.map(item => item.name)];
    const allSubjectRate = [];
    const classSUbjectRate = [];
    const graphData = [];
    const uv = data['className'];
    const pv = '全校難易度';

    header.forEach(item => {
      const allSubjectValue = title[item.value];
      const classSubjectValue = data.difficulty[item.value];
      allSubjectRate.push(`${allSubjectValue}%`);
      classSUbjectRate.push(`${classSubjectValue}%`);
      graphData.push({
        name: item.name,
        [uv]: classSubjectValue,
        [pv]: allSubjectValue,
      });
    });

    const newTableData = [
      {
        catelogey: '全校全科試卷難易度',
        list: allSubjectRate
      },
      {
        catelogey: data['className'],
        list: classSUbjectRate
      }
    ];
    return (
      <>
        <Graph.BarLine data={graphData} pv={pv} uv={uv} />
        <UiGraphRemindRow>
          <UiGraphRemind
            onClick={() => {
              setModal({
                title: '難度提示',
                html: alertStr,
                showCancelButton: false,
                confirmEvent: () => { },
              });
            }}
          >
            <Icon name='error' color='#f00' />
            <div>鑑別度提示</div>
          </UiGraphRemind>
        </UiGraphRemindRow>
        <StickyTable.FixedTitle schema={tableSchema} data={newTableData} headerCount={1} canSort={false} />
      </>
    );
  }, [difficultyData.title]);

  useEffect(() => {
    const payload = {
      reportType: 'Difficulty'
    };
    getReportSeletions(payload);
  }, []);

  useEffect(() => {
    const { education, grade: gradeOptions } = selections;
    if (education) {
      setState({
        remind: '※ 點選學校名稱會以藍色折線圖呈現於圖表'
      });
      if (educationSelected && schoolYear && examType) {
        const payload = {
          education: educationSelected,
          schoolYear,
          examType,
        };
        getDifficulty(payload);
      }
    } else if (gradeOptions) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
      if (schoolYear && examType && grade) {
        const payload = {
          grade,
          schoolYear,
          examType,
        };
        getDifficulty(payload);
      }
    } else {
      setState({
        remind: '※ 點選學生名稱會以藍色折線圖呈現於圖表'
      });
      if (schoolYear && examType) {
        const payload = {
          schoolYear,
          examType,
        };
        getDifficulty(payload);
      }
    }
  }, [educationSelected, schoolYear, examType, group]);

  return (
    <ReportContainer
      title="全科試卷難度"
      remind={remind}
      selectGroup={selectGroup()}
      radioChildren={filterGroup()}
    >
      {Object.keys(difficultyData.title).length > 0 && dataGroup()}
      {Object.keys(difficultyData.data).length > 0 && teacherDataGroup()}
    </ReportContainer>
  );
};
