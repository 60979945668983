import { SUBJECT_DESC } from 'constants/index';
import { objectMapCovertToArray } from 'utils/object';


export const useFormatterReport = () => {

  const formatterGraphData = ({//整理Api資料用於圖表(校長 縣籍 跟全科答對率一樣就可用)
    reportData = {},
    title,
    groupList,
    dataList,
    reportTypeString,
    reportRoleString,
    group = '',
  }) => {
    let nextGraphData = [];
    const uv = `${reportRoleString}全科${reportTypeString}`;
    let pv = '';
    if (Object.keys(reportData).length <= 0 || group === '') return [];
    if (Array.isArray(title)) {
      nextGraphData = title.map((item, index) => {
        pv = `${groupList[group]}${reportTypeString}`;
        return {
          name: (index + 1),
          [uv]: item,
          [`${groupList[group]}${reportTypeString}`]: dataList[group][index]
        };
      });
    } else {
      nextGraphData = objectMapCovertToArray(title, (data, key) => {
        pv = `${groupList[group]}${reportTypeString}`;

        return {
          name: SUBJECT_DESC[key],
          [uv]: data,
          [`${groupList[group]}${reportTypeString}`]: dataList[group][key]
        };
      });
    }

    return { data: nextGraphData, uv, pv };

  };

  const formatterRadarGraphData = ({
    reportData = {},
    title,
    groupList,
    dataList,
    reportTypeString,
    reportRoleString,
    group = '',
    header = [],
  }) => {
    let nextGraphData = [];
    if (Object.keys(reportData).length <= 0 || group === '') return [];
    let types = [
      {
        name: reportRoleString,
        id: '1',
        key: 'A'
      },
      {
        name: groupList[group],
        id: '2',
        key: 'B'
      },
    ];
    nextGraphData = objectMapCovertToArray(title, (data, key) => {
      const ability = header.filter(item => item.value === key)[0].name;
      return {
        subject: ability,
        A: data,
        B: dataList[group][key],
        fullMark: 100
      };
    });
    return { data: nextGraphData, types };
  };


  const formatterTableData = ({//整理Api資料用於表單(校長 縣籍 跟校長的全科答對率一樣就可用)
    reportData = {},
    title,
    groupList,
    dataList,
    reportTypeString,
    reportRoleString,
    groupName = '',
    group = [],
  }) => {
    if (Object.keys(reportData).length <= 0 || group === '') return [];
    let nextTableData = [];
    let newTableSchema = [`${groupName}`];
    const allSchoolAnswerRateList = objectMapCovertToArray(title, (data, key) => {
      newTableSchema.push(SUBJECT_DESC[key]);
      return `${data}%`;
    });
    const groupTableData = objectMapCovertToArray(dataList, (data, key) => {
      const groupAnswerRateList = objectMapCovertToArray(data, subjectAnswerRate => {
        return `${subjectAnswerRate}%`;
      });
      return {
        catelogey: groupList[key],
        list: groupAnswerRateList
      };

    });
    nextTableData = [
      {
        catelogey: `${reportRoleString}全科${reportTypeString}`,
        list: allSchoolAnswerRateList,
        isImportant: true
      }, ...groupTableData
    ];
    return {
      data: nextTableData,
      tableSchema: newTableSchema
    };
  };

  const formatterSubAbilityTableData = ({//整理Api資料用於表單(校長 縣籍 跟校長的分項能力答對率一樣就可用)
    reportData = {},
    title,
    groupList,
    dataList,
    reportTypeString,
    reportRoleString,
    groupName = '',
    group = [],
    header = [],
    subHeader = {},
    subHeaderTitle = '',//題數之類的第2個header
    useImportant = true,//table需要紅色的欄位
  }) => {
    if (Object.keys(reportData).length <= 0 || group === '') return [];
    let nextTableData = [];
    let newTableSchema = [`${groupName}`];
    let allSchoolAnswerRateList = [];
    if (Array.isArray(title)) {
      allSchoolAnswerRateList = title.map((item, index) => {
        newTableSchema.push(`題${index + 1}`);
        return `${item}%`;
      });
    } else {
      allSchoolAnswerRateList = objectMapCovertToArray(title, (data, key) => {
        const ability = header.filter(item => item.value === key)[0]?.name;
        newTableSchema.push(ability);
        return `${data}%`;
      });
    }

    const subHeaderList = objectMapCovertToArray(subHeader, (data, key) => {
      if (data === '精選試題') return '南一';
      if (data === '教師入題') return '老師上傳';
      return data;
    });
    const groupTableData = objectMapCovertToArray(dataList, (data, key) => {
      const groupAnswerRateList = objectMapCovertToArray(data, subjectAnswerRate => {
        return `${subjectAnswerRate}%`;
      });
      return {
        catelogey: groupList[key],
        list: groupAnswerRateList
      };

    });
    if (Object.keys(subHeader).length > 0) {
      nextTableData = [
        {
          catelogey: subHeaderTitle,
          list: subHeaderList,
          isImportant: useImportant
        },
        {
          catelogey: `${reportRoleString}全科${reportTypeString}`,
          list: allSchoolAnswerRateList,
          isImportant: useImportant
        },
        ...groupTableData
      ];
    } else {
      nextTableData = [
        {
          catelogey: `${reportRoleString}全科${reportTypeString}`,
          list: allSchoolAnswerRateList,
          isImportant: useImportant
        },
        ...groupTableData
      ];
    }

    return {
      data: nextTableData,
      tableSchema: newTableSchema
    };
  };




  return {
    formatterGraphData,
    formatterRadarGraphData,
    formatterTableData,
    formatterSubAbilityTableData,
  };
};
