import React from 'react';
import PropTypes from 'prop-types';
import { UiLoadingBackground } from './LoadingBackground.style';


/**
 * loading 背景
 */

export const LoadingBackground = ({ isLoading }) => {
  return (
    <UiLoadingBackground isLoading={isLoading}>
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </UiLoadingBackground>
  );
};


LoadingBackground.propTypes = {
  isLoading: PropTypes.bool,
};
