import React from 'react';
import { Button } from 'components';
import { useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useMember } from 'store/member';
import { UiFooter, UiHelpOption, UiNumber } from './Footer.style';


/**
 * 就是個footer
 */

export const Footer = () => {
  const [{ memberInfo },] = useMember();
  const history = useHistory();
  const goTo = (link) => {
    history.push(link);
  };
  return (
    <UiFooter>
      <UiHelpOption>
        {
          memberInfo.id ?
            <>
              <Button
                onClick={() => { goTo(MAIN_PATH.INSTRUCTIONS); }}
                icon='list'
                buttonborder={true}
                textcolor={'#000'}>
                操作說明
              </Button>
              <Button
                onClick={() => { goTo(MAIN_PATH.SUGGEST); }}
                icon='content_paste'
                buttonborder={true}
                textcolor={'#000'}>
                建議回饋
              </Button>
              <Button
                onClick={() => {
                  window.open('https://docs.google.com/presentation/d/1oZOw2s1fWRadFNSlN5_yw8UiWLquwWww/edit?usp=sharing&ouid=104871077358689138312&rtpof=true&sd=true');
                 }}
                icon='content_paste'
                buttonborder={true}
                textcolor={'#000'}>
                優化歷程
              </Button>
            </>
            : null
        }

        <UiNumber>服務專線：(02)2664-5500 # 519</UiNumber>
      </UiHelpOption>
      為了讓您獲得最佳瀏覽體驗，建議您使用的瀏覽器軟體為：IE Edge、Google Chrome 或 Firefox。<br />
        如使用IE瀏覽器瀏覽本網站，可能會發生部分網頁的呈現會有破圖或版面跑版的情況，亦可能導致某些網頁互動功能無法正確顯示與使用。
    </UiFooter>
  );
};
