import styled from 'styled-components';

export const UiColorBlockTitle = styled.div`
	padding: 1.5rem;
	width: 100%;
	font-size: 2.4rem;
	text-align: center;
	color: ${({ color }) => color || '#fff'};
	background-color: ${({ bgcolor, theme }) => bgcolor ? bgcolor : theme.colorTheme.secondary};
	margin-bottom: 3rem;
`;
