import React from 'react';
import PropTypes from 'prop-types';
import { UiButton, UiCircularProgress, UiInlineFlex } from './Button.style';
import { Icon } from 'components';
/**
 * Use `Button` to highlight key info with a predefined status. Easy peasy!!!!
 */

export const Button = ({
  buttoncolor = 'normal',
  textcolor = '#fff',
  sizetype = 'small',
  children,
  onClick,
  disabled = false,
  loading = false,
  size = 24,
  icon,
  buttonborder = false,
}) => {
  return (
    <UiButton
      buttoncolor={(disabled || loading) ? 'disable' : buttoncolor}
      textcolor={textcolor}
      sizetype={sizetype}
      onClick={(!loading && !disabled) ? onClick : () => { }}
      disabled={loading || disabled}
      buttonborder={buttonborder.toString()}
    >
      {icon && <UiInlineFlex><Icon name={icon} color={textcolor} sizetype={sizetype} /></UiInlineFlex>}
      {children}
      {loading && <UiCircularProgress size={size} />}
    </UiButton>
  );
};

Button.propTypes = {
  buttoncolor: PropTypes.string,
  textcolor: PropTypes.string,
  sizetype: PropTypes.PropTypes.oneOf(['large', 'normal', 'small']),
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
  icon: PropTypes.string,
  buttonborder: PropTypes.bool,
};
