import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiQuestionItem,
  UiInfo,
  UiImage,
  UiContent,
  UiSortInput,
  UiImageBox,
  UiIcon,
  UiNumber,
  UiTeacherText,
} from './QuestionItem.style';

/**
 * 題目組件
 */

const displayType = [
  'examAbility',
  'learning',
  'difficulty',
  'usertype',
  'source'
];
export const QuestionItem = ({
  data,
  number,
  showAllocation,
  openDetail,
  create = false,
  addExamPaper,
  addedQuestionList,
  addQuestionDisabled=false,
  onDelete = () => { },
  onChangeOrderby = () => { }
}) => {
  const [{
    id,
    detail,
    questionImage,
    modalData,
    addExamBtnState,
    oldNumber,
    newNumber,
    isTeacher,
  }, setState] = useSetState({
    id: '',
    detail: [],
    questionImage: '',
    modalData: [],
    addExamBtnState: false,
    oldNumber: 0,
    newNumber: 0,
    isTeacher: false,
  });
  const addExamBtnStateHanlder = () => {
    setState({
      addExamBtnState: !addExamBtnState
    });
    addExamPaper(modalData, !addExamBtnState);
  };

  const onChange = (oldNumber, newNumber) => {
    setState({
      oldNumber,
      newNumber
    });
  };

  const onChangeOrderbyHandler = () => {
    if (oldNumber === newNumber || newNumber === number) return;
    onChangeOrderby(oldNumber, newNumber);
  };

  useEffect(() => {
    if (!data || data.length === 0) return;
    const { metaInfos, question, id } = data;
    setState({
      questionImage: question,
      detail: metaInfos,
      modalData: data,
      id,
      isTeacher: data.author.isTeacher,
    });
  }, [data]);

  useEffect(() => {
    if (addedQuestionList && addedQuestionList.length > 0) {
      setState({
        addExamBtnState: (addedQuestionList.some(item => {
          return create? item.id === data.id : item===data.id;
        }))
      });
    }
  }, [data,addedQuestionList]);



  return (
    <UiQuestionItem>
      {
        showAllocation &&
        <UiNumber>{number}.</UiNumber>
      }
      <UiInfo showAllocation={showAllocation}>
        <UiImageBox>
          <UiImage src={questionImage} alt="" />
        </UiImageBox>
        <UiContent>
          <ul>
            {detail.map((item, index) => {
              if (displayType.includes(item.id)) {
                return (
                  <li key={item.id + index}>
                    <span>{item.target}: </span>
                    {item.value.map((option, index) => {
                      return (
                        <>{index > 0 ? ',' + option : option}</>
                      );
                    })}
                  </li>
                );
              }
            })}
          </ul>
          {
            showAllocation ?
              <div>
                <UiSortInput>
                  <label>變更題號</label>
                  <div>
                    <input type="text" onChange={e => onChange(number, e.target.value)} />
                    <UiIcon>
                      <Icon name="arrow_right" sizetype="large" onClick={() => onChangeOrderbyHandler()} />
                    </UiIcon>
                  </div>
                </UiSortInput>
                <Button icon="delete" buttoncolor="delete" onClick={() => onDelete(id)}>刪除</Button>
              </div>
              :
              <div>
                {
                  isTeacher && <UiTeacherText>老師自編題</UiTeacherText>
                }
                <Button
                  onClick={() => { openDetail(true, modalData); }}
                  buttoncolor={'blue'}
                  icon='preview'
                >
                  詳細內容
                  </Button>
                {
                  create ?
                  <Button
                    onClick={addExamBtnStateHanlder}
                    buttoncolor={!addExamBtnState ? 'orange' : 'delete'}
                    icon={!addExamBtnState ? 'post_add' : 'delete'}
                  >
                    {!addExamBtnState ? '加入試卷' : '移出試卷'}
                  </Button>:
                  <Button
                  disabled={addQuestionDisabled && !addExamBtnState}
                  onClick={addExamBtnStateHanlder}
                  buttoncolor={!addExamBtnState ? 'orange' : 'delete'}
                  icon={!addExamBtnState ? 'post_add' : 'delete'}
                >
                  {!addExamBtnState ? '加入試題' : '移出試題'}
                </Button>
                }
              </div>
          }
        </UiContent>
      </UiInfo>
    </UiQuestionItem>
  );
};

QuestionItem.propTypes = {
  data: PropTypes.object,
  showAllocation: PropTypes.bool,
  openDetail: PropTypes.func,
  create: PropTypes.bool,
  addExamPaper: PropTypes.func,
  addedQuestionList: PropTypes.array,
  addQuestionDisabled:PropTypes.bool,
  onDelete: PropTypes.func,
  onChangeOrderby: PropTypes.func,
  number: PropTypes.number
};


