import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { v4 as uuidv4 } from 'uuid';


import {
  getSearchSelect as getSearchSelectApi,
  getSearchChapter as getSearchChapterApi,
  getSearchQuestion as getSearchQuestionApi,
  downloadSingleQuestion as downloadSingleQuestionApi,
  calculateExamQuestion as calculateExamQuestionApi,
  getExamList as getExamListApi,
  createExam as createExamApi,
  examCignitive as examCignitiveApi,
  emptySample as emptySampleApi,
  deleteExam as deleteExamApi,
  getTempExam as getTempExamApi,
  updateExam as updateExamApi,
  registerScore as registerScoreApi,
  registerScoreUpload as registerScoreUploadApi
} from 'services/api/question';
import { objectMap } from 'utils/object';

const GET_QUESTION_SELECTIONS = 'GET_QUESTION_SELECTIONS';
const GET_QUESTION_CHAPTER = 'GET_QUESTION_CHAPTER';
const GET_SEARCH_QUESTION = 'GET_SEARCH_QUESTION';
const DOWNLOAD_SINGLE_QUESTION = 'DOWNLOAD_SINGLE_QUESTION';
const CALCULATE_EXAM_QUESTION = 'CALCULATE_EXAM_QUESTION';
const GET_ADDED_QUESTION_LIST = 'GET_ADDED_QUESTION_LIST';
const SAVE_STATIC_DATA = 'SAVE_STATIC_DATA';
const GET_EXAM_LIST = 'GET_EXAM_LIST';
const CREATE_EXAM = 'CREATE_EXAM';
const SORT_CREATE_EXAM = 'SORT_CREATE_EXAM';
const EXAM_CIGNITIVE = 'EXAM_CIGNITIVE';
const CLEAR_QUESTIONS = 'CLEAR_QUESTIONS';
const DELETE_QUESTION_LIST = 'DELETE_QUESTION_LIST';
const EMPTY_SAMPLE = 'EMPTY_SAMPLE';
const DELETE_EXAM_LIST = 'DELETE_EXAM_LIST';
const GET_TEMP_EXAM = 'GET_TEMP_EXAM';
const CLEAR_CHAPTER = 'CLEAR_CHAPTER';
const CLEAR_EXAM_ANALYSIS = 'CLEAR_EXAM_ANALYSIS';
const UPDATE_EXAM = 'UPDATE_EXAM';
const GET_FINISHED_EXAM_DETAIL = 'GET_FINISHED_EXAM_DETAIL';
const REGISTER_SCORE = 'REGISTER_SCORE';
const REGISTER_SCORE_UPLOAD = 'REGISTER_SCORE_UPLOAD';

const initState = {
  selections: {
    book: [],
    source: [],
    year: [],
    version: [],
    education: [],
    subject: {},
  },
  chapter: [],//Api取得的章節資料
  questions: [],
  searchQusetionPayload: {},
  analysisQuestionData: [],//選題分析
  addedQuestionList: [],//加入試卷的題目列表
  questionTitle: '',
  formatterChapter: {
    data: [],
    checkAll: false
  },//nestedGroup用的chapter資料
  searchQuestionPageData: {//搜尋題目頁面用的資料（非新增試卷應該都用這個）
    searchQusetionPayload: {},
    chapter: [],//Api取得的章節資料
    questions: [],
    formatterChapter: {
      data: [],
      checkAll: false
    },//nestedGroup用的chapter資料
  },
  isLoading: false,
  examList: {
    data: []
  },
  scoreType: [],
  section: [],
  examID: '',
  examAnalysis: {}
};

export const useQuestion = () => {
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const {
    selections,
    isLoading,
    chapter,
    searchQusetionPayload,
    analysisQuestionData,
    addedQuestionList,
    searchQuestionPageData,
    formatterChapter,
    questions,
    examList,
    scoreType,
    section,
    examID,
    examAnalysis,
    questionTitle,
  } = useSelector(state => state.question);


  const getQuestionSelectionsAction = createAction(GET_QUESTION_SELECTIONS, async () => {
    try {
      const result = await getSearchSelectApi();
      const nextJSubject = result.subject.J.filter(item => (item.value !== 'SO' && item.value !== 'NA'));
      const nextData = { ...result, subject: { ...result.subject, J: nextJSubject } };
      return nextData;
    } catch {
      return;
    }
  });
  const getQuestionSelections = () => dispatch(getQuestionSelectionsAction());

  const getQuestionChapterAction = createAction(GET_QUESTION_CHAPTER, async params => {
    try {
      const { data, type } = params;
      data.metaType = 'CHAPTER';
      const result = await getSearchChapterApi(data);
      return { data: result, type };
    } catch {
      return;
    }
  });
  const getQuestionChapter = params => dispatch(getQuestionChapterAction(params));

  const getSearchQuestionAction = createAction(GET_SEARCH_QUESTION, async params => {
    try {
      const { searchData, type } = params;
      const result = await getSearchQuestionApi(searchData);

      return { data: result, searchData, type };
    } catch {
      return;
    }
  });
  const getSearchQuestion = params => dispatch(getSearchQuestionAction(params));


  const downloadSingleQuestionAction = createAction(DOWNLOAD_SINGLE_QUESTION, async params => {

    const result = await downloadSingleQuestionApi(params);
    try {
      return result;
    } catch {
      return;
    }
  });
  const downloadSingleQuestion = params => dispatch(downloadSingleQuestionAction(params));

  const calculateExamQuestionAction = createAction(CALCULATE_EXAM_QUESTION, async params => {
    try {
      const result = await calculateExamQuestionApi(params);
      return result;
    } catch {
      return;
    }
  });
  const calculateExamQuestion = params => dispatch(calculateExamQuestionAction(params));

  //題目加入試卷
  const getAddedQuestionListAction = createAction(GET_ADDED_QUESTION_LIST, async params => {
    try {
      return params;
    } catch {
      return;
    }
  });
  const getAddedQuestionList = params => dispatch(getAddedQuestionListAction(params));

  //章節資料整理格式
  const saveStaticDataAction = createAction(SAVE_STATIC_DATA, async params => {
    try {
      return params;
    } catch {
      return;
    }
  });
  const saveStaticData = params => dispatch(saveStaticDataAction(params));

  const getExamListAction = createAction(GET_EXAM_LIST, async params => {
    try {
      const data = Object.keys(params).length > 0 ?
        await getExamListApi(params) :
        await getExamListApi();

      const nextData = data.map(item => {
        return ({
          ...item,
        });
      });

      return nextData;
    } catch {
      return [];
    }
  });

  const getExamList = params => dispatch(getExamListAction(params));


  const createExamAction = createAction(CREATE_EXAM, async params => {
    try {
      const data = await createExamApi(params);
      const nextSection = data.section.map(item => {
        item.id = uuidv4();
        return item;
      });
      const nextData = {
        ...data,
        section: nextSection
      };
      return nextData;
    } catch {
      return;
    }
  });

  const createExam = params => dispatch(createExamAction(params));

  const createExamBySortAction = createAction(SORT_CREATE_EXAM, params => {
    return params;
  });

  const createExamBySort = params => dispatch(createExamBySortAction(params));


  const examCignitiveAction = createAction(EXAM_CIGNITIVE, async params => {
    try {
      const data = await examCignitiveApi(params);
      return data;
    } catch {
      return [];
    }
  });

  const examCignitive = params => dispatch(examCignitiveAction(params));



  const clearQuestionsAction = createAction(CLEAR_QUESTIONS, () => {
    return;
  });
  const clearQuestions = () => dispatch(clearQuestionsAction());

  const deleteQuestionListAction = createAction(DELETE_QUESTION_LIST, id => {
    const newQuestionList = addedQuestionList.filter(item => item.id !== id);
    const newSection = section.map(item => ({
      ...item,
      questionInfo: item.questionInfo.filter(i => i.id !== id),
      answerNum: item.questionInfo.filter(i => i.id !== id).length,
      questionNum: item.questionInfo.filter(i => i.id !== id).length,
    }));
    return {
      newQuestionList,
      newSection
    };
  });

  const deleteQuestionList = id => dispatch(deleteQuestionListAction(id));

  const emptySampleAction = createAction(EMPTY_SAMPLE, async params => {
    try {
      const response = await emptySampleApi(params);
      return response;
    } catch {
      return '';
    }
  });

  const emptySample = params => dispatch(emptySampleAction(params));


  const deleteExamListAction = createAction(DELETE_EXAM_LIST, async params => {
    try {
      const { isSuccess } = await deleteExamApi(params);
      if (isSuccess) {
        const { data: { examID } } = params;
        const newExamList = examList.data.filter(item => item.examID !== examID);
        return newExamList;
      }
    } catch {
      return;
    }
  });

  const deleteExamList = params => dispatch(deleteExamListAction(params));


  const getTempExamAction = createAction(GET_TEMP_EXAM, async params => {
    try {
      const { payload, schoolName } = params;
      const data = await getTempExamApi(payload);
      const { examInfo, title } = data;
      let addedQusetionList = [];
      let questionTitle = '';
      let nextSection = [];
      nextSection = examInfo.section.map(item => {
        item.id = uuidv4();
        addedQusetionList = addedQusetionList.concat(item.questionInfo);
        return item;
      });
      const subject = title.subjectID.substr(1);
      const education = title.subjectID.substr(0, 1);
      const nextTitle = {
        ...title,
        subject,
        education
      };

      const titlePayload = {};

      objectMap(selections, (data, key) => {
        if (Array.isArray(data)) {
          titlePayload[`${key}Name`] = data.find(item => item.value === nextTitle[key]).name;
        } else if (Object.prototype.hasOwnProperty.call(data, education)) {
          titlePayload[`${key}Name`] = data[education].find(item => item.value === nextTitle[key]).name;
        }
      });


      const { yearName, subjectName, examTypeName } = titlePayload;

      questionTitle = `${schoolName}${yearName}${subjectName}${examTypeName}`;

      const nextData = {
        examInfo: {
          ...examInfo,
          section: nextSection
        },
        title: nextTitle,
        addedQusetionList,
        questionTitle
      };

      return nextData;
    } catch {
      return;
    }
  });

  const getTempExam = (params) => dispatch(getTempExamAction(params));


  const getFinishedExamDetailAction = createAction(GET_FINISHED_EXAM_DETAIL, async params => {
    try {
      const { payload, title: questionTitle } = params;

      const data = await getTempExamApi(payload);
      const { examInfo, title } = data;
      let addedQusetionList = [];
      let nextSection = [];
      nextSection = examInfo.section.map(item => {
        item.id = uuidv4();
        addedQusetionList = addedQusetionList.concat(item.questionInfo);
        return item;
      });
      const subject = title.subjectID.substr(1);
      const education = title.subjectID.substr(0, 1);
      const nextTitle = {
        ...title,
        subject,
        education
      };
      const nextData = {
        examInfo: {
          ...examInfo,
          section: nextSection
        },
        title: nextTitle,
        addedQusetionList,
        questionTitle
      };

      return nextData;
    } catch {
      return;
    }
  });

  const getFinishedExamDetail = (params) => dispatch(getFinishedExamDetailAction(params));


  const clearChapterAction = createAction(CLEAR_CHAPTER, () => {
    return;
  });
  const clearChapter = () => dispatch(clearChapterAction());


  const updateExamAction = createAction(UPDATE_EXAM, async params => {
    try {
      const data = await updateExamApi(params);
      const nextSection = data.section.map(item => {
        item.id = uuidv4();
        return item;
      });
      const nextData = {
        ...data,
        section: nextSection
      };
      return nextData;
    } catch {
      return [];
    }
  });

  const updateExam = params => dispatch(updateExamAction(params));

  const clearExamAnalysisAction = createAction(CLEAR_EXAM_ANALYSIS, () => {
    return;
  });
  const clearExamAnalysis = () => dispatch(clearExamAnalysisAction());


  const registerScoreAction = createAction(REGISTER_SCORE, async payload => {
    const response = await registerScoreApi(payload);
    return response;
  });

  const registerScore = payload => dispatch(registerScoreAction(payload));

  const registerScoreUploadAction = createAction(REGISTER_SCORE_UPLOAD, async payload => {
    await registerScoreUploadApi(payload);
    setAlert(ALERT_MESSAGE.UPDATE_SUCCESS, 'success');
    return;
  });

  const registerScoreUpload = payload => dispatch(registerScoreUploadAction(payload));

  return [
    {
      selections,
      chapter,
      questions,
      searchQusetionPayload,
      analysisQuestionData,
      addedQuestionList,
      searchQuestionPageData,
      formatterChapter,
      examList,
      scoreType,
      section,
      examID,
      examAnalysis,
      questionTitle,
      isLoading,
    }, // state
    {
      getQuestionSelections,
      getQuestionChapter,
      getSearchQuestion,
      downloadSingleQuestion,
      calculateExamQuestion,
      getAddedQuestionList,
      saveStaticData,
      getExamList,
      createExam,
      createExamBySort,
      examCignitive,
      clearQuestions,
      deleteQuestionList,
      emptySample,
      deleteExamList,
      getTempExam,
      clearChapter,
      updateExam,
      clearExamAnalysis,
      getFinishedExamDetail,
      registerScore,
      registerScoreUpload,
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    GET_QUESTION_SELECTIONS_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_QUESTION_SELECTIONS_FULFILLED: (state, action) => ({
      ...state,
      selections: action.payload,
      isLoading: false
    }),
    GET_QUESTION_SELECTIONS_REJECTED: state => ({
      ...state,
      selections: {
        book: [],
        source: [],
        year: [],
        version: [],
        education: [],
        subject: {},
      },
      isLoading: false
    }),
    GET_QUESTION_CHAPTER_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_QUESTION_CHAPTER_FULFILLED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          chapter: action.payload.data,
          searchQusetionPayload: {},
          addedQuestionList:[],
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            chapter: action.payload.data,
            searchQusetionPayload: {},
          },
          isLoading: false
        };
      }
    },
    GET_QUESTION_CHAPTER_REJECTED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          chapter: [],
          searchQusetionPayload: {},
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            chapter: [],
            searchQusetionPayload: {},
          },
          isLoading: false
        };
      }
    },
    GET_SEARCH_QUESTION_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_SEARCH_QUESTION_FULFILLED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          questions: action.payload.data,
          searchQusetionPayload: action.payload.searchData,
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            questions: action.payload.data,
            searchQusetionPayload: action.payload.searchData,
          },
          isLoading: false
        };
      }
    },
    GET_SEARCH_QUESTION_REJECTED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          questions: [],
          searchQusetionPayload: [],
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            questions: [],
            searchQusetionPayload: [],
          },
          isLoading: false
        };
      }
    },
    DOWNLOAD_SINGLE_QUESTION_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    DOWNLOAD_SINGLE_QUESTION_FULFILLED: state => ({
      ...state,
      isLoading: false
    }),
    DOWNLOAD_SINGLE_QUESTION_REJECTED: state => ({
      ...state,
      questions: [],
      isLoading: false
    }),
    CALCULATE_EXAM_QUESTION_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    CALCULATE_EXAM_QUESTION_FULFILLED: (state, action) => ({
      ...state,
      analysisQuestionData: action.payload,
      isLoading: false
    }),
    CALCULATE_EXAM_QUESTION_REJECTED: state => ({
      ...state,
      analysisQuestionData: [],
      isLoading: false
    }),

    GET_ADDED_QUESTION_LIST_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_ADDED_QUESTION_LIST_FULFILLED: (state, action) => {
      let nextAddedQuestionList = [];
      if (action.payload.state) {
        nextAddedQuestionList = [...state.addedQuestionList, action.payload.data];
      } else {
        nextAddedQuestionList = state.addedQuestionList.filter(item => item.id !== action.payload.data.id);
      }
      return {
        ...state,
        addedQuestionList: nextAddedQuestionList,
        isLoading: false,
      };
    },
    GET_ADDED_QUESTION_LIST_REJECTED: state => ({
      ...state,
      addedQuestionList: [],
      isLoading: false
    }),

    SAVE_STATIC_DATA_PENDING: state => ({
      ...state,
      isLoading: false
    }),
    SAVE_STATIC_DATA_FULFILLED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          formatterChapter: {
            ...state.formatterChapter,
            data: action.payload.chapter,
            checkAll: action.payload.checkAll,
          },
          questionTitle: action.payload.questionHeader,
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            formatterChapter: {
              ...state.searchQuestionPageData.formatterChapter,
              data: action.payload.chapter,
              checkAll: action.payload.checkAll,
            }
          },
          isLoading: false
        };
      }
    },
    SAVE_STATIC_DATA_REJECTED: (state, action) => {
      if (action.payload.type === 'create') {
        return {
          ...state,
          formatterChapter: [],
          questionTitle: '',
          isLoading: false
        };
      } else {
        return {
          ...state,
          searchQuestionPageData: {
            ...state.searchQuestionPageData,
            formatterChapter: [],
          },
          isLoading: false
        };
      }
    },
    GET_EXAM_LIST_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    GET_EXAM_LIST_FULFILLED: (state, action) => ({
      ...state,
      examList: {
        data: action.payload
      },
      isLoading: false
    }),
    GET_EXAM_LIST_REJECTED: state => ({
      ...state,
      examList: {
        data: []
      },
      isLoading: false
    }),
    CREATE_EXAM_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    CREATE_EXAM_FULFILLED: (state, action) => ({
      ...state,
      scoreType: action.payload.scoreType,
      section: action.payload.section,
      examID: action.payload.examID,
      isLoading: false
    }),
    CREATE_EXAM_REJECTED: (state, action) => ({
      ...state,
      isLoading: false
    }),
    SORT_CREATE_EXAM: (state, action) => ({
      ...state,
      section: action.payload
    }),
    EXAM_CIGNITIVE_PENDING: state => ({
      ...state,
    }),
    EXAM_CIGNITIVE_FULFILLED: (state, action) => ({
      ...state,
      examAnalysis: action.payload
    }),
    EXAM_CIGNITIVE_REJECTED: state => ({
      ...state,
      examAnalysis: {}
    }),
    CLEAR_QUESTIONS: () => ({
      ...initState,
    }),
    DELETE_QUESTION_LIST: (state, action) => ({
      ...state,
      section: action.payload.newSection,
      addedQuestionList: action.payload.newQuestionList
    }),
    EMPTY_SAMPLE_PENDING: state => ({
      ...state,
      isLoading: true,
    }),
    EMPTY_SAMPLE_FULFILLED: state => ({
      ...state,
      isLoading: false,
    }),
    EMPTY_SAMPLE_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    DELETE_EXAM_LIST_PENDING: state => ({
      ...state,
      isLoading: true,
    }),
    DELETE_EXAM_LIST_FULFILLED: (state, action) => ({
      ...state,
      examList: {
        data: action.payload
      },
      isLoading: false,
    }),
    DELETE_EXAM_LIST_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    GET_TEMP_EXAM_PENDING: state => ({
      ...state,
      isLoading: true,
    }),
    GET_TEMP_EXAM_FULFILLED: (state, action) => ({
      ...state,
      questionTitle: action.payload.questionTitle,
      addedQuestionList: action.payload.addedQusetionList,
      scoreType: action.payload.examInfo.scoreType,
      section: action.payload.examInfo.section,
      examID: action.payload.examInfo.examID,
      searchQusetionPayload: {
        client: 'tikuHualien',
        data: action.payload.title
      },
      isLoading: false,
    }),
    GET_TEMP_EXAM_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    GET_FINISHED_EXAM_DETAIL_PENDING: state => ({
      ...state,
      isLoading: true,
    }),
    GET_FINISHED_EXAM_DETAIL_FULFILLED: (state, action) => ({
      ...state,
      questionTitle: action.payload.questionTitle,
      addedQuestionList: action.payload.addedQusetionList,
      scoreType: action.payload.examInfo.scoreType,
      section: action.payload.examInfo.section,
      examID: action.payload.examInfo.examID,
      searchQusetionPayload: {
        client: 'tikuHualien',
        data: action.payload.title
      },
      isLoading: false,
    }),
    GET_FINISHED_EXAM_DETAIL_REJECTED: state => ({
      ...state,
      isLoading: false,
    }),
    //清除章節
    CLEAR_CHAPTER: (state) => ({
      ...state,
      chapter: [],
    }),
    //清除雙向分析資料
    CLEAR_EXAM_ANALYSIS: (state) => ({
      ...state,
      examAnalysis: {},
    }),
    UPDATE_EXAM_PENDING: state => ({
      ...state,
      isLoading: true
    }),
    UPDATE_EXAM_FULFILLED: (state, action) => ({
      ...state,
      scoreType: action.payload.scoreType,
      section: action.payload.section,
      examID: action.payload.examID,
      isLoading: false
    }),
    UPDATE_EXAM_REJECTED: (state, action) => ({
      ...state,
      isLoading: false
    }),
  },
  initState
);

export default reducer;
