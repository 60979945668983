import styled from 'styled-components';

export const UiSelectBox = styled.div`
	width: 100%;

	& > div {
		display: inline-block;
		width: 32%;
		margin-bottom: 1rem;

		&:not(:nth-child(3n)) {
			margin-right: 2%;
		}
	}
`;

export const UiButtonBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 2rem;
`;


export const UiPrecautions = styled.div`
	margin: 1.5rem 0;
	font-size: 1.6rem;
	color: ${({ theme }) => theme.colorTheme.secondary};
`;

export const UiPrecautionsHead = styled.div`
`;

export const UiPrecautionsContent = styled.div`
`;

export const UiPrecautionsUl = styled.ul`
	padding-left: 1.5rem;
	list-style: decimal;
`;

export const UiPrecautionsItem = styled.li`
`;
