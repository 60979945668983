import React from 'react';
import PropTypes from 'prop-types';
import { UiSliderBar, Uilabel, UiSliderBarFlex } from './SliderBar.style';
import { Slider as MatSlider } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { Input } from 'components';
/**
 * 範圍滑塊組件
 */

export const SliderBar = ({
  getValue,
  label,
  name,
}) => {
  const [{ value }, setState] = useSetState({
    value: [0, 0.30],
  });
  const handleChange = (event, newValue) => {
    setState({
      value: newValue
    });
    getValue(newValue, name);
  };

  const onChangeHandler = data => {
    const { max, min } = data;
    if (max === '' || max === '0.') return;
    if (min === '' || min === '0.') return;
    if (min) {
      if (min === value[0]) return;
      let nextMin, nextMax;
      if (min > value[1]) {
        nextMin = value[1];
        nextMax = min > 1 ? 1 : min;
      } else {
        nextMin = min;
        nextMax = value[1];
      }
      setState({
        value: [nextMin, nextMax]
      });

    }
    if (max) {
      if (max === value[1]) return;
      let nextMin, nextMax;
      if (max < value[0]) {
        nextMin = max;
        nextMax = value[0];
      } else {
        nextMin = value[0];
        nextMax = max > 1 ? 1 : max;
      }
      setState({
        value: [nextMin, nextMax]
      });
    }
  };

  return (
    <UiSliderBar>
      {
        label &&
        <Uilabel>{label}</Uilabel>
      }
      <UiSliderBarFlex>
        <Input check='number' name={'min'} value={value[0]} onChange={onChangeHandler} />
        <MatSlider
          value={value}
          min={0}
          step={0.01}
          max={1}
          onChange={handleChange}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
        />
        <Input check='number' name={'max'} value={value[1]} onChange={onChangeHandler} />
      </UiSliderBarFlex>

    </UiSliderBar>
  );
};

SliderBar.propTypes = {
  getValue: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
};


