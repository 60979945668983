import styled from 'styled-components';

export const UiTextStroke = styled.div`
	font-size: 4rem;
	font-weight: 1000;
	color: #005197;
	font-style: normal;
	text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
	cursor: default;
`;
