import React, { useEffect } from 'react';
import { TitleContainer, Table, Pagination } from 'components';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useReview } from 'store/review';
import { UiReviewTab, UiReviewTabItem } from './Review.style';

/**
 * 勾稽審查
 */

const schema = {
  createTime: {
    name: '建立日期',
  },
  desc: {
    name: '標題',
    size: 'large'
  },
  status: {
    name: '狀態',
    showText: {
      UnCheck: '未審查',
      Check: '已審查',
      Keep: '暫存紀錄'
    }
  }
};

const checkSchema = {
  createTime: {
    name: '建立日期',
  },
  checkedTime: {
    name: '審查日期'
  },
  desc: {
    name: '標題',
  },
  remark: {
    name: '備註'
  }
};

export const Review = () => {
  const history = useHistory();
  const { location } = history;
  const [{ reviewList, checkList }, { getReviewList, getCheckList }] = useReview();
  const [{
    page,
    limit,
    type
  }, setState] = useSetState({
    page: 0,
    limit: 7,
    type: location.state?.listType ?? 'review'
  });

  const actions = [
    {
      status: 'normal',
      icon: 'visibility',
      title: '詳細內容',
      background: '#ffb800',
      onClick: data => {
        const { id } = data;
        history.push(`${MAIN_PATH.REVIEW_DETAIL}/${id}?type=${type}`);
      }
    }
  ];

  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };

  const changeType = chooseType => {
    setState({
      type: chooseType
    });
  };

  useEffect(() => {
    if (type === 'review') {
      getReviewList();
    } else {
      getCheckList();
    }

  }, [type]);

  return (
    <TitleContainer
      title="勾稽審查"
      titleIcon="description"
      titleChildren={
        <UiReviewTab>
          <UiReviewTabItem
            status={type === 'review' || false}
            onClick={() => changeType('review')}
          >
            待審查
          </UiReviewTabItem>
          <UiReviewTabItem
            status={type === 'check' || false}
            onClick={() => changeType('check')}
          >
            已審查
          </UiReviewTabItem>
        </UiReviewTab>
      }
    >
      <Table
        data={type === 'review' ? reviewList.data : checkList.data}
        schema={type === 'review' ? schema : checkSchema}
        isNeedTitle={true}
        actions={actions}
        limit={limit}
        page={page}
        isLoading={false}
      />
      <Pagination total={[].length} limit={limit} onChange={onChangePage} />
    </TitleContainer>
  );
};
