import React, { useEffect } from 'react';
import { useConvert } from 'store/convert';
import { useAlert, ALERT_MESSAGE } from 'utils/hooks/useAlert';
import { useMember } from 'store/member';
import { useModal } from 'utils/hooks/useModal';
import { useDownload } from 'utils/hooks/useDownload';
import { useSetState } from 'utils/hooks/useSetState';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { v4 as uuidv4 } from 'uuid';
import { useQuestion } from 'store/question';
import {
  TitleContainer,
  Table,
  Pagination,
  Button,
  UploadFileModal,
  QuestionModal,
  SelectDownloadModal,
  InstructionsModal
} from 'components';


/**
 * 新題入庫
 */

const schema = {
  fileName: {
    name: '檔案名稱',
  },
  status: {
    name: '狀態',
    showText: {
      Succeed: '入庫成功',
      Waiting: '等待入庫中',
      Retry: '檔案格式有誤，請檢視檔案內容後重新上傳',
      Convert: '入庫中',
      Fail: '入庫失敗,請檢查檔案內容後重新上傳'
    }
  },
  systemTime: {
    name: '日期',
    default: '＝',
  },
  type: {
    name: '類別',
    showText: {
      TIKU: '老師命題'
    }
  }
};



export const NewQuestionList = () => {
  const { upload } = useFirebaseStorage();
  const { setAlert } = useAlert();
  const [{ memberInfo }] = useMember();
  const { blobDownload } = useDownload();
  const { setModal } = useModal();
  const [
    {
      convertList: {
        data: converListData
      },
      serachResult,
      isLoading,
    },
    {
      queuePush,
      getConvertList,
      getConvertSearch,
      getDisabledStatus
    }] = useConvert();
  const [{
    selections,
  }, {
    getQuestionSelections,
    emptySample
  }] = useQuestion();

  const [
    {
      isOpen,
      isQuestionOpen,
      isDownloadOpen,
      isInstructionsOpen,
      modalSubjectID,
      page,
      limit,
    },
    setState] = useSetState({
      isOpen: false,
      isQuestionOpen: false,
      isDownloadOpen: false,
      isInstructionsOpen: false,
      modalSubjectID: '',
      page: 0,
      limit: 7
    });

  //設定彈窗狀態
  const sendModalStateHandler = state => {
    setState({
      isOpen: state
    });
  };

  const getQuestionModalStateHandler = state => {
    setState({
      isQuestionOpen: state
    });
  };

  const modalStateHandler = state => {
    setState({
      isDownloadOpen: state
    });
  };

  const instructionsModalStateHandler = state => {
    setState({
      isInstructionsOpen: state
    });
  };

  const getDisabledStatusHandler = async id => {
    await getDisabledStatus(id);
  };

  const actions = [
    {
      status: 'Succeed',
      icon: 'visibility',
      title: '預覽',
      background: '#ffb800',
      onClick: async data => {
        const { fileID, subjectID, } = data;
        const payload = {
          client: 'tikuHuailen',
          data: {
            UUID: fileID,
            outType: 'PNG',
            subjectID
          }
        };
        await getConvertSearch(payload);
        setState({
          modalSubjectID: subjectID
        });
        getQuestionModalStateHandler(true);
      }
    },
    {
      status: 'download',
      icon: 'cloud_download',
      title: '下載',
      background: '#003a92',
      onClick: data => {
        const { filepath, fileName } = data;
        const [fileTitle,fileExtension] = fileName.split('.');
        blobDownload(filepath, `${fileTitle}(轉檔錯誤).${fileExtension}`, 'GET');
      }
    },
    {
      status: 'normal',
      icon: 'delete_forever',
      title: '刪除',
      background: '#ff3636',
      onClick: data => {
        const { fileID } = data;
        setModal({
          html: '確定要<span>刪除檔案</span>嗎？',
          confirmEvent: () => getDisabledStatusHandler(fileID),
        });
      }
    },
  ];

  const onUploaded = async file => {
    const fileName = `${uuidv4()}.${file.name.split('.')[1]}`;
    const uploadPath = `convert/${fileName}`;
    const response = await upload(uploadPath, file);
    const { status } = response;
    if (status) {
      return fileName;
    } else {
      return false;
    }
  };
  const onSubmitHandler = async files => {
    const regex = new RegExp('^(?!.*?_$)[\u4e00-\u9fa5(-|_)a-zA-Z0-9]+$');
    const isErrorFileName = files.some(item => !regex.test(item.name));
    if (isErrorFileName) {
      setAlert(ALERT_MESSAGE.FILENAME_ERROR, 'error');
      return;
    }
    setAlert('上傳檔案中!', 'success');
    await Promise.all(files.map(async file => {
      const uuidName = await onUploaded(file);
      if (!uuidName) throw uuidName;
      return {
        client: 'tikuHualien',
        user: memberInfo.id,
        fileType: 'TIKU',
        formatTo: [
          'Html',
          'PNG'
        ],
        fileID: uuidName,
        fileName: file.name,
        email: 'sian415@gmail.com'
      };
    })).then(async filesData => {
      setAlert('上傳檔案成功!', 'success');
      Promise.all(filesData.map(async data => {
        await queuePush(data);
      })).then(async () => {
        await getConvertList();
        sendModalStateHandler(false);
      });
    }).catch(() => {
      setAlert('上傳檔案失敗!', 'wrarning');
    });
  };
  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };

  const submitHandler = async params => {
    const {
      bookValue,
      educationValue,
      subjectValue,
      versionValue,
      writeTypeValue
    } = params;
    if (!bookValue || !educationValue || !subjectValue || !versionValue || writeTypeValue === '') return;
    const nextParams = {
      education: educationValue,
      subject: subjectValue,
      year: '109',
      version: versionValue,
      book: bookValue,
      format: writeTypeValue
    };
    const { value } = await emptySample(nextParams);
    blobDownload(value, '範例.doc', 'POST', nextParams);
    modalStateHandler(false);
  };

  const getConvertListHandler = id => {
    const getListPayload = {
      'client': 'tikuHualien',
      'data': {
        'user': id
      }
    };
    getConvertList(getListPayload);
  };

  useEffect(() => {
    if (!memberInfo.id) return;
    getConvertListHandler(memberInfo.id);
    getQuestionSelections();
  }, [memberInfo]);

  return (
    <TitleContainer
      title="新題入庫"
      titleIcon="create_new_folder"
      titleChildren={
        <>
          <Button icon="system_update_alt" onClick={() => modalStateHandler(true)}>下載題目範本</Button>
          <Button icon="notification_important" onClick={() => instructionsModalStateHandler(true)}>題目填寫說明</Button>
          <Button icon="cloud_upload" onClick={() => sendModalStateHandler(true)}>上傳題目檔案</Button>
          <Button icon="refresh" onClick={() => getConvertListHandler(memberInfo.id)}>重新整理入庫狀態</Button>
        </>
      }
    >
      <Table
        data={converListData}
        schema={schema}
        isNeedTitle={true}
        actions={actions}
        limit={limit}
        page={page}
        isLoading={isLoading}
      />
      <Pagination total={converListData.length} limit={limit} onChange={onChangePage} />
      <UploadFileModal
        open={isOpen}
        onSubmit={onSubmitHandler}
        sendModalState={sendModalStateHandler}
        filesLimit={50}
      />
      <QuestionModal
        subject={modalSubjectID}
        data={serachResult}
        open={isQuestionOpen}
        getModalState={getQuestionModalStateHandler}
        isEdit={true}
      />
      <SelectDownloadModal
        open={isDownloadOpen}
        data={selections}
        modalStateHandler={modalStateHandler}
        submit={submitHandler}
      />
      <InstructionsModal
        open={isInstructionsOpen}
        getModalState={instructionsModalStateHandler}
      />
    </TitleContainer>
  );
};
