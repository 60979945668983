import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MatImage from 'material-ui-image';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiQuestionDetail,
  UiQuestionDetailWrapper,
  UiQuestionDetailContent,
  UiQuestionDetailLeft,
  UiQuestionDetailRight,
  UiQuestionDetailItem,
  UiQuestionDetailTitle,
  UiQuestionDetailText,
  UiQuestionDetailTextItem,
  UiQuestionDetailImage,
} from './QuestionDetail.style';

/**
 * 題目詳細
 */


export const QuestionDetail = ({
  data = {},
  page = 1,
  isEdit = false
}) => {
  const [{ previewImg, detail, memberInfo }, setState] = useSetState({
    previewImg: 0,
    detail: [],
    memberInfo: {}
  });

  const onQuestionNumberChange = value => {
    const choosed = value - 1;
    const { metaInfos, question, author } = data.questions[choosed];
    const blacklist = [
      'EXAM_CONCEPT',
      'CHAP_POINT'
    ];
    const filterMetaInfos = metaInfos.filter(info => {
      for (let i = 0; i < blacklist.length; i++) {
        if (blacklist[i] === info.id) return false;
      }
      return info;
    });
    setState({
      previewImg: question,
      detail: filterMetaInfos,
      memberInfo: author
    });
  };

  useEffect(() => {
    onQuestionNumberChange(page);
  }, [page]);
  return (
    <UiQuestionDetail>
      <UiQuestionDetailWrapper>
        <UiQuestionDetailContent>
          <UiQuestionDetailLeft>
            <UiQuestionDetailTitle>題目</UiQuestionDetailTitle>
            <UiQuestionDetailImage>
              <MatImage src={previewImg} alt="" />
            </UiQuestionDetailImage>
            <UiQuestionDetailText>
              <UiQuestionDetailTextItem>
                <span>命題時間: {memberInfo?.systemTime}</span>
              </UiQuestionDetailTextItem>
              <UiQuestionDetailTextItem>
                <span>命題教師: {memberInfo?.name}</span>
              </UiQuestionDetailTextItem>
              <UiQuestionDetailTextItem>
                <span>學校: {memberInfo?.school ?? '無所屬'}</span>
              </UiQuestionDetailTextItem>
              <UiQuestionDetailTextItem>
                <span>題目狀態: 可使用</span>
              </UiQuestionDetailTextItem>
            </UiQuestionDetailText>
          </UiQuestionDetailLeft>
          <UiQuestionDetailRight>
            <UiQuestionDetailTitle>基本資料</UiQuestionDetailTitle>
            {detail.filter(item => item.target !== '').map((item, index) => {
              return (
                <UiQuestionDetailItem key={item.id + index}>
                  <span>{item.target}: </span>
                  {item.value.map((option, index) => {
                    return (
                      <>{index > 0 ? ',' + option : option}</>
                    );
                  })}
                </UiQuestionDetailItem>
              );
            })}
          </UiQuestionDetailRight>
        </UiQuestionDetailContent>
      </UiQuestionDetailWrapper>
    </UiQuestionDetail>
  );
};

QuestionDetail.propTypes = {
  data: PropTypes.shape({

  }),
  page: PropTypes.number,
  isEdit: PropTypes.bool,
};


