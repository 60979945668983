import styled from 'styled-components';

export const UiInstructions = styled.div`
`;

export const UiTable = styled.table`
	position: relative;
	width: 100%;
	flex: 1;
	border-spacing: 0;
`;

export const UiTbody = styled.tbody`
	width: 100%;
`;

export const UiTr = styled.tr`
	min-height: 70px;
	border: 0;

	&:nth-child(odd) {
		background: #fff;
	}

	&:nth-child(even) {
		background: ${({ theme }) => theme.colorTheme.quaternary};
	}

	&:first-child {
		color: #fff;
		background: ${({ theme }) => theme.colorTheme.secondary};
	}

	& > th:first-child {
		width: 50% !important;
	}

	& > td:first-child {
		font-size: 2.5rem;
		font-weight: bolder;
	}

`;

export const UiTh = styled.th`
	padding: 1.5rem 2rem;
	width: ${({ size }) => size === 2 ? '40rem' : 'auto'};
	font-size: 2rem;
	font-weight: bold;
	text-align: left;
`;

export const UiTd = styled.td`
	padding: 1.5rem 2rem;
	font-size: 1.5rem;

	& > div {
		display: flex;
		align-items: center;

		& > div > i {
			width: 4rem !important;
		}

		& > * + * {
			margin-left: 5px;
		}
	}
`;
