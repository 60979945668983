import styled from 'styled-components';
import {
  ListItem as MatListItem
} from '@material-ui/core';
export const UiMatListItem = styled(MatListItem)`
	padding-top: 0;
	padding-bottom: 0;
`;

export const UiOpen = styled.div`
	margin-left: 2rem;
`;
