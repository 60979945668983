import styled from 'styled-components';
import {
  FormHelperText as MatFormHelperText
} from '@material-ui/core';

const normalColor = '#2a4865';

export const UiInput = styled.div`
	input {
		padding: 5px 12px;
		width: 100%;
		min-height: 40px;
		font-size: 1.7rem;
		font-family: Noto Sans TC, Helvetica Neue, PingFang TC, Heiti TC, sans-serif;
		color: ${({ textcolor }) => textcolor || '#000'};
		background: #fff;
		border: 1px solid;
		border-color: ${({ theme, error }) => error ? theme.text.color.error : normalColor};
		border-radius: 5px;
		box-sizing: border-box;
	}

	textarea {
		padding: 5px 12px;
		width: 100%;
		min-height: 40px;
		font-size: 1.7rem;
		font-family: Noto Sans TC, Helvetica Neue, PingFang TC, Heiti TC, sans-serif;
		color: ${({ textcolor }) => textcolor || '#333'};
		background: #fff;
		border: 1px solid;
		border-color: ${({ theme, error }) => error ? theme.text.color.error : 'rgba(0,0,0,0.3)'};
		border-radius: 5px;
		resize: none;
		box-sizing: border-box;
	}
`;
export const Uilabel = styled.h3`
	margin: 0;
`;
export const UiMatFormHelperText = styled(MatFormHelperText)`
	color: ${({ theme }) => theme.text.color.error};
`;
