import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getReportSeletions as getReportSeletionsApi,
  getCorrectRate as getCorrectRateApi,
  getExamAbility as getExamAbilityApi,
  getSubCorrectRate as getSubCorrectRateApi,
  getDifficulty as getDifficultyApi,
  getSubDifficulty as getSubDifficultyApi,
  getDiscrimination as getDiscriminationApi,
  getSubDiscrimination as getSubDiscriminationApi,
  getReliability as getReliabilityApi,
  getCognitive as getCognitiveApi
} from 'services/api/report';
import { useAlert } from 'utils/hooks/useAlert';

const GET_REPORT_SELETIONS = 'GET_REPORT_SELETIONS';
const GET_CURRENT_RATE = 'GET_CURRENT_RATE';
const GET_EXAMABILITY = 'GET_EXAMABILITY';
const GET_SUBCORRECT_RATE = 'GET_SUBCORRECT_RATE';
const GET_DIFFICULTY = 'GET_DIFFICULTY';
const GET_SUBDIFFICULTY = 'GET_SUBDIFFICULTY';
const GET_DISCRIMINATION = 'GET_DISCRIMINATION';
const GET_SUBDISCRIMINATION = 'GET_SUBDISCRIMINATION';
const GET_RELIABILITY = 'GET_RELIABILITY';
const GET_COGNITIVE = 'GET_COGNITIVE';
const CLEAR_REPORT = 'CLEAR_REPORT';

const initState = {
  selections: {},
  reportData: {},
  examAbilityData: {},
  subCorrectReportData: {
    dataList: [],
    className: [],
    title: [],
    classCorrectRate: [],
    answer: [],
    schoolCorrectRate: []
  },
  difficultyData: {
    schoolName: {},
    title: {},
    className: {},
    header: [],
    data: {}
  },
  subDifficultyData: {
    schoolName: {},
    title: {},
    className: {},
    header: [],
    data: {}
  },
  discrimination: {
    schoolName: {},
    title: {},
    className: {},
    header: [],
    data: {},
    classDiscrimination: {}
  },
  subDiscrimination: {
    schoolName: {},
    title: {},
    className: {},
    header: [],
    data: {},
    classDiscrimination: {}
  },
  reliability: {
    schoolName: {},
    title: {},
    className: {},
    header: [],
    data: {},
    classDiscrimination: {}
  },
  cognitive: {

  }
};

export const useReport = () => {
  const dispatch = useDispatch();
  const {
    selections,
    reportData,
    examAbilityData,
    subCorrectReportData,
    difficultyData,
    subDifficultyData,
    discrimination,
    subDiscrimination,
    reliability,
    cognitive,
  } = useSelector(state => state.report);
  const { setAlert } = useAlert();

  const getReportSeletionsAction = createAction(GET_REPORT_SELETIONS, async params => {
    const response = await getReportSeletionsApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getReportSeletions = params => dispatch(getReportSeletionsAction(params));

  const getCorrectRateAction = createAction(GET_CURRENT_RATE, async params => {
    const response = await getCorrectRateApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getCorrectRate = params => dispatch(getCorrectRateAction(params));


  const getExamAbilityAction = createAction(GET_EXAMABILITY, async params => {
    const response = await getExamAbilityApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getExamAbility = params => dispatch(getExamAbilityAction(params));

  const getSubCorrectRateAction = createAction(GET_SUBCORRECT_RATE, async params => {
    const response = await getSubCorrectRateApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getSubCorrectRate = params => dispatch(getSubCorrectRateAction(params));

  const getDifficultyAction = createAction(GET_DIFFICULTY, async params => {
    const response = await getDifficultyApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getDifficulty = params => dispatch(getDifficultyAction(params));

  const getSubDifficultyAction = createAction(GET_SUBDIFFICULTY, async params => {
    const response = await getSubDifficultyApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getSubDifficulty = params => dispatch(getSubDifficultyAction(params));


  const getDiscriminationAction = createAction(GET_DISCRIMINATION, async params => {
    const response = await getDiscriminationApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getDiscrimination = params => dispatch(getDiscriminationAction(params));

  const getSubDiscriminationAction = createAction(GET_SUBDISCRIMINATION, async params => {
    const response = await getSubDiscriminationApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getSubDiscrimination = params => dispatch(getSubDiscriminationAction(params));


  const getReliabilityAction = createAction(GET_RELIABILITY, async params => {
    const response = await getReliabilityApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getReliability = params => dispatch(getReliabilityAction(params));

  const getCognitiveAction = createAction(GET_COGNITIVE, async params => {
    const response = await getCognitiveApi(params);
    if (response.errorMessage) {
      setAlert(response.errorMessage, 'error');
      return null;
    }
    return response.json();
  });
  const getCognitive = params => dispatch(getCognitiveAction(params));


  const clearReportAction = createAction(CLEAR_REPORT, () => {
    return;
  });
  const clearReport = () => dispatch(clearReportAction());

  return [
    {
      selections,
      reportData,
      examAbilityData,
      subCorrectReportData,
      difficultyData,
      subDifficultyData,
      subDiscrimination,
      discrimination,
      reliability,
      cognitive,
    }, // state
    {
      getReportSeletions,
      getCorrectRate,
      getExamAbility,
      getSubCorrectRate,
      getDifficulty,
      getSubDifficulty,
      getDiscrimination,
      getSubDiscrimination,
      getReliability,
      getCognitive,
      clearReport,
    }, // eventHanlder
  ];
};

const reducer = handleActions(
  {
    GET_REPORT_SELETIONS_PENDING: state => ({
      ...state,
    }),
    GET_REPORT_SELETIONS_FULFILLED: (state, action) => ({
      ...state,
      selections: action.payload
    }),
    GET_REPORT_SELETIONS_REJECTED: state => ({
      ...state,
    }),
    GET_CURRENT_RATE_PENDING: state => ({
      ...state,
    }),
    GET_CURRENT_RATE_FULFILLED: (state, action) => ({
      ...state,
      reportData: action.payload
    }),
    GET_CURRENT_RATE_REJECTED: state => ({
      ...state,
    }),
    GET_EXAMABILITY_PENDING: state => ({
      ...state,
    }),
    GET_EXAMABILITY_FULFILLED: (state, action) => ({
      ...state,
      examAbilityData: action.payload
    }),
    GET_EXAMABILITY_REJECTED: state => ({
      ...state,
    }),
    GET_SUBCORRECT_RATE_PENDING: state => ({
      ...state,
    }),
    GET_SUBCORRECT_RATE_FULFILLED: (state, action) => ({
      ...state,
      subCorrectReportData: action.payload
    }),
    GET_SUBCORRECT_RATE_REJECTED: state => ({
      ...state,
    }),
    GET_DIFFICULTY_PENDING: state => ({
      ...state,
    }),
    GET_DIFFICULTY_FULFILLED: (state, action) => ({
      ...state,
      difficultyData: {
        ...state.difficultyData,
        ...action.payload
      }
    }),
    GET_DIFFICULTY_REJECTED: state => ({
      ...state,
    }),
    GET_SUBDIFFICULTY_PENDING: state => ({
      ...state,
    }),
    GET_SUBDIFFICULTY_FULFILLED: (state, action) => ({
      ...state,
      subDifficultyData: {
        ...state.subDifficultyData,
        ...action.payload
      }
    }),
    GET_SUBDIFFICULTY_REJECTED: state => ({
      ...state,
    }),
    GET_DISCRIMINATION_PENDING: state => ({
      ...state,
    }),
    GET_DISCRIMINATION_FULFILLED: (state, action) => ({
      ...state,
      discrimination: {
        ...state.discrimination,
        ...action.payload
      }
    }),
    GET_DISCRIMINATION_REJECTED: state => ({
      ...state,
    }),
    GET_SUBDISCRIMINATION_PENDING: state => ({
      ...state,
    }),
    GET_SUBDISCRIMINATION_FULFILLED: (state, action) => ({
      ...state,
      subDiscrimination: {
        ...state.discrimination,
        ...action.payload
      }
    }),
    GET_SUBDISCRIMINATION_REJECTED: state => ({
      ...state,
    }),
    GET_RELIABILITY_PENDING: state => ({
      ...state,
    }),
    GET_RELIABILITY_FULFILLED: (state, action) => ({
      ...state,
      reliability: {
        ...state.reliability,
        ...action.payload
      }
    }),
    GET_RELIABILITY_REJECTED: state => ({
      ...state,
    }),
    GET_COGNITIVE_PENDING: state => ({
      ...state,
    }),
    GET_COGNITIVE_FULFILLED: (state, action) => ({
      ...state,
      cognitive: {
        ...state.cognitive,
        ...action.payload
      }
    }),
    GET_COGNITIVE_REJECTED: state => ({
      ...state,
    }),
    CLEAR_REPORT: () => ({
      ...initState
    })
  },
  initState
);

export default reducer;
