import React from 'react';
import { TitleContainer, Sidebar, } from 'components';
import PropTypes from 'prop-types';
import {
  UiOptionBox,
  UiOptionTop,
  UiOptionRemind,
  UiOptionBottom,
  UiRadioBox,
  UiRadioTitle,
  UiContent,
} from './ReportContainer.style';


/**
 * 報表容器
 */

export const ReportContainer = ({ title, selectGroup, remind, radioTitle, radioChildren, children }) => {
  return (
    <TitleContainer overflowHidden={true} title={title} titleIcon="account_balance">
      <UiOptionBox>
        <UiOptionTop>
          {selectGroup}
        </UiOptionTop>
        {
          radioChildren &&
          <UiOptionBottom>
            {remind && <UiOptionRemind>{remind}</UiOptionRemind>}
            <UiRadioBox>
              <UiRadioTitle>{radioTitle}</UiRadioTitle>
              {radioChildren}
            </UiRadioBox>
          </UiOptionBottom>
        }
      </UiOptionBox>
      <Sidebar />
      <UiContent>
        {children}
      </UiContent>
    </TitleContainer>
  );
};

ReportContainer.propTypes = {
  title: PropTypes.string,
  selectGroup: PropTypes.node,
  remind: PropTypes.string,
  radioTitle: PropTypes.string,
  radioChildren: PropTypes.node,
  children: PropTypes.node
};


