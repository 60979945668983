import styled from 'styled-components';

export const UiAnswerCardTab = styled.div`
	display: flex;
	align-items: stretch;
	margin: auto;
	width: 90%;
	margin-bottom: 2rem;
`;

export const UiSidebar = styled.div`
	width: 17rem;
`;

export const UiStudentTabs = styled.ul`
	overflow-x: hidden;
	margin: 0 0 2rem;
	padding: 0;
	height: ${({ height }) => height || 'max-content'};
	max-height: 600px;
	list-style: none;
`;

export const UiStudentItem = styled.li`
	cursor: pointer;
	padding: 1.2rem 1rem;
	font-size: 1.6rem;
	color: ${({ active }) => active ? '#fff' : '#333'};
	background-color: ${({ active, theme }) => active ? theme.colorTheme.primary : theme.colorTheme.tertiary};
	transition: 0.35s;

	& + * {
		margin-top: 0.3rem;
	}
`;

export const UiTwoCellItem = styled.li`
	display: flex;

	& + * {
		border-top: 1px solid #fff;
	}

	& > div {
		padding: 1rem 2rem;
		width: 50%;
		font-size: 1.6rem;
		text-align: center;
		color: ${({ isTitle }) => isTitle ? '#fff' : '#333'};
		background-color: ${({ isTitle, theme }) => isTitle ? theme.colorTheme.secondary : theme.colorTheme.tertiary};

		& + div {
			border-left: 1px solid #fff;
		}

		& > input {
			width: 100%;
			height: 40px;
			font-size: 1.4rem;
			text-align: center;
			border-radius: 5px;
		}
	}
`;

export const UiCenterContainer = styled.div`
	margin: 0 2rem;
	flex: 1;
`;

export const UiImage = styled.div`
	width: 100%;
	height: 600px;
	font-size: 0;
	background-color: #777;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
`;


export const UiPagination = styled.div`
	display: flex;
	align-items: center;
	margin: auto;
	padding: 4rem 0;
	width: max-content;

	& > div {
		padding: 0 1rem;
		font-size: 2.5rem;
	}
`;
