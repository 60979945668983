import styled from 'styled-components';


const template = (i) => {
  return `
      &:nth-child(${i + 1}) {
        right: ${`${i * 3}rem`};
        padding-left: ${'4rem'};
        z-index:${`${5 - i}`}
       }
    `;
};
const getleft = () => {
  let str = '';
  for (let index = 1; index <= 4; index += 1) {
    str += template(index);
  }
  return str;
};

export const UiQuestionStep = styled.div`
	display: flex;
	flex: 1;
`;

export const UiQuestionStepItem = styled.div`
	position: relative;
	padding: 0.5rem 1rem;
	font-size: 1.5rem;
	color: #3c4858;
	background-color: ${({ active }) => active ? '#C7DAFF' : '#fff'};
	border: 1px solid #9f9f9f;
	border-radius: 5px 20px 20px 5px;
	flex: 0.2;
	z-index: 5;
	text-align: center;

	${getleft()}

	&:last-child {
		border-radius: 5px;
	}

`;
