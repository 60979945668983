import styled from 'styled-components';

export const UiGraphRemind = styled.div`
	display: inline-flex;
	font-size: 1.4rem;
	color: #f00;
	cursor: pointer;
`;
export const UiGraphRemindRow = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 900px;
`;
