import React from 'react';
import PropTypes from 'prop-types';
import {
  UiQuestionStep,
  UiQuestionStepItem,
} from './QuestionStep.style';


/**
 * 新增題目上方步驟組件
 */

export const QuestionStep = ({
  data,
  activeIndex,
}) => {
  return (
    <UiQuestionStep>
      {
        data && data.map((item, index) => (
          <UiQuestionStepItem key={index} active={activeIndex === index}>{item}</UiQuestionStepItem>
        ))
      }
    </UiQuestionStep>
  );
};

QuestionStep.propTypes = {
  data: PropTypes.array,
  activeIndex: PropTypes.number,
};


