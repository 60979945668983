import React from 'react';
import { AllSubjectExtent } from 'components';

/**
 * 全科試卷難度
 */

export const AllSubjectExtentPage = () => {
  return (
    <AllSubjectExtent />
  );
};
