import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiMatListItem, UiOpen } from './ListItem.style';
import { Checkbox, Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * checkedList組件
 */

export const ListItem = ({
  isChecked = false,
  name,
  label,
  value,
  childrenList,
  onClick = () => { },
  onChange = () => { },
}) => {

  const [{ open, checked }, setState] = useSetState({
    open: false,
    checked: false,
  });

  const handlerChange = () => {
    setState({
      open: !open,
    });
    onClick(!open);
  };

  useEffect(() => {
    setState({
      checked: isChecked
    });
  }, [isChecked]);

  return (
    <UiMatListItem button >
      <Checkbox
        name={name}
        label={label}
        value={value}
        tick={checked}
        onCheck={onChange}
      />
      {childrenList &&
        <UiOpen>
          {open && <Icon name={'remove'} onClick={handlerChange} color='#000' border={true} />}
          {!open && <Icon name={'add'} onClick={handlerChange} color='#000' border={true} />}
        </UiOpen>
      }
    </UiMatListItem>
  );
};

ListItem.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  childrenList: PropTypes.bool,
  label: PropTypes.string,
};


