import React from 'react';
import { EditAnnouncementList } from 'components';

/**
 * 公告頁面
 */

export const EditAnnouncementListPage = () => {
  return (
    <EditAnnouncementList />
  );
};

