import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'components';
import { DropzoneArea as MatDropzoneArea } from 'material-ui-dropzone';
import { useSetState } from 'utils/hooks/useSetState';
import { UiUploadFileModal, UiButtonBox, UiImportantText } from './UploadFileModal.style';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'utils/hooks/useAlert';

/**
 * 上傳檔案彈窗
 */

export const UploadFileModal = ({
  open,
  filesLimit = 5,
  onSubmit = () => { },
  sendModalState = () => { },
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [{
    files,
    isOpen,
    isLoading
  }, setState] = useSetState({
    files: [],
    isOpen: false,
    isLoading: false
  });

  //改變彈窗狀態
  const sendModalStateHandler = state => {
    setState({
      isOpen: state
    });
    sendModalState(state);
  };

  //送出資料
  const onSubmitHandler = async () => {
    if (files.length === 0) return;
    setState({
      isLoading: true
    });
    await onSubmit(files);
    setState({
      files: [],
      isLoading: false
    });
    sendModalStateHandler(false);
  };

  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);


  return (
    <Modal title="上傳檔案" titleIcon="cloud_upload" open={isOpen}>
      <UiUploadFileModal>
        <MatDropzoneArea
          acceptedFiles={['.docx', '.doc']}
          dropzoneText={
            <>
              {t('common.dropzone_text')}
              <UiImportantText>
                {t('common.dropzone_text_imporant')}
              </UiImportantText>
            </>
          }
          getFileLimitExceedMessage={() => t('common.dropzone_limit_text')}
          getDropRejectMessage={() => '檔案類型不正確，請確認您的檔案類型再次重新上傳！'}
          filesLimit={filesLimit}
          showPreviews={true}
          showPreviewsInDropzone={false}
          showFileNamesInPreview={true}
          showAlerts={false}
          onChange={files => setState({ files })}
          onDrop={(file) => {
            setAlert(t('common.file') + file.name + t('common.file_add_text'), 'success');
          }}
          onDelete={(file) => {
            setAlert(t('common.file') + file.name + t('common.file_remove_text'), 'success');
          }}
          maxFileSize={300000000}
          {...otherProps}
        />
      </UiUploadFileModal>
      <UiButtonBox>
        <Button onClick={() => onSubmitHandler()} loading={isLoading}>送出</Button>
        <Button buttoncolor="highlight" onClick={() => sendModalStateHandler(false)} loading={isLoading}>取消</Button>
      </UiButtonBox>
    </Modal>
  );
};

UploadFileModal.propTypes = {
  open: PropTypes.bool,
  props: PropTypes.object,
  isLoading: PropTypes.bool,
  filesLimit: PropTypes.number,
  onSubmit: PropTypes.func,
  sendModalState: PropTypes.func,
};


