import React from 'react';
import { SubAbility } from 'components';

/**
 * 分項能力答對率
 */

export const SubAbilityPage = () => {
  return (
    <SubAbility />
  );
};
