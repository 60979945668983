import styled from 'styled-components';

export const UiReviewTab = styled.div`
	display: flex;
	align-items: center;
`;

export const UiReviewTabItem = styled.div`
	padding: 1rem;
	font-size: 1.4rem;
	color: ${({ status }) => status ? '#fff' : '#333'};
	background-color: ${({ theme, status }) => status ? theme.colorTheme.secondary : '#fff'};
	border: 1px solid ${({ theme }) => theme.colorTheme.primary};
	cursor: pointer;
	transition: 0.35s;

	&:hover {
		box-shadow: 0 7px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.1);
	}

	& + * {
		margin-left: 1rem;
	}
`;
