import styled from 'styled-components';

export const UiRadio = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
	width: 100%;
	color: #000;

	& > input[type=radio] {
		position: absolute;
		visibility: hidden;
	}

	& > label {
		position: relative;
		display: flex;
		font-size: 1.5rem;
		font-weight: 300;
		-webkit-transition: all 0.25s linear;
		cursor: pointer;
	}

	.check {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		display: block;
		margin: auto;
		width: 2rem;
		height: 2rem;
		border: 5px solid #fff;
		border-radius: 100%;
		box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
		-webkit-transition: border 0.25s linear;
		transition: border 0.25s linear;
	}

	& > label .checkBox {
		position: relative;
		margin-right: 1rem;
		min-width: 25px;
	}

	& > label .checkBox .check::before {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: block;
		margin: auto;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		-webkit-transition: background 0.25s linear;
		transition: background 0.25s linear;
		content: '';
	}

	input[type=radio]:checked ~ label {
		.checkBox {
			& > .check::before {
				background: ${({ theme }) => theme.colorTheme.primary};
			}
		}
	}
`;

