import styled from 'styled-components';

export const UiButtonBox = styled.div`
	display: flex;
	margin: 2rem auto 4rem;

	& > * {
		margin-left: 1rem;
	}
`;
