
import React from 'react';
import PropTypes from 'prop-types';
import { UiNestedList, UiMatList } from './NestedList.style';

import Collapse from '@material-ui/core/Collapse';
import { ListItem } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
/**
 * 巢狀checkedList組件
 */


export const NestedList = ({
  name,
  label,
  value,
  children,
  isChecked,
  onChange = () => { },
}) => {

  const [{ open }, setState] = useSetState({
    open: false,
  });

  const handleClick = open => {
    setState({
      open
    });
  };

  return (
    <UiNestedList>
      <UiMatList>
        <ListItem
          onChange={onChange}
          isChecked={isChecked}
          name={name}
          label={label}
          value={value}
          childrenList={true}
          onClick={handleClick}
        />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <UiMatList component="div" disablePadding>
            {children}
          </UiMatList>
        </Collapse>
      </UiMatList>
    </UiNestedList>
  );
};

NestedList.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};




