import React from 'react';
import { AnnouncementDetail } from 'components';

/**
 * 在這邊描述這個組件
 */

export const AnnouncementDetailPage = () => {
  return (
    <AnnouncementDetail />
  );
};



