import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnnouncement } from 'store/announcement';
import { useMember } from 'store/member';
import { useSetState } from 'utils/hooks/useSetState';
import { useModal } from 'utils/hooks/useModal';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { TitleContainer, Table, Button, Pagination } from 'components';

/**
 *  可編輯公告列表
 */
const schema = {
  date: {
    name: '起始日期',
  },
  title: {
    name: '標題',
    size: 'large'
  },
  creater: {
    name: '公告者',
  }
};


export const EditAnnouncementList = () => {
  const history = useHistory();
  const { setModal } = useModal();
  const [{ memberInfo }] = useMember();
  const [{ editAnnouncements, isLoading }, { getEditAnnouncement, deleteAnnouncement }] = useAnnouncement();
  const [{
    page,
    limit
  }, setState] = useSetState({
    page: 0,
    limit: 7
  });
  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };

  const deleteHandler = async (payload) => {
    await deleteAnnouncement(payload);
    await getEditAnnouncement(memberInfo.id);
  };

  const actions = [
    {
      status: 'normal',
      icon: 'visibility',
      title: '預覽',
      background: '#ffb800',
      onClick: async data => {
        const { id } = data;
        history.push(`${MAIN_PATH.ANNOUNCEMENT_DETAIL}/${id}`);
      }
    },
    {
      status: 'time',
      icon: 'create',
      title: '編輯',
      background: '#00923f',
      onClick: data => {
        const { id } = data;
        history.push(`${MAIN_PATH.ANNOUNCEMENT_FORM}/${id}`);
      }
    },
    {
      status: 'time',
      icon: 'delete_forever',
      title: '刪除',
      background: '#ff3636',
      onClick: data => {
        const { id } = data;
        const payload = {
          client: 'tikuHuailen',
          data: {
            id
          }
        };
        setModal({
          html: '確定要<span>刪除公告</span>嗎？',
          confirmEvent: () => deleteHandler(payload),
        });
      }
    },
  ];


  const goToForm = () => {
    history.push(MAIN_PATH.ANNOUNCEMENT_FORM);
  };

  const goHome = () => {
    history.push(MAIN_PATH.ANNOUNCEMENT_LIST);
  };

  useEffect(() => {
    if (!memberInfo.id) return;
    getEditAnnouncement();
  }, [memberInfo]);

  return (
    <TitleContainer
      title="編輯公告列表"
      titleIcon="description"
      titleChildren={
        <>
          <Button onClick={goToForm} icon="playlist_add">新增公告</Button>
          <Button onClick={goHome} icon="input">返回上一頁</Button>
        </>
      }
    >
      <Table
        data={editAnnouncements.data}
        schema={schema}
        isNeedTitle={true}
        actions={actions}
        page={page}
        limit={limit}
        isLoading={isLoading}
      />
      <Pagination total={editAnnouncements.data.length} limit={limit} onChange={onChangePage} />
    </TitleContainer>
  );
};





