import React from 'react';
import { ComprehensiveAnalysis } from 'components';


/**
 * 綜合分析page
 */

export const ComprehensiveAnalysisPage = () => {
  return (<ComprehensiveAnalysis />);
};

