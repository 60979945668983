import React from 'react';
import { RegisterScore } from 'components';

/**
 * 登入分數頁面
 */

export const RegisterScorePage = () => {
  return (
    <RegisterScore />
  );
};
