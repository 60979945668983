import styled from 'styled-components';

export const UiRegisterScoreTable = styled.div`
	display: table;
	margin: auto;
	white-space: nowrap;
`;

export const UiCol = styled.div`
	position: ${({ sticky }) => sticky ? 'sticky' : 'relative'};
	left: 0;
	display: table-cell;
	padding: 0;
	background-color: #eee;
	z-index: ${({ sticky }) => sticky ? 100 : 0};

	& + * {
		border-left: 1px solid #fff;
	}
`;

export const UiRow = styled.div`
	display: table-row;
	width: 100%;
	min-width: 300px;
`;

export const UiCell = styled.div`
	position: ${({ sticky }) => sticky ? 'sticky' : 'relative'};
	left: 0;
	display: table-cell;
	padding: ${({ isTitle }) => isTitle ? '1rem 3rem' : '10px 0.3rem'};
	width: ${({ col }) => col ? `${80 * col}px` : 'auto'};
	min-width: ${({ mincol }) => mincol ? mincol * 80 + 'px' : 'auto'};
	font-size: ${({ size }) => size || '1.4rem'};
	text-align: center;
	color: ${({ isTitle }) => isTitle ? '#fff' : '#333'};
	background-color: ${({ background }) => background || '#c7daff'};
	z-index: ${({ sticky }) => sticky ? 100 : 0};
	vertical-align: middle;
	word-wrap: break-word;
	border-bottom: 1px solid #fff;

	& + * {
		border-left: 1px solid #fff;
	}

	& > span {
		display: block;
		height: 35px;
		line-height: 2.5;
	}

	& > input {
		padding: 0.8rem 0.1rem;
		width: 100%;
		width: 50px;
		text-align: center;
		border-radius: 5px;
	}
`;

export const UiScrollBody = styled.div`
	position: relative;
	overflow: auto;
	margin: 0 auto 3rem;
	max-width: 95%;
	height: 100%;
	border-left: 1px solid #fff;
`;
