import React, { useEffect } from 'react';
import {
  TitleContainer,
  Button,
  DatePicker,
  Input,
  LoadingBackground,
} from 'components';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'utils/hooks/useModal';
import { useSetState } from 'utils/hooks/useSetState';
import { useAnnouncement } from 'store/announcement';
import { format, getUnixTime } from 'date-fns';

import {
  UiAnnouncementForm,
  UiAnnouncementFormWrapper,
  UiAnnouncementFormRow,
  UiAnnouncementFormContent,
  UiInlineFlex,
} from './AnnouncementForm.style';
/**
 * 公告表單組件
 */

export const AnnouncementForm = () => {
  const history = useHistory();
  const { setModal } = useModal();
  const { id } = useParams();
  const [{ isLoading }, { createAnnouncement, getAnnouncementById, updateAnnouncement }] = useAnnouncement();
  const [{ startDate, endDate, title, content }, setState] = useSetState({
    startDate: '',
    endDate: '',
    title: '',
    content: '',
  });

  const onChangeHandler = data => {
    setState({
      ...data
    });
  };

  const dateFormat = time => {
    return getUnixTime(new Date(time)) * 1000;
  };

  const goToList = () => history.push(MAIN_PATH.EDIT_ANNOUNCEMENT_LIST);

  const submitHandler = async () => {
    if (title === '' ||
      content === '' ||
      startDate > endDate) {
      setModal({ html: '有欄位尚未填寫！', icon: 'error', showCancelButton: false });
      return;
    }
    const nextData = {
      id,
      startDate: format(new Date(startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(endDate), 'yyyy-MM-dd'),
      title,
      content,
    };
    if (id) {
      await updateAnnouncement(nextData);
      goToList();
    } else {
      const result = await createAnnouncement(nextData);
      if (result.value) goToList();
    }
  };

  const editState = async id => {
    const response = await getAnnouncementById(id);
    const { title, content, startDate, endDate } = response.value;
    if (!title || !content || !startDate || !endDate) {
      setModal({ html: '有欄位尚未填寫！', icon: 'error', showCancelButton: false });
      return;
    }
    setState({
      title,
      content,
      startDate: dateFormat(startDate),
      endDate: dateFormat(endDate),
    });
  };

  useEffect(() => {
    if (id) editState(id);
  }, []);


  return (
    <UiAnnouncementForm>
      <TitleContainer
        title={id ? '編輯公告' : '新增公告'}
        titleIcon='playlist_add'
        titleChildren={
          <Button
            sizetype={'normal'}
            icon='input'
            buttonborder={true}
            textcolor={'#3C4858'}
            onClick={() => { goToList(); }}
          >
            返回公告列表
          </Button>}
      >
        <UiAnnouncementFormWrapper>
          <UiAnnouncementFormContent>
            <UiAnnouncementFormRow>
              <DatePicker
                label='公告開始日期'
                name='startDate'
                type="date"
                value={startDate}
                onChange={onChangeHandler}
              />
              <DatePicker
                label='公告結束日期'
                name='endDate'
                type="date"
                value={endDate}
                onChange={onChangeHandler}
              />
            </UiAnnouncementFormRow>
            <UiAnnouncementFormRow>
              <Input
                label='標題'
                name='title'
                type='text'
                value={title}
                placeholder='輸入標題*'
                onChange={onChangeHandler}
              />
            </UiAnnouncementFormRow>
            <UiAnnouncementFormRow>
              <Input
                label='內容'
                name='content'
                type='textarea'
                rows={8}
                placeholder='輸入內容*'
                value={content}
                onChange={onChangeHandler}
              />
            </UiAnnouncementFormRow>
            <UiAnnouncementFormRow>
              <UiInlineFlex>
                <Button
                  onClick={() => {
                    setModal({
                      html: `確定要<span>${id ? '儲存' : '新增'}公告</span>嗎？`,
                      confirmEvent: () => submitHandler(),
                    });
                  }}
                >確認</Button>
                <Button
                  buttoncolor='danger'
                  onClick={() => {
                    setModal({
                      html: '確定要<span>取消公告</span>嗎？',
                      confirmEvent: () => goToList(),
                    });
                  }}
                >取消</Button>
              </UiInlineFlex>
            </UiAnnouncementFormRow>
          </UiAnnouncementFormContent>
        </UiAnnouncementFormWrapper>
      </TitleContainer >
      {isLoading && <LoadingBackground />}
    </UiAnnouncementForm >
  );
};
