import styled from 'styled-components';

export const UiGraphRemind = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	font-size: 1.4rem;
	color: #f00;
	cursor: pointer;
`;
