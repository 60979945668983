import { useCallback } from 'react';
import { useFirebase } from 'utils/hooks/useFirebase';

export const useFirebaseStorage = () => {
  const { storage } = useFirebase();
  const storgeRef = useCallback(() => {
    return storage.ref();
  }, [storage]);

  const upload = async (uploadPath, file) => {
    try {
      const snapshot = await storgeRef().child(uploadPath).put(file);
      const url = await storgeRef().child(uploadPath).getDownloadURL();
      return {
        status: true,
        data: snapshot,
        url
      };
    } catch (error) {
      return {
        status: false,
        data: error
      };
    }
  };

  const getDownloadURL = async path => {
    try {
      const url = await storgeRef().child(path).getDownloadURL();
      return {
        status: 'success',
        url
      };
    } catch (error) {
      alert('檔案不存在');
      return {
        status: 'fail',
      };
    }
  };

  const deleteFile = async filePath => {
    const res = await storgeRef().child(filePath).delete().then(() => {
      return { isSuccess: true };
    }).catch(error => {
      return { isSuccess: false, error };
    });

    return res;
  };

  const getFileList = async filePath => {
    const res = await storgeRef().child(filePath).listAll().then((res) => {
      return { isSuccess: true, data: res.items };
    }).catch(error => {
      return { isSuccess: false, error };
    });

    return res;
  };

  return { upload, deleteFile, getFileList, getDownloadURL };
};
