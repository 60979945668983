import React, { useEffect } from 'react';
import {
  TitleContainer,
  Table,
  Button,
  Pagination,
} from 'components';
import { useMember } from 'store/member';
import { useQuestion } from 'store/question';
import { useSetState } from 'utils/hooks/useSetState';
import { useHistory } from 'react-router-dom';
import { useModal } from 'utils/hooks/useModal';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { useDownload } from 'utils/hooks/useDownload';
import { QUESTIONLIST_STATUS } from 'constants/index';

/**
 * 命題列表
 */

const schema = {
  systemTime: {
    name: '最後修改日期',
  },
  title: {
    name: '試卷名稱',
    default: '出卷尚未完成，試卷名稱未定。',
  },
  statusDesc: {
    name: '試卷狀態',
  },
};

export const QuestionList = () => {
  const history = useHistory();
  const { setModal } = useModal();
  const { blobDownload } = useDownload();
  const [{ memberInfo }] = useMember();
  const [
    {
      page,
      limit,
    },
    setState] = useSetState({
      page: 0,
      limit: 7
    });
  const [
    {
      examList: { data: examListData },
      isLoading
    },
    {
      getExamList,
      deleteExamList,
    }] = useQuestion();


  const downloadExam = async (data) => {
    const params = {
      client: 'tikuHuailen',
      data: {
        title: data.title,
        status: 'Finished',
        user: memberInfo.id,
        examID: data.examID,
      }
    };
    blobDownload(
      `${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/ExportExam`,
      `${data.title}`,
      'POST',
      params
    );
  };

  const actions = [
    {
      status: QUESTIONLIST_STATUS.FINISHED.label,
      title: '下載',
      icon: 'get_app',
      color: '#fff',
      background: '#003a92',
      onClick: (data) => {
        downloadExam(data);
      }
    },
    {
      status: QUESTIONLIST_STATUS.EDIT.label,
      title: '編輯',
      icon: 'create',
      color: '#fff',
      background: '#0066ff',
      onClick: (data) => {
        const { examID } = data;
        history.push(`${MAIN_PATH.CREATEEXAM}/${examID}`);
      }
    },
    {
      status: QUESTIONLIST_STATUS.EDIT.label,
      title: '刪除',
      icon: 'delete_forever',
      color: '#fff',
      background: '#ff3636',
      onClick: data => {
        const { examID } = data;
        const payload = {
          client: 'tikuHuailen',
          data: {
            user: memberInfo.id,
            examID
          }
        };
        setModal({
          html: '確定要<span>刪除試卷</span>嗎？',
          confirmEvent: async () => await deleteExamList(payload),
        });
      }
    }
  ];


  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };
  const goCreate = () => {
    history.push(MAIN_PATH.CREATEEXAM);
  };

  useEffect(() => {
    if (!memberInfo.id) return;
    const params = {
      isFinished: false,
    };
    getExamList(params);
  }, [memberInfo]);

  return (
    <TitleContainer
      title="個人試卷列表"
      titleIcon="recent_actors"
      titleChildren={
        <>
          <a
            href={`${process.env.PUBLIC_URL}/pdf/OneGrade_ans.pdf`}
            download="答案卡.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Button buttoncolor="deepblue" icon="get_app">
              答案卡下載
            </Button>
          </a>
          <Button onClick={goCreate} icon="note_add">新增考卷</Button>
        </>
      }
    >
      <Table
        data={examListData}
        schema={schema}
        isNeedTitle={true}
        actions={actions}
        limit={limit}
        page={page}
        isLoading={isLoading}
      />
      <Pagination total={examListData.length} limit={limit} onChange={onChangePage} />
    </TitleContainer>
  );
};
