import React from 'react';
import { DiscriminationOfSubjects } from 'components';


/**
 * 各科題目鑑別度
 */

export const DiscriminationOfSubjectsPage = () => {
  return (
    <DiscriminationOfSubjects />
  );
};
