import styled from 'styled-components';

export const UiReportFramed = styled.div`
	padding: 5rem;
`;

export const UiOptionBox = styled.div`
	background-color: ${({ theme }) => theme.colorTheme.secondary};
`;

export const UiOptionTop = styled.div`
	display: flex;
	padding: 1rem 0;
	padding-left: 8rem;
	color: #fff;
`;
export const UiOptionItem = styled.div`
	flex: 1;
	margin: 0  2rem;

	&:first-child {
		margin-left: 0;
	}
`;

export const UiOptionBottom = styled.div`
	padding: 1rem;
	padding-left: 8rem;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};
`;
export const UiOptionRemind = styled.div`
	font-size: 1.4rem;
	color: #f00;
`;
export const UiRadioTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.75rem;
`;
export const UiRadioBox = styled.div`
	display: flex;
`;

export const UiContent = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
`;

export const UiGraphBox = styled.div`
	margin: 2rem 0;
	padding: 2rem 1rem;
	width: 95%;
	max-width: 700px;
	background-color: ${({ theme }) => theme.colorTheme.tertiary};

	& > * + * {
		margin: 2rem auto 0;
	}
`;

export const UiTableBox = styled.div`
	margin: 2rem 0;
`;
export const UiGraphBoxBotom = styled.div`
	width: 95%;
	max-width: 700px;
	text-align: right;
	background-color: #fff;
`;
export const UiGraphRemind = styled.div`
	display: inline-flex;
	font-size: 1.4rem;
	color: #f00;
	cursor: pointer;
`;
export const UiGraphRemindRow = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 900px;
`;
