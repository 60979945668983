import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from 'components';
import RenderRoutes from 'routes/RenderRoutes';
import { UiMainLayout, UiContainer } from './MainLayout.style';


/**
 * 頁面主要的layout
 */

export const MainLayout = ({ routes }) => {
  return (
    <UiMainLayout>
      <Header />
      <UiContainer>
        <RenderRoutes routes={routes} />
      </UiContainer>
      <Footer />
    </UiMainLayout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

