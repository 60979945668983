import React, { useEffect, useCallback } from 'react';
import { ReportContainer, Select, RadioGroup, Graph, StickyTable, AutoComplete } from 'components';
import { useReport } from 'store/report';
import { useSetState } from 'utils/hooks/useSetState';
import { SELECTLIST, RADIOLIST } from 'constants/index';
import { useFormatterReport } from 'utils/hooks/useFormatterReport';

/**
 * 各科報表
 */

export const SingleSubject = () => {

  const {
    formatterGraphData,
    formatterTableData,
  } = useFormatterReport();

  const [{
    educationSelected,
    school,
    schoolYear,
    grade,
    subject,
    examType,
    group,
    student,
    remind,
    // schoolName,
  }, setState] = useSetState({
    educationSelected: '',
    school: '',
    schoolYear: '',
    grade: '',
    subject: '',
    examType: '',
    group: '',
    student: '',
    schoolName: '',
    remind: '',
  });

  const [{
    selections,
    subCorrectReportData
  }, {
    getReportSeletions,
    getSubCorrectRate,
  }] = useReport();


  const onChangeHandler = value => {
    setState({ ...value });
  };

  const onChangeSchool = value => {
    setState({
      school: value.school,
      schoolName: value.schoolName
    });
  };


  const selectGroup = useCallback(() => {
    if (Object.entries(selections).length === 0) return (<div>Loading...</div>);
    const {
      education,
      schools,
      schoolSelection,
      class: classList,
      schoolYear: schoolYearOptions,
      grade: gradeOptions,
      subject: subjectOptions,
      examType: examTypeOptions,
      subjectClass,
    } = selections;
    //縣籍
    if (education) {
      return (
        <>
          <Select
            label={SELECTLIST.EDUCATION.label}
            options={education}
            selectName="educationSelected"
            onChange={onChangeHandler}
          />
          {educationSelected &&
            <AutoComplete
              list={educationSelected ? schools[educationSelected] : []}
              label="選擇學校"
              name="school"
              onChange={onChangeSchool}
            />}
          {
            school &&
            <>
              <Select
                label={SELECTLIST.SCHOOLYEAR.countyLabel}
                options={schoolSelection[school].schoolYear}
                selectName="schoolYear"
                onChange={onChangeHandler}
              />
              <Select
                label="年級"
                options={schoolSelection[school].grade}
                selectName="grade"
                onChange={onChangeHandler}
              />
              <Select
                label="科目"
                options={schoolSelection[school].subject}
                selectName="subject"
                onChange={onChangeHandler}
              />
              <Select
                label={SELECTLIST.EXAMTYPE.label}
                options={schoolSelection[school].examType}
                selectName="examType"
                onChange={onChangeHandler}
              />
            </>
          }
        </>
      );
    }
    if (subjectClass) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            options={subjectOptions}
            selectName="subject"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
          {subject &&
            <Select
              label={SELECTLIST.CLASS.label}
              options={subjectClass[subject]}
              selectName="group"
              onChange={onChangeHandler}
            />}
        </>
      );
    }
    if (classList) {
      return (
        <>
          <Select
            label={SELECTLIST.SCHOOLYEAR.countyLabel}
            options={schoolYearOptions}
            selectName="schoolYear"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.GRADE.label}
            options={gradeOptions}
            selectName="grade"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.SUBJECT.label}
            options={subjectOptions}
            selectName="subject"
            onChange={onChangeHandler}
          />
          <Select
            label={SELECTLIST.EXAMTYPE.label}
            options={examTypeOptions}
            selectName="examType"
            onChange={onChangeHandler}
          />
        </>
      );
    }

    return (
      <div>Loading...</div>
    );
  }, [selections, educationSelected, school, subject]);


  const radioGroup = useCallback(() => {
    const { education, class: classList, schoolSelection, classStudents } = selections;
    //縣籍
    if (education && grade && school) {
      return (
        <RadioGroup
          name="group"
          data={schoolSelection[school].class[grade]}
          onChange={onChangeHandler}
          label={RADIOLIST.CLASS.label}
        />
      );
    }
    if (classStudents) {
      return (
        <RadioGroup
          name="student"
          data={classStudents[group]}
          onChange={onChangeHandler}
          label={RADIOLIST.STUDENTS.label}
        />
      );
    }
    if (classList && grade) {
      return (
        <RadioGroup
          name="group"
          data={classList[grade]}
          onChange={onChangeHandler}
          label={RADIOLIST.CLASS.label}
        />
      );
    }
  }, [selections, school, grade, group]);



  const dataGroup = () => {
    const { title, className: groupList, dataList } = subCorrectReportData;
    const { schoolSelection, subject: subjectList } = selections;
    const subjectName = educationSelected === '' ? subjectList.filter(item => item.value === subject)[0].name :
      schoolSelection[school].subject.filter(item => item.value === subject)[0].name;
    const schema = ['學校', ...title.map((item, index) => `題${index + 1}`)];
    const { data, pv, uv } = formatterGraphData({
      reportData: subCorrectReportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: `${educationSelected ? '全縣' : '全校'}${subjectName}`,
      group
    });
    const nextTableData = formatterTableData({
      reportData: subCorrectReportData,
      title,
      groupList,
      dataList,
      reportTypeString: '答對率',
      reportRoleString: '全校',
      groupName: '班級',
      group
    });
    return (
      <>
        <Graph.BarLine data={data} pv={pv} uv={uv} />
        <StickyTable.FixedTitle
          schema={schema}
          data={nextTableData.data}
          isSubjectTable={true}
        />
      </>
    );
  };

  const teacherDataGroup = () => {
    if (Object.entries(subCorrectReportData).length === 0 || student === '') return null;
    const { classCorrectRate, answer, dataList, schoolCorrectRate } = subCorrectReportData;
    const tableSchema = ['題號', '正解', '學生作答', '班級答對率', '全校答對率'];
    let newtableData = {};
    const nextData = {};
    const uv = '個人答對率';
    const pv = '全班答對率';
    const av = '全校答對率';

    Object.entries(dataList).forEach(([key, value]) => {
      let tableData = [];
      const rate = value.answer.map((item, index) => {
        tableData.push(
          {
            catelogey: index + 1,
            list: [answer[index], item, classCorrectRate[index], schoolCorrectRate[index],]
          }
        );
        if (item === answer[index]) return 100;
        return 0;
      });
      newtableData[key] = tableData;
      nextData[key] = rate;
    });
    const barData = schoolCorrectRate.map((item, index) => {
      return {
        name: index + 1,
        [uv]: nextData[group + student][index],
        [pv]: classCorrectRate[index],
        [av]: item
      };
    });
    const nextTableData = newtableData[group + student];
    return (
      Object.keys(subCorrectReportData).length > 0 &&
      <>
        <Graph.BarLine data={barData} pv={pv} uv={uv} av={av} />
        <StickyTable.FixedTitle
          schema={tableSchema}
          data={nextTableData}
          headerCount={1}
          canSort={false}
          checkAns={true}
        />
      </>
    );
  };

  useEffect(() => {
    const payload = {
      reportType: 'SubCorrectRate'
    };
    getReportSeletions(payload);
  }, []);

  useEffect(() => {
    const { education, class: classList, subjectClass } = selections;
    if (education) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
      if (grade && schoolYear && examType && school && group && subject) {
        const payload = {
          grade,
          schoolYear,
          examType,
          schoolID: school,
          subject,
          classNo: group
        };
        getSubCorrectRate(payload);
      }
    }
    if (classList) {
      setState({
        remind: '※ 點選班級名稱會以藍色折線圖呈現於圖表'
      });
      if (grade && schoolYear && examType && group && subject) {
        const payload = {
          grade,
          schoolYear,
          examType,
          subject,
          classNo: group
        };
        getSubCorrectRate(payload);
      }
    }
    if (subjectClass) {
      setState({
        remind: '※ 點選學生名稱會以藍色折線圖呈現於圖表'
      });
      if (schoolYear && examType && group && subject) {
        const payload = {
          schoolYear,
          examType,
          subject,
          classNo: group
        };
        getSubCorrectRate(payload);
      }
    }
  }, [grade, schoolYear, examType, school, group, subject]);

  return (
    <ReportContainer
      title="各科答題分析"
      remind={remind}
      selectGroup={selectGroup()}
      radioChildren={radioGroup()}
    >
      {subCorrectReportData.title?.length > 0 && dataGroup()}
      {subCorrectReportData.classCorrectRate?.length > 0 && teacherDataGroup()}

    </ReportContainer>
  );
};
