import React, { useEffect, useMemo,useState } from 'react';
import PropTypes from 'prop-types';
import {
  NestedListGroup,
  Button,
  Select,
  QuestionItem,
  Pagination,
  Icon,
  SliderBar,
  QuestionModal,
  GraphModal,
} from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { ClickAwayListener as MatClickAwayListener } from '@material-ui/core';
import { useQuestion } from 'store/question';
import { useDownload } from 'utils/hooks/useDownload';
import { objectMapCovertToArray } from 'utils/object';
import { useModal } from 'utils/hooks/useModal';
import {
  UiQuestionPickList,
  UiQuestionPickWrapper,
  UiQuestionPickContent,
  UiQuestionLeftBox,
  UiNestedListHeader,
  UiQuestionPickBox,
  UiQuestionPickHeader,
  UiQuestionPickHeaderContent,
  UiQuestionSearchBox,
  UiIconBox,
  UiQuestionSearchDrawer,
  UiQuestionSearchPagination,
  UiQuestionPick,
  UiQuestionPickItem,
  UiFixedBox,
  UiFixedBoxTop,
  UiFixedBoxBottom,
} from './QuestionPickList.style';

/**
 * 題目選擇（新增考卷step2）
 */

const advancedSelectOptionsName = {
  DIFFICULTY: '難易度',
  EXAM_ABILITY: '會考分項能力',
  PERFORMANCE: '學習表現',
  QUES_TYPE: '題型',
  AUTHOR: '試題作者'
};



export const QuestionPickList = ({
  type = 'create',
}) => {
  const { blobDownload } = useDownload();
  const [{
    searchQusetionPayload,
    questions,
    formatterChapter,
    analysisQuestionData,
    addedQuestionList,
    searchQuestionPageData,
  }, {
    getSearchQuestion,
    getAddedQuestionList,
    calculateExamQuestion,
  }] = useQuestion();
  const [{
    chapterOptions,
    drawerIsOpen,
    questionFilter,
    changeModalState,
    modalData,
    questionList,
    analysisTabs,
    analysisModalOpen,
    filterOptions,
    listPage,
    limit,
    sliceQuestionList,
    maxSearchQuestionCount,
  }, setState] = useSetState({
    chapterOptions: [],
    drawerIsOpen: false,
    questionFilter: {},
    changeModalState: false,
    modalData: {},
    questionList: [],
    analysisTabs: [],
    analysisModalOpen: false,
    filterOptions: {},
    listPage: 1,
    limit: 10,
    sliceQuestionList: [],
    maxSearchQuestionCount:20
  });

  const [searchAddQuestionState,setSearchAddQuestion] = useState([]);

  const { setModal } = useModal();
  let subjectID = '';
  const { data: { education, subject } } = type === 'create' ?
    searchQusetionPayload : searchQuestionPageData.searchQusetionPayload;
  subjectID = `${education}${subject}`;

  //檢查目前nestedList資料狀態
  const getDataHandler = data => {
    console.log('data:', data);
  };

  //進階搜尋收放
  const openDrawer = state => {
    setState({
      drawerIsOpen: state
    });
  };

  //進階搜尋 get sliderBar data
  const getValue = value => {
    console.log('value:', value);
  };

  //進階篩選
  const advancedSearchHandler = data => {
    const name = Object.keys(data);
    const value = Object.values(data)[0];
    if (value === '') {
      delete questionFilter[name];
      setState({
        questionFilter: { ...questionFilter }
      });
    } else {
      setState({
        questionFilter: { ...questionFilter, [name]: value }
      });
    }

  };

  //進階搜尋題目
  const advancedSubmitHandler = () => {
    const defaultQuestionList = type === 'create' ? questions.questions : searchQuestionPageData.questions.questions;
    const filterData = defaultQuestionList.filter(item => {
      const filterMap = item.metaInfos.map(metaItem => {
        for (var key in questionFilter) {
          if (metaItem.code !== null) {
            if (metaItem.id === key && metaItem.code.includes(questionFilter[key])) return 1;
          }
        }
        return 0;
      });
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      return (filterMap.reduce(reducer) === Object.keys(questionFilter).length);
    });
    setState({
      listPage: 1,
      questionList: filterData
    });
  };

  //拉出checked的資料
  const getNestedListData = (data, nextData) => {
    if (data) {
      data.forEach(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'nextPart')) {
          getNestedListData(item.nextPart, nextData);
        }
        if (item.checked) {
          nextData.push(item.value);
        }
      });
    }
  };

  //只顯示點選的章節
  const formatterNestedListDataNext = data => {
    let nextData = [];
    data.forEach(item => {
      if (item.checked) {
        nextData = [...nextData, item];
      } else {
        let nextPartData = [];
        item.nextPart && item.nextPart.forEach(nextItem => {
          if (nextItem.checked) {
            nextPartData = [...nextPartData, nextItem];
          }
        });
        if (nextPartData.length > 0) {
          nextData = [...nextData, {
            value: item.value,
            name: item.name,
            checked: true,
            nextPart: nextPartData
          }];
        }
      }
    });
    return nextData;
  };

  //變更章節搜題
  const chapterChangeHandler = async () => {
    let nextData = [];
    getNestedListData(chapterOptions, nextData);
    const nextsearchQusetionPayload = type === 'create' ? searchQusetionPayload :
      searchQuestionPageData.searchQusetionPayload;


    nextsearchQusetionPayload.data.knowledge = nextData;
    const nextQuestionListPayload = {
      searchData: nextsearchQusetionPayload,
      type
    };
    const { value: { data: { questions: queryQuestionList } } } = await getSearchQuestion(nextQuestionListPayload);
    if (queryQuestionList.length <= 0) {
      setModal({
        showCancelButton: false,
        width: '48rem',
        icon: 'error',
        html: '選擇的搜尋條件，<span>找不到題目</span>，請更改搜尋條件再試一次。',
        confirmEvent: () => { },
      });
    }
  };

  useEffect(() => {
    advancedSubmitHandler();
  }, [questionFilter]);

  //題目列表打開彈窗
  const openModalHandler = (state, data) => {
    setState({
      changeModalState: state,
      modalData: {
        questions: [data]
      }
    });
  };

  //添加題目至試卷
  const addQuestionToExamPaper = (data, state) => {
    if(type === 'create'){
      getAddedQuestionList({ data: data, state });
    }else{
      if(state){
        setSearchAddQuestion(prevData=>[...prevData,data.id]);
      }else{
        setSearchAddQuestion(prevData=>prevData.filter(item =>item !== data.id));
      }
    }
  };

  //彈窗打開狀態
  const getModalStateHandler = state => {
    setState({
      changeModalState: state,
    });
  };

  //分析彈窗打開狀態
  const getAnalysisModalStateHandler = state => {
    setState({
      analysisModalOpen: state,
    });
  };

  const downloadQuestion = async()=>{
    const params ={
      client: 'tikuHuailen',
      data:{
        eduSubject:subjectID,
      iDs: searchAddQuestionState
      }};
    blobDownload(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/MultiQuestion`,
     '月段考系統搜尋題目下載.zip',
     'POST',
      params
    );
  };

  const analysisExamPaper = async () => {

    const addedQuestionIDList = addedQuestionList.map(item => item.id);
    searchQusetionPayload.data = {
      ...searchQusetionPayload.data,
      subjectID,
      questionID: addedQuestionIDList
    };
    await calculateExamQuestion(searchQusetionPayload);

    setState({
      analysisModalOpen: true,
    });
  };

  const listPageChange = (num) => {
    setState({
      listPage: num
    });
  };

  useEffect(() => {
    const chapterData = type === 'create' ? formatterChapter.data : searchQuestionPageData.formatterChapter.data;
    const nextNestedData = formatterNestedListDataNext(chapterData);
    setState({
      chapterOptions: nextNestedData
    });
  }, []);

  useEffect(() => {
    if (type !== 'create') return;
    const filterOptions = objectMapCovertToArray(questions.filters, (data, key) => {
      if (data.length > 0) {
        return {
          name: key,
          options: data
        };
      }
    });
    setState({
      filterOptions,
      questionList: questions.questions
    });
  }, [questions]);
  //換頁
  useEffect(() => {
    if (questionList.length <= 0) {
      setState({
        sliceQuestionList: questionList
      });
      return;
    }
    let nextList = [];
    const nowPage = listPage - 1;
    for (let i = nowPage * limit; i < (nowPage * limit) + limit; i++) {
      if (!questionList[i]) break;
      nextList[i] = questionList[i];
    }
    setState({
      sliceQuestionList: nextList
    });
  }, [listPage, questionList]);

  useEffect(() => {
    if (type === 'create') return;
    const filterOptions = objectMapCovertToArray(searchQuestionPageData.questions.filters, (data, key) => {
      if (data.length > 0) {
        return {
          name: key,
          options: data
        };
      }
    });
    setState({
      filterOptions,
      questionList: searchQuestionPageData.questions.questions
    });
  }, [searchQuestionPageData.questions]);

  useEffect(() => {
    if (analysisQuestionData.length <= 0) return;
    const { chapter, difficulty, questionType } = analysisQuestionData;
    const tabsData = {
      chapter: {
        name: '章節比重',
        color: '#c7daff',
        chart: 'verticalBar',
        data: chapter
      },
      difficulty: {
        name: '難易比重',
        color: '#e0ff89',
        chart: 'pie',
        data: difficulty
      },
      questionType: {
        name: '題型比重',
        color: '#ffc9b2',
        chart: 'pie',
        data: questionType
      }
    };
    const valueStr = [
      'one',
      'two',
      'three'
    ];
    let count = -1;
    const tabs = objectMapCovertToArray(analysisQuestionData, (data, key) => {
      if (data.length > 0) {
        count++;
        return {
          ...tabsData[key],
          value: valueStr[count]
        };
      }
    });

    setState({
      analysisTabs: tabs,
    });
  }, [analysisQuestionData]);

  const addQuestionDisabled = useMemo(()=>{
    if(searchAddQuestionState.length === maxSearchQuestionCount){
      return true;
    }
    return false;
  },[searchAddQuestionState]);


  const MemorizeQuestionItem = useMemo(() => {
    return (
      sliceQuestionList.length > 0 && sliceQuestionList.map((item, index) => (
        <UiQuestionPickItem key={index}>
          <QuestionItem
            key={index}
            data={item}
            create={type === 'create'}
            openDetail={openModalHandler}
            addExamPaper={addQuestionToExamPaper}
            addedQuestionList={type === 'create'? addedQuestionList : searchAddQuestionState}
            addQuestionDisabled={addQuestionDisabled}
          />
        </UiQuestionPickItem>
      ))
    );
  }, [sliceQuestionList,addQuestionDisabled,searchAddQuestionState]);

  return (
    <UiQuestionPickList>
      <UiQuestionPickWrapper>
        <UiQuestionPickContent>
          <UiQuestionLeftBox>
            <UiNestedListHeader>依範圍檢視題目</UiNestedListHeader>
            <NestedListGroup checkAll={true} data={chapterOptions || {}} getData={getDataHandler} />
            <Button onClick={chapterChangeHandler} >列出試題</Button>
          </UiQuestionLeftBox>
          <UiQuestionPickBox>
            <UiQuestionPickHeader>
              <UiQuestionPickHeaderContent>
                <MatClickAwayListener
                  onClickAway={() => { openDrawer(false); }}
                >
                  <UiQuestionSearchBox
                    onClick={() => { openDrawer(!drawerIsOpen); }}
                  >
                    進階篩選
                    <UiIconBox drawerIsOpen={drawerIsOpen}>
                      <Icon name={'keyboard_arrow_down'} color={'#fff'} sizetype={'large'} />
                    </UiIconBox>
                    <UiQuestionSearchDrawer
                      drawerIsOpen={drawerIsOpen}
                      onClick={
                        e => {
                          e.stopPropagation();
                        }}
                    >
                      {
                        filterOptions.length > 0 &&
                        filterOptions.filter(item => advancedSelectOptionsName[item.name]).map((item, index) => (
                          <Select
                            key={index}
                            options={item.options}
                            selectName={item.name}
                            needDefault={true}
                            label={advancedSelectOptionsName[item.name]}
                            onChange={advancedSearchHandler}
                          />
                        ))
                      }
                      <SliderBar name='passRate' getValue={getValue} label={'通過率(請輸入範圍)'} />
                      <SliderBar name='discrimination' getValue={getValue} label={'鑑別度(請輸入範圍)'} />
                    </UiQuestionSearchDrawer>
                  </UiQuestionSearchBox>
                </MatClickAwayListener>
                <UiQuestionSearchPagination>
                  <Pagination
                    page={listPage}
                    total={questionList.length}
                    onChange={listPageChange}
                  />
                </UiQuestionSearchPagination>
              </UiQuestionPickHeaderContent>
              {
                type === 'create' ?
                <UiFixedBox>
                  <UiFixedBoxTop>
                    <Button
                      onClick={analysisExamPaper}
                      icon='donut_small'
                      buttoncolor={'orange'}>
                      選題分析
                    </Button>
                  </UiFixedBoxTop>
                  <UiFixedBoxBottom>
                    已選題數：{addedQuestionList.length || 0}
                  </UiFixedBoxBottom>
                </UiFixedBox>:
                <UiFixedBox>
                <UiFixedBoxTop>
                  <Button
                    onClick={downloadQuestion}
                    icon='download'
                    buttoncolor={'deepblue'}>
                    下載試題檔案
                  </Button>
                </UiFixedBoxTop>
                <UiFixedBoxBottom>
                  已選題數：{searchAddQuestionState.length || 0}/20
                </UiFixedBoxBottom>
              </UiFixedBox>
              }

            </UiQuestionPickHeader>

            <UiQuestionPick>
              {MemorizeQuestionItem}
            </UiQuestionPick>

          </UiQuestionPickBox>
        </UiQuestionPickContent>
      </UiQuestionPickWrapper>
      <QuestionModal
        data={modalData}
        subject={subjectID}
        open={changeModalState}
        getModalState={getModalStateHandler}
      />
      {
        analysisTabs.length > 0 &&
        <GraphModal
          open={analysisModalOpen}
          tabs={analysisTabs}
          modalStateHandler={getAnalysisModalStateHandler} />
      }
    </UiQuestionPickList>
  );
};

QuestionPickList.propTypes = {
  type: PropTypes.string
};


