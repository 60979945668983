import styled from 'styled-components';


export const UiProgressBg = styled.div`
	overflow: hidden;
	width: 100%;
	height: ${({ barHeight }) => `${barHeight}px`};
	background-color: #8c8c8c;
	border-radius: 50px;
`;

export const UiProgressMain = styled.div`
	width: ${({ startRate, endRate }) => endRate === 0 ? '0' : `${(startRate / endRate) * 100}%`};
	height: 100%;
	background-color: ${({ barColor }) => barColor};
	border-radius: 50px;
	transition: 0.35s;
`;
