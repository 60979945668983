import styled from 'styled-components';
import { Row, Cell, StickyTable } from 'react-sticky-table';

export const UiStickyTable = styled(StickyTable)`
	& > div {
		margin: auto;
	}
`;


export const UiStickyRow = styled(Row)`
	&:nth-child(1) > div {
		padding: 2rem 3rem;
		font-size: 2rem;
		color: #fff;
		background-color: ${({ theme }) => theme.colorTheme.secondary};

		&:first-child {
			background-color: ${({ theme }) => theme.colorTheme.primary};
		}
	}
`;

export const UiStickyCell = styled(Cell)`
	font-size: 1.8rem;
	text-align: center;
	background-color: ${({ isImportant }) => isImportant ? '#ffc7ce' : '#c7daff'};
	border: 1px solid #fff;

	& > div {
		padding: 0.5rem;
		font-size: 1.5rem;
		color: #fff;

		/* background-color: ${({ theme }) => theme.colorTheme.primary}; */
		border-radius: 5px;

		/* cursor: pointer; */
	}

	& > p {
		color: #ff3636;
	}
`;

export const UiStickyCellText = styled.span`
	display: block;
	font-size: 1.5rem;
`;

export const UiStickyCellIcon = styled.div`
	display: inline-flex;
	padding: 0 !important;
	background-color: #fff !important;
`;


export const UiCoustomCell = styled(Cell)`
	display: flex;
	padding: 0;
	height: 100px;
	max-height: 100%;
	font-size: 1.6rem;
	text-align: center;
	color: #333;
	background-color: #ffdc5f;
	flex-wrap: wrap;

	& > div {
		width: 50%;
		background-color: #ffefc7;
	}

	& > div:nth-child(1) {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #ffdc5f;
		flex: 1;

		& + * {
			border-left: 1px solid #fff;
		}
	}

	& > div:nth-child(2) {
		& > div {
			padding: 0.5rem 1rem;
			height: 50%;
		}

		& > div + div {
			border-top: 2px solid #fff;
		}
	}
`;

export const UiStickyTwoCell = styled(Cell)`
	padding: 0;
	font-size: 1.6rem;
	background-color: #c7daff;
	vertical-align: middle;

	& > div {
		width: 100%;
		text-align: center;

		& > * + * {
			border-top: 1px solid #fff;
		}
	}
`;
