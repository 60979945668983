import React from 'react';
import { Suggest } from 'components';

/**
 * 建議回饋頁面
 */

export const SuggestPage = () => {
  return (<Suggest />);
};


