import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { getUnixTime } from 'date-fns';
import { TABLE_STATUS, QUESTIONLIST_STATUS } from 'constants/index';
import { UiTable, UiTbody, UiTr, UiTh, UiTd, UiLoadingContainer, UiLoading, UiLoadingItem } from './Table.style';


/**
 * table組件
 */

export const Table = ({ schema, data, actions = [], isNeedTitle = true, page = 0, limit = 10, isLoading = false }) => {
  const tableHead = () => {
    return Object.entries(schema).map(([key, item]) => {
      return {
        id: key,
        label: item.name,
        default: item.default || '--',
        size: item.size
      };
    });
  };
  const dataFormat = () => {
    return data.map(item => {
      let nextData = {};
      Object.entries(schema).forEach(([key, value]) => {
        nextData[key] =
          value.showText ?
            value.showText[item[key]] :
            (item[key] ?? <span style={{ color: '#f00' }}>{value.default || '--'}</span>);
      });
      return nextData;
    });
  };
  return (
    <>
      <UiTable>
        <UiTbody>
          {
            (schema && isNeedTitle) &&
            <UiTr>
              {
                tableHead().map(title => {
                  return (
                    <UiTh key={title.id} size={title.size === 'large' ? 2 : 1}>{title.label}</UiTh>
                  );
                })
              }
              {
                actions.length > 0 && <UiTh>操作</UiTh>
              }
            </UiTr>
          }
          {
            data && dataFormat().slice(page * limit, page * limit + limit).map((trItem, index) => {
              return (
                <UiTr key={index}>
                  {
                    Object.entries(trItem).map(([key, value]) => {
                      return (
                        <UiTd key={key}>
                          {value}
                        </UiTd>
                      );
                    })
                  }
                  {
                    actions.length > 0 &&
                    <UiTd>
                      <div>
                        {
                          actions.map((action, actionIndex) => {
                            const { icon, title, background, onClick, status } = action;
                            const nowTimeStamp = new Date().getTime();
                            const itemData = data[index + (page * limit)];
                            return (
                              <>
                                {
                                  (
                                    itemData.status === status ||
                                    status === TABLE_STATUS.NORMAL ||
                                    (status === TABLE_STATUS.EDIT &&
                                      itemData.status !== QUESTIONLIST_STATUS.FINISHED.value)
                                    ||
                                    (status === TABLE_STATUS.FINISHED &&
                                      itemData.status === QUESTIONLIST_STATUS.FINISHED.value)
                                    ||
                                    (status === TABLE_STATUS.DOWNLOAD &&
                                      itemData.filepath !== null)
                                    ||
                                    (status === TABLE_STATUS.TIME &&
                                      getUnixTime(new Date(itemData.startAt)) * 1000 >= nowTimeStamp)
                                  ) &&
                                  <Icon
                                    sizetype="large"
                                    name={icon}
                                    title={title}
                                    background={background}
                                    onClick={() => onClick(itemData)}
                                    key={actionIndex}
                                  />
                                }
                              </>
                            );
                          })
                        }
                      </div>
                    </UiTd>
                  }
                </UiTr>
              );
            })
          }
        </UiTbody>
      </UiTable >
      <UiLoadingContainer isLoading={isLoading}>
        <UiLoading>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
          <UiLoadingItem></UiLoadingItem>
        </UiLoading>
      </UiLoadingContainer>
    </>
  );
};

Table.propTypes = {
  schema: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.string,
    showText: PropTypes.object
  }),
  data: PropTypes.array,
  actions: PropTypes.array,
  isNeedTitle: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
};


