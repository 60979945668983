import styled from 'styled-components';

const chineseNumber = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

export const UiExpandableContainer = styled.div`
	overflow-x: hidden;
	padding: 3rem;
	min-height: 300px;
	background-color: #d2d2d2;
`;

export const UiExpandableBox = styled.div`
	width: 100%;
	margin-bottom: 1rem;
`;

export const UiExpandableTitle = styled.div`
	position: relative;
	display: flex;
	padding: 1rem;
	width: 100%;
	font-size: 2rem;
	color: #fff;
	background-color: #005197;
	cursor: pointer;

	&::before {
		content: ${({ number }) => `'${chineseNumber[number]}、'`};
		font-size: 2rem;
	}
`;

export const UiIcon = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 1rem;
	margin: auto;
	height: max-content;
`;

export const UiExpandableContent = styled.div`
	overflow: hidden;
	padding: ${({ isOpen }) => isOpen ? '1rem' : '0 1rem'};
	width: 100%;
	max-height: ${({ isOpen }) => isOpen ? '9000px' : '0'};
	transition: 0.35s;
`;
