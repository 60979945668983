import styled from 'styled-components';

export const UiNestedListGroup = styled.div`
	width: 100%;
`;
export const UiCheckAll = styled.div`
	& > div {
		padding-left: 16px;
	}
`;
