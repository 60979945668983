import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, RadioGroup, Button, NestedListGroup, LoadingBackground } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useQuestion } from 'store/question';
import { useModal } from 'utils/hooks/useModal';
import { useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import { SELECTLIST } from 'constants/index';
import { useMember } from 'store/member';
import {
  UiQuestionRangeSelect,
  UiSearchQuestionWrapper,
  UiBasicSearchQuestionHeader,
  UiQuestionSelectList,
  UiQuestionCircleSelectList,
  UiNestedListTitle,
  UiSearchQuestionContent,
  UiNestedListBox,
  UiGetchapter,
} from './QuestionRangeSelect.style';


/**
 * 創建試卷-範圍搜題組件
 */

const selectList = [
  {
    label: SELECTLIST.EDUCATION.label,
    name: SELECTLIST.EDUCATION.name,
  },
  {
    label: SELECTLIST.SCHOOL.label,
    name: SELECTLIST.SCHOOL.name,
  },
  {
    label: SELECTLIST.SUBJECT.label,
    name: SELECTLIST.SUBJECT.name,
  },
  {
    label: SELECTLIST.YEAR.label,
    name: SELECTLIST.YEAR.name,
  },
  {
    label: SELECTLIST.EXAMTYPE.label,
    name: SELECTLIST.EXAMTYPE.name,
  },
  {
    label: SELECTLIST.VERSION.label,
    name: SELECTLIST.VERSION.name,
  },
  {
    label: SELECTLIST.BOOK.label,
    name: SELECTLIST.BOOK.name,
  }
];


export const QuestionRangeSelect = ({
  examID = '',
  nextStep = () => { },
  type = 'create'
}) => {
  const [{
    selections,
    formatterChapter,
    searchQusetionPayload,
    chapter,
    searchQuestionPageData,
    isLoading,
  }, {
    getQuestionSelections,
    getQuestionChapter,
    saveStaticData,
    getSearchQuestion, }] = useQuestion();
  const [{
    searchCriteria,
    educationValue,
    subjectOptions,
    subjectValue,
    subjectName,
    examTypeOptions,
    examType,
    examTypeName,
    yearsOptions,
    versionsOptions,
    // changeModalState,
    chapterOptions,
    book,
    year,
    yearName,
    version,
    isGetchapter,
    checkAll,
    radioReset,
    blockSelect,
    prevSearchCriteria,
    isPrev,
  }, setState] = useSetState({
    searchCriteria: {},//送出的資料
    educationValue: '',//基礎搜尋 學制
    subjectOptions: [],//科目選項
    subjectValue: '',//基礎搜尋 科目
    subjectName: '',
    examTypeOptions: [],
    examType: '',
    examTypeName: '',
    yearsOptions: [],//學年度選項
    versionsOptions: [],//版本選項
    // changeModalState: false,//跳窗開關
    chapterOptions: [],//章節選項
    book: '',//基礎搜尋 冊次
    year: '',//基礎搜尋 學年
    yearName: '',
    version: '',//基礎搜尋 版本
    isGetchapter: false,//是否有搜尋章節
    checkAll: false,
    radioReset: false,
    blockSelect: false,
    prevSearchCriteria: {},
    isPrev: false//是否從上一頁回來
  });

  const [{
    memberInfo
  }] = useMember();

  const { setModal } = useModal();
  const history = useHistory();

  const showGoToAddExamModal = () => {
    setModal({
      html: '編輯時，如果更換版本以外的選項時<span>將會跳轉至新增試卷</span>，確定要離開嗎？',
      confirmEvent: () => { history.push(MAIN_PATH.CREATEEXAM); },
    });
  };
  //基礎篩選（學年度,版本）
  const onChangeHandler = (data, name, edit) => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];
    if (key !== 'version' && examID && !edit) {
      showGoToAddExamModal();
      return;
    }
    setState({
      searchCriteria: Object.assign(searchCriteria, data),
      [key]: value,
    });
    if (key === 'year' || key === 'examType' || key === 'school') {
      const keyName = `${key}Name`;
      setState({
        [keyName]: name
      });
    }
  };

  //選擇學制
  const chooseEducation = (data, name, edit) => {
    if (!edit) {
      if (examID) {
        showGoToAddExamModal();
        return;
      }
      setState({
        subjectOptions: [],
        yearsOptions: [],
        versionsOptions: [],
        chapterOptions: [],
        subjectValue: '',
        year: '',
        version: '',
        schoolOptions: [],//學校選項
        school: '',//基礎搜尋 學校
        examTypeOptions: [],
        examType: '',
        book: '',
        radioReset: !radioReset
      });
    }
    const chooseEducation = Object.values(data);
    setState({
      educationValue: chooseEducation,
      searchCriteria: edit ? Object.assign(searchCriteria, data) : Object.assign({}, data),
    });

    setSubjectOption(chooseEducation);
  };

  const setSubjectOption = (chooseEducation) => {
    if (type !== 'create') {
      if (selections.subject[chooseEducation]) {
        const nextSubjects = selections.subject[chooseEducation].map(subject => {
          return {
            value: subject.value,
            name: subject.name
          };
        });
        setState({
          subjectOptions: nextSubjects
        });
      } else {
        setState({
          subjectOptions: [],
          yearsOptions: [],
          versionsOptions: [],
        });
      }
    }
  };

  //選擇科目
  const chooseSubject = (data, name, edit) => {
    const value = Object.values(data);
    if (examID && !edit) {
      showGoToAddExamModal();
      return;
    }
    setState({
      subjectValue: value,
      searchCriteria: Object.assign(searchCriteria, data),
      subjectName: name,
    });
  };


  //取得冊次資料（以後可能多選用selectGruop）
  const getDataFromRadioList = data => {
    setState({
      book: data,
      searchCriteria: Object.assign(searchCriteria, data)
    });
  };

  //檢查目前nestedList資料狀態
  const getDataHandler = (data, checkAll) => {
    setState({
      checkAll: checkAll || false
    });
  };

  //拉出checked的資料
  const getNestedListData = (data, nextData) => {
    if (data) {
      data.forEach(item => {
        if (Object.prototype.hasOwnProperty.call(item, 'nextPart')) {
          getNestedListData(item.nextPart, nextData);
        }
        if (item.checked) {
          nextData.push(item.value);
        }
      });
    }
  };

  //改變api取得章節的資料結構傳入nestedList
  const formatterNestedListData = data => {
    const formatterData = data.chapter.map(item => {
      const nextPart = item.subChapter && item.subChapter.map(value => {
        const subChapterNextPart = value.knowledge && value.knowledge.map(val => {
          return {
            value: val.value,
            name: val.name,
            checked: false
          };
        });
        if (value.knowledge === null) {
          return {
            value: value.option.value,
            name: `CH-${value.option.value} ${value.option.name}`,
            checked: false,
          };
        } else {
          return {
            value: value.option.value,
            name: `CH-${value.option.value} ${value.option.name}`,
            checked: false,
            nextPart: subChapterNextPart
          };
        }
      });
      if (item.subChapter === null) {
        return {
          value: item.option.value,
          name: `CH-${item.option.value} ${item.option.name}`,
          checked: false,
        };
      }
      return {
        value: item.option.value,
        name: `CH-${item.option.value} ${item.option.name}`,
        checked: false,
        nextPart,
      };
    });
    return formatterData;
  };


  const submitHandler = async () => {
    let nextData = [];
    getNestedListData(chapterOptions, nextData);
    if (nextData.length === 0) {
      setModal({
        showCancelButton: false,
        width: '48rem',
        icon: 'error',
        html: '選擇的搜尋條件，<span>找不到題目</span>，請更改搜尋條件再試一次。',
        confirmEvent: () => { },
      });
      return;
    }
    searchCriteria.knowledge = nextData;

    const getListPayload = {
      searchData: {
        'client': 'tikuHualien',
        'data': searchCriteria
      },
      type
    };
    const { value: { data: { questions: queryQuestionList } } } = await getSearchQuestion(getListPayload);
    if (queryQuestionList.length > 0) {
      const questionHeader = `${memberInfo.schoolName}${yearName}${subjectName}${examTypeName}`;
      await saveStaticData({ chapter: chapterOptions, type, checkAll, questionHeader });
      nextStep(1);
    } else {
      setModal({
        showCancelButton: false,
        width: '48rem',
        icon: 'error',
        html: '選擇的搜尋條件，<span>找不到題目</span>，請更改搜尋條件再試一次。',
        confirmEvent: () => { },
      });
      setState({
        checkAll: false
      });
    }
  };

  //進入頁面取得篩選選項
  useEffect(() => {
    getQuestionSelections();
  }, []);

  //========================CreateExamPaperPage Start========================

  useEffect(() => {
    if (type !== 'create') return;
    if (Object.keys(searchQusetionPayload).length === 0) return;

    const { data: { education, subject, version, school, year, book, examType } } = searchQusetionPayload;

    setState({
      chapterOptions: formatterChapter.data,
      checkAll: formatterChapter.checkAll,
      educationValue: education,
      subjectValue: subject,
      school,
      examType,
      version,
      year,
      book,
      isPrev: true
    });
  }, [selections]);

  useEffect(() => {
    if (type !== 'create') return;
    if (educationValue === '') return;

    const { year: yearsOptions } = selections;
    if (selections.subject[educationValue]) {
      const nextSubjects = selections.subject[educationValue].map(subject => {
        return {
          value: subject.value,
          name: subject.name
        };
      });

      setState({
        subjectOptions: nextSubjects,
        yearsOptions,
      });
    }
  }, [educationValue]);

  useEffect(() => {
    if (type !== 'create') return;
    if (year === '' || subjectValue === '') return;
    const { version: versionsOptions } = selections;

    if (selections.examType[educationValue]) {
      const nextExams = selections.examType[educationValue].map(examType => {
        return {
          value: examType.value,
          name: examType.name
        };
      });
      if (JSON.stringify(examTypeOptions) !== JSON.stringify(nextExams)) {
        setState({
          examTypeOptions: nextExams,
        });
      }
      setState({
        versionsOptions
      });
    }
  }, [subjectValue, year]);

  //條件更換全部都選好時,觸發查詢章節
  useEffect(() => {
    if (type !== 'create') return;
    if (
      subjectValue === '' ||
      educationValue === '' ||
      year === '' ||
      version === '' ||
      examType === '' ||
      Object.keys(book).length === 0
    ) {
      return;
    }
    setState({
      blockSelect: examID !== '' ? true : false
    });
    if (Object.keys(searchCriteria).length < 6) return;

    //新增試卷時回上一步不用重打章節查詢
    if (isPrev && !examID) {
      const prevSearchCriteria = { ...searchCriteria };
      setState({
        prevSearchCriteria,
        isPrev: false
      });
      return;
    }

    if (JSON.stringify(prevSearchCriteria) === JSON.stringify(searchCriteria)) {
      return;
    }

    const chapterPayload = {
      data: searchCriteria,
      type
    };
    getQuestionChapter(chapterPayload);
    setState({
      isGetchapter: true,
      checkAll: false,
    });
  }, [educationValue, subjectValue, year, version, book, examType]);

  useEffect(() => {
    if (type !== 'create') return;

    if (!chapter.chapter) return;

    const nextData = formatterNestedListData(chapter);

    setState({
      chapterOptions: nextData
    });

  }, [chapter]);

  //========================CreateExamPaperPage End========================


  //========================SearchQuestionPage Start========================

  useEffect(() => {
    if (type === 'create') return;
    if (Object.keys(searchQuestionPageData.searchQusetionPayload).length === 0) return;
    const { data: { education, subject, version, year, book } } = searchQuestionPageData.searchQusetionPayload;

    setState({
      chapterOptions: searchQuestionPageData.formatterChapter.data,
      checkAll: searchQuestionPageData.formatterChapter.checkAll,
      educationValue: education,
      subjectValue: subject,
      version,
      year,
      book,
      isGetchapter: true,
      isPrev: true
    });
  }, [selections]);

  useEffect(() => {
    if (type === 'create') return;
    if (subjectValue === '' || educationValue === '') return;
    const { year: yearsOptions, version: versionsOptions } = selections;
    setState({
      yearsOptions,
      versionsOptions
    });
  }, [educationValue, subjectValue]);

  //條件更換全部都選好時,觸發查詢章節
  useEffect(() => {
    if (type === 'create') return;
    if (
      subjectValue === '' ||
      educationValue === '' ||
      year === '' ||
      version === '' ||
      Object.keys(book).length === 0
    ) {
      setState({
        isGetchapter: false,
      });
      return;
    }

    if (Object.keys(searchCriteria).length < 5) return;

    if (isPrev) {
      const prevSearchCriteria = { ...searchCriteria };
      setState({
        prevSearchCriteria,
        isPrev: false
      });
      return;
    }

    if (JSON.stringify(prevSearchCriteria) === JSON.stringify(searchCriteria)) {
      return;
    }

    const chapterPayload = {
      data: searchCriteria,
      type
    };
    getQuestionChapter(chapterPayload);
    setState({
      isGetchapter: true,
      checkAll: false,
    });
  }, [educationValue, subjectValue, year, version, book]);

  useEffect(() => {
    if (type === 'create') return;
    if (!searchQuestionPageData.chapter.chapter) return;
    const nextData = formatterNestedListData(searchQuestionPageData.chapter);
    setState({
      chapterOptions: nextData
    });

  }, [searchQuestionPageData]);

  //========================SearchQuestionPage End========================



  return (
    <UiQuestionRangeSelect>
      <UiSearchQuestionWrapper>
        <UiBasicSearchQuestionHeader>
          <UiQuestionSelectList>
            {
              type === 'create' ?
                <>
                  <div>
                    <Select
                      options={selections.education}
                      selectName={selectList[0].name}
                      label={selectList[0].label}
                      value={educationValue}
                      blockSelect={blockSelect}
                      onChange={chooseEducation}
                    />
                    {
                      (subjectOptions && subjectOptions.length > 0) &&
                      <Select
                        options={subjectOptions}
                        selectName={selectList[2].name}
                        label={selectList[2].label}
                        value={subjectValue}
                        blockSelect={blockSelect}
                        onChange={chooseSubject}
                      />
                    }
                  </div>
                  <div>
                    {
                      (yearsOptions && yearsOptions.length > 0) &&
                      < Select
                        options={yearsOptions}
                        selectName={selectList[3].name}
                        label={selectList[3].label}
                        value={year}
                        blockSelect={blockSelect}
                        onChange={onChangeHandler}
                      />
                    }
                    {
                      (examTypeOptions && examTypeOptions.length > 0) &&
                      <Select
                        options={examTypeOptions}
                        selectName={selectList[4].name}
                        label={selectList[4].label}
                        value={examType}
                        blockSelect={blockSelect}
                        onChange={onChangeHandler}
                      />
                    }
                  </div>
                  <div>
                    {
                      (versionsOptions && versionsOptions.length > 0) &&
                      <Select
                        options={versionsOptions}
                        selectName={selectList[5].name}
                        label={selectList[5].label}
                        value={version}
                        onChange={onChangeHandler}
                      />
                    }
                  </div>
                </> :
                <>
                  <div>
                    <Select
                      options={selections?.education || []}
                      selectName={selectList[0].name}
                      label={selectList[0].label}
                      value={educationValue}
                      onChange={chooseEducation}
                    />
                    {
                      (subjectOptions && subjectOptions.length > 0) &&
                      <Select
                        options={subjectOptions}
                        selectName={selectList[2].name}
                        label={selectList[2].label}
                        value={subjectValue}
                        onChange={chooseSubject}
                      />
                    }
                  </div>
                  <div>
                    {
                      (yearsOptions && yearsOptions.length > 0) &&
                      < Select
                        options={yearsOptions}
                        selectName={selectList[3].name}
                        label={selectList[3].label}
                        value={year}
                        onChange={onChangeHandler}
                      />
                    }
                    {
                      (versionsOptions && versionsOptions.length > 0) &&
                      <Select
                        options={versionsOptions}
                        selectName={selectList[5].name}
                        label={selectList[5].label}
                        value={version}
                        onChange={onChangeHandler}
                      />
                    }
                  </div>
                </>
            }

          </UiQuestionSelectList>
          <UiQuestionCircleSelectList>
            {
              educationValue &&
              <RadioGroup
                data={selections.book[educationValue]}
                name={selectList[6].name}
                label={selectList[6].label}
                reset={radioReset}
                value={book}
                onChange={getDataFromRadioList}
              />
            }

          </UiQuestionCircleSelectList>
        </UiBasicSearchQuestionHeader>
        <UiSearchQuestionContent>
          <>
            {
              (chapterOptions.length > 0 &&

                Object.keys(searchCriteria).length > 2) ?
                <UiNestedListBox>
                  <UiNestedListTitle>範圍</UiNestedListTitle>
                  <NestedListGroup checkAll={checkAll} maxLevel={2} data={chapterOptions} getData={getDataHandler} />
                  <Button onClick={submitHandler} >下一步</Button>
                </UiNestedListBox> :
                <>
                  {
                    isGetchapter &&
                    <UiGetchapter>
                      <h2>無章節</h2>
                      <Button onClick={submitHandler} >下一步</Button>
                    </UiGetchapter>
                  }
                </>
            }
          </>
        </UiSearchQuestionContent>
      </UiSearchQuestionWrapper>
      {isLoading && <LoadingBackground />}
    </UiQuestionRangeSelect>);
};

QuestionRangeSelect.propTypes = {
  examID: PropTypes.string,
  nextStep: PropTypes.func,
  type: PropTypes.string,
};


