import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import {
  TitleContainer,
  Table,
  Pagination,
  QuestionModal,
} from 'components';
import { useMember } from 'store/member';
import { useQuestion } from 'store/question';
import { useSetState } from 'utils/hooks/useSetState';
import { Prompt } from 'react-router-dom';
import { UiExamSearchList, UiList } from './ExamSearchList.style';


/**
 * 批卷登分
 */


const schema = {
  systemTime: {
    name: '最後修改日期',
  },
  title: {
    name: '試卷名稱',
  },
};

export const ExamSearchList = () => {
  const history = useHistory();

  const [{ memberInfo }] = useMember();
  const [{
    examList: { data: examListData },
    searchQusetionPayload,
    addedQuestionList,
    questionTitle,
    isLoading,
  }, {
    getExamList,
    getFinishedExamDetail,
    clearQuestions,
  }] = useQuestion();
  const [
    {
      page,
      limit,
      isBlocking,
      examDetail,
      isQuestionOpen,
      subject,
    },
    setState] = useSetState({
      page: 0,
      limit: 7,
      isBlocking: true,
      examDetail: {},
      isQuestionOpen: false,
      subject: '',
    });

  const actions = [
    {
      status: 'normal',
      icon: 'visibility',
      title: '瀏覽試卷',
      background: '#ffb800',
      onClick: data => {
        getExamDetail(data);
      }
    },
    {
      status: 'normal',
      icon: 'move_to_inbox',
      title: '畫卡閱卷',
      background: '#ff660f',
      onClick: async data => {
        history.push(`${MAIN_PATH.IMPORT_ANSWERCARD}/${data.examID}`);
      }
    },
    {
      status: 'normal',
      icon: 'how_to_reg',
      title: '非選登分',
      background: '#0f62ff',
      onClick: async data => {
        history.push(`${MAIN_PATH.REGISTER_SCORE}/${data.examID}`);
      }
    },
  ];

  const onChangePage = value => {
    setState({
      page: value - 1
    });
  };

  const getExamDetail = async (data) => {
    const { examID, title } = data;
    let payload = {
      client: 'tikuHuailen',
      data: {
        user: memberInfo.id,
        examID
      }
    };
    await getFinishedExamDetail({ payload, title });
  };

  const getQuestionModalStateHandler = state => {
    setState({
      isQuestionOpen: state
    });
  };

  useEffect(() => {
    if (!memberInfo.id) return;
    const params = {
      isFinished: true,
    };
    getExamList(params);
  }, [memberInfo]);

  useEffect(() => {
    if (addedQuestionList.length <= 0) return;
    setState({
      examDetail: {
        examName: questionTitle,
        questions: addedQuestionList,
      },
      isQuestionOpen: true
    });
  }, [addedQuestionList]);

  useEffect(() => {
    if (Object.keys(searchQusetionPayload).length <= 0) return;
    const { data: { subjectID } } = searchQusetionPayload;
    setState({
      subject: subjectID
    });
  }, [searchQusetionPayload.data]);


  return (
    <UiExamSearchList>
      <TitleContainer titleshadow={true} title="已定稿試卷(請於考試後七日內完成畫卡閱卷與登入分數)" titleIcon="youtube_searched_for">
        <Prompt
          when={isBlocking}
          message={() => {
            clearQuestions();
          }}
        />
        <UiList>
          <Table
            data={examListData}
            schema={schema}
            isNeedTitle={true}
            actions={actions}
            limit={limit}
            page={page}
            isLoading={isLoading}
          />
          <Pagination total={examListData.length} limit={limit} onChange={onChangePage} />
        </UiList>
        <QuestionModal
          subject={subject}
          data={examDetail}
          open={isQuestionOpen}
          getModalState={getQuestionModalStateHandler}
        />
      </TitleContainer>
    </UiExamSearchList>
  );
};
