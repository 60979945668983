import styled from 'styled-components';

export const UiExamSearchList = styled.div`
`;

export const UiSearch = styled.div`
	display: flex;
	padding: 1rem 0;
	width: 100%;
	height: 10rem;
	color: #fff;
	background: ${({ theme }) => theme.colorTheme.secondary};

	& > div {
		flex: 1;
		margin: 0  2rem;
	}
`;

export const UiSearchItem = styled.div`
`;

export const UiList = styled.div`
	display: flex;
	padding: 3rem;
	flex-direction: column;
	min-height: 60rem;
`;

export const UiButtonBox = styled.div`
	display: flex;

	& > button {
		margin: 0 1rem;
	}
`;
