import styled from 'styled-components';
import {
  List as MatList
} from '@material-ui/core';

export const UiNestedList = styled.div`
	width: 100%;

	.MuiList-padding {
		padding-top: 0;
		padding-bottom: 0;
	}
`;

export const UiMatList = styled(MatList)`
	.MuiCollapse-wrapper {
		padding-left: 16px;
	}
`;
