import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiCheckbox, UiCircleCheckbox } from './Checkbox.style';
import { useSetState } from 'utils/hooks/useSetState';


/**
 * Checkbox組件
 */

export const Checkbox = ({
  name,
  label,
  value,
  tick,
  onCheck,
  type,
}) => {
  const [{ checked }, setState] = useSetState({
    checked: false,
  });
  const checkHandler = e => {
    const { value, checked: isChecked } = e.target;
    setState({
      checked: isChecked
    });
    onCheck && onCheck(value, isChecked);
  };
  useEffect(() => {
    setState({
      checked: tick
    });
  }, [tick]);
  return (
    <>
      {
        type === 'circle' ?
          <UiCircleCheckbox>
            <input
              id={value}
              name={name}
              type="checkbox"
              value={value}
              checked={checked}
              onChange={e => { checkHandler(e); }}
            />
            <label htmlFor={value}>
              <div className='checkBox'>
                <div className="check"></div>
              </div>
              {label}
            </label>
          </UiCircleCheckbox> :
          <UiCheckbox>
            <label
              htmlFor={value}
            >
              <input
                id={value}
                name={name}
                type="checkbox"
                value={value}
                checked={checked}
                onChange={e => { checkHandler(e); }}
              />
              {label}
            </label>

          </UiCheckbox>
      }
    </>);
};

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  tick: PropTypes.bool,
  onCheck: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
};


