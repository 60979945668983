import React from 'react';
import { ReportFramed } from 'components';

/**
 * 報表頁面
 */

export const ReportFramdPage = () => {
  return (
    <ReportFramed />
  );
};
