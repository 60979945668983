import React from 'react';
import { TwoWaySchedule } from 'components';


/**
 * 雙向細目表
 */

export const TwoWaySchedulePage = () => {
  return (
    <TwoWaySchedule />
  );
};
