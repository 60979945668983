import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UiCheckBoxGroup, Uilabel, UiCheckBoxList } from './CheckBoxGroup.style';
import { Checkbox } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

/**
 * CheckBoxGroup
 */

export const CheckBoxGroup = ({
  data,
  checkboxType,
  label,
  getData = () => { },
}) => {
  const [{ sendData }, setState] = useSetState({
    sendData: [],
  });
  const formatterData = (value, isChecked) => {
    if (isChecked) {
      setState({
        sendData: [...sendData, value]
      });
    } else {
      setState({
        sendData: sendData.filter(item => item !== value)
      });
    }

  };
  useEffect(() => {
    getData(sendData);
  }, [sendData]);
  return (
    <UiCheckBoxGroup>
      {
        label &&
        <Uilabel>{label}</Uilabel>
      }
      <UiCheckBoxList>
        {
          data && data.map(item => (
            <Checkbox
              key={item.name}
              type={checkboxType}
              label={item.name}
              value={item.value}
              onCheck={formatterData} />
          ))
        }
      </UiCheckBoxList>

    </UiCheckBoxGroup>
  );
};

CheckBoxGroup.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  getData: PropTypes.func,
  checkboxType: PropTypes.PropTypes.oneOf(['circle', 'square']),
};


