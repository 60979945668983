import React from 'react';
import PropTypes from 'prop-types';
import { useMember } from 'store/member';
import {
  Input,
  RadioGroup,
  Button,
  Checkbox,
  ColorBlockTitle,
  Modal,
  ProgressBar,
} from 'components';
import { useQuestion } from 'store/question';
import { useSetState } from 'utils/hooks/useSetState';
import { useDownload } from 'utils/hooks/useDownload';
import {useAlert} from 'utils/hooks/useAlert';
import { useHistory } from 'react-router-dom';
import { MAIN_PATH } from 'routes/routesConfig/constants';

import {
  UiExamLayoutConfig,
  UiExamLayoutConfigWrapper,
  UiExamLayoutConfigRow,
  UiExamLayoutConfigContent,
  UiExamLayoutConfigInfoContent,
  UiExamLayoutConfigInfoContentRow,
  UiExamLayoutConfigContentCheckboxRow,
  UiExamLayoutConfigContentRow,
  UiExamTeacher,
  UiStudentInfo,
  UiunderLine,
  UiExamLayoutConfigButtonList,
  UiExamLayoutConfigDescription,
  UiExamLayoutConfigDescriptionTitle,
  UiExamLayoutConfigDescriptionUl,
  UiExamLayoutConfigDescriptionLi,
} from './ExamLayoutConfig.style';

/**
 * 版面配置(新增試卷step5)
 */

export const ExamLayoutConfig = ({
  nextStep = () => { },
}) => {
  const {setAlert} = useAlert();
  const [{
    questionTitle,
    searchQusetionPayload,
    examID,
  },] = useQuestion();
  const [{ memberInfo }] = useMember();

  const history = useHistory();

  const [{
    exportExamFormat,
    nextQuestionTitle,
    isOpen,
    startRate,
    endRate }, setState] = useSetState({
      exportExamFormat: {},
      nextQuestionTitle: questionTitle,
      isOpen: false,
      startRate: 0,
      endRate: 180,//3分鐘
    });
  const { blobDownload } = useDownload();

  const data1 =
  {
    label: '紙張大小',
    name: 'size',
    list: [
      { name: 'A4', value: 'A4' },
      { name: 'B4', value: 'B4' },
    ]
  };
  const data2 =
  {
    label: '書寫方式',
    name: 'draw',
    list: [
      { name: '橫書單欄', value: '1' },
      { name: '橫書雙欄', value: '2' },
      { name: '直書單欄', value: '3' },
      { name: '直書雙欄', value: '4' },
    ]
  };

  const drawType = val => {
    switch (val) {
      case '1':
        return {
          isVertical: false,
          isSplitColumns: false,
        };
      case '2':
        return {
          isVertical: false,
          isSplitColumns: true,
        };
      case '3':
        return {
          isVertical: true,
          isSplitColumns: false,
        };
      case '4':
        return {
          isVertical: true,
          isSplitColumns: true,
        };
      default:
    }
  };


  const downloadExam = editState => {
    sendModalStateHandler(true);
    let count = 0;
    const progressCount = setInterval(() => {
      setState({
        startRate: count < 179 ? count++ : 179
      });
    }, 1000);
    setState({
      isOpen: true
    });
    const { client } = searchQusetionPayload;
    const params = {
      client,
      data: {
        title: `${nextQuestionTitle}${editState === 'Edit' ? '【試閱卷】' : ''}`,
        format: exportExamFormat,
        status: editState,
        user: memberInfo.id,
        examID,
      }
    };
    blobDownload(
      `${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/ExportExam`,
      `${nextQuestionTitle}${editState === 'Edit' ? '【試閱卷】' : ''}`,
      'POST',
      params,
      (res) => {
        if(res){
          setAlert('組卷發生錯誤','error');
        }
        setState({
          startRate: 180
        });
        clearInterval(progressCount);
        setTimeout(() => {
          history.push(MAIN_PATH.EXAMPAPER_LIST);
        }, 1000);
      }
    );

  };
  const onChangeHandler = data => {
    const key = Object.keys(data)[0];
    const value = Object.values(data)[0];

    if (key === 'draw') {
      const { isVertical, isSplitColumns } = drawType(value);
      setState({
        exportExamFormat: { ...exportExamFormat, isVertical, isSplitColumns }
      });
    } else if (key === 'size') {
      setState({
        exportExamFormat: { ...exportExamFormat, paperSize: value }
      });
    }
  };
  const onChange = data => setState({ ...data });

  const getCheckboxData = (value, isChecked) => {
    setState({
      exportExamFormat: { ...exportExamFormat, isExtend: isChecked }
    });
  };

  //設定彈窗狀態
  const sendModalStateHandler = state => {
    setState({
      isOpen: state
    });
  };

  return (
    <UiExamLayoutConfig>
      <UiExamLayoutConfigWrapper>
        <UiExamLayoutConfigRow>
          <ColorBlockTitle>試卷資訊</ColorBlockTitle>
          <UiExamLayoutConfigInfoContent>
            <UiExamLayoutConfigInfoContentRow>
              <Input
                value={nextQuestionTitle}
                textcolor={'#00923F'}
                label='試卷標題'
                placeholder='試卷標題'
                name="nextQuestionTitle"
                onChange={onChange}
              />
              <UiExamTeacher>
                命題教師：{memberInfo.userName}
              </UiExamTeacher>
            </UiExamLayoutConfigInfoContentRow>
            <UiExamLayoutConfigInfoContentRow>
              <UiStudentInfo>
                <UiunderLine></UiunderLine>年
              </UiStudentInfo>
              <UiStudentInfo>
                <UiunderLine></UiunderLine>班
              </UiStudentInfo>
              <UiStudentInfo>
                座號<UiunderLine></UiunderLine>
              </UiStudentInfo>
              <UiStudentInfo>
                姓名<UiunderLine col='2'></UiunderLine>
              </UiStudentInfo>
            </UiExamLayoutConfigInfoContentRow>
          </UiExamLayoutConfigInfoContent>
        </UiExamLayoutConfigRow>
        <UiExamLayoutConfigRow>
          <ColorBlockTitle>試卷排版</ColorBlockTitle>
          <UiExamLayoutConfigContent>
            <UiExamLayoutConfigContentRow>
              <RadioGroup
                label={data1.label}
                name={data1.name}
                data={data1.list}
                onChange={onChangeHandler}
              />
            </UiExamLayoutConfigContentRow>
            <UiExamLayoutConfigContentRow>
              <RadioGroup
                label={data2.label}
                name={data2.name}
                data={data2.list}
                onChange={onChangeHandler}
              />
            </UiExamLayoutConfigContentRow>
            <UiExamLayoutConfigContentCheckboxRow>
              <Checkbox label='題號連續' onCheck={getCheckboxData} />
            </UiExamLayoutConfigContentCheckboxRow>
            <UiExamLayoutConfigContentRow>
              <UiExamLayoutConfigButtonList>
                <Button onClick={() => { nextStep(3); }}>上一步</Button>
                {
                  Object.entries(exportExamFormat).length >= 3 &&
                  <>
                    <Button onClick={() => { downloadExam('Edit'); }} buttoncolor='deepblue'>下載試閱卷</Button>
                    <Button onClick={() => { downloadExam('Review'); }} buttoncolor='deepblue'>下載審題卷</Button>
                    <Button onClick={() => { downloadExam('Finished'); }} buttoncolor='orange'>定稿列印</Button>
                  </>
                }
              </UiExamLayoutConfigButtonList>
            </UiExamLayoutConfigContentRow>
            <UiExamLayoutConfigContentRow>
              <UiExamLayoutConfigDescription>
                <UiExamLayoutConfigDescriptionTitle>
                  下載說明
                </UiExamLayoutConfigDescriptionTitle>
                <UiExamLayoutConfigDescriptionUl>
                  <UiExamLayoutConfigDescriptionLi>
                    點選「下載試閱卷」與「下載審題卷」將包含<span>題目卷</span>與<span>解析卷</span>
                  </UiExamLayoutConfigDescriptionLi>
                  <UiExamLayoutConfigDescriptionLi>
                    點選「定稿列印」將包含<span>題目卷、解析卷與空白畫卡</span>
                  </UiExamLayoutConfigDescriptionLi>
                  <UiExamLayoutConfigDescriptionLi>
                    畫卡僅供<span>是非題</span>與<span>選擇題</span>作答
                  </UiExamLayoutConfigDescriptionLi>
                  <UiExamLayoutConfigDescriptionLi>
                    是非題作答說明：
                    <ul>
                      <li>
                        作答O，國小請劃記在1、國中請劃記在A
                      </li>
                      <li>
                        作答X，國小請劃記在2、國中請劃記在B
                      </li>
                    </ul>
                  </UiExamLayoutConfigDescriptionLi>
                </UiExamLayoutConfigDescriptionUl>
              </UiExamLayoutConfigDescription>
            </UiExamLayoutConfigContentRow>
          </UiExamLayoutConfigContent>
        </UiExamLayoutConfigRow>
      </UiExamLayoutConfigWrapper>
      <Modal
        open={isOpen}
        title='系統組卷中'
        type='normal'
      >
        <ul>
          <li>組卷完成後會自動下載檔案至您的電腦</li>
          <li>待檔案下載後，需先解壓縮後開啟檔案</li>
        </ul>
        <p> 感謝您的耐心等候</p>

        <ProgressBar
          barColor={'#ff6969'}
          startRate={startRate}
          endRate={endRate}
        />
      </Modal>
    </UiExamLayoutConfig>
  );
};

ExamLayoutConfig.propTypes = {
  nextStep: PropTypes.func
};


