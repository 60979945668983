export const COMMON_ACTION_TYPE = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
};

// 學制
const ELEMENTARY = '國小';
const JUNIOR = '國中';
export const EDUCATION_LEVEL = {
  [ELEMENTARY]: 'E',
  [JUNIOR]: 'J',
};


export const TABLE_STATUS = {
  EDIT: 'edit',
  REVIEW: 'review',
  FINISHED: 'Finished',
  NORMAL: 'normal',
  DOWNLOAD: 'download',
  TIME: 'time'
};
export const QUESTIONLIST_STATUS = {
  EDIT: { label: TABLE_STATUS.EDIT, value: 0 },
  REVIEW: { label: TABLE_STATUS.REVIEW, value: 1 },
  FINISHED: { label: TABLE_STATUS.FINISHED, value: 2 },
};

export const SUBJECT_DESC = {
  CH: '國語',
  PC: '國文',
  EN: '英文',
  MA: '數學',
  NA: '自然',
  SO: '社會',
  CT: '公民',
};

export const RADIOLIST = {
  STUDENTS: {
    name: 'students',
    label: '班級學生列表：'
  },
  CLASS: {
    name: 'className',
    label: '全校班級總覽：'
  },
  COUNTY: {
    name: 'schoolName',
    label: '全縣學校總覽：'
  }
};

export const SELECTLIST = {
  EDUCATION: {
    label: '學制',
    name: 'education',
  },
  SCHOOL: {
    label: '學校',
    name: 'school',
  },
  SUBJECT: {
    label: '科目',
    name: 'subject',
  },
  YEAR: {
    label: '年度',
    name: 'year',
  },
  EXAMTYPE: {
    label: '考試名稱',
    name: 'examType',
  },
  VERSION: {
    label: '版本',
    name: 'version',
  },
  BOOK: {
    label: '冊次',
    name: 'book',
  },
  SCHOOLYEAR: {
    label: '學年度',
    countyLabel: '學年期',
    name: 'schoolYear',
  },
  GRADE: {
    label: '年級',
    name: 'grade',
  },
  CLASS: {
    label: '班級',
    name: 'classValue',
  },
  CLASSNO: {
    label: '班級',
    name: 'classNo',
  }
};
