import React, { useEffect } from 'react';
import { TitleContainer, Button, Input, Select } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'utils/hooks/useQuery';
import { useSetState } from 'utils/hooks/useSetState';
import { MAIN_PATH } from 'routes/routesConfig/constants';
import MatImage from 'material-ui-image';
import { useReview } from 'store/review';
import { useAlert } from 'utils/hooks/useAlert';
import { addHours, format } from 'date-fns';
import {
  UiReviewDetail,
  UiSelectBox,
  UiContainer,
  UiImage,
  UiInfo,
  UiInfoItem,
  UiTitle,
  UiAuthor,
} from './ReviewDetail.style';


/**
 * 審查詳細
 */

const PARENT = 'parent';
const QUESTION = 'question';

export const ReviewDetail = () => {
  const history = useHistory();
  const { setAlert } = useAlert();
  const { getQueryParams } = useQuery();
  const apiType = getQueryParams('type');
  const [{
    isParent,
    remark,
    EXAM_ABILITY,
    KNOWLEDGE
  }, setState] = useSetState({
    isParent: false,
    remark: '',
    EXAM_ABILITY: '',
    KNOWLEDGE: ''
  });
  const { id } = useParams();
  const [{ reviewDetail }, { getReviewDetail, getCheckDetail, saveReview }] = useReview();

  const toggleQuestion = () => {
    setState({
      isParent: !isParent
    });
  };

  const onChnageHandler = value => {
    setState({
      ...value
    });
  };

  const formatTime = date => {
    const newDate = format(addHours(new Date(date), 8), 'yyyy-mm-dd HH:mm:ss');
    return newDate;
  };

  const submitHandler = async isChecked => {
    let examName = '';
    let knowName = '';
    reviewDetail.selection.forEach(item => {
      item.options.forEach(i => {
        if (i.value === EXAM_ABILITY) examName = i.name;
        if (i.value === KNOWLEDGE) knowName = i.name;
      });
    });
    const params = {
      id,
      updateMeta: {
        EXAM_ABILITY: {
          value: EXAM_ABILITY,
          name: examName
        },
        KNOWLEDGE: {
          value: KNOWLEDGE,
          name: knowName
        }
      },
      remark: remark,
      isChecked
    };
    await saveReview(params);
    setAlert(isChecked ? '審查完成' : '暫存紀錄成功');
    history.push(`${MAIN_PATH.REVIEW}`);
  };

  useEffect(() => {
    apiType === 'review' ? getReviewDetail(id) : getCheckDetail(id);
  }, [id]);

  useEffect(() => {
    if (!reviewDetail[isParent ? PARENT : QUESTION]?.selectedValue) return;
    setState({
      EXAM_ABILITY: reviewDetail[isParent ? PARENT : QUESTION].selection[0].selectedValue,
      KNOWLEDGE: reviewDetail[isParent ? PARENT : QUESTION].selection[1].selectedValue
    });
  }, [reviewDetail, isParent]);

  return (
    <TitleContainer
      title={apiType === 'review' ? (isParent ? '檢視原題' : '待審查題') : '已審題目'}
      titleIcon="description"
      titleChildren={
        <>
          {
            reviewDetail?.selection &&
            <>
              <Button
                icon="visibility"
                buttoncolor="highlight"
                onClick={() => toggleQuestion()}
              >
                {isParent ? '返回題目' : '檢視原題'}
              </Button>
              <Button icon="save" onClick={() => submitHandler(false)}>暫存紀錄</Button>
              <Button icon="done_all" buttoncolor="deepblue" onClick={() => submitHandler(true)}>審查完成</Button>
            </>
          }
          <Button
            icon="exit_to_app"
            onClick={() => history.push(`${MAIN_PATH.REVIEW}`, { listType: apiType })}
          >返回列表</Button>
        </>
      }
    >
      <UiReviewDetail>
        {reviewDetail?.selection &&
          <Input
            type="textarea"
            onChange={onChnageHandler}
            label="備註"
            name="remark"
            value={reviewDetail.remark || ''}
          />
        }
        <UiSelectBox>
          {
            reviewDetail.selection?.map((item, index) => {
              return (
                <Select
                  key={index}
                  onChange={onChnageHandler}
                  selectName={item.value}
                  label={item.name}
                  options={item.options}
                  value={item.selectedValue}
                />
              );
            })
          }
        </UiSelectBox>
      </UiReviewDetail>
      <UiContainer>
        <UiImage>
          <UiTitle isParent={isParent}>題目</UiTitle>
          <MatImage src={reviewDetail[isParent ? PARENT : QUESTION]?.question || ''} alt="question" />
        </UiImage>
        <UiInfo>
          <UiTitle isParent={isParent}>基本資料</UiTitle>
          {
            reviewDetail[isParent ? PARENT : QUESTION]?.metaInfos.map(item => {
              return (
                <UiInfoItem key={item.id}>
                  {item.target} :
                  {item.value.map((option, index) => {
                    return (
                      <>{index > 0 ? ',' + option : option}</>
                    );
                  })}
                </UiInfoItem>
              );
            })
          }
          {apiType !== 'review' && <UiInfoItem>與原題關聯度: {reviewDetail.isConnected ? '維持' : '不維持'}</UiInfoItem>}
        </UiInfo>
        <UiAuthor isParent={isParent}>
          <li>命題時間: {reviewDetail[isParent ? PARENT : QUESTION]?.author.systemTime}</li>
          <li>命題教師: {reviewDetail[isParent ? PARENT : QUESTION]?.author.name}</li>
          <li>學校: {reviewDetail[isParent ? PARENT : QUESTION]?.author.school ?? '無'}</li>
          <li>題目狀態: 可使用</li>
          {apiType === 'check' && <li>備註: {reviewDetail?.remark}</li>}
          {reviewDetail.checkedTime && <li>審查紀錄: {formatTime(reviewDetail.checkedTime)}</li>}
          {reviewDetail.userName && <li>審查者: {reviewDetail.userName}</li>}
        </UiAuthor>
      </UiContainer>
    </TitleContainer >
  );
};
