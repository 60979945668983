import styled from 'styled-components';

export const UiSelectBox = styled.div`
	margin: 2rem auto;
	max-width: 250px;
`;

export const UiButtonBox = styled.div`
	display: flex;
	margin: 0 auto 3rem;

	& > * + * {
		margin-left: 1rem;
	}
`;
