import React, { useEffect } from 'react';
import { Button, ColorBlockTitle, QuestionTable, GraphModal } from 'components';
import { useQuestion } from 'store/question';
import { useSetState } from 'utils/hooks/useSetState';
import {useDownload} from 'utils/hooks/useDownload';
import { objectMapCovertToArray } from 'utils/object';
import { UiExamAnalysis, UiButtonBox } from './ExamAnalysis.style';


/**
 * 試卷分析
 */

export const ExamAnalysis = () => {
  const { blobDownload } = useDownload();
  const [{
    examAnalysis,
    addedQuestionList,
    analysisQuestionData,
    searchQusetionPayload,
    examID,
    section,
  }, { examCignitive, calculateExamQuestion }] = useQuestion();
  const [{
    analysisTabs,
    analysisModalOpen
  }, setState] = useSetState({
    analysisTabs: [],
    analysisModalOpen: false
  });
  const getAnalysisModalStateHandler = state => {
    setState({
      analysisModalOpen: state
    });
  };
  const analysisExamPaper = async () => {
    const { data: { education, subject } } = searchQusetionPayload;
    const subjectID = education + subject;
    const addedQuestionIDList = addedQuestionList.map(item => item.id);
    const params = {
      ...searchQusetionPayload,
      data: {
        ...searchQusetionPayload.data,
        subjectID,
        questionID: addedQuestionIDList
      }
    };

    await calculateExamQuestion(params);

    setState({
      analysisModalOpen: true,
    });
  };
  const downloadCognitiveReport = ()=>{
    const params = {
          client: 'tikuHualien',
          data: {
            examID,
            section:section.map(item => ({
              ...item,
              questionInfo: item.questionInfo.map(info => info.id),
              score: parseInt(item.score)
              }))
          }
        };
        blobDownload(`${process.env.REACT_APP_QUESTION_DOMAIN}/api/Export/CognitiveReport`,
    `雙向細目分析表${examID}.xlsx`,
    'POST',
    params
    );
  };
  useEffect(() => {
    const params = {
      client: 'tikuHualien',
      data: {
        examID,
        section
      }
    };
    examCignitive(params);
  }, []);
  useEffect(() => {
    if (analysisQuestionData.length <= 0) return;
    const { chapter, difficulty, questionType } = analysisQuestionData;
    const tabsData = {
      chapter: {
        name: '章節比重',
        color: '#c7daff',
        chart: 'verticalBar',
        data: chapter
      },
      difficulty: {
        name: '難易比重',
        color: '#e0ff89',
        chart: 'pie',
        data: difficulty
      },
      questionType: {
        name: '題型比重',
        color: '#ffc9b2',
        chart: 'pie',
        data: questionType
      }
    };
    const valueStr = [
      'one',
      'two',
      'three'
    ];
    let count = -1;
    const tabs = objectMapCovertToArray(analysisQuestionData, (data, key) => {
      if (data.length > 0) {
        count++;
        return {
          ...tabsData[key],
          value: valueStr[count]
        };
      }
    });

    setState({
      analysisTabs: tabs,
    });
  }, [analysisQuestionData]);

  return (
    <>
      <ColorBlockTitle>試題雙向分析</ColorBlockTitle>

      <UiExamAnalysis>
        <UiButtonBox>
        <Button
          buttoncolor='deepblue'
          icon="download"
          onClick={
          ()=>{downloadCognitiveReport();}
        }>下載雙向細目表</Button>
          {/* <Button buttoncolor="blue" icon="developer_board">匯出表單</Button> */}
          <Button buttoncolor="orange" icon="pie_chart" onClick={() => analysisExamPaper()}>選題分析</Button>
        </UiButtonBox>
        {examAnalysis.chapter && <QuestionTable tableWidth="100%" data={examAnalysis} />}
      </UiExamAnalysis>
      {
        analysisTabs.length > 0 &&
        <GraphModal
          open={analysisModalOpen}
          tabs={analysisTabs}
          modalStateHandler={getAnalysisModalStateHandler} />
      }
    </>
  );
};
