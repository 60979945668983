import React from 'react';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import {
  TitleContainer,
  Icon,
  Button
} from 'components';
import { UiTable, UiTbody, UiTr, UiTh, UiTd } from './Instructions.style';


/**
 * 操作說明
 */

const schema = {
  listName: {
    name: '項目名稱'
  },
  video: {
    name: '影音內容'
  },
  desc: {
    name: '操作手冊'
  }
};

const data = [
  {
    type: 'text',
    label: '命題',
    icon: 'edit',
    videoLink: 'public/proposition.mp4',
    instructionsLink: `${process.env.PUBLIC_URL}/pdf/proposition.pdf`
  },
  {
    type: 'text',
    label: '出卷',
    icon: 'receipt_long',
    videoLink: 'public/test.mp4',
    instructionsLink: `${process.env.PUBLIC_URL}/pdf/test.pdf`
  },
  {
    type: 'text',
    label: '閱卷/登分',
    icon: 'grading',
    videoLink: 'public/scoring.mp4',
    instructionsLink: `${process.env.PUBLIC_URL}/pdf/scoring.pdf`
  },
  {
    type: 'text',
    label: '報表',
    icon: 'insert_chart_outlined',
    videoLink: 'public/report.mp4',
    instructionsLink: `${process.env.PUBLIC_URL}/pdf/report.pdf`
  },
  {
    type: 'text',
    label: '勾稽審查',
    icon: 'done_outline',
    videoLink: 'public/check.mp4',
    instructionsLink: `${process.env.PUBLIC_URL}/pdf/check.pdf`
  }
];

export const Instructions = () => {
  const {
    getDownloadURL
  } = useFirebaseStorage();
  const tableHead = () => {
    return Object.entries(schema).map(([key, item]) => {
      return {
        label: item.name,
      };
    });
  };
  const downloadMp4 = async (link) => {
    const { status, url } = await getDownloadURL(link);
    if (status === 'success') {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'video.mp4';
      a.click();
      document.body.removeChild(a);
    }
  };
  const downloadPdf = (link,name) => {
    if (link === '') return;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = link;
    a.download = `${name === '閱卷/登分' ? '閱卷登分' : name}.pdf`;
    a.click();
    document.body.removeChild(a);
  };
  return (
    <TitleContainer
      title="操作說明"
      titleIcon="list"
    >
      <UiTable>
        <UiTbody>
          <UiTr>
            {
              tableHead().map(title => {
                return (
                  <UiTh key={title.id} size={title.size === 'large' ? 2 : 1}>{title.label}</UiTh>
                );
              })
            }
          </UiTr>
          {
            data.map((item, index) => {
              return (
                <UiTr key={index}>
                  <UiTd>
                    <div>
                      <Icon name={item.icon} sizetype='large' color="#000" />
                      {item.label}
                    </div>
                  </UiTd>
                  <UiTd>
                    <Button
                      onClick={() => { downloadMp4(item.videoLink); }}
                      icon='slideshow'
                      buttoncolor="yellow">
                      瀏覽影音
                    </Button>
                  </UiTd>
                  <UiTd>
                    <Button
                      onClick={() => { downloadPdf(item.instructionsLink,item.label); }}
                      icon='menu_book'
                      buttoncolor="normal">
                      下載手冊
                    </Button>
                  </UiTd>
                </UiTr>
              );
            })
          }

        </UiTbody>
      </UiTable>
    </TitleContainer>
  );
};

// Instructions.propTypes = {

// };


