import styled, { keyframes } from 'styled-components';

const opacity = keyframes`
	0% { opacity: 0.8; }
	100% { opacity: 0; }
`;

export const UiTable = styled.table`
	position: relative;
	width: 100%;
	flex: 1;
	border-spacing: 0;
`;

export const UiTbody = styled.tbody`
	width: 100%;
`;

export const UiTr = styled.tr`
	min-height: 70px;
	border: 0;

	&:nth-child(odd) {
		background: #fff;
	}

	&:nth-child(even) {
		background: ${({ theme }) => theme.colorTheme.quaternary};
	}

	&:first-child {
		color: #fff;
		background: ${({ theme }) => theme.colorTheme.secondary};
	}

`;

export const UiTh = styled.th`
	padding: 1.5rem 2rem;
	width: ${({ size }) => size === 2 ? '40rem' : 'auto'};
	font-size: 2rem;
	font-weight: bold;
	text-align: left;
`;

export const UiTd = styled.td`
	padding: 1.5rem 2rem;
	font-size: 1.5rem;

	& > div {
		display: flex;
		align-items: center;

		& > * + * {
			margin-left: 5px;
		}
	}
`;


export const UiLoadingContainer = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	visibility: ${({ isLoading }) => isLoading ? 'visible' : 'hidden'};
	z-index: ${({ theme }) => theme.zIndex.loading};
`;

export const UiLoading = styled.ul`
	position: relative;
	top: 0;
	margin-top: 100px;
	width: 60px;
	height: 60px;
`;

export const UiLoadingItem = styled.li`
	transform-origin: 30px 30px;
	animation: ${opacity} 1.1s linear infinite;

	&::after {
		position: absolute;
		margin: -10px;
		width: 15px;
		height: 15px;
		background: #e4e4e4;
		border-radius: 50%;
		content: "";
	}

	&:first-child {
		animation-delay: -1.1s;
	}

	&:nth-child(2) {
		transform: rotate(30deg);
		animation-delay: -1s;
	}

	&:nth-child(3) {
		transform: rotate(60deg);
		animation-delay: -0.9s;
	}

	&:nth-child(4) {
		transform: rotate(90deg);
		animation-delay: -0.8s;
	}

	&:nth-child(5) {
		transform: rotate(120deg);
		animation-delay: -0.7s;
	}

	&:nth-child(6) {
		transform: rotate(150deg);
		animation-delay: -0.6s;
	}

	&:nth-child(7) {
		transform: rotate(180deg);
		animation-delay: -0.5s;
	}

	&:nth-child(8) {
		transform: rotate(210deg);
		animation-delay: -0.4s;
	}

	&:nth-child(9) {
		transform: rotate(240deg);
		animation-delay: -0.3s;
	}

	&:nth-child(10) {
		transform: rotate(270deg);
		animation-delay: -0.2s;
	}

	&:nth-child(11) {
		transform: rotate(300deg);
		animation-delay: -0.1s;
	}

	&:nth-child(12) {
		transform: rotate(330deg);
		animation-delay: 0;
	}
`;

