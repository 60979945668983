import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Button } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiSelectBox,
  UiButtonBox,
  UiPrecautions,
  UiPrecautionsHead,
  UiPrecautionsContent,
  UiPrecautionsUl,
  UiPrecautionsItem
} from './SelectDownloadModal.style';


/**
 * 篩選下載內容的彈窗
 */

const writeTypeOptions = [{
  value: 0,
  name: '選單填寫'
}, {
  value: 1,
  name: '代碼填寫'
}];

export const SelectDownloadModal = ({ data, open, modalStateHandler, submit }) => {
  const [{
    isOpen,
    book,
    education,
    subject,
    version,
    bookValue,
    educationValue,
    subjectValue,
    versionValue,
    writeTypeValue,
    isLoading,
  }, setState] = useSetState({
    isOpen: false,
    book: [],
    education: [],
    subject: [],
    version: [],
    bookValue: '',
    educationValue: '',
    subjectValue: '',
    versionValue: '',
    writeTypeValue: '',
    isLoading: false
  });
  const buttons = [
    {
      text: '取消',
      sizetype: 'normal',
      icon: 'close',
      buttonborder: true,
      textcolor: '#3C4858',
      func: () => { getModalState(false); }
    }
  ];
  const onChange = value => {
    setState({
      ...value
    });
  };
  const submitHandler = async () => {
    setState({
      isLoading: true
    });
    await submit({ bookValue, educationValue, subjectValue, versionValue, writeTypeValue });
    setState({
      bookValue: '',
      educationValue: '',
      subjectValue: '',
      versionValue: '',
      writeTypeValue: '',
      isLoading: false,
    });
  };
  const getModalState = state => {
    setState({
      isOpen: state
    });
    modalStateHandler(state);
  };

  const downloadFile = () => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    let targetSubject = subjectValue;
    if(subjectValue === 'BI' || subjectValue === 'PY' || subjectValue === 'EA') targetSubject = 'TT';
    if(subjectValue === 'EL') targetSubject = 'EN';
    a.href = `${process.env.PUBLIC_URL}/codeList/${educationValue}/${targetSubject}.xlsx`;
    const educationName = education.filter(item => item.value === educationValue)[0].name;
    const subjectName = subject[educationValue].filter(item => item.value === subjectValue)[0].name;
    a.download = `${educationName}${subjectName}代碼對應表.xlsx`;
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (!data) return;
    const { book, education, subject, version, format } = data;
    setState({
      book,
      education,
      subject,
      version,
      writeTypeValue: format
    });
  }, [data]);
  useEffect(() => {
    setState({
      isOpen: open
    });
  }, [open]);
  return (
    <Modal title="下載題目範本" open={isOpen} buttons={buttons} getModalState={getModalState}>
      <UiSelectBox>
        <Select label="學制" options={education} selectName="educationValue" onChange={onChange} />
        {educationValue &&
          <Select label="科目" options={subject[educationValue]} selectName="subjectValue" onChange={onChange} />
        }
        <Select label="版本" options={version} selectName="versionValue" onChange={onChange} />
        {
          educationValue &&
          <Select label="冊次" options={book[educationValue]} selectName="bookValue" onChange={onChange} />
        }
        <Select label="填寫方式" options={writeTypeOptions} selectName="writeTypeValue" onChange={onChange} />
      </UiSelectBox>
      <UiPrecautions>
        <UiPrecautionsHead>注意事項：</UiPrecautionsHead>
        <UiPrecautionsContent>
          <UiPrecautionsUl>
            <UiPrecautionsItem>
              若選填方式點選「選單填寫」時，基本資料欄請使用下拉選單選擇。
            </UiPrecautionsItem>
            <UiPrecautionsItem>
              若選填方式點選「代碼填寫」時，核心素養、學習內容及學習表現請用代碼填寫。
            </UiPrecautionsItem>
            <UiPrecautionsItem>
              若需要填寫複數核心素養、學習內容及學習表現，請選擇「代碼填寫」，填寫範例請至題目填寫說明查看。
            </UiPrecautionsItem>
          </UiPrecautionsUl>
        </UiPrecautionsContent>
      </UiPrecautions>
      <UiButtonBox>
        <Button onClick={() => submitHandler()} loading={isLoading}>下載題目範本</Button>
        { educationValue && subjectValue && <Button onClick={() => downloadFile()} loading={isLoading}>下載代碼資料</Button> }
      </UiButtonBox>
    </Modal>
  );
};

SelectDownloadModal.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  modalStateHandler: PropTypes.func,
  submit: PropTypes.func
};


