import React from 'react';
import { NewQuestionList } from 'components';

/**
 * 命題入庫頁面
 */

export const NewQuestionListPage = () => {
  return (
    <NewQuestionList />
  );
};
