import React from 'react';
import { ExamSearchList } from 'components';


/**
 * 批卷登分 頁面
 */

export const ExamSearchListPage = () => {
  return (
    <ExamSearchList />
  );
};
